import { useDispatch, useSelector } from "react-redux"
import { IProspect, ReferredPatientCallStatus } from "../../../models"
import { updatePatientInfo } from "../../../store/actions/counsellor/patients.action"
import { REFERRED_CALL_STATUS_DISPLAY_MAP } from "../../generic/CallStatus"
import { StateParams } from "../../../store/reducers"
import { isCareCoordinator } from "../accessControl"

interface Props {
    isReferredPatient: boolean | undefined
    setSelectedStatus: (selectedStatus: ReferredPatientCallStatus) => void
    selectedStatus: ReferredPatientCallStatus | undefined
    prospect: IProspect
}

type SelectedCallStatus = keyof ReferredPatientCallStatus
const STATUSES_TO_EXCLUDE = ['not_accepted', 'in_progress']

const ReferredCallStatus = ({ isReferredPatient, selectedStatus, prospect, setSelectedStatus }: Props) => {
    const dispatch = useDispatch()
    const role = useSelector((state: StateParams) => state.account.role)
    if (!isReferredPatient || !selectedStatus) return null

    const isInProgress = (selectedStatus as unknown as SelectedCallStatus) === 'in_progress'

    const availableStatuses = Object.keys(REFERRED_CALL_STATUS_DISPLAY_MAP).filter(status => !STATUSES_TO_EXCLUDE.includes(status)) as SelectedCallStatus[]
    const inProgressStatus = Object.keys(REFERRED_CALL_STATUS_DISPLAY_MAP).filter(status => status === 'in_progress') as SelectedCallStatus[]
    const statuses = isInProgress ? inProgressStatus : availableStatuses


    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const status = event.target.value as unknown as ReferredPatientCallStatus
        setSelectedStatus(status)
        dispatch(updatePatientInfo(prospect.sourcePatientId, prospect.referredByPracticeId, { referredCallStatus: status }))
    }

    const renderAvailableStatuses = () => {
        return (
            <div>
                { isCareCoordinator(role) &&
                    <>
                    <label htmlFor="status" className="text-sjGray text-sm">Patient Status</label>  <br />
                    </>
                }

                <select
                    value={selectedStatus as unknown as SelectedCallStatus}
                    onChange={handleChange}
                    className="w-[153px] px-[8px] py-[4px] border-none rounded-[3px] h-[30px] bg-sjLightOrange text-sjOrange"
                >
                    {statuses.map((status) => (
                        <option key={status} value={status} disabled={isInProgress}>
                            {REFERRED_CALL_STATUS_DISPLAY_MAP[status].description}
                        </option>
                    ))}
                </select>
            </div>

        )
    }

    return (
        <div className="ml-1">
            {!STATUSES_TO_EXCLUDE.includes(selectedStatus as unknown as SelectedCallStatus) && renderAvailableStatuses()}
            {isInProgress && renderAvailableStatuses()}
        </div>
    )
}

export default ReferredCallStatus
