import {
    ACCOUNT_RESET_PASSWORD_REQUEST,
    ACCOUNT_SEND_EMAIL_REQUEST,
    ACCOUNT_SIGN_IN_REQUEST,
    ACCOUNT_VALIDATE_CODE_REQUEST,
    changePasswordFromProfileFailed,
    changePasswordFromProfileProgress,
    changePasswordFromProfileSuccess,
    CHANGE_PASSWORD_FROM_PROFILE_REQUEST,
    clearErrorMessages,
    generatedQRCode,
    generateQRCodeInProgress,
    GENERATE_QR_CODE,
    generatingQRCodeFailed,
    resetPasswordFailed,
    resetPasswordProgress,
    resetPasswordSuccess,
    sendEmailProgress,
    sendMailFailed,
    sendMailSuccess,
    sendOTPFailed,
    sendOTPInProgress,
    sendOTPSuccess,
    SEND_OTP,
    setIsTwoFactorEnabled,
    signInFailed,
    signInInProgress,
    storeAccountDetails,
    updatedPhoneNumber,
    updatePhoneNumberFailed,
    updatePhoneNumberInProgress,
    UPDATE_PHONE_NUMBER,
    validateCodeFailed,
    validateCodeProgress,
    validateCodeSuccess,
    CHANGE_DEFAULT_PASSWORD_REQUEST,
    changeDefaultPasswordProgress,
    changeDefaultPasswordFailed,
    changeDefaultPasswordSuccess,
    verificationOTPFailed,
    verifyOTPInProgress,
    VERIFY_OTP
} from "../../actions/physician/account.action";
import {call, put, takeLatest} from "@redux-saga/core/effects";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {physicianHttpClient} from "../../../lib";
import {AuthState, IAccount, IPhysicianAccount, ISendEmail} from "../../../models";
import {Either} from "monet";
import {CheckedError} from "../../../types/ServiceError";
import {getAPI, postAPI, putAPI} from "../../../lib/api-helpers";
import {signinSuccessful} from "../../actions/common";

const apiSignInAccount = (signInRequest: { email: string, password: string }) => {
    const url = `/login`
    return postAPI<IAccount, { email: string, password: string }>(
        url,
        signInRequest,
        null,
        physicianHttpClient)
}

const apiSendEmail = (sendEmailRequest: { email: string }) => {
    const url = `/reset-password/code`
    return postAPI<ISendEmail, { email: string }>(url, sendEmailRequest, null, physicianHttpClient)
}

const apiValidateCode = (validateCodeRequest: { email: string, code: string }) => {
    const url = `/reset-password/validate-code`
    return postAPI<ISendEmail, { email: string, code: string }>(url, validateCodeRequest, null, physicianHttpClient)
}

const apiResetPassword = (resetPasswordRequest: {
    email: string,
    code: string,
    providerId: string,
    password: string
}) => {
    const url = `/reset-password-with-code`
    return postAPI<IPhysicianAccount, { email: string, code: string, providerId: string, password: string }>(
        url, resetPasswordRequest, null, physicianHttpClient
    )
}

const apiChangePassword = (changePasswordRequest: { email: string, currentPassword: string, password: string }) => {
    const url = `/change-password-from-profile`
    return postAPI<IPhysicianAccount, { email: string, currentPassword: string, password: string }>(
        url, changePasswordRequest, null, physicianHttpClient
    )
}

const apiGenerateQRCode = (action: { type: string, authState: AuthState, payload: { slug: string } }) => {
    const url = `/${action.authState.accountId}/generate-qrcode/${action.payload.slug}`;
    return getAPI<{ url: string }>(url, action.authState, physicianHttpClient)
}

const apiUpdatePhoneNumber = (action: { type: string, authState: AuthState, payload: { phoneNumber: string } }) => {
    const url = `/${action.authState.accountId}/phone-number`;
    return putAPI<{ phoneNumber: string }, {
        phoneNumber: string
    }>(url, action.payload, action.authState, physicianHttpClient)
}

const apiChangeDefaultPassword = (changePasswordRequest: {
    email: string,
    currentPassword: string,
    password: string
}) => {
    const url = `/set-password`;
    return postAPI<IPhysicianAccount, { email: string, currentPassword: string, password: string }>(
        url, changePasswordRequest, null, physicianHttpClient
    )
}

const apiVerifyOTP = (verifyRequest: { otp: string, email: string, phoneNumber: string }) => {
    const url = `/verify-otp`;
    return postAPI<IAccount, { otp: string, email: string, phoneNumber: string }>(
        url,
        verifyRequest,
        null,
        physicianHttpClient)
}

const apiSendOTP = (sendOTPRequest: { email: string }) => {
    const url = `/send-otp`;
    return postAPI<boolean, { email: string }>(
        url,
        sendOTPRequest,
        null,
        physicianHttpClient)
}

function* signInAccount(action: { type: string, payload: { email: string, password: string } }) {
    try {
        console.log(`AccountPhysicianSaga:signinAccount`)
        yield put(callInProgress())
        yield put(signInInProgress());
        const eitherSignInResponse: Either<CheckedError, IPhysicianAccount> = yield call(apiSignInAccount, {...action.payload})
        if (eitherSignInResponse.isLeft()) {
            const error = eitherSignInResponse.left()
            if (error.isChecked) {
                console.log("Encountered a Checked Error", error.message)
                yield put(signInFailed({error: error.message}));
            } else {
                yield put(signInFailed({error: error.message}));
            }
        } else {
            const account = eitherSignInResponse.right();
            if (account.isTwoFactorAuthenticationEnabled) {
                console.log(`AccountSaga:signinAccount->\n otp generated`)
                yield put(setIsTwoFactorEnabled(account));
            } else {
                console.log(`AccountSaga:signinAccount->\n fetched account details: ${JSON.stringify(account)} `)
                yield put(storeAccountDetails({...account, role: account.role === 'medical_assistant' ? "Medical_Assistant" : "Physician", roleType: account.role}));
                yield put(signinSuccessful({
                    email: account.email,
                    firstName: account.name.split(" ")[0],
                    lastName: account.name.split(" ")[1],
                    role: account.role === 'medical_assistant' ? "Medical_Assistant" : "Physician",
                    id: account.id,
                    canMARefer : account.canMARefer,
                    token: account.jwt,
                    gender: "N/A",
                    permissions: account.permissions,
                    isSOAPEnabled: account.isSOAPEnabled,
                    isRegistryEnabled: account.isRegistryEnabled
                } as IAccount));
            }
        }
    } catch (e) {
        console.log(e)
        yield put(signInFailed({error: "Error signing into account!"}));
    } finally {
        yield put(callFinished())
    }
}

function* sendEmail(action: { type: string, payload: { email: string } }) {
    try {
        console.log(`AccountSaga:sendEmail`)
        yield put(callInProgress())
        yield put(sendEmailProgress());
        const eitherSendEmailResponse: Either<CheckedError, ISendEmail> = yield call(apiSendEmail, {...action.payload})
        if (eitherSendEmailResponse.isLeft()) {
            const error = eitherSendEmailResponse.left()
            if (error.isChecked) {
                console.log("Encountered a Checked Error", error.message)
                yield put(clearErrorMessages())
                yield put(sendMailFailed({error: error.message}));
            } else {
                yield put(clearErrorMessages())
                yield put(sendMailFailed({error: error.message}));
            }
        } else {
            const response = eitherSendEmailResponse.right();
            console.log(`AccountSaga:sendEmail->\n fetched account details: ${response.providerId} `)
            yield put(sendMailSuccess(response));
        }
    } catch (e) {
        console.log(e)
        yield put(sendMailFailed({error: "Error sending email!"}));
    } finally {
        yield put(callFinished())
    }
}

function* validateCode(action: { type: string, payload: { email: string, code: string } }) {
    try {
        console.log(`AccountSaga:validateCode`)
        yield put(callInProgress())
        yield put(validateCodeProgress());
        const eitherValidateCodeResponse: Either<CheckedError, ISendEmail> = yield call(apiValidateCode, {...action.payload})
        if (eitherValidateCodeResponse.isLeft()) {
            const error = eitherValidateCodeResponse.left()
            if (error.isChecked) {
                console.log("Encountered a Checked Error", error.message)
                yield put(validateCodeFailed({error: error.message}));
            } else {
                yield put(validateCodeFailed({error: error.message}));
            }
        } else {
            const response = eitherValidateCodeResponse.right();
            console.log(`AccountSaga:validateCode->\n fetched account details: ${response.providerId} `)
            yield put(validateCodeSuccess(response));
        }
    } catch (e) {
        console.log(e)
        yield put(validateCodeFailed({error: "Error while validating code!"}));
    } finally {
        yield put(callFinished())
    }
}

function* resetPassword(action: {
    type: string,
    payload: { email: string, code: string, providerId: string, password: string }
}) {
    try {
        console.log(`AccountSaga:resetPassword`)
        yield put(callInProgress())
        yield put(resetPasswordProgress());
        const eitherResetPasswordResponse: Either<CheckedError, IPhysicianAccount> = yield call(apiResetPassword, {...action.payload})
        if (eitherResetPasswordResponse.isLeft()) {
            const error = eitherResetPasswordResponse.left()
            if (error.isChecked) {
                console.log("Encountered a Checked Error", error.message)
                yield put(resetPasswordFailed({error: error.message}));
            } else {
                yield put(resetPasswordFailed({error: error.message}));
            }
        } else {
            const account = eitherResetPasswordResponse.right();
            console.log(`AccountSaga:resetPassword->\n fetched account details: ${account.jwt} `)
            yield put(storeAccountDetails({
                ...account,
                role: account.role === 'medical_assistant' ? "Medical_Assistant" : "Physician",
                roleType: account.role
            }));
            yield put(signinSuccessful({
                email: account.email,
                firstName: account.name.split(" ")[0],
                lastName: account.name.split(" ")[1],
                role: account.role === 'medical_assistant' ? "Medical_Assistant" : "Physician",
                id: account.id,
                token: account.jwt,
                gender: "N/A",
                isSOAPEnabled: account.isSOAPEnabled,

            } as IAccount));
        }
    } catch (e) {
        console.log(e)
        yield put(resetPasswordFailed({error: "Error resetting password!"}));
    } finally {
        yield put(callFinished())
    }
}

function* changePasswordFromProfile(action: {
    type: string,
    payload: { email: string, currentPassword: string, password: string }
}) {
    try {
        console.log(`AccountSaga:changePassword`)
        yield put(callInProgress())
        yield put(changePasswordFromProfileProgress());
        const eitherChangePasswordResponse: Either<CheckedError, IPhysicianAccount> = yield call(apiChangePassword, {...action.payload})
        if (eitherChangePasswordResponse.isLeft()) {
            const error = eitherChangePasswordResponse.left()
            if (error.isChecked) {
                console.log("Encountered a Checked Error", error.message)
                yield put(clearErrorMessages())
                yield put(changePasswordFromProfileFailed({error: error.message}));
            } else {
                yield put(clearErrorMessages())
                yield put(changePasswordFromProfileFailed({error: error.message}));
            }
        } else {
            const response = eitherChangePasswordResponse.right();
            console.log(`AccountSaga:changePassword->\n fetched account details: ${response.jwt} `)
            yield put(changePasswordFromProfileSuccess(response));
        }
    } catch (e) {
        console.log(e)
        yield put(changePasswordFromProfileFailed({error: "Error changing password!"}));
    } finally {
        yield put(callFinished())
    }
}

function* generateQRCode(action: { type: string, authState: AuthState, payload: { slug: string } }) {
    try {
        console.log(`AccountSaga:generatedQRCode`)
        yield put(callInProgress())
        yield put(generateQRCodeInProgress())
        const apiGeneratedQRCodeResponse: Either<CheckedError, { url: string }> = yield call(apiGenerateQRCode, action)
        if (apiGeneratedQRCodeResponse.isLeft()) {
            const error = apiGeneratedQRCodeResponse.left()
            if (error.isChecked) {
                console.log("AccountSaga: Encountered a Checked Error", error.message)
                yield put(generatingQRCodeFailed({error: error.message}));
            } else {
                yield put(generatingQRCodeFailed({error: error.message}));
            }
        } else {
            const response = apiGeneratedQRCodeResponse.right();
            yield put(generatedQRCode(response.url));
        }
    } catch (e) {
        console.log(e)
        yield put(generatingQRCodeFailed({error: "Error generating QR code!"}));
    } finally {
        yield put(callFinished())
    }
}

function* updatePhoneNumber(action: { type: string, authState: AuthState, payload: { phoneNumber: string } }) {
    try {
        console.log(`AccountSaga:updatePhoneNumber`)
        yield put(callInProgress())
        yield put(updatePhoneNumberInProgress())
        const apiUpdatePhoneNumberResponse: Either<CheckedError, {
            phoneNumber: string
        }> = yield call(apiUpdatePhoneNumber, action)
        if (apiUpdatePhoneNumberResponse.isLeft()) {
            const error = apiUpdatePhoneNumberResponse.left()
            if (error.isChecked) {
                console.log("AccountSaga: Encountered a Checked Error", error.message)
                yield put(updatePhoneNumberFailed({error: error.message}));
            } else {
                yield put(updatePhoneNumberFailed({error: error.message}));
            }
        } else {
            const response = apiUpdatePhoneNumberResponse.right();
            yield put(updatedPhoneNumber({phoneNumber: action.payload.phoneNumber}));
        }
    } catch (e) {
        console.log(e)
        yield put(updatePhoneNumberFailed({error: "Error updating phone number!"}));
    } finally {
        yield put(callFinished())
    }
}

function* verifyOTP(action: { type: string, payload: { otp: string, phoneNumber: string, email: string } }) {
    try {
        console.log(`AccountSaga:verifyOTP`)
        yield put(callInProgress())
        yield put(verifyOTPInProgress())
        const apiVerifyOTPResponse: Either<CheckedError, IPhysicianAccount> = yield call(apiVerifyOTP, action.payload)
        if (apiVerifyOTPResponse.isLeft()) {
            const error = apiVerifyOTPResponse.left()
            if (error.isChecked) {
                console.log("AccountSaga: Encountered a Checked Error while verifying", error.message)
                yield put(verificationOTPFailed({error: error.message}));
            } else {
                yield put(verificationOTPFailed({error: error.message}));
            }
        } else {
            const account = apiVerifyOTPResponse.right();
            console.log(`AccountSaga:verifyOTP->\n fetched account details: ${account.jwt} `)
            yield put(storeAccountDetails({
                ...account,
                role: account.role === 'medical_assistant' ? "Medical_Assistant" : "Physician",
                roleType: account.role
            }));
            yield put(signinSuccessful({
                email: account.email,
                firstName: account.name.split(" ")[0],
                lastName: account.name.split(" ")[1],
                role: account.role === 'medical_assistant' ? "Medical_Assistant" : "Physician",
                id: account.id,
                token: account.jwt,
                gender: "N/A",
                permissions: account.permissions
            } as IAccount));
        }
    } catch (e) {
        console.log(e)
        yield put(verificationOTPFailed({error: "Error verifying OTP!"}));
    } finally {
        yield put(callFinished())
    }
}

function* sendOTP(action: { type: string, payload: { email: string } }) {
    try {
        console.log(`AccountSaga:sendOTP`)
        yield put(callInProgress())
        yield put(sendOTPInProgress())
        const apiSendOTPResponse: Either<CheckedError, { isOTPSent: boolean }> = yield call(apiSendOTP, action.payload)
        if (apiSendOTPResponse.isLeft()) {
            const error = apiSendOTPResponse.left()
            if (error.isChecked) {
                console.log("AccountSaga: Encountered a Checked Error while sending OTP", error.message)
                yield put(sendOTPFailed({error: error.message}));
            } else {
                yield put(sendOTPFailed({error: error.message}));
            }
        } else {
            const response = apiSendOTPResponse.right();
            yield put(sendOTPSuccess({isOTPSent: response.isOTPSent}));
        }
    } catch (e) {
        console.log(e)
        yield put(sendOTPFailed({error: "Error sending OTP!"}));
    } finally {
        yield put(callFinished())
    }
}

function* changeDefaultPassword(action: {
    type: string,
    payload: { email: string, currentPassword: string, password: string }
}) {
    try {
        console.log(`Physician AccountSaga :: changeDefaultPassword`);
        yield put(callInProgress())
        yield put(changeDefaultPasswordProgress());
        const eitherChangePasswordResponse: Either<CheckedError, IPhysicianAccount> = yield call(apiChangeDefaultPassword, {...action.payload})
        if (eitherChangePasswordResponse.isLeft()) {
            const error = eitherChangePasswordResponse.left()
            if (error.isChecked) {
                console.log("Encountered a Checked Error", error.message)
                yield put(clearErrorMessages())
                yield put(changeDefaultPasswordFailed({error: error.message}));
            } else {
                yield put(clearErrorMessages())
                yield put(changeDefaultPasswordFailed({error: error.message}));
            }
        } else {
            const response = eitherChangePasswordResponse.right();
            console.log(`Physician AccountSaga :: changeDefaultPassword :: fetched account details`);
            yield put(changeDefaultPasswordSuccess(response));  
        }
    } catch (e) {
        console.log(e)
        yield put(changeDefaultPasswordFailed({error: "Error changing password!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* accountSaga() {
    yield takeLatest(ACCOUNT_SIGN_IN_REQUEST, signInAccount)
    yield takeLatest(ACCOUNT_SEND_EMAIL_REQUEST, sendEmail)
    yield takeLatest(ACCOUNT_VALIDATE_CODE_REQUEST, validateCode)
    yield takeLatest(ACCOUNT_RESET_PASSWORD_REQUEST, resetPassword)
    yield takeLatest(CHANGE_PASSWORD_FROM_PROFILE_REQUEST, changePasswordFromProfile)
    yield takeLatest(CHANGE_DEFAULT_PASSWORD_REQUEST, changeDefaultPassword)
    yield takeLatest(GENERATE_QR_CODE, generateQRCode)
    yield takeLatest(UPDATE_PHONE_NUMBER, updatePhoneNumber)
    yield takeLatest(VERIFY_OTP, verifyOTP)
    yield takeLatest(SEND_OTP, sendOTP)
}
