import {Checkbox, Input, Radio, Textarea} from '@material-tailwind/react'
import _ from 'lodash'
import {DateTime} from 'luxon'
import React, {useCallback, useEffect, useState} from 'react'
import {
    Control,
    Controller,
    FieldArrayWithId,
    FieldErrorsImpl,
    UseFieldArrayRemove,
    UseFormGetValues,
    UseFormRegister,
    UseFormReset,
    UseFormWatch
} from "react-hook-form"
import {batch, shallowEqual, useDispatch, useSelector} from "react-redux"
import {IProspect, IReferenceData, ISelect, NotesStatus, SessionTypes} from '../../../models'
import {intakeFormSave} from '../../../store/actions/counsellor/prospects.action'
import {StateParams} from '../../../store/reducers'
import strengthsAndLimitations from '../../../assets/data/strengthsAndLimitations.json';
import RadioWithTextComponent from './RadioWithTextComponent'
import gradeData from '../../../assets/data/grade.json';
import {determineKey, Inputs} from './index'
import SocialDeterminantsHealth from './SocialDeterminantsHealth'
import MentalExam from './MentalExam'
import TraumaAbove18V2 from './TraumaAbove18V2'
import {TraumaUnder18} from '../../prospects/TraumaUnder18'
import CreatableSelect from '../../generic/CreatableSelect'
import {useModalContext} from '../../../context/ModalContext'
import ReactModal from 'react-modal'
import {Button} from '../../generic/Buttons'
import {useStatusChange} from '../../../hooks/useStatusChange'
import {toast} from 'react-toastify'
import {AiFillCloseCircle} from 'react-icons/ai'
import ConfirmationModal from '../ConfirmationModal'
import {
    canAccessPsychiatristNotes,
    canAddPsychiatristNotes,
    isCounsellorNotesLocked,
    isNotesLocked,
    isPsychiatrist
} from '../accessControl'
import PsychiatristNotes from './PsychiatristNotes'
import Addendum from '../Addendum'
import {SavedStatusLabel} from "../../generic/SavedStatusLabel";

type Props = {
    prospect: IProspect
    canEdit: boolean
    register: UseFormRegister<Inputs>
    control: Control<Inputs, any>
    watch: UseFormWatch<Inputs>
    errors: Partial<FieldErrorsImpl<Inputs>>
    reset: UseFormReset<Inputs>
    addendums: FieldArrayWithId<Inputs, "newAddendum", "id">[]
    remove: UseFieldArrayRemove
    getValues: UseFormGetValues<Inputs>
    sourceRefData: IReferenceData
    notesStatus?: NotesStatus
}

// Set the app element before rendering any modals
ReactModal.setAppElement('#root');

const IntakeComponentV2: React.FC<Props> = ({ notesStatus, sourceRefData, register, control, watch, errors, reset, prospect, canEdit, addendums, remove, getValues  }) => {
    const dispatch = useDispatch()
    const intakeForm = useSelector((state: StateParams) => state.prospects.intakeForm)
    const aceScreener = useSelector((state: StateParams) => state.prospects.aceScreener, shallowEqual)
    const role = useSelector((state: StateParams) => state.account.role, shallowEqual)
    const [targetDate, setTargetDate] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [medicationsList, setMedicationsList] = useState<any>()
    const [medicationsListPrior, setMedicationsListPrior] = useState<any>()
    const selectedLimitations = watch('limitations.selected')
    const selectedStrengths = watch('strengths.selected')
    const { isModalOpen, toggleModal } = useModalContext()
    const dispatchNotesStatusChange = useStatusChange(SessionTypes.Intake)

    const saveNotes = useCallback(_.debounce((key, answer) => dispatch(intakeFormSave(prospect.id, { patientIntakeNotes: { [key]: answer } })), 500), [])

    const isPatientAbove18 = () => {
        const dobDateTime = DateTime.fromJSDate(new Date(prospect.dob))
        const age = dobDateTime.diffNow('years').negate().years;
        return age > 18
    }

    useEffect(() => {
        const subscription = watch(async (value, { name, type }) => {
            if (name && type === 'change') {
                const extendedName = name.split('.').length > 0 ? name.split('.')[0] : name
                const key = determineKey(extendedName)
                const answer = value[key]
                saveNotes(key, answer)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const renderErrorMessage = (message: string | null | undefined = 'This field is required.') => message && <p className="error-msg">{message}</p>

    useEffect(() => {
        if (sourceRefData) {
            const medications = sourceRefData.additionalInfo?.patientMedicationsHistory?.selected;
            const fromCurrentPhysician = sourceRefData.additionalInfo?.currentMedications?.selected;

            const medicationResult = sourceRefData.result.filter(x => x.key === 'medications')[0]
            const medicationResultCurrentCreated = fromCurrentPhysician && (fromCurrentPhysician as string[]).map(saved => ({
                label: saved, value: saved
            })) || []
            const medicationResultPriorCreated = medications && (medications as string[]).map(saved => ({
                label: saved, value: saved
            })) || []
            if(medicationResult.key) {
                const completeResultSetForCurrent = [...medicationResult.label as ISelect[], ...medicationResultCurrentCreated]
                const completeResultSetForPrior = [...medicationResult.label as ISelect[], ...medicationResultPriorCreated]
                setMedicationsList(completeResultSetForCurrent)
                setMedicationsListPrior(completeResultSetForPrior)
            }

            if(prospect.intakeTargetDate) setTargetDate(DateTime.fromISO(prospect.intakeTargetDate.toString()).toFormat('MM/dd/yyyy'))
            setIsLoading(false)
            reset(intakeForm?.patientIntakeNotes || sourceRefData.additionalInfo)
        }
    }, [sourceRefData])

    const handleStatusChange = (status: NotesStatus) => {
        const id = prospect.id
        const documentId = sourceRefData?.noteId
        if (id && documentId) {
            batch(() => {
                dispatchNotesStatusChange(status, documentId, id)
                if (status === NotesStatus.LOCKED) {
                    toast("Notes has been locked successfully.")
                    setShowConfirmationModal(false)
                    toggleModal()
                } else {
                    toast("Notes has been sent for review successfully.")
                }
            })
        }
    }

    const submitPsychiatristNotes = (canContinue: boolean = false) => {
        if(canContinue) handleStatusChange(NotesStatus.LOCKED)
        else setShowConfirmationModal(true)
    }

    const renderFunctionImpairments = () => <fieldset className={'divide-y-4'} disabled={!canEdit}>
        <div className="py-4">
            <SavedStatusLabel labelKey={"physicianNotes"} labelText={"Additional Physician Notes"} reducer={"prospects"} notesKey={"intakeNotesSavedKeys"} classes={"text-lg mt-3"}/>
            <Textarea className="outline-none rounded-md" placeholder=""
                {...register('physicianNotes')} />
        </div>
        <div className="py-4">
            <label className="text-md">SYMPTOMS & FUNCTIONAL IMPAIRMENT: </label>
            <div>
                {errors.symptoms?.selected && renderErrorMessage(errors.symptoms.selected.message)}
                {errors.problems?.selected && renderErrorMessage(errors.problems.selected.message)}
            </div>
            <div className='grid grid-cols-2'>
                {sourceRefData && sourceRefData.result.filter(x => x.key === 'symptoms').map(result => (
                    <>
                        <div >
                            {typeof result.label === 'string' &&
                                // <label key={result.key} className="block mt-3 text-md">{result.label}:</label>
                                <SavedStatusLabel labelKey={"symptoms"} labelText={result.label} reducer={"prospects"} notesKey={"intakeNotesSavedKeys"} classes={"block mt-3 text-md"}/>
                            }
                            <div>
                                {result.data?.map(field => (
                                    <div>
                                        {field.key !== 'other' && <Checkbox label={field.label} key={field.key} id={field.label}
                                        {...register('symptoms.selected')} value={field.label} />}
                                    </div>
                                ))}
                            </div>
                            <div className="ml-12 mt-3">
                                <label>Others</label>
                                <input type="text" className="w-1/2 outline-none rounded-md block" {...register('symptoms.other')} />
                            </div>
                        </div>
                    </>
                ))}
                {sourceRefData && sourceRefData.result.filter(x => x.key === 'problems').map(result => (
                    <>
                        <div >
                            {typeof result.label === 'string' &&
                                <SavedStatusLabel labelKey={"problems"} labelText={result.label} reducer={"prospects"} notesKey={"intakeNotesSavedKeys"} classes={"block mt-3 text-md"}/>
                            }
                            <div>
                                {result.data?.map(field => (
                                    <div>
                                        {field.key !== 'other' && <Checkbox label={field.label} key={field.key} id={field.label} {...register('problems.selected')}
                                            value={field.label} />}
                                    </div>
                                ))}
                            </div>
                            <div className="ml-12 mt-3">
                                <label>Others</label>
                                <input type="text" className="w-1/2 outline-none rounded-md block" {...register('problems.other')} />
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
        <div className="py-4">
            <label className="text-lg mt-3">Did the caregiver and /or patient provide verbal consent to be referred to CoCM?</label>
            <div className="grid grid-rows-1 grid-flow-col ">
                <Radio id="isConsentGiven" {...register("isConsentGiven")} label="Yes" value="Yes" />
                <Radio id="isConsentGiven1" {...register("isConsentGiven")} label="Patient would like to continue behavioural health services with another provider" value="Patient would like to continue behavioural health services with another provider" />
            </div>
        </div>
        <div className="py-4">
            <label className="text-lg mt-3">PRESENTING PROBLEM / CHIEF COMPLAINT:</label>
            {errors.presentChiefComplaint && renderErrorMessage(errors.presentChiefComplaint.message)}
            <Textarea className="outline-none rounded-md" placeholder="Describe the problem / complaint of the patient in detail"
                {...register('presentChiefComplaint')} />
        </div>
    </fieldset>

    const renderIntakeNotesAboveTraumSection = () => <fieldset className={'divide-y-4'} disabled={!canEdit}>
        <div className="py-4">
            <label className="text-lg mt-6 text-sjOrange uppercase">h/o Psychiatric illness:</label>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'psychiatricIllness' as any} inputText={'psychiatricIllnessOther' as any} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="No" textPlaceholder="Describe any known psychiatric illnesses within the biological maternal/paternal history"
            />}
        </div>
        <div className="py-4">
            <label className="text-lg mt-6 text-sjOrange uppercase">h/o Committed Suicide:</label>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'committedSuicide' as any} inputText={'committedSuicideOther' as any} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="No" textPlaceholder="Describe any known psychiatric illnesses within the biological maternal/paternal history"
            />}
        </div>
        <div className="py-4">
            <label className="text-lg mt-6 text-sjOrange uppercase">h/o Substance abuse: </label>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'substanceAbuse' as any} inputText={'substanceAbuseOther' as any} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="No" textPlaceholder="Describe any known psychiatric illnesses within the biological maternal/paternal history"
            />}
        </div>
        <div className="py-4">
            <label className="text-lg">HISTORY OF PRESENT PROBLEM: </label>
            {errors.historyPresentProblem && renderErrorMessage(errors.historyPresentProblem.message)}
            <div className="mt-2">
                <Textarea className="outline-none rounded-md" {...register('historyPresentProblem')}
                    placeholder="Symptom onset, duration, and frequency" />
            </div>
        </div>
        <div className="py-4">
            <label className="text-lg">PAST PSYCHIATRIC HISTORY</label>
            {errors.diagnosis?.selected && renderErrorMessage(errors.diagnosis.selected.message)}
            {sourceRefData && sourceRefData.result.filter(x => x.key === 'diagnosis').map(result => (
                <>
                    <div>
                        <label key={result.key} className="block mt-3 text-md text-sjOrange">PRIOR DIAGNOSES FROM PHYSICIAN:</label>
                        <div className="grid grid-cols-2">
                            {result.data?.map(field => (
                                <div>
                                    {field.key !== 'other' && <Checkbox label={field.label} key={field.key} id={field.label} {...register('diagnosis.selected')}
                                        value={field.label} />}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="ml-12 mt-3">
                        <label>Others</label>
                        <input type="text" className="w-1/4 outline-none rounded-md block" {...register('diagnosis.other')}/>
                    </div>
                </>
            ))}
        </div>
        <div className="py-4">
            <label className="text-lg text-sjOrange">PRIOR/CURRENT PSYCHIATRIST’S INFORMATION:</label>
            <div className="grid grid-cols-6 gap-4">
                <Radio id="priorCurrentPsychiatristInfo" {...register("priorCurrentPsychiatristInfo")} label="None" value="none" />
                <Radio id="priorCurrentPsychiatristInfo1" {...register("priorCurrentPsychiatristInfo")} label="Prior" value="prior" />
                <Radio id="priorCurrentPsychiatristInfo2" {...register("priorCurrentPsychiatristInfo")} label="Current" value="current" />
            </div>
            <div className='grid grid-cols-2 gap-6 pt-4' hidden={watch('priorCurrentPsychiatristInfo') !== 'none'}>
                <div>
                    <label>Name</label>
                    {errors.psychiatristInfo?.name && renderErrorMessage()}
                    <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register('psychiatristInfo.name',
                    { disabled: watch('priorCurrentPsychiatristInfo') === 'none' })} placeholder="Enter Name" />
                </div>
                <div>
                    <label>Contact Number</label>
                    {errors.psychiatristInfo?.contactNo && renderErrorMessage()}
                    <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register('psychiatristInfo.contactNo',
                        { disabled: watch('priorCurrentPsychiatristInfo') === 'none' })} placeholder="Enter Contact Number" />
                </div>
            </div>
        </div>
        <div className="py-4">
            <label className="text-lg text-sjOrange uppercase">Prior/Current Counselor’s INFORMATION:</label>
            <div className="grid grid-cols-6 gap-4">
                <Radio id="priorCurrentCounselor" {...register("priorCurrentCounselor")} label="None" value="none" />
                <Radio id="priorCurrentCounselor1" {...register("priorCurrentCounselor")} label="Prior" value="prior" />
                <Radio id="priorCurrentCounselor2" {...register("priorCurrentCounselor")} label="Current" value="current" />
            </div>
            <div className='grid grid-cols-2 gap-6 pt-4' hidden={watch('priorCurrentCounselor') === 'none'}>
                <div>
                    <label>Name</label>
                    {errors.counselorInfo?.name && renderErrorMessage()}
                    <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register('counselorInfo.name',
                        { disabled: watch('priorCurrentCounselor') === 'none' })} placeholder="Enter Name" />
                </div>
                <div>
                    <label>Contact Number</label>
                    {errors.counselorInfo?.contactNo && renderErrorMessage()}
                    <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register('counselorInfo.contactNo',
                        { disabled: watch('priorCurrentCounselor') === 'none' })} placeholder="Enter Contact Number" />
                </div>
            </div>
        </div>
        <div className="mb-4 pt-4">
            <label className="text-lg text-sjOrange">HAS THERE BEEN ANY PSYCHIATRIC HOSPITALIZATIONS:</label>
            <div className="grid grid-cols-4 gap-4">
                <Radio id="psychiatricHospitalization" {...register("psychiatricHospitalization")} label="Yes" value="Yes" />
                <Radio id="psychiatricHospitalization1" {...register("psychiatricHospitalization")} label="No" value="No" />
            </div>
            <div hidden={watch('psychiatricHospitalization') !== 'Yes'}>
                <div className="grid grid-cols-4 gap-4">
                    <Radio id="psychiatricHospitalizationTimeSpan" {...register("psychiatricHospitalizationTimeSpan")} label="Within the last six month" value="withInSixMonths" />
                    <Radio id="psychiatricHospitalizationTimeSpan1" {...register("psychiatricHospitalizationTimeSpan")} label="Over six months ago" value="overSixMonths" />
                </div>
                <div>
                    <Textarea placeholder="Type answer"
                        className="outline-none rounded-md" {...register('psychiatricHospitalizationTimeSpanOther')} />
                </div>
            </div>
        </div>
        <div className="mb-4 pt-4">
            <label className="text-lg text-sjOrange uppercase">Self-Injurious Behaviors:</label>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'selfInjuriousBehavior' as any} inputText={'selfInjuriousBehaviorOther' as any} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="No" textPlaceholder="N/A"
            />}
        </div>
        <div className="mb-4 pt-4">
            <label className="text-lg text-sjOrange uppercase">Violence Towards Others:</label>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'violenceTowardsOthers' as any} inputText={'violenceTowardsOthersOther' as any} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="No" textPlaceholder="N/A"
            />}
        </div>
        <div className="pb-4 pt-4">
            <label className="text-lg text-sjOrange uppercase">Psychotropic Meds: </label>
            <div className="grid">
                <label className="block text-md mt-4">From Physician (current): </label>
                {errors.currentMedications?.selected && renderErrorMessage(errors.currentMedications.selected.message)}
                {!isLoading && <Controller
                    control={control}
                    name='currentMedications.selected'
                    render={({ field }) => (
                        <CreatableSelect list={medicationsList} field={field} disabled={!canEdit}/>
                    )}
                />}
            </div>
            <div className="mt-8">
                <label className="block text-md">From Physician (prior): </label>
                {errors.patientMedicationsHistory?.selected && renderErrorMessage(errors.patientMedicationsHistory.selected.message)}
                {!isLoading && <Controller
                    control={control}
                    name='patientMedicationsHistory.selected'
                    render={({ field }) => (
                        <CreatableSelect list={medicationsListPrior} field={field} disabled={!canEdit}/>
                    )}
                />}
            </div>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'priorPhysician.selected' as any} inputText={'psychotropicMeds.description' as any} data={[{ label: 'None', value: 'none' }, { label: 'Current', value: 'current' }, { label: 'Prior', value: 'prior' }]}
                hasToDisableOn="none" textPlaceholder="Add additional medication(s) and dosage discussed in the session"
            />}
        </div>
    </fieldset>

    const renderIntakeNotes = () => <fieldset className={'divide-y-4'} disabled={!canEdit}>
        <div className="mb-4">
            {sourceRefData && sourceRefData.result.filter(x => x.key === 'comorbidities').map(result => (
                <>
                    <div>
                        <label key={result.key} className="text-lg mt-6 uppercase">Comorbidities</label>
                        {errors.comorbidities?.selected && renderErrorMessage(errors.comorbidities.selected.message)}
                        {result.data?.map(field => (
                            <div className="grid grid-cols-1">
                                <Checkbox label={field.label} key={field.key} id={field.label} {...register('comorbidities.selected')}
                                    value={field.label} />
                            </div>
                        ))}
                    </div>
                    <div className="ml-12">
                        <label>Others</label>
                        <input type="text" className="mt-3 w-1/2 outline-none rounded-md block" {...register('comorbiditiesOther')}/>
                    </div>
                </>
            ))}
        </div>
        <div className="pt-4 mb-4">
            <label className="text-lg mt-6 uppercase">Allergies</label>
            <label className="text-md mt-3 block">(including medication allergies)</label>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'allergies.selected' as any} inputText={'allergies.other' as any} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="no"
            />}
        </div>
        <div className="mb-4">
            {<RadioWithTextComponent {...{ register, watch }} question={'Alcohol:'}
                input={'alcohol'} inputText={'alcoholOther'} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="No"
            />}
        </div>
        <div className="mb-4">
            {<RadioWithTextComponent {...{ register, watch }} question={'Illicit drugs:'}
                input={'illicitDrugs'} inputText={'illicitDrugsOther'} data={[{label: 'Yes', value:'Yes'}, {label: 'No', value: 'No'}]}
                hasToDisableOn="No"
            />}
        </div>
        <div className="pt-4 mb-4">
            <label className="text-lg uppercase">DEVELOPMENTAL delays:</label>
            {<RadioWithTextComponent {...{ register, watch }} question={''}
                input={'developmentalDelays'} inputText={'developmentalDelaysOther'} data={[{label: 'Yes', value:'yes'}, {label: 'No', value: 'no'}]}
                hasToDisableOn="no"
            />}
        </div>
        <div className="pt-4 mb-4">
            <label className="text-lg pt-4 uppercase">Behaviors:</label>
            {errors.behaviors?.home && renderErrorMessage(errors.behaviors.home.message)}
            <label className="text-lg pt-4 block text-sjOrange">Behavior at home:</label>
            <div className="grid pt-4">
                <Textarea placeholder="Describe patient's overall behavior, personality and temperament at home"
                    className="outline-none rounded-md" {...register('behaviors.home')} />
            </div>
        </div>
        <div className="pt-4 mb-4">
            <label className="text-lg uppercase text-sjOrange">Social behaviors:</label>
            {errors.behaviors?.social && renderErrorMessage(errors.behaviors.social.message)}
            <div className="grid pt-4">
                <Textarea placeholder="Type here" className="outline-none rounded-md" {...register('behaviors.social')} />
            </div>
        </div>
        <div className="pt-4 mb-4">
            <label className="text-lg uppercase">EDUCATIONAL HISTORY:</label>
            <label className="text-lg mt-3 block text-sjOrange">Grade
                <label className="text-md ml-1">(Applicable for School Age population): </label>
            </label>
            <select className="mt-3 outline-none rounded-md w-1/2" {...register('educationHistory')}>
                {gradeData.map(x => <option value={x.value}>{x.label}</option>)}
            </select>
        </div>
        <div className="mb-4">
            {<RadioWithTextComponent {...{ register, watch }} question={'CURRENT EDUCATIONAL SUPPORT:'}
                input={'currentEducationalSupport'} inputText={'other'} data={[{label: 'No', value: 'no'}, {label: '504 Plan', value: '504'}, {label: 'IEP', value:'IEP'}]}
                hasToDisableOn="no"
            />}
        </div>
        <div className="mb-4">
            {<RadioWithTextComponent {...{ register, watch }} question={'h/o educational support:'}
                input={'hoEducationalSupport'} inputText={'hoEducationSupportOther'} data={[{label: 'No', value: 'no'}, {label: '504 Plan', value: '504'}, {label: 'IEP', value:'IEP'}]}
                hasToDisableOn="no"
            />}
        </div>
        <div className="mb-4">
            {<RadioWithTextComponent {...{ register, watch }} question={'Fights/Suspensions:'}
                input={'fightsSuspensions'} inputText={'fightsSuspensionsOther'} data={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                hasToDisableOn="no"
            />}
        </div>
        <div className="pt-4 mb-4">
            <label className="text-lg uppercase">LEGAL HISTORY:</label>
            {<RadioWithTextComponent {...{ register, watch }} question={'History of arrest, probation, sentencing, DUIs, incarceration, or litigation?'}
                input={'legalHistory'} inputText={'legalHistoryOther'} data={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                hasToDisableOn="no"
            />}
        </div>
        <div className="pt-4 mb-2">
            {strengthsAndLimitations.filter(x => x.key === 'strengths').map(x => (
                <>
                    <label className="text-lg mt-6 uppercase" key={x.key}>{x.label}</label>
                    {errors.strengths?.selected && renderErrorMessage(errors.strengths.selected.message)}
                    <div className="grid grid-cols-1 pr-12 mt-3">
                        {x.fields.map(field => (
                            <>
                                {field.type === 'checkbox' &&
                                    <Checkbox label={field.label} key={field.key+'1'} id={field.label+'strengths'} {...register('strengths.selected')}
                                        value={field.label} />
                                }
                                {
                                    field.type === 'text' && <Input className="intake-input ml-11 outline-none" placeholder={field.placeholder} id={field.label}
                                    {...register('strengths.other', {disabled: !(selectedStrengths && selectedStrengths.includes('other'))})}/>
                                }
                            </>
                        ))}
                    </div>
                </>
            ))}
        </div>
        <div className="pt-4 mb-2">
            {strengthsAndLimitations.filter(x => x.key === 'limitations').map(x => (
                <>
                    <label className="text-lg mt-6 uppercase" key={x.key}>{x.label}</label>
                    {errors.limitations?.selected && renderErrorMessage(errors.limitations.selected.message)}
                    <div className="grid grid-cols-1 pr-12 mt-3">
                        {x.fields.map(field => (
                            <>
                                {field.type === 'checkbox' &&
                                    <Checkbox label={field.label} key={field.key+'1'} id={field.label+'limitations'} {...register('limitations.selected')}
                                        value={field.label} />
                                }
                                {
                                    field.type === 'text' && <Input className="intake-input ml-11 outline-none" placeholder={field.placeholder} id={field.label}
                                    {...register('limitations.other', {disabled: !(selectedLimitations && selectedLimitations.includes('other'))})}/>
                                }
                            </>
                        ))}
                    </div>
                </>
            ))}
        </div>
        <div className="mt-2 mb-2">
            <label className="text-lg uppercase">OTHER INFORMATION:</label>
            <label className="block text-lg mt-6 text-sjOrange uppercase">PARTICIPANTS:</label>
            {errors.participants && renderErrorMessage(errors.participants.message)}
            <Textarea {...register("participants")} className="mt-3 outline-none rounded-md"
                placeholder="Patient was present with caregiver." />
        </div>
    </fieldset>

    const renderCC = () => <fieldset disabled={!canEdit}>
        <div className='mt-2 mb-2'>
            <label className="block text-lg mt-6 uppercase">
                Do you want to accept this patient for collaborative care?
            </label>
            <div className="flex justify-start">
                <Radio id="isConsentGivenCollaborativeCare" {...register("isConsentGivenCollaborativeCare")} className="" label="Yes" value="Yes" />
                <Radio id="isConsentGivenCollaborativeCare1" {...register("isConsentGivenCollaborativeCare")} className="" label="No" value="No" />
            </div>
            {errors.isConsentGivenCollaborativeCare && renderErrorMessage(errors.isConsentGivenCollaborativeCare.message)}
            <div className="mt-2 mb-2">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Recommendation notes:</label>
                {errors.recommendationNotes && renderErrorMessage(errors.recommendationNotes.message)}
                <Textarea {...register("recommendationNotes")} className="mt-3 outline-none rounded-md"
                    placeholder="Describe patient's overall behavior, personality and temperament at home" />
            </div>
        </div>
        <div className="mt-2 mb-2">
            <label className="block text-lg mt-6 uppercase">Target Date:</label>
            <input type="text" className="mt-3 outline-none rounded-md bg-gray-300" {...register("targetDate", { value: targetDate })} disabled={true} />
        </div>
    </fieldset>

    const renderPatientEncounterTime = () => <fieldset disabled={!canEdit}>
        <div className="grid grid-rows-3 grid-flow-col gap-6 mt-2 mb-2">
            <div className="row-span-3">
                <label className="block text-lg mt-6 uppercase">Clinical session time</label>
                <select {...register("clinicalDuration")} className="mt-3 outline-none rounded-md" >
                    <option value="20"> 20 minutes</option>
                    <option value="30"> 30 minutes</option>
                    <option value="45" selected> 45 minutes</option>
                    <option value="60"> 60 minutes</option>
                </select>
            </div>
            <div id="documentationType" className="row-span-3">
                <label className="block text-lg mt-6 uppercase">Documentation time</label>
                <select {...register("documentationTime")} className="mt-3 outline-none rounded-md" >
                    <option value="20"> 20 minutes</option>
                    <option value="30" selected> 30 minutes</option>
                    <option value="40"> 40 minutes</option>
                </select>
            </div>
        </div>
    </fieldset>

    const renderSaveButton = () => <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={() => toast("Notes has been saved successfully.")}>Save</Button>

    const renderPsychiatricNotesHeader = () => <>
        <div className='cursor-pointer mr-5'>
            {canAddPsychiatristNotes(notesStatus, role) &&
                <>
                    {renderSaveButton()}
                    <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={() => submitPsychiatristNotes(false)}>Submit</Button>
                </>}
        </div>
        <div onClick={toggleModal} className="cursor-pointer absolute top-0 right-0 m-1">
            <AiFillCloseCircle className="text-gray-500 hover:text-gray-700" style={{ width: '25px', height: '25px' }} />
        </div></>

    return (<>
        <section id={"prospect-meeting-link"} style={{ backgroundColor: '#FFFFFF' }}
            className={`w-full intake-section mt-4 p-10  min-h-[25vh] rounded-lg shadow-lg`}>
            <div className={'divide-y-4'}>
                {renderFunctionImpairments()}
                <MentalExam {...{ register, watch, errors }} renderErrorMessage={renderErrorMessage} canEdit={canEdit} />
                <SocialDeterminantsHealth {...{ control, register, watch, errors }} renderErrorMessage={renderErrorMessage} canEdit={canEdit} />
                {renderIntakeNotesAboveTraumSection()}
                {(isPatientAbove18() ?
                    <TraumaAbove18V2 {...{ register, watch, control }} canEdit={canEdit} /> :
                    (aceScreener && aceScreener.answeredQuestions.length > 0) && <TraumaUnder18 key={aceScreener.aceScreenerSlug} aceScreener={aceScreener} />)
                }
                {renderIntakeNotes()}
                {renderCC()}
                {renderPatientEncounterTime()}
                <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={true}
                    className={"relative top-5 mx-auto p-5 border w-3/4 h-auto shadow-lg rounded-md bg-white"}
                >
                    <ConfirmationModal isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}
                        onConfirm={() => submitPsychiatristNotes(true)}
                        key={'psychiatristSubmitConfirmation'}>
                        <p className="text-sm text-gray-500">The notes will become non-editable and will be submitted for billing</p>
                    </ConfirmationModal>
                    <PsychiatristNotes {...{ register, watch, reset, control, errors }} renderErrorMessage={() => ''} canEdit={canEdit}>
                        <div>{renderPsychiatricNotesHeader()}</div>
                    </PsychiatristNotes>
                </ReactModal>
            </div>
        </section>
        {canAccessPsychiatristNotes(notesStatus, false) && <section style={{ backgroundColor: '#FFFFFF' }}
            className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
            <PsychiatristNotes {...{ register, watch, reset, control, errors }} renderErrorMessage={() => ''} canEdit={canEdit} />
        </section>}

        {isNotesLocked(notesStatus) && isPsychiatrist(role)
            && (sourceRefData?.additionalInfo.addendums || addendums.length > 0 ||
                intakeForm?.patientIntakeNotes?.addendums)
            && <section style={{ backgroundColor: '#FFFFFF' }}
                className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
                <Addendum {...{ register, control, addendums, remove, getValues }} saveNotes={saveNotes} />
            </section>}

        {isCounsellorNotesLocked(notesStatus, role) && (sourceRefData?.additionalInfo.addendums || addendums.length > 0 ||
            intakeForm?.patientIntakeNotes?.addendums)
            && <section style={{ backgroundColor: '#FFFFFF' }}
                className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
                <Addendum {...{ register, control, addendums, remove, getValues }} saveNotes={saveNotes} />
            </section>}
      </>)
}

export default IntakeComponentV2
