import { AnyAction } from "redux";
import {
    AggregatePatientSummaryByLocation,
    ClinicalConditionsResponse,
    clinicalImprovementMetricsResponse,
    IRegistryCocmPatientStatusFilter,
    PatientSummaryResponseType,
    RegistryClinicalConditionDistributionResponse,
    RegistryNotesMetricsResponse,
    RegistryPatientTreatmentMetricsResponse,
    RegistryStaticsCardResponse,
    ScreenerCompletionRate,
} from "../../../models";
import { DashboardFilters } from "../../../screens/dashboard/physician/PhysicianDashboardScreen";

export const FETCH_DASHBOARD_PATIENTS_SUMMARY = "FETCH_DASHBOARD_PATIENTS_SUMMARY"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS = "FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS = "FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED = "FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED"

export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION = "FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS = "FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS = "FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED = "FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED"

export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE = "FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE"
export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS = "FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS"
export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS = "FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS"
export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED = "FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED"

export const FETCH_REGISTRY_STATICS_CARD = "FETCH_REGISTRY_STATICS_CARD"
export const FETCH_REGISTRY_STATICS_CARD_IN_PROGRESS = "FETCH_REGISTRY_STATICS_CARD_IN_PROGRESS"
export const FETCH_REGISTRY_STATICS_CARD_SUCCESS = "FETCH_REGISTRY_STATICS_CARD_SUCCESS"
export const FETCH_REGISTRY_STATICS_CARD_FAILED = "FETCH_REGISTRY_STATICS_CARD_FAILED"

export const FETCH_REGISTRY_NOTES_METRICS = "FETCH_REGISTRY_NOTES_METRICS"
export const FETCH_REGISTRY_NOTES_METRICS_IN_PROGRESS = "FETCH_REGISTRY_NOTES_METRICS_IN_PROGRESS"
export const FETCH_REGISTRY_NOTES_METRICS_SUCCESS = "FETCH_REGISTRY_NOTES_METRICS_SUCCESS"
export const FETCH_REGISTRY_NOTES_METRICS_FAILED = "FETCH_REGISTRY_NOTES_METRICS_FAILED"

export const FETCH_CLINICAL_IMPROVEMENT_METRICS = "FETCH_CLINICAL_IMPROVEMENT_METRICS"
export const FETCH_CLINICAL_IMPROVEMENT_METRICS_IN_PROGRESS = "FETCH_CLINICAL_IMPROVEMENT_METRICS_IN_PROGRESS"
export const FETCH_CLINICAL_IMPROVEMENT_METRICS_SUCCESS = "FETCH_CLINICAL_IMPROVEMENT_METRICS_SUCCESS"
export const FETCH_CLINICAL_IMPROVEMENT_METRICS_FAILED = "FETCH_CLINICAL_IMPROVEMENT_METRICS_FAILED"

export const FETCH_REGISTRY_BASELINE_METRICS = "FETCH_REGISTRY_BASELINE_METRICS"
export const FETCH_REGISTRY_BASELINE_METRICS_IN_PROGRESS = "FETCH_REGISTRY_BASELINE_METRICS_IN_PROGRESS"
export const FETCH_REGISTRY_BASELINE_METRICS_SUCCESS = "FETCH_REGISTRY_BASELINE_METRICS_SUCCESS"
export const FETCH_REGISTRY_BASELINE_METRICS_FAILED = "FETCH_REGISTRY_BASELINE_METRICS_FAILED"

export const FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION = "FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION"
export const FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_IN_PROGRESS = "FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_IN_PROGRESS"
export const FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_SUCCESS = "FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_SUCCESS"
export const FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_FAILED = "FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_FAILED"

export const FETCH_REGISTRY_CLINICAL_CONDITIONS = "FETCH_REGISTRY_CLINICAL_CONDITIONS"
export const FETCH_REGISTRY_CLINICAL_CONDITIONS_IN_PROGRESS = "FETCH_REGISTRY_CLINICAL_CONDITIONS_IN_PROGRESS"
export const FETCH_REGISTRY_CLINICAL_CONDITIONS_SUCCESS = "FETCH_REGISTRY_CLINICAL_CONDITIONS_SUCCESS"
export const FETCH_REGISTRY_CLINICAL_CONDITIONS_FAILED = "FETCH_REGISTRY_CLINICAL_CONDITIONS_FAILED"

export const fetchPatientsSummary = (filters: DashboardFilters): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SUMMARY, payload: { filters } }
}
export const fetchPatientsSummaryFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED, payload }
}
export const fetchPatientsSummaryInProgress = (): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS }
}
export const fetchedPatientsSummary = (patientsSummary: PatientSummaryResponseType): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS, payload: { patientsSummary } }
}

export const fetchPatientsSummaryByLocation = (filters: DashboardFilters): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, payload: { filters } }
}
export const fetchPatientsSummaryByLocationFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED, payload }
}
export const fetchedPatientsSummaryByLocation = (patientsSummaryByLocation: AggregatePatientSummaryByLocation[]): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS, payload: { patientsSummaryByLocation } }
}

export const fetchPatientsScreenerCompletionRate = (filters: DashboardFilters): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE, payload: { filters } }
}
export const fetchPatientsScreenerCompletionRateFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED, payload }
}
export const fetchedPatientsScreenerCompletionRate = (patientsScreenerCompletionRate: ScreenerCompletionRate[]): AnyAction => {
    return { type: FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS, payload: { patientsScreenerCompletionRate } }
}

export const fetchRegistryStaticsCard = (payload: { filters: IRegistryCocmPatientStatusFilter }): AnyAction => {
    return { type: FETCH_REGISTRY_STATICS_CARD, payload }
}
export const fetchRegistryStaticsCardInProgress = (): AnyAction => {
    return { type: FETCH_REGISTRY_STATICS_CARD_IN_PROGRESS }
}
export const fetchRegistryStaticsCardFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_REGISTRY_STATICS_CARD_FAILED, payload }
}
export const fetchedRegistryStaticsCard = (patientsStaticsCard: RegistryStaticsCardResponse): AnyAction => {
    return { type: FETCH_REGISTRY_STATICS_CARD_SUCCESS, payload: { patientsStaticsCard } }
}

export const fetchRegistryNotesMetrics = (payload: { filters: IRegistryCocmPatientStatusFilter }): AnyAction => {
    return { type: FETCH_REGISTRY_NOTES_METRICS, payload }
}

export const fetchRegistryNotesMetricsInProgress = (): AnyAction => {
    return { type: FETCH_REGISTRY_NOTES_METRICS_IN_PROGRESS }
}

export const fetchRegistryNotesMetricsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_REGISTRY_NOTES_METRICS_FAILED, payload }
}

export const fetchedRegistryNotesMetrics = (notesMetrics: RegistryNotesMetricsResponse): AnyAction => {
    return { type: FETCH_REGISTRY_NOTES_METRICS_SUCCESS, payload: { notesMetrics } }
}

export const fetchClinicalImprovementMetrics = (payload: { filters: IRegistryCocmPatientStatusFilter }): AnyAction => {
    return { type: FETCH_CLINICAL_IMPROVEMENT_METRICS, payload }
}

export const fetchClinicalImprovementMetricsInProgress = (): AnyAction => {
    return { type: FETCH_CLINICAL_IMPROVEMENT_METRICS_IN_PROGRESS }
}

export const fetchClinicalImprovementMetricsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_CLINICAL_IMPROVEMENT_METRICS_FAILED, payload }
}

export const fetchedClinicalImprovementMetrics = (clinicalImprovementMetrics: clinicalImprovementMetricsResponse): AnyAction => {
    return { type: FETCH_CLINICAL_IMPROVEMENT_METRICS_SUCCESS, payload: { clinicalImprovementMetrics } }
}

export const fetchRegistryBaselineMetrics = (payload: { filters: IRegistryCocmPatientStatusFilter }): AnyAction => {
    return { type: FETCH_REGISTRY_BASELINE_METRICS, payload }
}
export const fetchRegistryBaselineMetricsInProgress = (): AnyAction => {
    return { type: FETCH_REGISTRY_BASELINE_METRICS_IN_PROGRESS }
}
export const fetchRegistryBaselineMetricsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_REGISTRY_BASELINE_METRICS_FAILED, payload }
}
export const fetchedRegistryBaselineMetrics = (registryBaselineMetrics: RegistryPatientTreatmentMetricsResponse): AnyAction => {
    return { type: FETCH_REGISTRY_BASELINE_METRICS_SUCCESS, payload: { registryBaselineMetrics } }
}

export const fetchRegistryClinicalConditions = (): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITIONS }
}
export const fetchedRegistryClinicalConditions = (clinicalConditions: ClinicalConditionsResponse[]): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITIONS_SUCCESS, payload: { clinicalConditions } }
}
export const fetchRegistryClinicalConditionsInProgress = (): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITIONS_IN_PROGRESS }
}
export const fetchRegistryClinicalConditionsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITIONS_FAILED, payload }
}

export const fetchRegistryClinicalConditionDistribution = (payload: { filters: IRegistryCocmPatientStatusFilter }): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION, payload }
}
export const fetchRegistryClinicalConditionDistributionInProgress = (): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_IN_PROGRESS }
}
export const fetchRegistryClinicalConditionDistributionFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_FAILED, payload }
}
export const fetchedRegistryClinicalConditionDistribution = (clinicalConditionDistribution: RegistryClinicalConditionDistributionResponse[]): AnyAction => {
    return { type: FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_SUCCESS, payload: { clinicalConditionDistribution } }
}