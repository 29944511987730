import React, { useCallback, useEffect } from "react";
import { Popover, PopoverContent, PopoverHandler, } from "@material-tailwind/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IPhysicianAppointmentPatient, IPhysicianAppointmentProvider } from "../../../models";
import { Button } from "../../../components/generic/Buttons";
import ConfirmationModal from "../../../components/clinical-notes/ConfirmationModal";
import { DateTime } from "luxon";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import {
    deletePhysicianAppointment, patientCommentsSidebarActive, patientCommentsSidebarInActive, resetDeletePhysicianAppointment,
    resetScreenerLinkToPatient
} from "../../../store/actions/care-coordinator/patients.action";
import { LiaSmsSolid } from "react-icons/lia";
import { toast } from "react-toastify";
import { useFlags } from "flagsmith/react";
import CommentListComponent from "../../../components/comments/CommentListComponent";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";


interface IPhysicianAppointmentListItemContextMenuProps {
    value: {
        appointmentId: string;
        appointmentDate: string;
        provider: IPhysicianAppointmentProvider;
        patient: IPhysicianAppointmentPatient;
    }
    refreshPhysicianAppointmentList: () => void;
    onCommentsClick?: () => void;
}

export const PhysicianAppointmentListItemContextMenu = (props: IPhysicianAppointmentListItemContextMenuProps) => {

    const {
        appointmentIdToBeDeleted,
        deleteAppointmentInProgress,
        deleteAppointmentSuccess,
        deleteAppointmentError
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const isAppointmentDeletionEnabled = useFlags(['delete_physician_appointment'])?.delete_physician_appointment?.enabled;
    const isAppointmentCommentsEnabled = useFlags(['physician_appointments_comments'])?.physician_appointments_comments?.enabled;
    const dispatch = useDispatch();
    const { value, refreshPhysicianAppointmentList } = props;
    const { appointmentId, provider, appointmentDate, patient } = value;
    const [showAppointmentDeleteConfirmationModal, setShowAppointmentDeleteConfirmationModal] = React.useState<boolean>(false);
    const [reasonForAppointmentDeletion, setReasonForAppointmentDeletion] = React.useState<string>('');
    const [showCommentsRightSidebar, setShowCommentsRightSidebar] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    // const params = new URLSearchParams();

    useEffect(() => {
        if (appointmentIdToBeDeleted === appointmentId) {
            if (deleteAppointmentError) {
                console.error("sendScreenerLinkToPatientError", deleteAppointmentError, appointmentId);
                toast(deleteAppointmentError);
                dispatch(resetDeletePhysicianAppointment());
            }
            if (deleteAppointmentSuccess) {
                toast(`Appointment deleted successfully`);
                setShowAppointmentDeleteConfirmationModal(false);
                dispatch(resetDeletePhysicianAppointment());
                refreshPhysicianAppointmentList();
            }
        }
    }, [deleteAppointmentSuccess, appointmentIdToBeDeleted, deleteAppointmentError, dispatch, refreshPhysicianAppointmentList]);

    const openAppointmentDeleteConfirmation = useCallback(() => {
        setReasonForAppointmentDeletion('');
        setShowAppointmentDeleteConfirmationModal(true);
    }, []);

    const closeAppointmentDeleteConfirmation = useCallback(() => {
        setShowAppointmentDeleteConfirmationModal(false);
    }, []);

    const deleteAppointment = useCallback(() => {
        dispatch(deletePhysicianAppointment(appointmentId, {
            reason: reasonForAppointmentDeletion
        }));
    }, [appointmentId, dispatch, reasonForAppointmentDeletion]);

    const renderAppointmentDeleteConfirmationModal = useCallback(() => {
        const patientFullName = `${patient?.firstName} ${patient?.lastName}`;
        const capitalizedName = _.capitalize(patientFullName);
        const appointmentPhysicianName = `${provider ? provider?.firstName + ' ' + provider?.lastName : 'N/A'}`;
        const patientDob = patient && patient.dob && DateTime.fromISO(patient.dob, { zone: 'utc' }).toFormat('MM/dd/yyyy');
        const apptmentEstTimestamp = DateTime.fromISO(appointmentDate, { zone: 'utc' }).setZone('America/New_York');
        const apptmentDate = apptmentEstTimestamp.toFormat('MM/dd/yyyy')
        const apptmentTime = apptmentEstTimestamp.toFormat('hh:mm a');
        const disableDeleteButton = !reasonForAppointmentDeletion || reasonForAppointmentDeletion.trim().length === 0;
        return <ConfirmationModal isOpen={showAppointmentDeleteConfirmationModal}
            onClose={closeAppointmentDeleteConfirmation}
            key={'sendScreeningReminderSMSModal'}
            modalClassNames={'!w-1/2 text-left'}
            alignContentInCenter={false}
            actions={
                <div className="flex gap-2">
                    <Button
                        className="rounded-l-md !ml-2 px-[16px] py-[8px] !h-8 text-sm w-full justify-center"
                        onClick={closeAppointmentDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button
                        disabled={disableDeleteButton}
                        className={`${(deleteAppointmentInProgress || disableDeleteButton) ? 'border-sjGray text-sjGray opacity-80 cursor-not-allowed' : 'border-sjOrange text-sjOrange'} rounded-l-md  !ml-2 px-[16px] py-[8px] !h-8 text-sm w-full justify-center`}
                        onClick={deleteAppointment}>
                        <div className={"flex flex-row gap-x-2 items-center"}>
                            <span>
                                {deleteAppointmentInProgress ? 'Deleting...' : 'Delete'}
                            </span>
                            <LiaSmsSolid width={20} />
                        </div>
                    </Button>
                </div>
            }
        >
            <>
                <h4 className='font-semibold'>
                    Confirm Delete Appointment
                </h4>
                <div className="text-gray-700 mt-4">
                    <div className='mv-5'>
                        Are you sure you want to delete the appointment for <i>{capitalizedName} ({patientDob})</i> with <br /> <i>Dr. {appointmentPhysicianName}</i> on {apptmentDate} at {apptmentTime}?
                    </div>
                    <div className="mt-5">
                        <label htmlFor="reasonForAppointmentDeletion" className="block text-sm font-medium text-gray-700">
                            Reason for deletion <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                            <textarea
                                id="reasonForAppointmentDeletion"
                                name="reasonForAppointmentDeletion"
                                rows={3}
                                className="shadow-sm focus:ring-sjOrange focus:border-sjOrange mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                placeholder="Enter reason for deletion"
                                value={reasonForAppointmentDeletion}
                                onChange={(e) => setReasonForAppointmentDeletion(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mt-5 mb-2 border rounded-md p-2 bg-red-100 border-red-500 text-red-500">
                        Note: This action cannot be undone.
                    </div>
                </div>
            </>
        </ConfirmationModal>
    }, [patient, provider, showAppointmentDeleteConfirmationModal, closeAppointmentDeleteConfirmation, appointmentDate, reasonForAppointmentDeletion, deleteAppointmentInProgress, deleteAppointment]);

    const handleDrawerClose = useCallback(() => {
        dispatch(patientCommentsSidebarInActive());
        setShowCommentsRightSidebar(false);
    }, [searchParams, searchParams]);

    const renderCommentsRightSidebar = useCallback(() => {
        return <>
            <div className={"fixed top-0 right-0 w-3/5 h-screen bg-white z-[2000] p-8"}>
                <CommentListComponent patientId={patient.id} onClose={
                    handleDrawerClose
                }
                    source='Appointments' />
            </div >
        </>
    }, [patient,handleDrawerClose]);

    const handleComments = useCallback(() => {
        dispatch(patientCommentsSidebarActive());
        setShowCommentsRightSidebar(true);
    }, []);

    return (
        <>
            {(isAppointmentDeletionEnabled || isAppointmentCommentsEnabled) &&
                <Popover placement="bottom-end">
                    <PopoverHandler>
                        <button><BsThreeDotsVertical className='w-5 h-5 cursor-pointer' /></button>
                    </PopoverHandler>
                    <PopoverContent className="w-30">
                        <ul className="flex flex-col items-start gap-3">
                            {isAppointmentDeletionEnabled && <li
                                className="cursor-pointer"
                                onClick={openAppointmentDeleteConfirmation}
                            >
                                Delete
                            </li>}
                            {(isAppointmentCommentsEnabled) &&
                                <li
                                    className="cursor-pointer"
                                    onClick={handleComments}
                                >
                                    Comments
                                </li>}
                        </ul>
                    </PopoverContent>
                </Popover>
            }
            {showCommentsRightSidebar && renderCommentsRightSidebar()}
            {showAppointmentDeleteConfirmationModal && renderAppointmentDeleteConfirmationModal()}
        </>
    );
}
