import { el } from "date-fns/locale";
import { DateTime } from "luxon";

export const convertISODateToMMDDYYYY = (dateString: string, format: string = 'MM/dd/yyyy', zone = 'utc'): string | null => {
    if (!dateString || !format) return null
    try {
        const parsedDate = DateTime.fromISO(dateString, { zone })
        return parsedDate.toFormat(format)
    } catch (error) {
        console.error('Error parsing date string:', error)
        return null
    }
}

export const formatISODate = (dateString: string, format: string = 'MM/dd/yyyy, hh:mm a'): string | null => {
    if (!dateString || !format) return null
    try {
        const parsedDate = DateTime.fromISO(dateString)
        return parsedDate.toFormat(format)
    } catch (error) {
        console.error('Error parsing date string:', error)
        return null
    }
}

export const getTimeFromMinutes = (minutes: number | undefined): string => { // return time in 24h format
    if (!minutes || minutes === 0) return '00:00';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}`;
}

export const getMinutesFromTime = (timeIn12h: string): number | undefined => { // accepts time in 12h format
    if (!timeIn12h || timeIn12h?.match(/:/g)?.length !== 1) {
        return undefined;
    }
    const AMPM = timeIn12h?.split(' ')[1];
    const hours = parseInt(timeIn12h?.split(':')[0]);
    const minutes = parseInt(timeIn12h?.split(':')[1]?.split(' ')[0]);
    if (AMPM === 'AM') {
        if (hours === 12) {
            return minutes;
        } else {
            return hours * 60 + minutes;
        }
    } else {
        return hours === 12 ? (hours * 60 + minutes) : (hours + 12) * 60 + minutes;
    }
}

export const generateTimestamp = (date: string): string => {
    if (date === undefined) return '';
    return DateTime.fromISO(date)?.toFormat('MM/dd/yyyy HH:mm');
}

export const formatTimeZone = (timeZone: string) => {
    return timeZone.split('/').map(part => part.replace(/_/g, ' ')).join(' / ')
};


export const getSystemTimeZone = () => {
    let abbrevation = DateTime.now().toFormat('ZZZZZ');
    return abbrevation
      .split(' ')
      .map((i) => i[0])
      .reduce((i, s) => i + s);
  };