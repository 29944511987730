import React, {useCallback, useEffect, useState} from "react";

const PatientCallStatusOptions = [
    {value: true, code: 'Yes', label: 'Yes', bgColor: 'bg-green-100'},
    {value: false, code: 'No', label: 'No', bgColor: 'bg-red-100'}
];

interface IPhysicianAppointmentPatientCallStatusComponentProps {
    value: {
        appointmentId: string;
        isSpokenWithPatient: boolean | null;
    },
    updateAppointmentPatientCallStatus: (appointmentId: string, isSpokenWithPatient: boolean) => void;
}

const PhysicianAppointmentPatientCallStatusComponent = (props: IPhysicianAppointmentPatientCallStatusComponentProps) => {

    const {value, updateAppointmentPatientCallStatus} = props;
    const {appointmentId, isSpokenWithPatient} = value;

    const [selectedOption, setSelectedOption] = useState<string>('');

    useEffect(() => {
        if (isSpokenWithPatient !== null) {
            setSelectedOption(isSpokenWithPatient ? 'Yes' : 'No');
        } else {
            setSelectedOption('');
        }
    }, [isSpokenWithPatient]);

    const handleStatusChange = useCallback((event: any) => {
        const selectedOptionCode = event?.target?.value;
        setSelectedOption(selectedOptionCode);
        const selectedOption = PatientCallStatusOptions.find(option => option.code === selectedOptionCode)?.value;
        if (selectedOption !== undefined) {
            updateAppointmentPatientCallStatus(appointmentId, selectedOption);
        }
    }, [updateAppointmentPatientCallStatus, appointmentId]);

    return <select
        onChange={handleStatusChange}
        value={selectedOption}
        defaultValue={''}
        className={`block min-w-[70px] w-5/6 outline-none py-1 px-4  placeholder-gray-500 rounded-md border border-gray-300 ${selectedOption ? PatientCallStatusOptions.find(option => option.code === selectedOption)?.bgColor : ''}`}
    >
        <option value={''} disabled={true}>Select</option>
        {
            PatientCallStatusOptions.map((option) => {
                return <option key={option.code} value={option.code}>{option.label}</option>
            })
        }
    </select>
}

export default PhysicianAppointmentPatientCallStatusComponent;
