import { IReferredPatientsDetails, ITherapistPatient, LanguageCodeDescription } from "../../../models";
import { DateTime } from "luxon";
import { CallStatus } from "../../generic/CallStatus";
import { ProspectsDataView } from "../../prospects/common/ProspectsDataView";
import { Link } from "react-router-dom";
import { Button } from "../../generic/Buttons";
import { DetailsButton } from "../../generic/DetailsButton";

export class ReferredPatientsDataView implements ProspectsDataView {
    private therapistProspects: IReferredPatientsDetails[];

    constructor() {
        this.therapistProspects = []
    }

    setProspects(therapistProspects: IReferredPatientsDetails[]) {
        this.therapistProspects = therapistProspects
    }

    getColumns(): any {
        return () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dateOfBirth" },
            { header: "Referring Physician", accessorKey: "referringPhysician" },
            { header: "Referred Date", accessorKey: "referredDate" },
            { header: "Preferred Language", accessorKey: "preferredLanguage" },
            { header: "Practice", accessorKey: "practice" },
            { header: "Status", accessorKey: "callStatus", cell: (props: any) => <CallStatus value={props.getValue()} isReferred={true} /> },
            {
                header: "", accessorKey: "summaryLink", cell: (props: any) => {
                    return <DetailsButton value={props.getValue()} />
                }
            },
        ]
    };

    getData(): any {
        return this.therapistProspects.map((therapistProspect) => {
            const isoDateTime = therapistProspect.referredDate ? DateTime.fromISO(therapistProspect.referredDate.toString(), { zone: 'utc' }).toFormat('MM-dd-yyyy') : ""
            const dob = DateTime.fromISO(therapistProspect.dob.toString(), { zone: 'utc' }).toFormat('MM-dd-yyyy')
            const practice = therapistProspect.screenerUserPractices[0];
            const provider = therapistProspect?.patientStatuses?.[0]?.provider;
            const referringPhysician = provider ? `Dr. ${provider?.firstName} ${provider?.lastName}` : "N/A"
            return {
                id: therapistProspect.id,
                summaryLink: { url: `${therapistProspect.id}/patient-information`, practiceId: practice.practiceId, sourcePatientId: therapistProspect.id },
                name: `${therapistProspect.lastName}, ${therapistProspect.firstName}`,
                dateOfBirth: dob,
                preferredLanguage: LanguageCodeDescription[therapistProspect.preferredLanguage] || "English",
                practice: `${practice.providerPractice.name}`,
                referredDate: isoDateTime,
                referringPhysician,
                callStatus: therapistProspect.referredCallStatus,
            }
        })
    }
}
