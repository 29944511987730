import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { Button } from "../../components/generic/Buttons";
import {CalledPatientFilterOptions, CallResponseFilterOptions, PreferredLanguageFilterOptions, SmsStatusFilterOptions} from "../../services/common.service";

interface Filter {
    isOpen: boolean
    setOpenFilterModal: (openFilterModal: boolean) => void
    onFiltersApply: () => void
    calledPatient: string | undefined
    setCalledPatient: (calledPatient: string | undefined) => void
    callResponse: string | undefined
    setCallResponse: (callResponse: string | undefined) => void
    smsStatus: string | undefined
    setSmsStatus: (smsStatus: string | undefined) => void
}

const PhysicianAppointmentAdvanceFilters = (props: Filter) => {
    const { isOpen, setOpenFilterModal, onFiltersApply, calledPatient, setCalledPatient, callResponse, setCallResponse, smsStatus, setSmsStatus } = props

    console.log("PhysicianAppointmentAdvanceFilters -> isOpen", isOpen)

    const handleCancelFilter = () => {
        setOpenFilterModal(false)
    }

    console.log("isOpen", isOpen)

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={() => setOpenFilterModal(false)}
            className="fixed top-0 right-0 mx-auto p-5 border w-[480px] shadow-lg bg-white min-h-screen"
        >
            <span className="flex justify-center font-bold text-lg">Advance Filters</span>
            <div className="mt-2 px-4 py-3">
                    <div className="physician-dashboard-date-filter">
                    <span className="content-start">Called Patient</span>
                    <div className="relative">
                        <Select
                            className={"w-full"}
                            options={CalledPatientFilterOptions}
                            placeholder={"Called Patient"}
                            isClearable={true}
                            isSearchable={false}
                            onChange={(selectedOption: any) => {
                                setCalledPatient?.(selectedOption?.value);
                            }}
                            value={CalledPatientFilterOptions.find(option => option.value === calledPatient) || null}
                        />
                        </div>
                    </div>
                <div className="mt-4">
                    <span>Call Response</span>
                    <Select
                        className={"w-full"}
                        options={CallResponseFilterOptions}
                        placeholder={"Call Response"}
                        isClearable={true}
                        isSearchable={false}
                        onChange={(selectedOption: any) => {
                            setCallResponse?.(selectedOption?.value);
                        }}
                        value={CallResponseFilterOptions.find(option => option.value === callResponse) || null}
                    />
                </div>
                <div className="mt-4">
                    <span>SMS Status</span>
                    <Select
                        className={"w-full"}
                        options={SmsStatusFilterOptions}
                        placeholder={"SMS Status"}
                        isClearable={true}
                        isSearchable={false}
                        onChange={(selectedOption: any) => {
                            setSmsStatus?.(selectedOption?.value);
                        }}
                        value={SmsStatusFilterOptions.find(option => option.value === smsStatus) || null}
                    />
                </div>
            </div>
            <div className="mt-8 flex justify-center px-4 py-3">
                <Button className='!bg-sjOrange !text-sjWhite mr-4' onClick={onFiltersApply}>
                    Apply Filter
                </Button>
                <Button className='!text-sjOrange !border-sjOrange !border-2' onClick={handleCancelFilter}>
                    Discard
                </Button>
            </div>
        </Modal>
    )
}

export default PhysicianAppointmentAdvanceFilters