import { AnyAction } from "redux";
import {
    AggregatePatientSummaryByLocation,
    ClinicalConditionsResponse,
    clinicalImprovementMetricsResponse,
    PatientSummaryResponseType,
    RegistryClinicalConditionDistributionResponse,
    RegistryNotesMetricsResponse,
    RegistryPatientTreatmentMetricsResponse,
    RegistryStaticsCardResponse,
    ScreenerCompletionRate
} from "../../../models";
import { FETCH_CLINICAL_IMPROVEMENT_METRICS, FETCH_CLINICAL_IMPROVEMENT_METRICS_FAILED, FETCH_CLINICAL_IMPROVEMENT_METRICS_IN_PROGRESS, FETCH_CLINICAL_IMPROVEMENT_METRICS_SUCCESS, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS, FETCH_DASHBOARD_PATIENTS_SUMMARY, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS, FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED, FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS, FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS, FETCH_REGISTRY_BASELINE_METRICS, FETCH_REGISTRY_BASELINE_METRICS_FAILED, FETCH_REGISTRY_BASELINE_METRICS_IN_PROGRESS, FETCH_REGISTRY_BASELINE_METRICS_SUCCESS, FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION, FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_IN_PROGRESS, FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_SUCCESS, FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_FAILED, FETCH_REGISTRY_CLINICAL_CONDITIONS, FETCH_REGISTRY_CLINICAL_CONDITIONS_FAILED, FETCH_REGISTRY_CLINICAL_CONDITIONS_IN_PROGRESS, FETCH_REGISTRY_CLINICAL_CONDITIONS_SUCCESS, FETCH_REGISTRY_NOTES_METRICS, FETCH_REGISTRY_NOTES_METRICS_FAILED, FETCH_REGISTRY_NOTES_METRICS_IN_PROGRESS, FETCH_REGISTRY_NOTES_METRICS_SUCCESS, FETCH_REGISTRY_STATICS_CARD, FETCH_REGISTRY_STATICS_CARD_FAILED, FETCH_REGISTRY_STATICS_CARD_IN_PROGRESS, FETCH_REGISTRY_STATICS_CARD_SUCCESS } from "../../actions/physician/dashboard.action";
import { stat } from "fs";

export interface PatientsDashboardParams {
    noOfSOAPNotes?: number
    patientsSummary?: PatientSummaryResponseType
    fetchPatientsSummaryInProgress?: boolean
    patientsSummaryByLocation: AggregatePatientSummaryByLocation[]
    fetchPatientsSummaryByLocationInProgress?: boolean
    patientsScreenerCompletionRate: ScreenerCompletionRate[]
    fetchPatientsScreenerCompletionRateInProgress?: boolean
    registryPatientStaticsCard?: RegistryStaticsCardResponse
    fetchRegistryPatientStaticsCardInProgress?: boolean
    registryNotesMetrics?: RegistryNotesMetricsResponse
    fetchRegistryNotesMetricsInProgress?: boolean
    fetchRegistryNotesMetricsFailed?: boolean
    fetchClinicalImprovementMetricsInProgress?: boolean
    fetchClinicalImprovementMetricsFailed?: boolean
    clinicalImprovementMetrics?: clinicalImprovementMetricsResponse
    registryBaselineMetricsInProgress?: boolean
    registryBaselineMetricsFailed?: boolean
    registryBaselineMetrics?: RegistryPatientTreatmentMetricsResponse
    fetchRegistryClinicalConditionsInProgress?: boolean
    fetchRegistryClinicalConditionsFailed?: boolean
    registryClinicalConditions?: ClinicalConditionsResponse[]
    registryClinicalConditionDistributionInProgress?: boolean
    registryClinicalConditionDistributionFailed?: boolean
    registryClinicalConditionDistribution: RegistryClinicalConditionDistributionResponse[]
}

const initialState: PatientsDashboardParams = {
    patientsSummary: undefined,
    fetchPatientsSummaryInProgress: undefined,
    patientsSummaryByLocation: [],
    fetchPatientsSummaryByLocationInProgress: undefined,
    patientsScreenerCompletionRate: [],
    fetchPatientsScreenerCompletionRateInProgress: undefined,
    registryPatientStaticsCard: undefined,
    fetchRegistryPatientStaticsCardInProgress: undefined,
    registryNotesMetrics: undefined,
    fetchRegistryNotesMetricsInProgress: undefined,
    fetchRegistryNotesMetricsFailed: undefined,
    fetchClinicalImprovementMetricsInProgress: undefined,
    fetchClinicalImprovementMetricsFailed: undefined,
    clinicalImprovementMetrics: undefined,
    registryBaselineMetricsInProgress: undefined,
    registryBaselineMetricsFailed: undefined,
    registryBaselineMetrics: undefined,
    fetchRegistryClinicalConditionsInProgress: undefined,
    fetchRegistryClinicalConditionsFailed: undefined,
    registryClinicalConditions: undefined,
    registryClinicalConditionDistributionInProgress: undefined,
    registryClinicalConditionDistributionFailed: undefined,
    registryClinicalConditionDistribution: [],
}

const PatientsDashboardReducer = (state: PatientsDashboardParams = initialState, action: AnyAction): PatientsDashboardParams => {
    switch (action.type) {
        case FETCH_DASHBOARD_PATIENTS_SUMMARY:
            state = { ...state, patientsSummary: undefined, fetchPatientsSummaryInProgress: undefined }
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED:
            state = { ...state, fetchPatientsSummaryInProgress: false }
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS:
            state = { ...state, fetchPatientsSummaryInProgress: true }
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS:
            state = { ...state, patientsSummary: action.payload.patientsSummary, fetchPatientsSummaryInProgress: false }
            return state

        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION:
            state = { ...state, patientsSummaryByLocation: [], fetchPatientsSummaryByLocationInProgress: undefined }
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED:
            state = { ...state, fetchPatientsSummaryByLocationInProgress: false }
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS:
            state = { ...state, fetchPatientsSummaryByLocationInProgress: true }
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS:
            state = { ...state, patientsSummaryByLocation: action.payload.patientsSummaryByLocation, fetchPatientsSummaryByLocationInProgress: false }
            return state

        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE:
            state = { ...state, patientsScreenerCompletionRate: [], fetchPatientsScreenerCompletionRateInProgress: undefined }
            return state
        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED:
            state = { ...state, fetchPatientsScreenerCompletionRateInProgress: false }
            return state
        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS:
            state = { ...state, fetchPatientsScreenerCompletionRateInProgress: true }
            return state
        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS:
            state = { ...state, patientsScreenerCompletionRate: action.payload.patientsScreenerCompletionRate, fetchPatientsScreenerCompletionRateInProgress: false }
            return state

        case FETCH_REGISTRY_STATICS_CARD:
            state = { ...state, registryPatientStaticsCard: undefined, fetchRegistryPatientStaticsCardInProgress: undefined }
            return state
        case FETCH_REGISTRY_STATICS_CARD_FAILED:
            state = { ...state, fetchRegistryPatientStaticsCardInProgress: false }
            return state
        case FETCH_REGISTRY_STATICS_CARD_IN_PROGRESS:
            state = { ...state, fetchRegistryPatientStaticsCardInProgress: true }
            return state
        case FETCH_REGISTRY_STATICS_CARD_SUCCESS:
            state = { ...state, registryPatientStaticsCard: action.payload.patientsStaticsCard, fetchRegistryPatientStaticsCardInProgress: false }
            return state

        case FETCH_REGISTRY_NOTES_METRICS:
            state = { ...state, registryNotesMetrics: undefined, fetchRegistryNotesMetricsFailed: undefined }
            return state

        case FETCH_REGISTRY_NOTES_METRICS_FAILED:
            state = { ...state, fetchRegistryNotesMetricsInProgress: false, fetchRegistryNotesMetricsFailed: true }
            return state

        case FETCH_REGISTRY_NOTES_METRICS_IN_PROGRESS:
            state = { ...state, fetchRegistryNotesMetricsInProgress: true, fetchRegistryNotesMetricsFailed: false }
            return state
        case FETCH_REGISTRY_NOTES_METRICS_SUCCESS:
            state = { ...state, registryNotesMetrics: action.payload.notesMetrics, fetchRegistryNotesMetricsInProgress: false }
            return state

        case FETCH_CLINICAL_IMPROVEMENT_METRICS:
            state = { ...state, clinicalImprovementMetrics: undefined, fetchClinicalImprovementMetricsFailed: undefined }
            return state

        case FETCH_CLINICAL_IMPROVEMENT_METRICS_FAILED:
            state = { ...state, fetchClinicalImprovementMetricsInProgress: false, fetchClinicalImprovementMetricsFailed: true }
            return state
        case FETCH_CLINICAL_IMPROVEMENT_METRICS_IN_PROGRESS:
            state = { ...state, fetchClinicalImprovementMetricsInProgress: true, fetchClinicalImprovementMetricsFailed: false }
            return state

        case FETCH_CLINICAL_IMPROVEMENT_METRICS_SUCCESS:
            state = {
                ...state, clinicalImprovementMetrics: action.payload.clinicalImprovementMetrics,
                fetchClinicalImprovementMetricsFailed: false,
                fetchClinicalImprovementMetricsInProgress: false
            }
            return state
        case FETCH_REGISTRY_BASELINE_METRICS:
            state = { ...state, registryBaselineMetrics: undefined, registryBaselineMetricsInProgress: undefined }
            return state;

        case FETCH_REGISTRY_BASELINE_METRICS_IN_PROGRESS:
            state = {
                ...state, registryBaselineMetricsInProgress: true, registryBaselineMetricsFailed: false
            }
            return state;

        case FETCH_REGISTRY_BASELINE_METRICS_FAILED:
            state = {
                ...state, registryBaselineMetricsInProgress: false, registryBaselineMetricsFailed: true
            }
            return state;

        case FETCH_REGISTRY_BASELINE_METRICS_SUCCESS:
            state = {
                ...state, registryBaselineMetricsInProgress: false, registryBaselineMetricsFailed: false,
                registryBaselineMetrics: action.payload.registryBaselineMetrics
            }
            return state;

        case FETCH_REGISTRY_CLINICAL_CONDITIONS:
            state = { ...state, fetchRegistryClinicalConditionsInProgress: undefined }
            return state;

        case FETCH_REGISTRY_CLINICAL_CONDITIONS_IN_PROGRESS:
            state = { ...state, fetchRegistryClinicalConditionsInProgress: true }
            return state;

        case FETCH_REGISTRY_CLINICAL_CONDITIONS_SUCCESS:
            state = { ...state, fetchRegistryClinicalConditionsInProgress: false, fetchRegistryClinicalConditionsFailed: false, registryClinicalConditions: action.payload.clinicalConditions }
            return state;
            
        case FETCH_REGISTRY_CLINICAL_CONDITIONS_FAILED:
            state = { ...state, fetchRegistryClinicalConditionsInProgress: false, fetchRegistryClinicalConditionsFailed: true }
            return state;

        case FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION:
            state = { ...state, registryClinicalConditionDistribution: [], registryClinicalConditionDistributionInProgress: undefined }
            return state;
        case FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_IN_PROGRESS:
            state = { ...state, registryClinicalConditionDistributionInProgress: true }
            return state;
        case FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_FAILED:
            state = { ...state, registryClinicalConditionDistributionInProgress: false, registryClinicalConditionDistributionFailed: true }
            return state;
        case FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION_SUCCESS:
            state = { ...state, registryClinicalConditionDistributionInProgress: false, registryClinicalConditionDistributionFailed: false, registryClinicalConditionDistribution: action.payload.clinicalConditionDistribution }
            return state;

        default:
            return state;
    }
}

export default PatientsDashboardReducer
