import React from "react";
import {IPatientInCall} from "../../models";
import {canEditAndMakeCallsToPatient, isCareCoordinator} from "../clinical-notes/accessControl";
import {Button} from "../generic/Buttons";
import PhoneInput, {Country, isValidPhoneNumber} from "react-phone-number-input";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {clearErrorForUpdatePatientInfo, updatePatientInfo} from "../../store/actions/counsellor/patients.action";
import {toast, ToastContainer} from "react-toastify";
import EditIcon from "../../assets/images/common/edit.svg"
import {updatePatientBasicInfo} from "../../store/actions/care-coordinator/patients.action";
import { MaskPhoneNumber } from "../generic/MaskPhoneNumber";

const EditablePhoneNumber: React.FC<{patient: IPatientInCall, className?: string, editMode?: boolean, onEdit?: (phoneNumber: string)=> void, onCancel?: ()=> void}> = ({patient, className='', editMode, onEdit, onCancel}) => {
    const [inEditMode, setInEditMode] = React.useState(editMode);
    const role = useSelector((state: StateParams) => state.account.role)
    const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = React.useState<string>()
    const countries: Country[] = ['US', 'IN']

    const dispatch = useDispatch()
    const errorOnUpdateContactPhoneNumber = useSelector((state: StateParams) => state.patients.updatePatientInfoError)
    const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(patient.phoneNumber)

    function handlePhoneError() {
        if(phoneNumber && !isValidPhoneNumber(phoneNumber)) {
            setPhoneNumberErrorMsg('Phone number format is invalid.')
            return false
        } else {
            setPhoneNumberErrorMsg('')
            return true
        }
    }

    function handlePhoneNumberSave() {
        if(phoneNumber && patient.sourcePatientId) {
            if(handlePhoneError()) {
                dispatch(updatePatientInfo(patient.sourcePatientId, patient.referredByPracticeId, { contactPhoneNumber: phoneNumber }))
                if(errorOnUpdateContactPhoneNumber) {
                    toast(`There was an issue while saving the contact's phone number.`, {
                        onClose: () => { dispatch(clearErrorForUpdatePatientInfo()) },
                        toastId: 'error'
                    })
                } else if (!errorOnUpdateContactPhoneNumber) {
                    setInEditMode(false)
                    {isCareCoordinator(role) && dispatch(updatePatientBasicInfo(phoneNumber))}
                    toast('Contact phone number saved successfully.', {
                        onClose: () => { dispatch(clearErrorForUpdatePatientInfo()) },
                        toastId: 'saved'
                    });
                    onEdit && onEdit(phoneNumber);
                }
            }
        }
    }

    function renderEditPhoneNumber() {
        return (
            <>
                <MaskPhoneNumber value={patient.phoneNumber} disabled={true} className={`w-[150px] border-0 bg-transparent ${className}`}/>
                {canEditAndMakeCallsToPatient(role) && patient.phoneNumber && <>
                    <img src={EditIcon} width={14} height={14} alt="Edit Phone number" onClick={() => setInEditMode(!inEditMode)}
                         className={"hover:cursor-pointer"}/>
                </>}
            </>
        )
    }

    function renderPhoneNumberWithCountry() {
        return (
            <div className="flex flex-row items-center">
                <PhoneInput defaultCountry='US'
                            countries={countries}
                            placeholder="Enter Contact number" value={phoneNumber} onChange={setPhoneNumber}
                            onBlur={handlePhoneError}
                            className="w-1/2"
                />
                <Button className="!bg-sjOrange text-white ml-4" onClick={handlePhoneNumberSave}>Save</Button>
                <Button className="text-sjOrange border-sjOrange ml-2" onClick={() => {
                    setInEditMode(!inEditMode);
                    setPhoneNumber(patient.phoneNumber);
                    setPhoneNumberErrorMsg('');
                    onCancel && onCancel();
                }}>Cancel</Button>
            </div>
        )
    }

    return <>
        <div className={"flex flex-row items-center"}>
            {/* <ToastContainer limit={1}/> */}
            {inEditMode ? renderPhoneNumberWithCountry() : renderEditPhoneNumber()}
        </div>
        {phoneNumberErrorMsg && <span className="text-red-500 font-bold text-sm">{phoneNumberErrorMsg}</span>}
    </>

}

export default EditablePhoneNumber
