import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {fetchPatientJourney, fetchPatientSummary} from "../../../store/actions/counsellor/patients.action"
import {StateParams} from "../../../store/reducers"
import {Link} from "react-router-dom"
import {DateTime} from "luxon"
import {DownloadNotesRequest, NotesStatus, PatientJourney, PatientSessionTypes, Roles, SessionTypes} from "../../../models"
import icdCodes from "../../../assets/data/icd-codes.json";
import {handleJourneyRoutes} from "../../../shared/Utils";
import DownloadPDF from "../../generic/DownloadPdf"
import { downloadPatientSessionNotes, downloadPatientSummary } from "../../../store/actions/physician/patients.action"
import { Tooltip } from "react-tooltip"
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { useFlags } from "flagsmith/react"
import useDownloadBlob from "../../../hooks/useDownloadBlob"

const PatientSummary: React.FC<{ patientProspectId: string, sourcePatientId: string }> = ({ patientProspectId, sourcePatientId }) => {
    const dispatch = useDispatch()
    const patientSummary = useSelector((state: StateParams) => state.patients.patientSummary)
    const patientJourney = useSelector((state: StateParams) => state.patients.patientJourney)
    const role = useSelector((state: StateParams) => state.account.role);
    const { patientSummaryExportLink, patientSummaryFileName, patientSessionNotesExportLink, patientSessionNotesFileName } = useSelector((state: StateParams) => state.physicianPatients)
    const { downloadBlob, loading, error } = useDownloadBlob();
    const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
    const [downloadNotesButtonClicked, setDownloadNotesButtonClicked] = useState(false);

    useEffect(() => {
        if (patientProspectId) {
            dispatch(fetchPatientSummary(patientProspectId))
            dispatch(fetchPatientJourney(patientProspectId, sourcePatientId))
        }
    }, [patientProspectId])

    useEffect(() => {
        if(downloadNotesButtonClicked && patientSessionNotesExportLink && patientSessionNotesFileName) {
            downloadBlob(patientSessionNotesExportLink, {filename: patientSessionNotesFileName, setDownloadButtonClicked: setDownloadNotesButtonClicked})
        }
    }, [patientSessionNotesExportLink, patientSessionNotesFileName])

    const handleDownloadPatientSummary = () => {
        setDownloadButtonClicked(true)
        dispatch(downloadPatientSummary(patientProspectId))
    }

    const handleDownloadPatientSessionNotes = (journey: PatientJourney) => {
        if(journey.notesId && journey.sessionType) {
            setDownloadNotesButtonClicked(true)
            dispatch(downloadPatientSessionNotes({patientId:patientProspectId, notesId: journey.notesId, sourcePatientId, notesType: journey.sessionType} as DownloadNotesRequest))
        }
    }

    const sortedPatientJourney = patientJourney && patientJourney.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))

    function canShowPatientNotes(journey: PatientJourney) {
        const canAccess = journey.notesId && journey.notesStatus  && canAccessNotes(journey.notesStatus)
        if(journey.sessionType === PatientSessionTypes.Catchup) {
            return canAccess && journey.notesStatus && [NotesStatus.PENDING_PSYCH_REVIEW, NotesStatus.LOCKED].includes(journey.notesStatus) 
        }
        return canAccess && journey.notesStatus === NotesStatus.LOCKED
    }

    function renderProposedMedicationTable(medications: any) {
        return <table className="w-full min-w-0 table-fixed text-left">
            <thead>
                <tr>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Medicine</th>
                    {/* <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Medicine Type</th> */}
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Dosage</th>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Frequency</th>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6 w-3/12">Comments</th>
                </tr>
            </thead>
            <tbody>
                {medications.map((medication: any) =>
                    <tr className="py-1">
                        <td className="border-none text-sjText text-base font-nunito align-top">{medication.medicine || 'N/A'}</td>
                        {/* <td className="border-none text-sjText text-base font-nunito align-top">{medication.type || 'N/A'}</td> */}
                        <td className="border-none text-sjText text-base font-nunito align-top">{medication.dosage || 'N/A'}</td>
                        <td className="border-none text-sjText text-base font-nunito align-top">{medication.frequency || 'N/A'}</td>
                        <td className="border-none text-sjText text-base font-nunito align-top truncate py-1"
                            data-tooltip-id={`medications-${medication.medicine}`}>
                            {medication.details || 'N/A'}
                            <Tooltip id={`medications-${medication.medicine}`} style={{ width: '25%' }}>
                                <div className="whitespace-normal break-all">{medication.details}</div>
                            </Tooltip>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    }

    const renderDownloadNotesButton = (journey: PatientJourney) => {
        return (
            <MdOutlineDownloadForOffline className="text-sjOrange hover:text-gray-700 ml-2 cursor-pointer"
                    onClick={() => handleDownloadPatientSessionNotes(journey)} style={{ width: '20px', height: '20px' }} />
        )
    }

    function renderPatientJourney() {
        return <table className="w-full min-w-0 table-auto text-left leading-7">
            <thead>
                <tr>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Session</th>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Date</th>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Notes</th>
                </tr>
            </thead>
            <tbody>
                {sortedPatientJourney?.map((journey: PatientJourney, index: number) =>
                    <>{journey && journey.notesId && canShowPatientNotes(journey) ? <tr>
                        <td className="border-none text-sjText text-base font-nunito">{journey.sessionTypeName}</td>
                        <td className="border-none text-sjText text-base font-nunito">{DateTime.fromISO(journey.createdAt).toFormat('MM/dd/yyyy')}</td>
                        <td className="border-none text-sjText text-base font-nunito flex flex-row">
                            <Link key={journey.id} to={handleJourneyRoutes(journey.sessionType as SessionTypes, journey.notesId, patientProspectId, role)}>
                                <span className="rounded-l-md border-sjOrange text-sjLink hover:underline hover:cursor-pointer" >
                                    View details
                                </span>
                            </Link>
                            {journey.sessionType &&
                                renderDownloadNotesButton(journey)
                            }
                        </td>
                    </tr> : <tr></tr>}</>
                )}
                <tr>
                    {sortedPatientJourney?.every(journey => journey.notesStatus !== NotesStatus.LOCKED) &&
                    <td className="border-none text-sjText text-base font-nunito">N/A</td>}
                </tr>
            </tbody>
        </table>
    }

    function renderSectionHeading(sectionName: string) {
        return <label className="text-1xl text-sjOrange font-nunito font-bold">{sectionName}</label>
    }

    function renderAnswer(answer: string | null) {
        return <>
            {answer ?
                <p className="text-base text-sjText font-nunito font-medium leading-6">{answer}</p> :
                renderNotAvailable()
            }
        </>
    }

    function renderHeading(headingName: string) {
        return <label className="text-lg text-sjText font-nunito font-bold leading-7">{headingName}</label>
    }

    function renderNotAvailable() {
        return <p className="text-base text-sjText font-nunito font-medium leading-6">N/A</p>
    }

    function canAccessNotes(notesStatus: string) {
        if (role === Roles.Counsellor || role === Roles.Admin) return true
        if ((role === Roles.Psychiatrist || role === Roles.Physician)
                && (notesStatus !== NotesStatus.PENDING_SUBMIT_TO_PSYCH)) return true
        return false
    }
    return (
        <div className="mt-4">
            {<fieldset className="min-w-0">
                <div className="float-right">
                    <DownloadPDF
                        downloadButtonClicked={downloadButtonClicked}
                        setDownloadButtonClicked={setDownloadButtonClicked}
                        handleDownload={handleDownloadPatientSummary}
                        fileName={patientSummaryFileName} url={patientSummaryExportLink}/>
                </div>
                <div className="mt-4">
                    {renderSectionHeading('History of present illness')}
                </div>
                <div className="mt-4">
                    {renderHeading('Chief Complaint')}
                    {renderAnswer(patientSummary?.presentChiefComplaint)}
                </div>
                <div className="mt-4">
                    {renderHeading('Journey of the Patient Sessions')}
                    {patientJourney ?
                        renderPatientJourney():
                        renderNotAvailable()
                    }
                </div>
                <div className="mt-4">
                    {renderHeading('Interventions')}
                    {renderAnswer(patientSummary?.interventions)}
                </div>
                <div className="mt-4">
                    {renderSectionHeading('Diagnosis Codes')}
                </div>
                <div className="mt-4">
                    {renderHeading('Mental, Behavioural and Neurodevelopmental Disorders F01- F99')}
                    {patientSummary?.mentalBehavioral?.length ?
                        patientSummary.mentalBehavioral.map((x: {code: string, name: string}) => <p className="text-base text-sjText font-nunito font-medium leading-7"> {x.name} </p> ):
                        renderNotAvailable()
                    }
                </div>
                <div className="mt-4">
                    {renderHeading('Social Determinants of Health')}
                    {patientSummary?.socialDeterminants?.length ?
                        patientSummary.socialDeterminants.map((x: {code: string, name: string}) => <p className="text-base text-sjText font-nunito font-medium leading-7"> {x.name} </p>) :
                        renderNotAvailable()
                    }
                </div>
                <div className="mt-4">
                    {renderSectionHeading('Medication by the Psychiatrist')}
                </div>
                <div className="mt-4 overflow-x-auto">
                    {renderHeading('Proposed Medication')}
                    {patientSummary?.proposedMedication ?
                        renderProposedMedicationTable(patientSummary.proposedMedication):
                        renderNotAvailable()
                    }
                </div>
                <div className="mt-4">
                    {renderHeading('Proposed Lab')}
                    {renderAnswer(patientSummary?.proposedPlan)}
                </div>
            </fieldset>}
        </div>
    )
}

export default PatientSummary
