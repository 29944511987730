import {
    IPatient,
    IPatientScreenerIndex,
    IScreenerQuestionAnswer,
    ISummary,
    IUserScreenerSession
} from "../../../models";
import React, {useEffect, useState} from "react";
import {classNames} from "../../../shared/Utils";
import ScreenerSummary from "./ScreenerSummary";
import ScreenerQuestionAnswers from "./ScreenerQuestionAnswers";
import {fetchPatientQAndA} from "../../../store/actions/physician/patients.action";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {withScreenerScores} from "./ScreenerScoreProvider";

interface PatientResultProps {
    screeners: {[key: string]: IPatientScreenerIndex}
    summary: ISummary
    sessions: IUserScreenerSession[]
    patient: IPatient
    selectedTab: string
    isStaticTab: boolean
    summaryComponentRef: any
    updateSelectedIndex: Function
}

const ProspectResultContent: React.FC<PatientResultProps> = (
        {sessions, screeners, patient, summary, selectedTab, isStaticTab, summaryComponentRef, updateSelectedIndex}) => {
    const dispatch = useDispatch()
    const [selectedScreener, setSelectedScreener] = useState<string>()
    const [selectedScreenerSlug, setSelectedScreenerSlug] = useState<string>()
    const [questionAnswers, setQuestionAnswers] = useState<IScreenerQuestionAnswer[]>()
    const screenerQuestionAnswers = useSelector((state: StateParams) => state.physicianPatients.screenerQuestionAnswers)
    const fetchInProgress = useSelector((state: StateParams) => state.physicianPatients.fetchPatientQAndAInProgress)
    const { role } = useSelector((state: StateParams) => state.account)

    const StaticTabs : {[key: string] : () => JSX.Element | null }  = {
        "Summary": () => { return <ScreenerSummary sessions={sessions} patientDetails={patient} summary={summary} summaryComponentRef={summaryComponentRef} updateSelectedIndex={updateSelectedIndex} role={role}/> }
    }

    useEffect(() => {
        if(!isStaticTab) {
            const screener = screeners[selectedTab]
            setSelectedScreener(screener.id)
            setSelectedScreenerSlug(screener.slug)
            setQuestionAnswers([])
            dispatch(fetchPatientQAndA(patient.id, screener.id))
        }
    }, [selectedTab, isStaticTab])

    useEffect(() => {
        console.log("Screener Id", selectedScreener, screenerQuestionAnswers)
        if(selectedScreener && screenerQuestionAnswers && screenerQuestionAnswers[selectedScreener]) {
            console.log("Q and As", screenerQuestionAnswers)
            setQuestionAnswers(screenerQuestionAnswers[selectedScreener])
        }
    }, [screenerQuestionAnswers, selectedScreener])

    function renderNoQuestionsAnswered() {
        return <div>
            {fetchInProgress === false ? <p>No questions have been answered yet</p> : null}
        </div>;
    }

    function renderQuestionAnswerTab() {
        return questionAnswers && questionAnswers.length > 0 && selectedScreenerSlug
            ? <ScreenerQuestionAnswers summary={summary} screenerSlug={selectedScreenerSlug} questionAnswers={questionAnswers}/>
            : renderNoQuestionsAnswered();
    }

    return <div
        className={classNames(
            'w-full rounded-xl p-3 h-full',
            'ring-offset-2 focus:outline-none'
        )}>
        {isStaticTab
            ? StaticTabs[selectedTab]()
            : renderQuestionAnswerTab()}
    </div>
}

export default withScreenerScores(ProspectResultContent)
