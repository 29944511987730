import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { StateParams } from "../../../../store/reducers";
import { Cell, Label, Pie, Tooltip, PieChart } from "recharts";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const ClinicalConditionDistributionComponent = () => {
    const { registryClinicalConditionDistribution } = useSelector((state: StateParams) => state.patientsDashboard);

    const totalPatients = useMemo(() => registryClinicalConditionDistribution.reduce((sum, item) => sum + item.numberOfPatients, 0), [registryClinicalConditionDistribution]);

    const pieChartData = useMemo(() => {
        const data = [
            { name: "Depression", value: 0, color: "#4CAF50" },
            { name: "Anxiety", value: 0, color: "#2196F3" },
            { name: "Anxiety and Depression", value: 0, color: "#FF9800" },
            { name: "Others", value: 0, color: "#FFC107" },
        ];

        registryClinicalConditionDistribution.forEach((item) => {
            switch (item.clinicalCondition) {
                case "Depression":
                    data[0].value = item.percentage;
                    break;
                case "Anxiety":
                    data[1].value = item.percentage;
                    break;
                case "Anxiety and Depression":
                    data[2].value = item.percentage;
                    break;
                default:
                    data[3].value = item.percentage;
                    break;
            }
        });

        return data;
    }, [registryClinicalConditionDistribution]);

    const firstColumnData = pieChartData.slice(0, 2);
    const secondColumnData = pieChartData.slice(2);

    const CustomLabel: React.FC<{ viewBox?: any; total: number; label: string }> = ({ viewBox, total, label }) => {
        if (!viewBox) return null;
        const { cx, cy } = viewBox;
        return (
            <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                <tspan x={cx} dy="-20" fontSize="14">{label}</tspan>
                <tspan x={cx} dy="30" fontSize="20" fontWeight="bold">{total}</tspan>
            </text>
        );
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-1 mb-10">
            <div className="border border-gray-200 p-4 rounded-md bg-white">
                <div className="flex gap-48">
                    <div>
                        <div className="text-lg text-normal">Clinical conditions for active CoCM patients</div>
                        <div className="text-sm text-normal py-2 text-gray-500">
                            Percentage of patients in active treatment for various clinical conditions.
                        </div>
                        <div className="flex mt-10 gap-6 text-base text-gray-600">
                            <div>
                                {firstColumnData.map((item, index) => (
                                    <div key={index} className="flex items-center">
                                        <span className="w-2 h-2 rounded-full mr-2" style={{ backgroundColor: item.color }}></span>
                                        {item.name} - {item.value}%
                                    </div>
                                ))}
                            </div>
                            <div>
                                {secondColumnData.map((item, index) => (
                                    <div key={index} className="flex items-center">
                                        <span className="w-2 h-2 rounded-full mr-2" style={{ backgroundColor: item.color }}></span>
                                        {item.name} - {item.value}%
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <PieChart width={200} height={200}>
                            <Pie
                                data={pieChartData}
                                innerRadius={70}
                                outerRadius={100}
                                paddingAngle={0}
                                dataKey="value"
                            >
                                {pieChartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                                <Label
                                    content={
                                        <CustomLabel
                                            total={totalPatients}
                                            label="Total Patients"
                                        />
                                    }
                                />
                            </Pie>
                            <Tooltip
                                contentStyle={{
                                    backgroundColor: "black",
                                    border: "1px solid #ccc",
                                    borderRadius: "10px",
                                    padding: "3px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                }}
                                itemStyle={{ color: "white" }}
                                formatter={(value, name) => [`${value}%`, name]}
                            />
                        </PieChart>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClinicalConditionDistributionComponent;

