import {AnyAction} from "redux";
import {
    CatchupNotesResponse,
    CurrentQuestion,
    DischargedPatients,
    IAssignedScreeners,
    IProspect,
    ISummary,
    ITherapistPatient,
    LocationIndexItem,
    NotesStatus,
    PatientDailyCheckinInsights,
    PatientJourney,
    PatientProgressNotes,
    PatientSafetyPlanNotes,
    PatientSessionInsights,
    PsychiatristNotesResponse,
    SWScreenerInsights,
    SourcePatient,
    TreatmentPlanNotes
} from "../../../models";
import {
    CLEAR_ERROR_UPDATE_PATIENT_INFO,
    CLEAR_SCREENER_QUESTIONS,
    CLEAR_TOAST_NOTES_STATUS,
    FETCH_ASSIGNED_SCREENERS,
    FETCH_ASSIGNED_SCREENERS_FAILED,
    FETCH_ASSIGNED_SCREENERS_IN_PROGRESS,
    FETCH_ASSIGNED_SCREENERS_SUCCESS,
    FETCH_CATCHUP_NOTES,
    FETCH_CATCHUP_NOTES_FAILED,
    FETCH_CATCHUP_NOTES_IN_PROGRESS,
    FETCH_CATCHUP_NOTES_SUCCESS,
    FETCH_DAILY_CHECKIN_INSIGHTS,
    FETCH_DAILY_CHECKIN_INSIGHTS_FAILED,
    FETCH_DAILY_CHECKIN_INSIGHTS_IN_PROGRESS,
    FETCH_DAILY_CHECKIN_INSIGHTS_SUCCESS,
    FETCH_PATIENT,
    FETCH_PATIENT_BY_SOURCE_PATIENT_ID,
    FETCH_PATIENT_BY_SOURCE_PATIENT_ID_FAILED,
    FETCH_PATIENT_BY_SOURCE_PATIENT_ID_IN_PROGRESS,
    FETCH_PATIENT_BY_SOURCE_PATIENT_ID_SUCCESS,
    FETCH_PATIENT_FAILED,
    FETCH_PATIENT_IN_PROGRESS,
    FETCH_PATIENT_JOURNEY,
    FETCH_PATIENT_JOURNEY_FAILED,
    FETCH_PATIENT_JOURNEY_IN_PROGRESS,
    FETCH_PATIENT_JOURNEY_SUCCESS,
    FETCH_PATIENT_SUCCESS,
    FETCH_PATIENT_SUMMARY,
    FETCH_PATIENT_SUMMARY_FAILED,
    FETCH_PATIENT_SUMMARY_IN_PROGRESS,
    FETCH_PATIENT_SUMMARY_SUCCESS,
    FETCH_PATIENT_WITH_SUMMARY,
    FETCH_PATIENT_WITH_SUMMARY_FAILED,
    FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS,
    FETCH_PATIENT_WITH_SUMMARY_SUCCESS,
    FETCH_PATIENTS,
    FETCH_PATIENTS_FAILED,
    FETCH_PATIENTS_IN_PROGRESS,
    FETCH_PATIENTS_SUCCESS,
    FETCH_PROGRESS_NOTES,
    FETCH_PROGRESS_NOTES_FAILED,
    FETCH_PROGRESS_NOTES_IN_PROGRESS,
    FETCH_PROGRESS_NOTES_SUCCESS,
    FETCH_PSYCHIATRIST_NOTES,
    FETCH_PSYCHIATRIST_NOTES_FAILED,
    FETCH_PSYCHIATRIST_NOTES_IN_PROGRESS,
    FETCH_PSYCHIATRIST_NOTES_SUCCESS,
    FETCH_SAFETY_PLAN_NOTES,
    FETCH_SAFETY_PLAN_NOTES_FAILED,
    FETCH_SAFETY_PLAN_NOTES_IN_PROGRESS,
    FETCH_SAFETY_PLAN_NOTES_SUCCESS,
    FETCH_SCREENER_Q_AND_A,
    FETCH_SCREENER_Q_AND_A_FAILED,
    FETCH_SCREENER_Q_AND_A_IN_PROGRESS,
    FETCH_SCREENER_Q_AND_A_SUCCESS,
    FETCH_SESSION_INSIGHTS,
    FETCH_SESSION_INSIGHTS_FAILED,
    FETCH_SESSION_INSIGHTS_IN_PROGRESS,
    FETCH_SESSION_INSIGHTS_SUCCESS,
    FETCH_TREATMENT_NOTES,
    FETCH_TREATMENT_NOTES_FAILED,
    FETCH_TREATMENT_NOTES_IN_PROGRESS,
    FETCH_TREATMENT_NOTES_SUCCESS,
    REVIEW_AND_SUBMIT_TREATMENT_PLAN,
    REVIEW_AND_SUBMIT_TREATMENT_PLAN_FAILED,
    REVIEW_AND_SUBMIT_TREATMENT_PLAN_SUCCESS,
    SAVE_CATCHUP_NOTES,
    SAVE_CATCHUP_NOTES_FAILED,
    SAVE_CATCHUP_NOTES_IN_PROGRESS,
    SAVE_CATCHUP_NOTES_SUCCESS,
    SAVE_PROGRESS_NOTES,
    SAVE_PROGRESS_NOTES_FAILED,
    SAVE_PROGRESS_NOTES_IN_PROGRESS,
    SAVE_PROGRESS_NOTES_SUCCESS,
    SAVE_PSYCHIATRIST_NOTES,
    SAVE_PSYCHIATRIST_NOTES_FAILED,
    SAVE_PSYCHIATRIST_NOTES_IN_PROGRESS,
    SAVE_PSYCHIATRIST_NOTES_SUCCESS,
    SAVE_SAFETY_PLAN_NOTES,
    SAVE_SAFETY_PLAN_NOTES_FAILED,
    SAVE_SAFETY_PLAN_NOTES_IN_PROGRESS,
    SAVE_SAFETY_PLAN_NOTES_SUCCESS,
    SAVE_SCREENER_ANSWER,
    SAVE_SCREENER_ANSWER_FAILED,
    SAVE_SCREENER_ANSWER_IN_PROGRESS,
    SAVE_SCREENER_ANSWER_SUCCESS,
    SAVE_TREATMENT_NOTES,
    SAVE_TREATMENT_NOTES_FAILED,
    SAVE_TREATMENT_NOTES_IN_PROGRESS,
    SAVE_TREATMENT_NOTES_SUCCESS,
    SEARCH_PATIENTS,
    SEARCH_PATIENTS_FAILED,
    SEARCH_PATIENTS_IN_PROGRESS,
    SEARCH_PATIENTS_SUCCESS,
    SET_TREATMENT_PLAN_SCREENERS,
    UPDATE_LATEST_NOTES_STATUS,
    UPDATE_PATIENT_INFO,
    UPDATE_PATIENT_INFO_FAILED,
    UPDATE_PATIENT_INFO_IN_PROGRESS,
    UPDATE_PATIENT_INFO_SUCCESS,
    FETCH_PATIENT_LOCATION,
    FETCH_PATIENT_LOCATION_FAILED,
    FETCH_PATIENT_LOCATION_SUCCESS,
    CLEAR_UPDATE_PATIENT_INFO_STATUS,
    SEARCH_DISCHARGED_PATIENTS,
    SEARCH_DISCHARGED_PATIENTS_FAILED,
    SEARCH_DISCHARGED_PATIENTS_IN_PROGRESS,
    SEARCH_DISCHARGED_PATIENTS_SUCCESS,
    FETCH_SOURCE_PATIENT_DETAILS,
    FETCH_SOURCE_PATIENT_DETAILS_SUCCESS,
    FETCH_SOURCE_PATIENT_DETAILS_FAILED,
    CLEAR_PATIENT_AND_SOURCE_PATIENT,
    FETCH_SW_SCREENERS_INSIGHTS,
    FETCH_SW_SCREENERS_INSIGHTS_SUCCESS,
    FETCH_SW_SCREENERS_INSIGHTS_IN_PROGRESS,
    FETCH_SW_SCREENERS_INSIGHTS_FAILED,
    CLEAR_SW_SCREENER_INSIGHTS,
    FETCH_PATIENT_SCREENING_SUMMARY,
    FETCH_PATIENT_SCREENING_SUMMARY_FAILED,
    FETCH_PATIENT_SCREENING_SUMMARY_SUCCESS,
    FETCH_PATIENT_LOCATION_IN_PROGRESS,
    CLEAR_PATIENT_LOCATION,
    UPDATE_PATIENT_INFO_STATUS_SUCCESS
} from "../../actions/counsellor/patients.action";
import {SIGN_OUT_ACCOUNT} from "../../actions/common";

export interface PatientsParams {
    patients: ITherapistPatient[]
    patientsCount: number
    patient?: IProspect
    patientBySource?: IProspect
    treatmentNotes?: TreatmentPlanNotes
    treatmentNotesSavedKeys: string[]
    treatmentNotesCount?: number
    sessionNotesId?: string
    notesStatus?: NotesStatus
    toastNotesStatus?: NotesStatus
    progressNotes?: PatientProgressNotes
    progressNotesSavedKeys: string[]
    isTreatmentNotesSaved?: boolean
    isProgressNotesSaved?: boolean
    fetchPatientsInProgress?: boolean
    fetchPatientInProgress?: boolean
    fetchPatientBySourceInProgress?: boolean
    fetchTreatmentNotesInProgress?: boolean
    fetchProgressNotesInProgress?: boolean
    saveTreatmentNotesInProgress?: boolean
    saveProgressNotesInProgress?: boolean
    isTreatmentNotesReviewed?: boolean
    safetyPlanNotes?: PatientSafetyPlanNotes
    safetyPlanNotesSavedKeys: string[]
    isSafetyPlanNotesSaved?: boolean
    fetchSafetyPlanNotesInProgress?: boolean
    saveSafetyPlanNotesInProgress?: boolean
    patientJourney?: PatientJourney[]
    fetchPatientJourneyInProgress?: boolean
    sessionInsights?: PatientSessionInsights
    fetchSessionInsightsInProgress?: boolean
    dailyCheckinInsights?: PatientDailyCheckinInsights
    fetchDailyCheckinInsightsInProgress?: boolean
    screenersToBeAssigned?: string[]
    assignedScreeners?: IAssignedScreeners[]
    fetchAssignedScreenersInProgress?: boolean
    screenerQAndA?: CurrentQuestion
    fetchScreenerQAndAInProgress?: boolean

    fetchUpdatingPatientInfoInProgress?: boolean
    updatePatientInfoError?: string
    updatePatientInfoSuccess?: boolean

    catchupNotes?: CatchupNotesResponse
    catchupNotesSavedKeys: string[]
    fetchCatchupNotesInProgress?: boolean
    saveCatchupNotesInProgress?: boolean

    psychiatristNotes?: PsychiatristNotesResponse
    psychiatristNotesSavedKeys: string[]
    fetchPsychiatristNotesInProgress?: boolean
    savePsychiatristNotesInProgress?: boolean
    nextQuestionLink?: string

    fetchPatientSummaryInProgress?: boolean
    patientSummary?: any

    patientLocation?: LocationIndexItem
    fetchPatientLocationInProgress?: boolean

    dischargedPatients: DischargedPatients[]
    dischargedPatientsCount: number
    isDischargedPatientsSuccess?: boolean

    sourcePatient?: SourcePatient
    fetchSourcePatientInProgress?: boolean
    isSourcePatientSuccess?: boolean

    swScreenerInsights?: SWScreenerInsights[]
    fetchSwScreenerInsightsInProgress?: boolean
    fetchSwScreenerInsightsFailed?: boolean
}

const initialState: PatientsParams = {
    patients: [],
    patientsCount: 0,
    treatmentNotesSavedKeys: [],
    safetyPlanNotesSavedKeys: [],
    catchupNotesSavedKeys: [],
    progressNotesSavedKeys: [],
    psychiatristNotesSavedKeys: [],
    patient: undefined,
    patientBySource: undefined,
    treatmentNotes: undefined,
    treatmentNotesCount: undefined,
    sessionNotesId: undefined,
    notesStatus: undefined,
    progressNotes: undefined,
    isTreatmentNotesSaved: undefined,
    isProgressNotesSaved: undefined,
    fetchPatientsInProgress: undefined,
    fetchPatientInProgress: undefined,
    fetchPatientBySourceInProgress: undefined,
    fetchTreatmentNotesInProgress: undefined,
    fetchProgressNotesInProgress: undefined,
    saveTreatmentNotesInProgress: undefined,
    saveProgressNotesInProgress: undefined,
    isTreatmentNotesReviewed: undefined,
    safetyPlanNotes: undefined,
    isSafetyPlanNotesSaved: undefined,
    fetchSafetyPlanNotesInProgress: undefined,
    saveSafetyPlanNotesInProgress: undefined,
    patientJourney: undefined,
    fetchPatientJourneyInProgress: undefined,
    sessionInsights: undefined,
    fetchSessionInsightsInProgress: undefined,
    dailyCheckinInsights: undefined,
    fetchDailyCheckinInsightsInProgress: undefined,
    screenersToBeAssigned: undefined,
    catchupNotes: undefined,
    fetchCatchupNotesInProgress: undefined,
    saveCatchupNotesInProgress: undefined,
    assignedScreeners: undefined,
    fetchAssignedScreenersInProgress: undefined,
    screenerQAndA: undefined,
    fetchScreenerQAndAInProgress: undefined,
    nextQuestionLink: undefined,
    fetchPatientSummaryInProgress: undefined,
    patientSummary: undefined,
    patientLocation: undefined,
    fetchPatientLocationInProgress: undefined,
    updatePatientInfoSuccess: undefined,
    dischargedPatients: [],
    dischargedPatientsCount: 0,
    isDischargedPatientsSuccess: undefined,
    sourcePatient: undefined,
    fetchSourcePatientInProgress: undefined,
    isSourcePatientSuccess: undefined,
    swScreenerInsights: undefined,
    fetchSwScreenerInsightsInProgress: undefined,
    fetchSwScreenerInsightsFailed: undefined,
}

const PatientsReducer = (state: PatientsParams = initialState, action: AnyAction) : PatientsParams => {
    switch (action.type) {
        case FETCH_PATIENTS:
            state = {...state, patients: []}
            return state
        case FETCH_PATIENTS_FAILED:
            state = {...state, fetchPatientsInProgress: false}
            return state
        case FETCH_PATIENTS_IN_PROGRESS:
            state = {...state, fetchPatientsInProgress: true}
            return state
        case FETCH_PATIENTS_SUCCESS:
            state = { ...state, patients: action.payload.patients, fetchPatientsInProgress: false, patient: undefined, patientJourney: undefined }
            return state

        case SEARCH_PATIENTS:
            state = {...state, patients: [], patientsCount: 0, patient: undefined}
            return state
        case SEARCH_PATIENTS_FAILED:
            state = {...state, fetchPatientsInProgress: false}
            return state
        case SEARCH_PATIENTS_IN_PROGRESS:
            state = {...state, fetchPatientsInProgress: true}
            return state
        case SEARCH_PATIENTS_SUCCESS:
            state = {...state, 
                patients: action.payload.patients, 
                patientsCount: action.payload.count, 
                fetchPatientsInProgress: false,
                sourcePatient: undefined,
                swScreenerInsights: undefined
            }
            return state

        case FETCH_PATIENT:
            state = { ...state, patient: undefined }
            return state
        case FETCH_PATIENT_FAILED:
            state = { ...state, fetchPatientInProgress: false }
            return state
        case FETCH_PATIENT_IN_PROGRESS:
            state = { ...state, fetchPatientInProgress: true }
            return state
        case FETCH_PATIENT_SUCCESS:
            state = { ...state, patient: action.payload.patient, fetchPatientInProgress: false }
            return state

        case FETCH_PATIENT_WITH_SUMMARY:
            state = { ...state, patient: undefined }
            return state
        case FETCH_PATIENT_WITH_SUMMARY_FAILED:
            state = { ...state, fetchPatientInProgress: false }
            return state
        case FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS:
            state = { ...state, fetchPatientInProgress: true }
            return state
        case FETCH_PATIENT_WITH_SUMMARY_SUCCESS:
            state = {
                ...state, patient: action.payload.patient, fetchPatientInProgress: false
            }
            return state

        case FETCH_PATIENT_BY_SOURCE_PATIENT_ID:
            state = { ...state, patientBySource: undefined }
            return state
        case FETCH_PATIENT_BY_SOURCE_PATIENT_ID_FAILED:
            state = { ...state, fetchPatientBySourceInProgress: false }
            return state
        case FETCH_PATIENT_BY_SOURCE_PATIENT_ID_IN_PROGRESS:
            state = { ...state, fetchPatientBySourceInProgress: true }
            return state
        case FETCH_PATIENT_BY_SOURCE_PATIENT_ID_SUCCESS:
            state = { ...state, patientBySource: action.payload.patient, fetchPatientBySourceInProgress: false }
            return state

        case FETCH_TREATMENT_NOTES:
            state = { ...state, treatmentNotes: undefined, treatmentNotesCount: undefined }
            return state
        case FETCH_TREATMENT_NOTES_FAILED:
            state = { ...state, fetchTreatmentNotesInProgress: false, treatmentNotes: undefined }
            return state
        case FETCH_TREATMENT_NOTES_IN_PROGRESS:
            state = { ...state, fetchTreatmentNotesInProgress: true, treatmentNotes: undefined }
            return state
        case FETCH_TREATMENT_NOTES_SUCCESS:
            state = { ...state, treatmentNotes: action.payload.treatmentNotes, treatmentNotesCount: action.payload.treatmentPlanCount,
                sessionNotesId: action.payload.treatmentId ,fetchTreatmentNotesInProgress: false, notesStatus: action.payload.notesStatus }
            return state

        case FETCH_PROGRESS_NOTES:
            state = { ...state, progressNotes: undefined }
            return state
        case FETCH_PROGRESS_NOTES_FAILED:
            state = { ...state, fetchProgressNotesInProgress: false }
            return state
        case FETCH_PROGRESS_NOTES_IN_PROGRESS:
            state = { ...state, fetchProgressNotesInProgress: true }
            return state
        case FETCH_PROGRESS_NOTES_SUCCESS:
            state = { ...state, progressNotes: action.payload.progressNotes, fetchProgressNotesInProgress: false,
                sessionNotesId: action.payload.progressNotesId, notesStatus: action.payload.notesStatus  }
            return state

        case SAVE_TREATMENT_NOTES:
            state = { ...state, isTreatmentNotesSaved: undefined }
            return state
        case SAVE_TREATMENT_NOTES_FAILED:
            state = { ...state, saveTreatmentNotesInProgress: false }
            return state
        case SAVE_TREATMENT_NOTES_IN_PROGRESS:
            state = { ...state, saveTreatmentNotesInProgress: true }
            return state
        case SAVE_TREATMENT_NOTES_SUCCESS:
            state = { ...state,
                treatmentNotesSavedKeys: [...state.treatmentNotesSavedKeys, ...action.payload.keys],
                isTreatmentNotesSaved: action.payload.isTreatmentNotesSaved,
                saveTreatmentNotesInProgress: false
            }
            return state

        case SAVE_PROGRESS_NOTES:
            state = { ...state, isProgressNotesSaved: undefined }
            return state
        case SAVE_PROGRESS_NOTES_FAILED:
            state = { ...state, saveProgressNotesInProgress: false }
            return state
        case SAVE_PROGRESS_NOTES_IN_PROGRESS:
            state = { ...state, saveProgressNotesInProgress: true }
            return state
        case SAVE_PROGRESS_NOTES_SUCCESS:
            state = { ...state,
                progressNotesSavedKeys: [...state.progressNotesSavedKeys, ...action.payload.keys],
                isProgressNotesSaved: action.payload.isProgressNotesSaved,
                saveProgressNotesInProgress: false
            }
            return state

        case REVIEW_AND_SUBMIT_TREATMENT_PLAN:
            state = { ...state, isTreatmentNotesReviewed: undefined }
            return state
        case REVIEW_AND_SUBMIT_TREATMENT_PLAN_FAILED:
            state = { ...state, isTreatmentNotesReviewed: false }
            return state
        case REVIEW_AND_SUBMIT_TREATMENT_PLAN_SUCCESS:
            state = { ...state, isTreatmentNotesReviewed: action.payload.isTreatmentNotesReviewed }
            return state

        case FETCH_SAFETY_PLAN_NOTES:
            state = { ...state, safetyPlanNotes: undefined }
            return state
        case FETCH_SAFETY_PLAN_NOTES_FAILED:
            state = { ...state, fetchSafetyPlanNotesInProgress: false }
            return state
        case FETCH_SAFETY_PLAN_NOTES_IN_PROGRESS:
            state = { ...state, fetchSafetyPlanNotesInProgress: true }
            return state
        case FETCH_SAFETY_PLAN_NOTES_SUCCESS:
            state = { ...state, safetyPlanNotes: action.payload.safetyPlanNotes, fetchSafetyPlanNotesInProgress: false,
                notesStatus: action.payload.notesStatus }
            return state

        case SAVE_SAFETY_PLAN_NOTES:
            state = { ...state, isSafetyPlanNotesSaved: undefined }
            return state
        case SAVE_SAFETY_PLAN_NOTES_FAILED:
            state = { ...state, saveSafetyPlanNotesInProgress: false }
            return state
        case SAVE_SAFETY_PLAN_NOTES_IN_PROGRESS:
            state = { ...state, saveSafetyPlanNotesInProgress: true }
            return state
        case SAVE_SAFETY_PLAN_NOTES_SUCCESS:
            state = { ...state,
                safetyPlanNotesSavedKeys: [...state.safetyPlanNotesSavedKeys, ...action.payload.keys],
                isSafetyPlanNotesSaved: action.payload.isSafetyPlanNotesSaved,
                saveSafetyPlanNotesInProgress: false }
            return state

        case FETCH_PATIENT_JOURNEY:
            state = { ...state, patientJourney: undefined }
            return state
        case FETCH_PATIENT_JOURNEY_FAILED:
            state = { ...state, fetchPatientJourneyInProgress: false }
            return state
        case FETCH_PATIENT_JOURNEY_IN_PROGRESS:
            state = { ...state, fetchPatientJourneyInProgress: true }
            return state
        case FETCH_PATIENT_JOURNEY_SUCCESS:
            state = { ...state, patientJourney: action.payload.patientJourney, fetchPatientJourneyInProgress: false }
            return state

        case FETCH_SESSION_INSIGHTS:
            state = { ...state, sessionInsights: undefined }
            return state
        case FETCH_SESSION_INSIGHTS_FAILED:
            state = { ...state, fetchSessionInsightsInProgress: false }
            return state
        case FETCH_SESSION_INSIGHTS_IN_PROGRESS:
            state = { ...state, fetchSessionInsightsInProgress: true }
            return state
        case FETCH_SESSION_INSIGHTS_SUCCESS:
            state = { ...state, sessionInsights: action.payload.sessionInsights, fetchSessionInsightsInProgress: false }
            return state

        case FETCH_DAILY_CHECKIN_INSIGHTS:
            state = { ...state, dailyCheckinInsights: undefined }
            return state
        case FETCH_DAILY_CHECKIN_INSIGHTS_FAILED:
            state = { ...state, fetchDailyCheckinInsightsInProgress: false }
            return state
        case FETCH_DAILY_CHECKIN_INSIGHTS_IN_PROGRESS:
            state = { ...state, fetchDailyCheckinInsightsInProgress: true }
            return state
        case FETCH_DAILY_CHECKIN_INSIGHTS_SUCCESS:
            state = { ...state, dailyCheckinInsights: action.payload.dailyCheckinInsights, fetchDailyCheckinInsightsInProgress: false }
            return state

        case FETCH_SW_SCREENERS_INSIGHTS:
            state = { ...state, swScreenerInsights: undefined }
            return state
        case FETCH_SW_SCREENERS_INSIGHTS_FAILED:
            state = { ...state, fetchSwScreenerInsightsInProgress: false, fetchSwScreenerInsightsFailed: true }
            return state
        case FETCH_SW_SCREENERS_INSIGHTS_IN_PROGRESS:
            state = { ...state, fetchSwScreenerInsightsInProgress: true, fetchSwScreenerInsightsFailed: false }
            return state
        case FETCH_SW_SCREENERS_INSIGHTS_SUCCESS:
            state = { ...state, swScreenerInsights: action.payload.swScreenerInsights, fetchSwScreenerInsightsInProgress: false, fetchSwScreenerInsightsFailed: false }
            return state
        case CLEAR_SW_SCREENER_INSIGHTS:
            state = { ...state, swScreenerInsights: undefined }
            return state

        case SET_TREATMENT_PLAN_SCREENERS:
            state = { ...state, screenersToBeAssigned: action.payload.screeners }
            return state
        case UPDATE_LATEST_NOTES_STATUS:
            state = { ...state, notesStatus: action.payload.notesStatus, toastNotesStatus: action.payload.notesStatus }
            return state
        case CLEAR_TOAST_NOTES_STATUS:
            state = { ...state, toastNotesStatus: undefined }
            return state

        case FETCH_CATCHUP_NOTES:
            state = { ...state, catchupNotes: undefined }
            return state
        case FETCH_CATCHUP_NOTES_FAILED:
            state = { ...state, fetchCatchupNotesInProgress: false }
            return state
        case FETCH_CATCHUP_NOTES_IN_PROGRESS:
            state = { ...state, fetchCatchupNotesInProgress: true }
            return state
        case FETCH_CATCHUP_NOTES_SUCCESS:
            state = {
                ...state, catchupNotes: action.payload, fetchCatchupNotesInProgress: false,
                sessionNotesId: action.payload.catchupNotesId, notesStatus: action.payload.notesStatus
            }
            return state

        case SAVE_CATCHUP_NOTES:
            state = { ...state, catchupNotes: undefined }
            return state
        case SAVE_CATCHUP_NOTES_FAILED:
            state = { ...state, fetchCatchupNotesInProgress: false }
            return state
        case SAVE_CATCHUP_NOTES_IN_PROGRESS:
            state = { ...state, fetchCatchupNotesInProgress: true }
            return state
        case SAVE_CATCHUP_NOTES_SUCCESS:
            state = {
                ...state,
                catchupNotesSavedKeys: [...state.catchupNotesSavedKeys, ...action.payload.keys],
                notesStatus: action.payload.catchupNotesResponse.notesStatus,
                fetchCatchupNotesInProgress: false
            }
            return state


        case FETCH_PSYCHIATRIST_NOTES:
            state = { ...state, psychiatristNotes: undefined }
            return state
        case FETCH_PSYCHIATRIST_NOTES_FAILED:
            state = { ...state, fetchPsychiatristNotesInProgress: false }
            return state
        case FETCH_PSYCHIATRIST_NOTES_IN_PROGRESS:
            state = { ...state, fetchPsychiatristNotesInProgress: true }
            return state
        case FETCH_PSYCHIATRIST_NOTES_SUCCESS:
            state = {
                ...state, psychiatristNotes: action.payload, fetchPsychiatristNotesInProgress: false,
                sessionNotesId: action.payload.psychiatristNotesId, notesStatus: action.payload.notesStatus
            }
            return state

        case SAVE_PSYCHIATRIST_NOTES:
            state = { ...state, psychiatristNotes: undefined }
            return state
        case SAVE_PSYCHIATRIST_NOTES_FAILED:
            state = { ...state, fetchPsychiatristNotesInProgress: false }
            return state
        case SAVE_PSYCHIATRIST_NOTES_IN_PROGRESS:
            state = { ...state, fetchPsychiatristNotesInProgress: true }
            return state
        case SAVE_PSYCHIATRIST_NOTES_SUCCESS:
            state = {
                ...state,
                psychiatristNotesSavedKeys: [...state.psychiatristNotesSavedKeys, ...action.payload.keys],
                notesStatus: action.payload.psychiatristNotesResponse.notesStatus,
                fetchPsychiatristNotesInProgress: false
            }
            return state

        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state

        case FETCH_ASSIGNED_SCREENERS:
            state = { ...state, assignedScreeners: undefined }
            return state
        case FETCH_ASSIGNED_SCREENERS_FAILED:
            state = { ...state, fetchAssignedScreenersInProgress: false }
            return state
        case FETCH_ASSIGNED_SCREENERS_IN_PROGRESS:
            state = { ...state, fetchAssignedScreenersInProgress: true }
            return state
        case FETCH_ASSIGNED_SCREENERS_SUCCESS:
            state = {...state, assignedScreeners: action.payload.patientAssignedScreeners}
            return state

        case FETCH_SCREENER_Q_AND_A:
            state = { ...state, screenerQAndA: undefined }
            return state
        case FETCH_SCREENER_Q_AND_A_FAILED:
            state = { ...state, fetchScreenerQAndAInProgress: false }
            return state
        case FETCH_SCREENER_Q_AND_A_IN_PROGRESS:
            state = { ...state, fetchScreenerQAndAInProgress: true }
            return state
        case FETCH_SCREENER_Q_AND_A_SUCCESS:
            state = {...state, screenerQAndA: action.payload.screenerQAndA}
            return state

        case SAVE_SCREENER_ANSWER:
            state = { ...state, nextQuestionLink: undefined, swScreenerInsights: undefined }
            return state
        case SAVE_SCREENER_ANSWER_FAILED:
            state = { ...state, fetchScreenerQAndAInProgress: false }
            return state
        case SAVE_SCREENER_ANSWER_IN_PROGRESS:
            state = { ...state, fetchScreenerQAndAInProgress: true }
            return state
        case SAVE_SCREENER_ANSWER_SUCCESS:
            state = { ...state, nextQuestionLink: action.payload.notesStatus, fetchScreenerQAndAInProgress: false }
            return state

        case UPDATE_PATIENT_INFO:
            state = {...state, fetchUpdatingPatientInfoInProgress: false}
            return state
        case UPDATE_PATIENT_INFO_FAILED:
            state = {...state, updatePatientInfoError: action.payload.error, fetchUpdatingPatientInfoInProgress: false,
                updatePatientInfoSuccess: false}
            return state
        case UPDATE_PATIENT_INFO_IN_PROGRESS:
            state = {...state, fetchUpdatingPatientInfoInProgress: true}
            return state
        case UPDATE_PATIENT_INFO_SUCCESS:
            state = {...state, fetchUpdatingPatientInfoInProgress: false, updatePatientInfoSuccess: true,
                patient: state.patient ? {
                ...state.patient, phoneNumber: action.payload.contactPhoneNumber
              } : undefined}
            return state

        case UPDATE_PATIENT_INFO_STATUS_SUCCESS:
            state = {...state, updatePatientInfoSuccess: true}
            return state

        case CLEAR_UPDATE_PATIENT_INFO_STATUS:
            state = {...state, updatePatientInfoSuccess: undefined}
            return state

        case FETCH_PATIENT_SUMMARY:
            state = {...state, fetchPatientSummaryInProgress: false}
            return state
        case FETCH_PATIENT_SUMMARY_FAILED:
            state = {...state, fetchPatientSummaryInProgress: false}
            return state
        case FETCH_PATIENT_SUMMARY_IN_PROGRESS:
            state = {...state, fetchPatientSummaryInProgress: true}
            return state
        case FETCH_PATIENT_SUMMARY_SUCCESS:
            state = {...state, fetchPatientSummaryInProgress: false, patientSummary: action.payload.patientSummary}
            return state

        case FETCH_PATIENT_LOCATION:
            state = { ...state, fetchPatientLocationInProgress: false }
            return state
        case FETCH_PATIENT_LOCATION_FAILED:
            state = { ...state, fetchPatientLocationInProgress: false }
            return state
        case FETCH_PATIENT_LOCATION_SUCCESS:
            state = { ...state, fetchPatientLocationInProgress: false, patientLocation: action.payload.patientLocation }
            return state
        case FETCH_PATIENT_LOCATION_IN_PROGRESS:
            state = { ...state, fetchPatientLocationInProgress: true }
            return state
        case CLEAR_PATIENT_LOCATION:
            state = { ...state, patientLocation: undefined, fetchPatientLocationInProgress: false }
            return state

        case SEARCH_DISCHARGED_PATIENTS:
            state = {...state, isDischargedPatientsSuccess: undefined, sourcePatient: undefined}
            return state
        case SEARCH_DISCHARGED_PATIENTS_FAILED:
            state = {...state, isDischargedPatientsSuccess: false}
            return state
        case SEARCH_DISCHARGED_PATIENTS_SUCCESS:
            state = {...state, 
                isDischargedPatientsSuccess: true, 
                dischargedPatients: action.payload.patients,
                dischargedPatientsCount: action.payload.count,
                patient: undefined, patientJourney: undefined, swScreenerInsights: undefined
            }
            return state

        case FETCH_SOURCE_PATIENT_DETAILS:
            state = {...state, isSourcePatientSuccess: false}
            return state
        case FETCH_SOURCE_PATIENT_DETAILS_FAILED:
            state = {...state, isSourcePatientSuccess: false}
            return state
        case FETCH_SOURCE_PATIENT_DETAILS_SUCCESS:
            state = {...state, isSourcePatientSuccess: true, sourcePatient: action.payload.sourcePatient}
            return state
        case CLEAR_PATIENT_AND_SOURCE_PATIENT:
            state = {...state, sourcePatient: undefined, patient: undefined}
            return state

        case CLEAR_SCREENER_QUESTIONS:
            state = {...state, screenerQAndA: undefined}
            return state

        case CLEAR_ERROR_UPDATE_PATIENT_INFO:
            state = { ...state, updatePatientInfoError: undefined }
            return state

        case FETCH_PATIENT_SCREENING_SUMMARY:
            state = { ...state }
            return state
        // case FETCH_PATIENT_SCREENED_DATES_FAILED:
        //     state = { ...state, screenedDates: undefined }
        //     return state
        case FETCH_PATIENT_SCREENING_SUMMARY_SUCCESS:
            state = {
                ...state, patient: state.patient ? {
                    ...state.patient, patient: state.patient.patient ? {
                        ...state.patient.patient, screeners: action.payload.patient.screeners
                    } : undefined,
                    summary: action.payload.summary,
                } : undefined
            }
            return state

        default:
            return state;
    }
}

export default PatientsReducer
