import { DateTime } from "luxon"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { InsuranceStatusTypes, IProspect, Roles } from "../../models"
import { RoutePaths } from "../../shared/Utils"
import { StateParams } from "../../store/reducers"
import { PageButtonV2 } from "../generic/Buttons"
import CalendarIcon from "../../assets/images/common/calendar.svg"
import { Tooltip } from 'react-tooltip'
import { useFlags } from "flagsmith/react"


interface Props {
    prospect: IProspect,
    url: string,
    insuranceStatus?: string
}

const ScheduleAppointmentButton = ({ prospect, url, insuranceStatus }: Props) => {
    const flags = useFlags(['cc_patient_insurance']);
    const isPatientInsuranceEnabled = flags.cc_patient_insurance.enabled
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)
    if (role !== Roles.Care_Coordinator) return null;
   

    const prospectDob = DateTime.fromJSDate(new Date(prospect.dob), { zone: "utc" }).toFormat('MM/dd/yyyy')
    const isButtonDisabled = insuranceStatus !== InsuranceStatusTypes.eligible


    function renderToolTip() {
        return <Tooltip style={{ borderRadius: 5, zIndex: 9999 }} id="insurance-status-tooltip" >
            <div className={"w-[200px]"}>
                {
                    insuranceStatus === InsuranceStatusTypes.not_eligible ? <span>
                        The patient is 'Not Eligible' for insurance, cannot be booked for Intake.
                    </span> : <span>
                        The patient must be marked as 'Eligible' for insurance in order to book an intake.
                    </span>
                }
            </div>
        </Tooltip>
    }

    return (
        <div>
            <Link
                to={isButtonDisabled ? "#" : url}
                state={{
                    sessionTypes: 'Intake',
                    patientName: `${prospect.firstName} ${prospect.lastName}`,
                    dob: prospectDob,
                    practiceName: prospect.referredByPracticeName,
                    patientId: prospect.id,
                    sourcePatientId: prospect.sourcePatientId,
                    practiceId: prospect.referredByPracticeId
                }}
            >
                <PageButtonV2
                    className={`ml-2 px-[16px] py-[8px] h-8 text-sm border-2 border-sjOrange ${isButtonDisabled ? "!bg-sjGrey !text-white border-none cursor-not-allowed" : "text-sjOrange"}`}
                    data-tooltip-id="insurance-status-tooltip"
                    disabled={(isButtonDisabled && isPatientInsuranceEnabled)}
                >
                    <div className={"flex flex-row gap-x-2"}>
                        <span>Schedule Appointment</span>
                        <img src={CalendarIcon} width={14} height={14}
                            className={`${isButtonDisabled ? "opacity-30 brightness-0 !text-white" : ""} hover:cursor-pointer`}
                        />
                    </div>
                </PageButtonV2>
            </Link>
            {isButtonDisabled && renderToolTip()}
        </div>
    )
}

export default ScheduleAppointmentButton