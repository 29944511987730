import ENV from './ENV';
import ImageConfig from './ImageConfig';
const SCREENING_SOURCE_MAP: {[key: string]: string} = {
    "sms-temp-link": "SMS",
    "provider-web": "Practice",
    "provider-mobile": "Practice"
}


export {
    ENV,
    SCREENING_SOURCE_MAP,
    ImageConfig
}
