import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FiltersTypes, ReferredPatientsAdvancedFilters, Roles } from "../../../../models";
import { StateParams } from "../../../../store/reducers";
import PaginationV2 from "../../../../components/generic/PaginationV2";
import StatusTabs from "../../../../components/generic/StatusTabs";
import ProspectTable from "../../../../components/prospects/ProspectTable";
import { IoFilter } from "react-icons/io5";
import Modal from 'react-modal';
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { DateTime } from 'luxon';
import { HiCalendarDays } from "react-icons/hi2";
import { Button } from "../../../../components/generic/Buttons";
import DatePicker from 'react-datepicker';
import { fetchReferredPatients } from "../../../../store/actions/care-coordinator/patients.action";
import TableFilterPaginationV2 from "../../../../components/generic/TableFilterPaginationV2";
import { ReferredPatientsDataView } from "../../../../components/patients/care-coordinator/ReferredPatients";
import { fetchPractices } from "../../../../store/actions/counsellor/prospects.action";


const CARECOORDINATORTABS = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "Scheduled", value: "scheduled" },
    { label: "Reschedule", value: "reschedule" },
    { label: "Call Again", value: "call_again" },
    { label: "No Answer", value: "no_answer" },
    { label: "No Show", value: "no_show" },
    { label: "Not Accepted", value: "not_accepted" }
];

const INSURANCE_STATUS_OPTIONS = [
    { label: "Pending", value: "pending" },
    { label: "Semi Verified", value: "semi_verified" },
    { label: "Verified", value: "verified" },
    { label: "Eligible", value: "eligible" },
    { label: "Not Eligible", value: "not_eligible" },
    { label: 'Missing Subscriber Info', value: 'invalid_or_missing_subscriber_information' },
];

const PATIENT_CALL_STATUS_OPTIONS = [
    { value: 'pending', label: 'Pending' },
    { value: 'no_answer', label: 'No Answer' },
    { value: 'call_again', label: 'Call Again' },
    { value: 'interested', label: 'Interested' },
    { value: 'not_interested', label: 'Not Interested' },
];

const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
]

const ReferredPatientsScreen = () => {
    const prospectsDataViewForRole = new ReferredPatientsDataView()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { prospects, prospectsCount, tabCount, fetchProspectsInProgress } = useSelector((state: StateParams) => state.coordinatorPatients)
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [filters, setFilters] = useState<ReferredPatientsAdvancedFilters>();
    const [searchText, setSearchText] = useState('')
    const defaultTab = "pending"
    const [activeTab, setActiveTab] = useState(searchParams.get("tab") || defaultTab);
    const [filterCount, setFilterCount] = useState(0);
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const { practices } = useSelector((state: StateParams) => state.prospects)
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);
    const [practiceId, setPracticeId] = useState<string[]>([]);
    const [callStatus, setCallStatus] = useState<string | null>(null);
    const startDateString = filterStartDate ? DateTime.fromJSDate(filterStartDate).startOf('day').toFormat('MM-dd-yyyy') : undefined;
    const endDateString = filterEndDate ? DateTime.fromJSDate(filterEndDate).startOf('day').toFormat('MM-dd-yyyy') : undefined;
    const [gender, setGender] = useState<string | null>(null);
    const [dob, setDob] = useState<Date | null>(null);



    const handleSelectedFilters = (status: string) => {
        setCurrentPage(1)
        setActiveTab(status);
    }

    useEffect(() => {
            dispatch(fetchPractices())
        }, [])

    useEffect(() => {
        if (openFilterModal && filters) {
            setFilterStartDate(filters.startDate ? DateTime.fromFormat(filters.startDate, 'yyyy-MM-dd').toJSDate() : undefined);
            setFilterEndDate(filters.endDate ? DateTime.fromFormat(filters.endDate, 'yyyy-MM-dd').toJSDate() : undefined);
            setPracticeId(filters.practiceId || []);
            setGender(filters.gender || null);
            setDob(filters.dobMin ? DateTime.fromISO(filters.dobMin).toJSDate() : null);
            setCallStatus(filters.callStatus || null);
        }
    }, [openFilterModal, filters]);

    useEffect(() => {
        if (searchParams && searchParams.get("page")) {
            setCurrentPage(Number(searchParams.get("page")) || 1)
            setActiveTab(searchParams.get("tab") || defaultTab)
        }
    }, [searchParams])

    useEffect(() => {
        let selectedTab = activeTab === 'all' ? '' : activeTab;
        dispatch(fetchReferredPatients(currentPage, recordsPerPage, selectedTab, filters, searchText));
    }, [currentPage, recordsPerPage, filters, searchText, activeTab, searchParams, dispatch]);

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
    }, []);

    const handleInputClick = () => {
        setShowCustomDateFilter(!showCustomDateFilter);
    };

    const handleCancel = useCallback(() => {
        setFilterStartDate(undefined)
        setFilterEndDate(undefined)
        setShowCustomDateFilter(false);
    }, []);

    const handleClear = useCallback(() => {
        setFilterStartDate(undefined)
        setFilterEndDate(undefined)
    }, []);


    const handleApply = () => {
        const dobMinDate = dob ? DateTime.fromJSDate(dob).startOf('day') : null;
        const dobMaxDate = dobMinDate ? dobMinDate.endOf('day') : null;

        setFilters({
            practiceId: practiceId,
            startDate: filterStartDate ? DateTime.fromJSDate(filterStartDate).toFormat('yyyy-MM-dd') : null,
            endDate: filterEndDate ? DateTime.fromJSDate(filterEndDate).toFormat('yyyy-MM-dd') : null,
            callStatus: callStatus,
            gender: gender,
            dobMin: dobMinDate ? dobMinDate.toISO() : null,
            dobMax: dobMaxDate ? dobMaxDate.toISO() : null,
        });

        setOpenFilterModal(false);

        setFilterCount(
            (practiceId.length > 0 ? 1 : 0) +
            (filterStartDate && filterEndDate ? 1 : 0) +
            (callStatus ? 1 : 0) +
            (gender ? 1 : 0) +
            (dob ? 1 : 0)
        );
    };


    const handleReset = () => {
        setPracticeId([]);
        setFilterStartDate(undefined);
        setFilterEndDate(undefined);
        setGender(null);
        setDob(null);
        setCallStatus(null);
        setCurrentPage(1);
    }


    const getStatusTabClassName = (value: string) => {
        return activeTab === value ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"
    };

    return <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
        <div className="flex flex-row justify-between pb-4">
            <h1 className="text-xl">Referred Patients </h1>
            <div className="w-[240px]">
                <Select
                    className="placeholder: italic"
                    options={INSURANCE_STATUS_OPTIONS}
                    placeholder={"Insurance Status"}
                    isClearable={true}
                    isSearchable={false}
                    isMulti={false}
                    getOptionLabel={option => `${option?.label}`}
                    getOptionValue={option => option?.value}
                    onChange={(selectedOption) => {
                        setFilters((prevFilters: any) => ({
                            ...prevFilters,
                            insuranceStatus: selectedOption?.value
                        }))
                    }}
                />
            </div>
        </div>
        <div className={"flex justify-between items-center pb-2"}>
            <div className="inline-flex rounded-md shadow-sm">
                <StatusTabs
                    tabs={CARECOORDINATORTABS}
                    handleSelectedFilters={handleSelectedFilters}
                    getTabClassName={getStatusTabClassName}
                    tabCount={tabCount}
                />


            </div>
            <div className={"flex justify-between space-x-2"}>
                <TableFilterPaginationV2
                    setTableFilter={setSearchText}
                    currentPageHandler={setCurrentPage}
                />
                <button className="btn btn-icon relative"
                    onClick={() => setOpenFilterModal(true)}
                    aria-label="Open Advanced Filters">
                    <IoFilter className="h-7 w-7 cursor-pointer" color="#575F6E"
                    />
                    {filterCount > 0 && (
                        <span className="absolute bottom-4 left-3 flex h-5 w-5 items-center justify-center rounded-full bg-orange-500 text-white text-xs">
                            {filterCount}
                        </span>
                    )}
                </button>
            </div>
        </div>
        <ProspectTable
            dataView={prospectsDataViewForRole}
            prospects={prospects || []}
            searchInProgress={fetchProspectsInProgress}
            activeTab={activeTab} />

        {prospects && prospects.length > 0 &&
            <PaginationV2
                totalRows={prospectsCount || 0}
                rowsPerPage={recordsPerPage}
                recordsPerPageHandler={setRecordsPerPage}
                currentPage={currentPage}
                currentPageHandler={setCurrentPage}
                showPages={false}
            />
        }

        <Modal
            isOpen={openFilterModal}
            onRequestClose={() => setOpenFilterModal(false)}
            className="fixed top-0 right-0 mx-auto p-5 border w-1/4 shadow-lg bg-white h-full max-h-screen overflow-y-auto"
        >
            <div className="flex justify-between">
                <h1 className="text-lg leading-27">Advance Filters</h1>
                <AiOutlineClose onClick={() => setOpenFilterModal(false)} className="h-5 w-5 cursor-pointer" color="#575F6E" />
            </div>
            <div className="space-y-4 mt-8">

                <div>
                    <span className="content-start">Practice</span>
                    <Select
                        className="placeholder: italic"
                        options={practices}
                        placeholder={"Practice"}
                        isClearable={true}
                        isSearchable={false}
                        isMulti={true}
                        getOptionLabel={option => `${option?.name}`}
                        getOptionValue={option => option?.id}
                        onChange={(selectedOptions) => {
                            setPracticeId(selectedOptions ? selectedOptions.map(option => option.id) : [])
                        }}
                        value={practices?.filter(practice => practiceId.includes(practice.id))}
                    />
                </div>

                <div>
                    <span className="content-start">Gender</span>
                    <Select
                        className="placeholder: italic"
                        options={genderOptions}
                        placeholder={'Gender'}
                        isClearable={true}
                        isSearchable={false}
                        isMulti={false}
                        getOptionLabel={option => `${option?.label}`}
                        getOptionValue={option => option?.value}
                        value={genderOptions.find(option => option.value === gender) || null}
                        onChange={(selectedOption) => {
                            if (!selectedOption) {
                                setGender(null)
                                return
                            }
                            setGender(selectedOption.value)
                        }}
                    />
                </div>

                <div>
                    <span className="content-start">Date of Birth</span>
                    <DatePicker
                        className={
                            "p-2 w-full border-[1.3px] rounded-md h-10 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50 placeholder:font-normal"
                        }
                        dateFormat="MM-dd-yyyy"
                        maxDate={new Date()}
                        todayButton="Today"
                        dropdownMode="select"
                        shouldCloseOnSelect
                        onChange={(date) => {
                            setDob(date)
                        }}
                        selected={dob}
                        timeIntervals={10}
                        placeholderText="Date of Birth"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        isClearable

                    />
                </div>

                <div className="physician-dashboard-date-filter">
                    <span className="content-start">Referred Date</span>
                    <div className="relative">
                        <input
                            value={filterStartDate && filterEndDate ? `${startDateString} - ${endDateString}` : ''}
                            placeholder="Referred Date"
                            className={"p-2 w-full border-[1.3px] rounded-md h-10 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                            readOnly
                            onClick={handleInputClick}
                        />
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <HiCalendarDays className="w-5 h-5 text-sjLightGrey" />
                        </span>
                    </div>
                    {showCustomDateFilter &&
                        <div className="absolute mt-2 ml-4 text-center">
                            <DatePicker
                                onChange={handleChange}
                                startDate={filterStartDate}
                                endDate={filterEndDate}
                                maxDate={new Date()}
                                selectsRange
                                inline
                            >
                                <div className="custom-date-filter-actions mb-2">
                                    <button
                                        onClick={handleCancel}
                                        className="rounded-l-md text-sjDarkGray py-2 px-2"
                                    >Cancel
                                    </button>
                                    <button
                                        onClick={handleClear}
                                        className="rounded-l-md border-sjOrange text-sjOrange py-2 px-4"
                                    >Clear
                                    </button>&nbsp;
                                    <button
                                        onClick={() => {
                                            setShowCustomDateFilter(false);
                                        }}
                                        disabled={!filterStartDate || !filterEndDate}
                                        className="bg-sjOrange text-white py-2 px-4 rounded"
                                    >Apply
                                    </button>
                                </div>
                            </DatePicker>
                        </div>
                    }
                </div>

                {/* <div>
                    <span className="content-start">Patient Call</span>
                    <Select
                        className="placeholder: italic"
                        options={PATIENT_CALL_STATUS_OPTIONS}
                        placeholder={"Patient Call"}
                        isClearable={true}
                        isSearchable={false}
                        isMulti={false}
                        getOptionLabel={option => `${option?.label}`}
                        getOptionValue={option => option?.value}
                        value={PATIENT_CALL_STATUS_OPTIONS.find(option => option.value === callStatus) || null}
                        onChange={(selectedOption) => {
                            if (!selectedOption) {
                                setCallStatus(null)
                                return
                            }
                            setCallStatus(selectedOption.value)
                        }}
                    />
                </div> */}


                <div className="flex gap-4 justify-end">
                    <Button className={"!border-sjOrange !text-sjOrange"}
                        onClick={() => {
                            handleReset()
                            setFilterCount(0)
                        }
                        }
                    >Reset</Button>
                    <Button className={"!bg-sjOrange !text-white"}
                        onClick={handleApply}
                    >Confirm</Button>
                </div>


            </div>
        </Modal>
    </div>
}

export default ReferredPatientsScreen
