import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {
    createAppointment,
    fetchSession,
    fetchTherapists,
    resetCreateAppointment
} from "../../store/actions/counsellor/appointments.action";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { StateParams } from "../../store/reducers";
import { useNavigate } from "react-router";
import "./Datepicker.scss"
import { ITherapistsResponse, SessionTypes } from "../../models";
import { AiOutlineClose } from "react-icons/ai";
import _ from "lodash";
import Modal from 'react-modal';
import {formatTimeZone} from "../../shared/DateUtils";

type AppointmentFormData = {
    therapistId: string
    patientId: string
    sessionType: keyof typeof SessionTypes
    selectedDate: Date
    modality: string
};

const CoordinatorAppointmentCreateComponent: React.FC = () => {

    const dispatch = useDispatch()
    const router = useNavigate()
    const location = useLocation();
    const { control, register, handleSubmit, setError, formState: { errors }, reset, watch, getValues } = useForm<AppointmentFormData>();
    const appointmentCreated = useSelector((state: StateParams) => state.appointments.createdAppointment)
    const createAppointmentError = useSelector((state: StateParams) => state.appointments.createAppointmentError)
    const { therapists } = useSelector((state: StateParams) => state.appointments)
    const [appointmentRequest, setAppointmentRequest] = useState<AppointmentFormData>()
    const timeZone = Intl.DateTimeFormat(undefined, { timeZone: 'America/New_York' }).resolvedOptions().timeZone;

    const prospectDetails = {
        sessionTypes: location.state?.sessionTypes,
        patientName: location.state?.patientName,
        dob: location.state?.dob,
        practiceName: location.state?.practiceName,
        patientId: location.state?.patientId,
        sourcePatientId: location.state?.sourcePatientId,
        practiceId: location.state?.practiceId
    }
    const modality = [
        { key: "phone", value: "Phone" },
        { key: "video", value: "Video" }
    ]

    const debouncedSubmit = useCallback(_.debounce((data: AppointmentFormData) => {
        if (data.therapistId !== "0" && data.modality !== "0") {
            setAppointmentRequest({ ...data, patientId: prospectDetails.patientId, sessionType: prospectDetails.sessionTypes as keyof typeof SessionTypes })
            const scheduledDate = DateTime.fromJSDate(data.selectedDate).setZone('America/New_York', {
                keepLocalTime: true
            }).toUTC().toISO()
            if (scheduledDate) {
                const appointmentRequest = { ...data, scheduledDate: scheduledDate, patientId: prospectDetails.patientId, sessionType: prospectDetails.sessionTypes as keyof typeof SessionTypes }
                dispatch(createAppointment(appointmentRequest, prospectDetails.sourcePatientId, prospectDetails.practiceId))
            }
        } else {
            if (data.therapistId === "0") {
                setError("therapistId", {
                    type: "manual",
                    message: "Social Worker selection is required."
                });
            }
            if (data.modality === "0") {
                setError("modality", {
                    type: "manual",
                    message: "Modality selection is required."
                });
            }
        }
    }, 2000, { leading: true }), [])

    const onSubmit = handleSubmit(debouncedSubmit)

    useEffect(() => {
        if (appointmentRequest && appointmentCreated && !createAppointmentError) {
            router(-1)
            dispatch(fetchSession(prospectDetails.patientId, SessionTypes.Intake));
            dispatch(resetCreateAppointment());
        }
    }, [appointmentRequest, appointmentCreated, createAppointmentError, dispatch])

    useEffect(() => {
        dispatch(fetchTherapists())
    }, [])

    function renderTopButtonPanel() {
        return <div>
            <Link to={'...'} onClick={(e) => { e.preventDefault(); router(-1); }}>
                <AiOutlineClose
                    className="text-gray-500 hover:text-gray-700"
                    style={{ width: '25px', height: '25px' }}
                />
            </Link>
        </div>;
    }

    const filterTimeToBeInFuture = (time: Date) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    function renderAppointmentForm() {
        return (
            <div className="p-10">
                <form onSubmit={onSubmit}>
                    <div className="flex justify-between">
                        <label className="text-[18px] font-semibold">Schedule Intake Appointment</label>
                        {renderTopButtonPanel()}
                    </div>
                    <div className="text-sm mt-[16px] text-sjLightGrey font-thin">Book an intake appointment for <span className="text-black">{prospectDetails.patientName} ({prospectDetails.dob}) </span>  of <span className="text-black">{prospectDetails.practiceName}</span> Practice.</div>
                    <div className="flex mt-[37px]">
                        <div>
                            <div className="text-gray-700 font-nunito text-sm font-normal leading-6">Social Worker</div>
                            <select {...register("therapistId", { required: true })}
                                className={`mt-[8px] w-[332px] h-[44px] flex-shrink-0 rounded-md border border-gray-300 placeholder-gray-500 ${errors.patientId
                                    ? "text-black border-sjOrange"
                                    : "text-black border-sjOrange"
                                    }`}

                            >
                                <option value="0" disabled selected>Select Social Worker</option>
                                {therapists.map((item: ITherapistsResponse) => <option value={item.id}>{item.firstName} {item.lastName}</option>)}
                            </select>
                            {errors.therapistId && (
                                <p className="error-msg">
                                    Social Worker selection is required.
                                </p>
                            )}
                        </div>
                        <div className="ml-[72px]">
                            <div className="text-gray-700 font-nunito text-sm font-normal leading-6">Date ({formatTimeZone(timeZone)})</div>
                            <Controller
                                {...register("selectedDate", { required: true })}
                                control={control}
                                name='selectedDate'
                                render={({ field }) => (
                                    <DatePicker
                                        className={`mt-[8px] w-[332px] h-[44px] flex-shrink-0 rounded-md border border-gray-300 placeholder-gray-500 ${errors.selectedDate
                                            ? "text-black border-sjOrange"
                                            : "text-black border-sjOrange"
                                            }`}
                                        dateFormat="d MMM yyyy h:mm aa"
                                        placeholderText="Select Appointment Date"
                                        minDate={new Date()}
                                        showTimeSelect={true}
                                        todayButton="Today"
                                        dropdownMode="select"
                                        isClearable
                                        shouldCloseOnSelect
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        timeIntervals={10}
                                    />
                                )}
                            />
                            {errors.selectedDate && (
                                <p className="error-msg">
                                    A valid Session Date is required.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex mt-[24px]">
                        <div>
                            <div className="text-gray-700 font-nunito text-sm font-normal leading-6">Modality</div>
                            <select {...register("modality", { required: true })}
                                className={`mt-[8px] w-[332px] h-[44px] flex-shrink-0 rounded-md border border-gray-300 placeholder-gray-500 ${errors.selectedDate
                                    ? "text-black border-sjOrange"
                                    : "text-black border-sjOrange"
                                    }`}
                            >
                                <option value="0" disabled selected>Select Modality</option>
                                {modality.map((item: { key: string, value: string }) => <option value={item.key}>{item.value}</option>)}
                            </select>
                            {errors.modality && (
                                <p className="error-msg">
                                    Modality selection is required.
                                </p>
                            )}
                        </div>
                    </div>
                    <input type={"submit"} value={"Create Appointment"}
                        className="mt-[48px] bg-sjOrange text-white rounded shadow w-[170px] py-[16px] px-[8px] text-sm" />
                    {createAppointmentError && <p className="error-msg">{createAppointmentError}</p>}
                </form>
            </div>
        )
    }

    return (<div className="py-10 px-10 min-h-screen">
        <div className={"absolute h-full top-0 right-0 w-3/7 bg-white z-[2000]"}>
            {renderAppointmentForm()}
        </div>
        <div className={"foreground"} />
    </div>)
}

export default CoordinatorAppointmentCreateComponent
