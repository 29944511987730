import React, {useCallback, useEffect} from "react";
import {IoChatboxEllipses} from "react-icons/io5";
import {Button} from "../../../components/generic/Buttons";
import CallModalComponent from "../../prospects/care-coordinator/CallModalComponent";
import {useDispatch, useSelector} from "react-redux";
import {
    IPatientInCall, IPhysicianAppointmentLocation,
    IPhysicianAppointmentPatient,
    IPhysicianAppointmentPractice, IPhysicianAppointmentProvider,
    LocationIndexItem
} from "../../../models";
import {
    clearLocationIdToInitiateCall,
    setLocationIdToInitiateCall
} from "../../../store/actions/physician/admin-operations.action";
import {requestCall} from "../../../store/actions/counsellor/calls.action";
import {toast} from "react-toastify";
import {MaskPhoneNumber} from "../../../components/generic/MaskPhoneNumber";
import ConfirmationModal from "../../../components/clinical-notes/ConfirmationModal";
import {isPhoneNumberValid} from "../../../shared/Utils";
import {Tooltip} from "react-tooltip";
import {DateTime} from "luxon";
import EditablePhoneNumber from "../../../components/calls/EditablePhoneNumber";
import {StateParams} from "../../../store/reducers";
import {
    resetScreenerLinkToPatient,
    resetSendPhysicianAppointmentReminderToPatient,
    sendPhysicianAppointmentReminderToPatient,
    sendScreenerLinkToPatient
} from "../../../store/actions/care-coordinator/patients.action";
import {FaSpinner} from "react-icons/fa6";
import _ from "lodash";
import {LiaSmsSolid} from "react-icons/lia";
import Select from "react-select";
import {Radio, Typography} from "@material-tailwind/react";

interface IPhysicianAppointmentSendSMSComponentProps {
    value: {
        appointmentId: string;
        appointmentDate: string;
        provider: IPhysicianAppointmentProvider;
        patient: IPhysicianAppointmentPatient;
        practice: IPhysicianAppointmentPractice;
        location: IPhysicianAppointmentLocation;
    },
}

const PhysicianAppointmentSendSMSComponent = (props: IPhysicianAppointmentSendSMSComponentProps) => {

    const {value} = props;
    const dispatch = useDispatch();
    const {appointmentId, appointmentDate, provider , patient} = value;
    const [isPatientPhoneNumberValid, setIsPatientPhoneNumberValid] = React.useState<boolean | undefined>(undefined);
    const [showSMSConfirmationModal, setShowSMSConfirmationModal] = React.useState<boolean>(false);

    const {
        physicianAppointmentIdToSendSMS,
        physicianAppointmentSendSMSInProgress,
        physicianAppointmentSendSMSSuccess,
        physicianAppointmentSendSMSError,

    } = useSelector((state: StateParams) => state.coordinatorPatients);

    const openSendSMSForm = useCallback(() => {
        setShowSMSConfirmationModal(true);
    }, []);

    const closeSendSMSForm = useCallback(() => {
        setShowSMSConfirmationModal(false);
    }, []);

    const sendSMS = useCallback(() => {
        dispatch(sendPhysicianAppointmentReminderToPatient(appointmentId));
    }, [dispatch, appointmentId]);

    useEffect(() => {
        if (physicianAppointmentIdToSendSMS === appointmentId) {
            if (physicianAppointmentSendSMSError) {
                console.log("PhysicianAppointmentSendSMSComponent :: useEffect :: physicianAppointmentSendSMSError :: ", physicianAppointmentSendSMSError);
                toast(physicianAppointmentSendSMSError);
                dispatch(resetSendPhysicianAppointmentReminderToPatient());
            }
            if (physicianAppointmentSendSMSSuccess) {
                toast(`SMS sent successfully to ${patient?.contactPhoneNumber}`);
                dispatch(resetSendPhysicianAppointmentReminderToPatient());
                closeSendSMSForm();
            }
        }
    }, [physicianAppointmentSendSMSSuccess, patient?.contactPhoneNumber, physicianAppointmentIdToSendSMS, physicianAppointmentSendSMSError, dispatch, appointmentId, closeSendSMSForm]);

    useEffect(() => {
        if (patient && isPhoneNumberValid(patient?.contactPhoneNumber)) {
            setIsPatientPhoneNumberValid(true);
        } else {
            setIsPatientPhoneNumberValid(false);
        }
    }, [patient]);

    const renderToolTipForInvalidPhoneNumber = useCallback(() => {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}}
                        id={`${patient?.id}-physician-appointment-sms-tooltip`}>
            <div>
                The patient phone number is invalid. Please update the phone number.
            </div>
        </Tooltip>
    }, [patient?.id, isPatientPhoneNumberValid]);

    const handleSendSMS = useCallback(() => {
        if (!isPatientPhoneNumberValid || physicianAppointmentSendSMSInProgress) {
            return;
        } else {
            openSendSMSForm();
        }
    }, [isPatientPhoneNumberValid, physicianAppointmentSendSMSInProgress, openSendSMSForm]);

    const renderSendSMSConfirmationModal = useCallback(() => {
        const patientFullName = `${patient?.firstName} ${patient?.lastName}`;
        const capitalizedName = _.capitalize(patientFullName);
        const phoneNumber = patient?.contactPhoneNumber;
        const appointmentPhysicianName = `${provider ? provider?.firstName + ' ' + provider?.lastName : 'N/A'}`;
        const patientDob = patient && patient.dob && DateTime.fromISO(patient.dob, { zone: 'utc' }).toFormat('MM/dd/yyyy');
        let isUpcoming = false;
        const currentESTTime = DateTime.now().setZone('America/New_York');
        const apptmentEstTimestamp = DateTime.fromISO(appointmentDate, {zone: 'utc'}).setZone('America/New_York');
        const apptmentDate = apptmentEstTimestamp.toFormat('MM/dd/yyyy')
        const apptmentTime = apptmentEstTimestamp.toFormat('hh:mm a');
        isUpcoming = apptmentEstTimestamp.diff(currentESTTime, 'seconds').seconds > -60;
        let smsMessage = '';
        if (isUpcoming) {
            smsMessage = `Hello ${capitalizedName}, this is a reminder for your upcoming appointment with Dr. ${appointmentPhysicianName} on ${apptmentDate} at ${apptmentTime}. To save time during visit please complete the required forms before the visit:  <Screening Link>.`
        } else {
            smsMessage = `Hello ${capitalizedName}, you have had an appointment with Dr. ${appointmentPhysicianName} on ${apptmentDate} at ${apptmentTime} and have a few pending forms. Please fill them out here <Screening Link>.`
        }
        return <ConfirmationModal isOpen={showSMSConfirmationModal}
                                  onClose={closeSendSMSForm}
                                  key={'sendScreeningReminderSMSModal'}
                                  modalClassNames={'!w-1/2 text-left'}
                                  alignContentInCenter={false}
                                  actions={
                                      <Button
                                          disabled={physicianAppointmentSendSMSInProgress}
                                          className={`${physicianAppointmentSendSMSInProgress ? 'border-sjGray text-sjGray opacity-80 cursor-not-allowed' : 'border-sjOrange text-sjOrange'} rounded-l-md  !ml-2 px-[16px] py-[8px] !h-8 text-sm w-full justify-center`}
                                          onClick={sendSMS}>
                                          <div className={"flex flex-row gap-x-2 items-center"}>
                                              <span>
                                                  {physicianAppointmentSendSMSInProgress ? 'Sending...' : 'Send SMS'}
                                              </span>
                                              <LiaSmsSolid width={20}/>
                                          </div>
                                      </Button>
                                  }
        >
            <>
                <h4 className='font-semibold'>
                    Confirm SMS Notification
                </h4>
                <div className="text-gray-700">
                    <div className='mt-5'>
                        SMS containing the screener link will be send to {capitalizedName}’s ({patientDob}) phone number: <MaskPhoneNumber value={phoneNumber}/>
                    </div>
                    <div className='my-2'>
                        <div>
                            SMS Message -
                        </div>
                        <div className='mt-2'>
                            {smsMessage}
                        </div>
                    </div>
                </div>
            </>
        </ConfirmationModal>
    }, [patient, provider, showSMSConfirmationModal, closeSendSMSForm, sendSMS, appointmentDate, physicianAppointmentSendSMSInProgress]);

    return <>
        <Button
            data-tooltip-id={`${patient?.id}-physician-appointment-sms-tooltip`}
            className={`rounded-l-md !ml-2 px-[8px] py-[8px] !h-8 text-sm ${(!isPatientPhoneNumberValid || physicianAppointmentSendSMSInProgress) ? "border-sjGray text-sjGray cursor-not-allowed" : "border-sjOrange text-sjOrange"}`}
            onClick={handleSendSMS}>
            {(physicianAppointmentSendSMSInProgress && physicianAppointmentIdToSendSMS === appointmentId) ? <FaSpinner className="spin"/> : <IoChatboxEllipses/>}
        </Button>
        {!isPatientPhoneNumberValid && renderToolTipForInvalidPhoneNumber()}
        {showSMSConfirmationModal && renderSendSMSConfirmationModal()}
    </>
}

export default PhysicianAppointmentSendSMSComponent;
