import {SET_IS_REGISTRY_ENABLED, SIGN_INTO_ACCOUNT, SIGN_OUT_ACCOUNT} from "../actions/common";

export interface AccountParams {
    id?: string
    token?: string
    email?: string
    firstName?: string
    lastName?: string
    gender?: string
    role?: string
    createdAt?: string
    updatedAt?: string
    providerId?: string
    canMARefer?: boolean
    isSOAPEnabled?: boolean
    isRegistryEnabled?: boolean
}

const initialState: AccountParams = {
    id: undefined,
    token: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    gender: undefined,
    role: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    providerId: undefined,
    canMARefer: false,
    isSOAPEnabled: false,
    isRegistryEnabled: false
}

const AccountReducer = (state: AccountParams = initialState, action: any): AccountParams => {

    switch (action.type) {
        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state
        case SIGN_INTO_ACCOUNT:
            state = {...state, ...action.payload}
            return state
        case SET_IS_REGISTRY_ENABLED:
            state = {...state, isRegistryEnabled: action.payload}
            return state
        default:
            return state;
    }

}

export default AccountReducer
