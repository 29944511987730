import {MdOutlineExpandMore, MdOutlineExpandLess} from "react-icons/md";
import React, {useCallback, useEffect, useState} from "react";
import {Textarea} from "@material-tailwind/react";
import {Button} from "../../../components/generic/Buttons";
import {toast} from "react-toastify";
import {FaInfoCircle} from "react-icons/fa";
import {Tooltip} from "react-tooltip";
import {SlDislike, SlLike} from "react-icons/sl";
import {useFlags} from "flagsmith/react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchSOAPNoteFeedback,
    resetUpdateSOAPNoteFeedback,
    updateSOAPNoteFeedback
} from "../../../store/actions/physician/admin-operations.action";
import {StateParams} from "../../../store/reducers";

const SOAPAttributes = [
    'Overview', 'Subjective', 'Objective', 'Assessment', 'Plan', 'Multi langauge', 'Speaker Identification'
]

interface ISOAPFeedbackFeatureFlag {
    like: boolean;
    what_went_well: boolean;
    what_can_be_improved: boolean;
    additional_comments: boolean;
}

interface ISOAPFeedback {
    whatWentWell: string[];
    whatCouldBeImproved: string[];
    additionalComments: string;
}

interface ISOAPFeedbackComponent {
    soapNoteId: string;
}

export const SOAPFeedbackComponent = (props: ISOAPFeedbackComponent) => {

    const {soapNoteId} = props;
    const {
        fetchSoapNoteFeedbackInProgress,
        updateSOAPNoteFeedbackInProgress,
        soapNoteFeedback,
        fetchSoapNoteFeedbackSuccess,
        soapNoteIdToUpdateFeedback,
        updateSOAPNoteFeedbackSuccess,
        updateSOAPNoteFeedbackError
    } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const dispatch = useDispatch();
    const soapFeedBackFlagRaw = useFlags(['soap_feedback'])?.soap_feedback?.value as string;
    const soapFeedBackFlag: ISOAPFeedbackFeatureFlag = JSON.parse(soapFeedBackFlagRaw);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isFormTobeSubmitted, setIsFormTobeSubmitted] = useState<boolean>(false);
    const [isLiked, setIsLiked] = useState<boolean | null>(null);
    const [feedback, setFeedback] = useState<ISOAPFeedback>({
        whatWentWell: [],
        whatCouldBeImproved: [],
        additionalComments: ''
    });

    const toggleExpand = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    const handleAttributeSelect = useCallback((section: 'whatWentWell' | 'whatCouldBeImproved', attribute: string) => {
        const isSelected = feedback[section].includes(attribute);
        if (isSelected) {
            setFeedback((prev) => {
                return {
                    ...prev,
                    [section]: prev[section].filter((attr) => attr !== attribute)
                }
            })
        } else {
            setFeedback((prev) => {
                return {
                    ...prev,
                    [section]: [...prev[section], attribute]
                }
            })
        }
    }, [feedback]);

    const renderSOAPAttribute = useCallback((attribute: string, section: 'whatWentWell' | 'whatCouldBeImproved', handleSelect: () => void) => {
        const isSelected = feedback[section].includes(attribute);
        return (
            <div
                className={`p-1 select-none text-sm cursor-pointer border-solid border-2 rounded-md ${isSelected ? 'bg-amber-400 text-white border-amber-400' : 'text-sjDarkGray'}`}
                onClick={handleSelect}>
                {attribute}
            </div>
        )
    }, [feedback]);

    const handleAdditionalCommentsChange = useCallback((e: any) => {
        setFeedback((prev) => {
            return {
                ...prev,
                additionalComments: e.target.value
            }
        })
    }, []);

    const handleSaveFeedback = useCallback(() => {
        dispatch(updateSOAPNoteFeedback(soapNoteId, {
            isLiked,
            feedback
        }));
    }, [soapNoteId, dispatch, feedback, isLiked]);

    const handleLike = useCallback((liked: boolean) => {
        if (isLiked === liked) {
            setIsLiked(null);
        } else {
            setIsLiked(liked);
        }
    }, [isLiked]);

    useEffect(() => {
        dispatch(resetUpdateSOAPNoteFeedback());
        setIsLiked(null);
        setFeedback({
            whatWentWell: [],
            whatCouldBeImproved: [],
            additionalComments: ''
        });
        if (soapNoteId) {
            dispatch(fetchSOAPNoteFeedback(soapNoteId));
        }
    }, [soapNoteId, dispatch]);

    useEffect(() => {
        if (soapNoteId && fetchSoapNoteFeedbackSuccess && soapNoteFeedback) {
            setFeedback(soapNoteFeedback.feedback);
            setIsLiked(soapNoteFeedback.isLiked);
        } else {
            setFeedback({
                whatWentWell: [],
                whatCouldBeImproved: [],
                additionalComments: ''
            });
            setIsLiked(null);
        }
    }, [soapNoteFeedback, fetchSoapNoteFeedbackSuccess, soapNoteId]);

    useEffect(() => {
        if (soapNoteIdToUpdateFeedback === soapNoteId) {
           if (updateSOAPNoteFeedbackSuccess) {
               toast.dismiss();
               toast.success('Feedback saved successfully');
               dispatch(resetUpdateSOAPNoteFeedback());
           }
           if (updateSOAPNoteFeedbackError) {
               toast.dismiss();
               toast.error('Failed to save feedback');
               dispatch(resetUpdateSOAPNoteFeedback());
           }
        }
    }, [dispatch, soapNoteIdToUpdateFeedback, soapNoteId, updateSOAPNoteFeedbackSuccess, updateSOAPNoteFeedbackError]);

    useEffect(() => {
        if (soapNoteFeedback) {
            if (soapNoteFeedback?.feedback !== feedback || soapNoteFeedback?.isLiked !== isLiked) {
                setIsFormTobeSubmitted(true);
            } else {
                setIsFormTobeSubmitted(false);
            }
        } else {
            if (feedback?.additionalComments || feedback?.whatCouldBeImproved?.length || feedback?.whatWentWell?.length || isLiked !== undefined) {
                setIsFormTobeSubmitted(true);
            } else {
                setIsFormTobeSubmitted(false);
            }
        }
    }, [isLiked, feedback, soapNoteFeedback]);

    return (
        <div className="fixed bottom-0 right-0 p-5 bg-white w-[380px] border-sj2 border-2 border-t-8 rounded-md">
            <div className="flex gap-2">
                <div className="flex-col gap-2">
                    <div>
                        Feedback {(isFormTobeSubmitted && !fetchSoapNoteFeedbackInProgress) &&
                        <span className="text-gray-500 text-sm">( Unsaved Changes )</span>}
                    </div>
                    <div className="text-xs text-gray-500">
                        Your feedback is important to us to improve the quality of SOAP notes.
                    </div>
                </div>
                <div className="cursor-pointer" onClick={toggleExpand}>
                    {isExpanded ? <MdOutlineExpandMore size={24}/> : <MdOutlineExpandLess size={24}/>}
                </div>
            </div>
            {
                isExpanded && <div className="border-t-2 border-solid mt-2 pt-2">
                    { fetchSoapNoteFeedbackInProgress && <div className="flex justify-center"> Loading... </div> }
                    {
                        soapFeedBackFlag?.like && <div className="flex-col gap-2 flex-wrap mb-5">
                            <div className="flex gap-2 align-items-center">
                                How is the overall Note ?
                            </div>
                            <div className="flex justify-center mt-2">
                                <div className="flex gap-5">
                                    <Tooltip
                                        id="like-tooltip"
                                        place="top"
                                    >
                                        <div>
                                            {isLiked ? 'Remove Like' : 'Like'}
                                        </div>
                                    </Tooltip>
                                    <Tooltip
                                        id="dislike-tooltip"
                                        place="top"
                                    >
                                        <div>
                                            {isLiked === false ? 'Remove Dislike' : 'Dislike'}
                                        </div>
                                    </Tooltip>
                                    <div className="cursor-pointer"
                                         data-tooltip-id="like-tooltip"
                                         onClick={() => handleLike(true)}>
                                        <SlLike size={40}
                                                color={isLiked ? '#00A86B' : '#919191'}
                                                className="pointer-events-none"
                                        />
                                    </div>
                                    <div className="cursor-pointer"
                                         data-tooltip-id="dislike-tooltip"
                                         onClick={() => handleLike(false)}>
                                        <SlDislike size={40}
                                                   color={(isLiked === false) ? '#FF5A5F' : '#919191'}
                                                   className="pointer-events-none"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div>
                        {
                            soapFeedBackFlag?.what_went_well && <div className="flex gap-2 flex-wrap mb-5">
                                <Tooltip
                                    id={'what-went-well-tooltip'}
                                    place="top"
                                >
                                    <div>
                                        Select the attributes that went well in this SOAP note.
                                    </div>
                                </Tooltip>
                                <div className="flex gap-2 align-items-center">
                                    What went well ? <span className="cursor-help"
                                                           data-tooltip-id={'what-went-well-tooltip'}>
                                <FaInfoCircle style={{color: '#919191'}}/>
                            </span>
                                </div>
                                <div className="flex gap-2 flex-wrap">
                                    {SOAPAttributes.map((attribute) => renderSOAPAttribute(attribute, 'whatWentWell', () => {
                                        handleAttributeSelect('whatWentWell', attribute);
                                    }))}
                                </div>
                            </div>
                        }
                        {
                            soapFeedBackFlag?.what_can_be_improved &&
                            <div className="flex gap-2 flex-wrap mb-5">
                                <Tooltip
                                    id={'what-could-be-improved-tooltip'}
                                    place="top"
                                >
                                    <div>
                                        Select the attributes that could be improved in this SOAP note.
                                    </div>
                                </Tooltip>
                                <div className="flex gap-2 align-items-center">
                                    What could be improved ? <span className="cursor-help"
                                                                   data-tooltip-id={'what-could-be-improved-tooltip'}>
                                                                <FaInfoCircle style={{color: '#919191'}}/>
                            </span>
                                </div>
                                <div className="flex gap-2 flex-wrap">
                                    {SOAPAttributes.map((attribute) => renderSOAPAttribute(attribute, 'whatCouldBeImproved', () => {
                                        handleAttributeSelect('whatCouldBeImproved', attribute);
                                    }))}
                                </div>
                            </div>
                        }
                        {
                            soapFeedBackFlag?.additional_comments &&
                            <div>
                                <Tooltip
                                    id={'additional-help-tooltip'}
                                    place="top"
                                >
                                    <div>
                                        Any additional comments you would like to provide for this SOAP note.
                                    </div>
                                </Tooltip>
                                <div className="flex gap-2 align-items-center">
                                    Additional comments <span className="cursor-help"
                                                              data-tooltip-id={'additional-help-tooltip'}>
                                <FaInfoCircle style={{color: '#919191'}}/>
                            </span>
                                </div>
                                <div className="mt-2">
                                    <Textarea className="outline-0 rounded-md border-solid border-sjGray"
                                              rows={4}
                                              placeholder="Type here..."
                                              value={feedback.additionalComments}
                                              onChange={handleAdditionalCommentsChange}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="flex justify-center mt-2">
                        <Button className={`rounded-md border-2 border-solid ${updateSOAPNoteFeedbackInProgress ? 'bg-sjGray cursor-not-allowed' : 'bg-sj2 border-sj2'}`}
                                disabled={updateSOAPNoteFeedbackInProgress}
                                onClick={handleSaveFeedback}>
                            {updateSOAPNoteFeedbackInProgress ? 'Saving...' : 'Save Feedback'}
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}
