import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import {
    fetchClinicalImprovementMetrics,
    fetchPatientsSummary,
    fetchPatientsSummaryByLocation,
    fetchPatientsScreenerCompletionRate,
    fetchRegistryClinicalConditions,
    fetchRegistryNotesMetrics,
    fetchRegistryStaticsCard,
    fetchRegistryBaselineMetrics,
    fetchRegistryClinicalConditionDistribution
} from "../../../store/actions/physician/dashboard.action";
import { fetchPhysiciansByPractice, fetchPracticePermissions, fetchTherapistsByPractice } from "../../../store/actions/physician/admin-operations.action";
import { DateTime } from "luxon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFlags } from 'flagsmith/react';
import ClinicalImprovmentsChartsComponent from "./components/ClinicalImprovmentsChartsComponent";
import PhysicianDashboardStatisticsComponent from "./components/PhysicianDashboardStatisticsComponent";
import PhysicianDashboardLocationBasedScreenersComponent from "./components/PhysicianDashboardLocationBasedScreenersComponent";
import PhysicianDashboardScreenerCompletionRateComponent from "./components/PhysicianDashboardScreenerCompletionRateComponent";
import DashboardNotesMetricComponent from "./components/DashboardNotesMetricComponent";
import PatientTreatmentTableViewCards from "./components/PatientTreatmentTableViewCards";
import PhysicianClinicalMetricsComponent from "./components/PhysicianClinicalMetricsComponent";
import PhysicianDashboardDateFilterComponent from "./components/PhysicianDashboardDateFilterComponent";
import StatusTabs from "../../../components/generic/StatusTabs";
import { RoutePaths } from "../../../shared/Utils";
import "./PhysicianDashboardScreen.scss";
import Select from "react-select";
import { AggregatePatientSummaryByLocation, ScreenerCompletionRate } from "../../../models";
import { setIsRegistryEnabled } from "../../../store/actions/common";
import ClinicalConditionDistributionComponent from "./components/PhysicianClinicalConditionDistributionComponent";

export type DashboardFilters = {
    startDate: string,
    endDate: string
}

const TABS = [
    { label: "Operations Metrics", value: "operationsMetrics" },
    { label: "CoCM Registry", value: "clinicalMetrics" }
];

const PhysicianDashboardScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { firstName, lastName, id: practiceId, isRegistryEnabled } = useSelector((state: StateParams) => state.account);
    const { practicePermissions } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const [searchParams] = useSearchParams();
    const { patientsSummaryByLocation, patientsScreenerCompletionRate, fetchPatientsSummaryByLocationInProgress, fetchPatientsScreenerCompletionRateInProgress, registryBaselineMetricsInProgress, registryBaselineMetrics } = useSelector((state: StateParams) => state.patientsDashboard);
    const { physiciansByPractice, therapistsByPractice } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const [locationBasedPatientSummary, setLocationBasedPatientSummary] = useState<AggregatePatientSummaryByLocation[]>([]);
    const [completionRateForPatientScreeners, setCompletionRateForPatientScreeners] = useState<ScreenerCompletionRate[]>([]);
    const initialStartDate = DateTime.local().minus({ days: 7 }).toFormat('yyyy-MM-dd');
    const initialEndDate = DateTime.local().endOf('day').toFormat('yyyy-MM-dd');
    const [filters, setFilters] = useState<DashboardFilters>({ startDate: initialStartDate, endDate: initialEndDate });
    const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "operationsMetrics");
    const [selectedPhysicianId, setSelectedPhysicianId] = useState<string[]>([]);
    const [selectedTherapistId, setSelectedTherapistId] = useState<string[]>([]);
    const [selectedClinicalCondition, setSelectedClinicalCondition] = useState<string[]>([]);
    const canViewRegistry = useFlags(['physician_registry'])?.physician_registry?.enabled;
    const isStaticsCardEnabled = useFlags(['physician_registry_statics_card'])?.physician_registry_statics_card?.enabled;
    const isNotesMetricsEnabled = useFlags(['physician_registry_notes_metrics'])?.physician_registry_notes_metrics?.enabled;
    const isClinicalImprovementMetricsEnabled = useFlags(['physician_registry_clinical_improvement_metrics'])?.physician_registry_clinical_improvement_metrics?.enabled;
    const isPatientsTreatmentMetricsEnabled = useFlags(['physician_registry_patients_treatment_metrics'])?.physician_registry_patients_treatment_metrics?.enabled;
    const isClinicalConditionDistributionEnabled = useFlags(['physician_registry_clinical_condition_distribution'])?.physician_registry_clinical_condition_distribution?.enabled;

    useEffect(() => {
        if (activeTab === 'clinicalMetrics' && practiceId) {
            batch(() => {
                dispatch(fetchRegistryStaticsCard({ filters: { physicianId: selectedPhysicianId, therapistId: selectedTherapistId } }));
                dispatch(fetchRegistryNotesMetrics({ filters: { physicianId: selectedPhysicianId, therapistId: selectedTherapistId } }));
                dispatch(fetchPhysiciansByPractice(practiceId));
                dispatch(fetchTherapistsByPractice(practiceId));
                dispatch(fetchRegistryClinicalConditions());
                dispatch(fetchRegistryBaselineMetrics({ filters: { physicianId: selectedPhysicianId, therapistId: selectedTherapistId } }));
                dispatch(fetchRegistryClinicalConditionDistribution({ filters: { physicianId: selectedPhysicianId, therapistId: selectedTherapistId } }));
                dispatch(fetchPracticePermissions())
            });
        } else if (activeTab === 'operationsMetrics' || !canViewRegistry) {
            batch(() => {
                dispatch(fetchPatientsSummary(filters));
                dispatch(fetchPatientsSummaryByLocation(filters));
                dispatch(fetchPatientsScreenerCompletionRate(filters));
                dispatch(fetchPracticePermissions())
            });
        }
    }, [filters, activeTab, canViewRegistry, selectedPhysicianId, selectedTherapistId]);

    useEffect(() => {
        if (activeTab === 'clinicalMetrics' && selectedClinicalCondition.length > 0) {
            dispatch(fetchClinicalImprovementMetrics({ filters: { physicianId: [''], therapistId: [''], screenerIds: selectedClinicalCondition } }));
        }
    }, [filters, activeTab, canViewRegistry, selectedPhysicianId, selectedTherapistId, selectedClinicalCondition]);

    useEffect(() => {
        if (patientsSummaryByLocation) {
            setLocationBasedPatientSummary(patientsSummaryByLocation);
        }
    }, [patientsSummaryByLocation]);

    useEffect(() => {
        if (patientsScreenerCompletionRate) {
            setCompletionRateForPatientScreeners(patientsScreenerCompletionRate);
        }
    }, [patientsScreenerCompletionRate]);

    useEffect(() => {
        if (practicePermissions) {
            dispatch(setIsRegistryEnabled(practicePermissions.permissions.includes("REGISTRY")));
        }
    }, [practicePermissions]);

    useEffect(() => {
        if (!canViewRegistry) {
            navigate(`${RoutePaths.physicianCare.dashboard}`)
        } else if (activeTab === 'operationsMetrics') {
            navigate(`${RoutePaths.physicianCare.dashboard}?tab=${activeTab}`)
        } else {
            navigate(`${RoutePaths.physicianCare.dashboard}?tab=${activeTab}`);
        }
    }, [activeTab, canViewRegistry]);

    const handleSelectedFilters = (status: string) => {
        setActiveTab(status);
    };

    const getStatusTabClassName = (value: string) => {
        return activeTab === value ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black";
    };

    const operationsMetrics = () => (
        <>
            <div className="physician-dashboard-screen-header">
                <h1 className="physician-dashboard-screen-title"> Hello Dr. {firstName} {lastName} 👋</h1>
                <div className="physician-dashboard-screen-actions">
                    <PhysicianDashboardDateFilterComponent
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        onFilterChange={(startDate, endDate) => setFilters({ startDate, endDate })}
                    />
                </div>
            </div>
            <div className="physician-dashboard-screen-content">
                <PhysicianDashboardStatisticsComponent />
                <div className="physician-dashboard-screen-cards">
                    <div className={"font-bold mb-2"}>
                        Screener Metrics
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        {locationBasedPatientSummary && <PhysicianDashboardLocationBasedScreenersComponent patientSummary={locationBasedPatientSummary} searchInProgress={fetchPatientsSummaryByLocationInProgress} />}
                        {completionRateForPatientScreeners && <PhysicianDashboardScreenerCompletionRateComponent patientSummary={completionRateForPatientScreeners} searchInProgress={fetchPatientsScreenerCompletionRateInProgress} />}
                        <div className="grid grid-cols-subgrid col-span-2">
                            {/* <PhysicianDashboardMAPerformanceComponent/> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    const clinicalMetrics = () => (
        <>
            <h1 className="physician-dashboard-screen-title"> Hello Dr. {firstName} {lastName} 👋</h1>
            <div>
                <div className={"physician-dashboard-screen-header"}>
                    <div className="inline-flex rounded-md shadow-sm">
                        <StatusTabs
                            tabs={TABS}
                            handleSelectedFilters={handleSelectedFilters}
                            getTabClassName={getStatusTabClassName}
                        />
                    </div>
                    <div className="flex flex-row gap-4">
                        {activeTab === 'clinicalMetrics' &&
                            <div className="flex flex-row gap-4 w-full">
                                <Select
                                    options={physiciansByPractice}
                                    placeholder={"Physician"}
                                    isClearable={true}
                                    isSearchable={false}
                                    getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                                    getOptionValue={option => option?.id}
                                    onChange={(selectedOptions) => {
                                        setSelectedPhysicianId(selectedOptions ? [selectedOptions.id] : [])
                                    }}
                                    value={physiciansByPractice?.filter(physician => selectedPhysicianId.includes(physician.id))}
                                    className="w-48"
                                />
                                <Select
                                    options={therapistsByPractice}
                                    placeholder={"Therapist"}
                                    isClearable={true}
                                    isSearchable={false}
                                    getOptionLabel={option => `${option?.therapist.firstName} ${option?.therapist.lastName}`}
                                    getOptionValue={option => option?.therapistId}
                                    onChange={(selectedOptions) => {
                                        setSelectedTherapistId(selectedOptions ? [selectedOptions.therapistId] : [])
                                    }}
                                    value={therapistsByPractice?.filter(therapist => selectedTherapistId.includes(therapist.therapistId))}
                                    className="w-48"
                                />
                            </div>
                        }
                        {activeTab === 'operationsMetrics' && <div className="physician-dashboard-screen-actions">
                            <PhysicianDashboardDateFilterComponent
                                startDate={filters.startDate}
                                endDate={filters.endDate}
                                onFilterChange={(startDate, endDate) => setFilters({ startDate, endDate })}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            {activeTab === 'operationsMetrics' ?
                <div className="physician-dashboard-screen-content">
                    <PhysicianDashboardStatisticsComponent />
                    <div className="physician-dashboard-screen-cards">
                        <div className={"font-bold mb-2"}>
                            Screener Metrics
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            {locationBasedPatientSummary && <PhysicianDashboardLocationBasedScreenersComponent patientSummary={locationBasedPatientSummary} searchInProgress={fetchPatientsSummaryByLocationInProgress} />}
                            {completionRateForPatientScreeners && <PhysicianDashboardScreenerCompletionRateComponent patientSummary={completionRateForPatientScreeners} searchInProgress={fetchPatientsScreenerCompletionRateInProgress} />}
                            <div className="grid grid-cols-subgrid col-span-2">
                                {/* <PhysicianDashboardMAPerformanceComponent/> */}
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="physician-dashboard-screen-content">
                    {isStaticsCardEnabled && <PhysicianClinicalMetricsComponent />}
                    {isClinicalConditionDistributionEnabled && <ClinicalConditionDistributionComponent />}
                    {isNotesMetricsEnabled && <DashboardNotesMetricComponent />}
                    {isClinicalImprovementMetricsEnabled && <ClinicalImprovmentsChartsComponent selectedClinicalCondition={selectedClinicalCondition} setSelectedClinicalCondition={setSelectedClinicalCondition} />}
                </div>
            }
        </>
    );

    return (
        <div className="flex-1 physician-dashboard-screen bg-[#F7F8FA] min-h-screen">
            {canViewRegistry && isRegistryEnabled ? clinicalMetrics() : operationsMetrics()}
        </div>
    );
}

export default PhysicianDashboardScreen;
