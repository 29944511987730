import { use } from "chai"
import { InsuranceStatusTypes, IProspect } from "../../models"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { resetInsuranceStatus, updateInsuranceStatus } from "../../store/actions/care-coordinator/patients.action"
import { StateParams } from "../../store/reducers"

interface Props {
    patient: { id: string, insuranceStatus: InsuranceStatusTypes }
    setInsuranceStatus: (insuranceStatus: InsuranceStatusTypes) => void
    disabled?: boolean
}

const INSURANCE_STATUS_OPTIONS = [
    { label: 'Pending', value: 'pending' },
    { label: 'Semi Verified', value: 'semi_verified' },
    { label: 'Verified', value: 'verified' },
    { label: 'Eligible', value: 'eligible' },
    { label: 'Not Eligible', value: 'not_eligible' },
    { label: 'Missing Subscriber Info', value: 'invalid_or_missing_subscriber_information' },
]

const InsuranceStatusAction = ({ patient, setInsuranceStatus, disabled }: Props) => {
    const dispatch = useDispatch()
    const {
        updateInsuranceStatusSuccess, updatedInsuranceStatus
    } = useSelector((state: StateParams) => state.coordinatorPatients);

    const [selectedStatus, setSelectedStatus] = useState<InsuranceStatusTypes | undefined>(patient.insuranceStatus)

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const status = event.target.value as unknown as InsuranceStatusTypes
        dispatch(updateInsuranceStatus(patient.id, status))
    }


    useEffect(() => {
        if (updateInsuranceStatusSuccess) {
            setSelectedStatus(updatedInsuranceStatus?.insuranceStatus as InsuranceStatusTypes)
            setInsuranceStatus(updatedInsuranceStatus?.insuranceStatus as InsuranceStatusTypes)
            dispatch(resetInsuranceStatus())
        }
    }, [updateInsuranceStatusSuccess, updatedInsuranceStatus]);

    const renderAvailableStatuses = () => {
        return (
            <select
                disabled={disabled}
                value={selectedStatus as InsuranceStatusTypes}
                onChange={handleChange}
                className={`w-[220px] px-[8px] py-[4px] border-none rounded-[6px] h-[30px] bg-sjLightGreen text-sjGreen focus:outline-none focus:border-none ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                // className={  "w-[220px] px-[8px] py-[4px] border-none rounded-[6px] h-[30px] bg-sjLightGreen text-sjGreen focus:outline-none focus:border-none"}
                style={{
                    outline: 'none',
                    border: 'none',
                    boxShadow: 'none',
                }}
                >
                {
                    INSURANCE_STATUS_OPTIONS.map((status) => (
                        <option key={status.value} value={status.value}>
                            {status.label}
                        </option>
                    ))
                }
            </select>
        )
    }

    return (
        <div className="ml-1">
            {renderAvailableStatuses()}
        </div>
    )
}

export default InsuranceStatusAction
