import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { StateParams } from '../../store/reducers'
import PaginationV2 from "../../components/generic/PaginationV2";
import PhysicianAppointmentListComponent from "./PhysicianAppointmentListComponent";
import {
    fetchPhysicianAppointments
} from "../../store/actions/care-coordinator/patients.action";
import { Button } from "../../components/generic/Buttons";
import PhysicianAppointmentFormComponent from "./PhysicianAppointmentFormComponent";
import { fetchPracticeMasterList } from "../../store/actions/care-coordinator/master-data.action";
import { DateTime } from "luxon";
import PhysicianAppointmentsFiltersComponent from "./PhysicianAppointmentsFiltersComponent";

const PhysicianAppointmentsScreen = () => {

    const dispatch = useDispatch();
    const {
        physicianAppointmentList,
        physicianAppointmentListInProgress,
        physicianAppointmentListSuccess,
        physicianAppointmentListCount,
        PatientCommentsSideBarActive,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<string | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<string | undefined>(undefined);
    const [practiceId, setPracticeId] = useState<string | undefined>(undefined);

    console.log("PhysicianAppointmentsScreen -> searchParams", searchParams)

    useEffect(() => {
        if (searchParams) {
            if (searchParams.get("page")) {
                setPageNumber(Number(searchParams.get("page")));
            } else {
                setPageNumber(1);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        dispatch(fetchPracticeMasterList());
    }, [dispatch]);

    useEffect(() => {
        getPhysicianAppointments();
    }, [pageNumber, recordsPerPage, searchText, practiceId, filterStartDate, filterEndDate]);

    const getPhysicianAppointments = useCallback(() => {
        if (pageNumber || recordsPerPage || searchText) {
            dispatch(fetchPhysicianAppointments({
                pageNumber,
                recordsPerPage,
                searchText,
                startDate: filterStartDate,
                endDate: filterEndDate,
                practiceId: practiceId
            }));
        }
    }, [pageNumber, recordsPerPage, searchText, filterStartDate, filterEndDate, dispatch, practiceId]);

    const openFormDrawer = useCallback(() => {
        setShowForm(true);
    }, []);

    const closeFormDrawer = useCallback((refreshList: boolean) => {
        setShowForm(false);
        if (refreshList) {
            getPhysicianAppointments();
        }
    }, [getPhysicianAppointments]);


    const renderPhysicianAppointments = useCallback(() => {
        return <>
            <div className={showForm ? "foreground" : ""} />
            <div className={showForm ? "uneditable" : ""}>
                <div className="flex flex-row justify-between items-center flex-wrap gap-2">
                    <div className='flex'>
                        <h1 className="text-xl"> SMS Appointments </h1>
                    </div>
                    <div className='flex flex-row gap-x-2 items-center flex-wrap gap-2'>
                        <PhysicianAppointmentsFiltersComponent
                            searchText={searchText}
                            setSearchText={setSearchText}
                            setCurrentPage={setPageNumber}
                            setPracticeId={setPracticeId}
                            onFilterChange={(startDate, endDate) => {
                                setFilterStartDate(startDate);
                                setFilterEndDate(endDate);
                            }}
                        />
                        <Button className={"!bg-sjOrange !text-white !ml-2"}
                            onClick={openFormDrawer}>
                            Add Appointment +
                        </Button>
                    </div>
                </div>
                <div className="mt-4 relative">
                    <PhysicianAppointmentListComponent
                        searchInProgress={physicianAppointmentListInProgress}
                        physicianAppointmentList={physicianAppointmentList}
                        refreshPhysicianAppointmentList={getPhysicianAppointments}
                    />
                    {(physicianAppointmentListSuccess && physicianAppointmentList) &&
                        <PaginationV2
                            totalRows={physicianAppointmentListCount || 0}
                            rowsPerPage={recordsPerPage}
                            recordsPerPageHandler={setRecordsPerPage}
                            currentPage={pageNumber}
                            currentPageHandler={setPageNumber}
                            showPages={false}
                        />
                    }
                </div>
            </div>
        </>;
    }, [
        openFormDrawer, searchText,
        showForm, physicianAppointmentList,
        physicianAppointmentListInProgress,
        physicianAppointmentListCount, physicianAppointmentListSuccess,
        recordsPerPage, pageNumber, getPhysicianAppointments,
        setRecordsPerPage, setPageNumber
    ]);

    const renderRightSidebar = useCallback(() => {
        return <>
            {showForm &&
                <div className={"absolute top-0 right-0 w-1/2 bg-white z-[2000]"}>
                    <PhysicianAppointmentFormComponent onClose={closeFormDrawer} />
                </div>
            }
        </>
    }, [showForm, closeFormDrawer]);

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            {renderRightSidebar()}
            {renderPhysicianAppointments()}
            <div className={PatientCommentsSideBarActive ? "foreground" : 'uneditable'} />
        </div>
    )
}

export default PhysicianAppointmentsScreen;
