import React, { useEffect } from 'react';
import LoadingComponent from '../../../components/generic/LoadingComponent';
import InsuranceDetails from './Inusrance-details';
import { useDispatch, useSelector } from 'react-redux';
import { StateParams } from '../../../store/reducers';
import { fetchInsuranceList } from '../../../store/actions/care-coordinator/patients.action';
import { useParams } from 'react-router-dom';
import StatusTabs from '../../../components/generic/StatusTabs';
import { Tab } from '@headlessui/react'
import { IInsurancesResponse } from '../../../models';
import classNames from 'classnames';
import NoDataImage from '../../../assets/images/no_data.svg';

const insuranceTypeLabels: Record<string, string> = {
    primary: "Primary Insurance",
    secondary: "Secondary Insurance",
    tertiary: "Tertiary Insurance",
    self_pay: "Self Pay",
};

const InsuranceListComponent = () => {
    const SELF_PAY = "self_pay";
    const {
        insuranceList,
        insuranceListInProgress
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const dispatch = useDispatch();
    const params = useParams();
    const patientId = params.patientId || params.prospectId

    const getInsuranceTypeLabel = (insuranceType: string): string => {
        return insuranceTypeLabels[insuranceType.toLowerCase()] ||
            insuranceType.charAt(0).toUpperCase() + insuranceType.slice(1);
    };


    useEffect(() => {
        if (patientId) {
            dispatch(fetchInsuranceList(patientId));
        }
    }, [patientId])

    //  useEffect(() => {
    //     console.log("checkInsuranceEligibilitySuccess", checkInsuranceEligibilitySuccess)
    //     if (checkInsuranceEligibilitySuccess) {
    //         setEligibilityDrawerOpen(false);
    //         if (patientId) {
    //             dispatch(fetchInsuranceList(patientId));
    //             dispatch(resetCheckedInsuranceEligibility());
    //         }
    //     }
    // }, [checkInsuranceEligibilitySuccess, patientId]);

    return (
        <main className="relative min-h-screen mx-auto flex flex-row">

            {insuranceListInProgress ?
                <LoadingComponent isLoading={insuranceListInProgress} top='20%' left='40%' />
                : <>

                    {
                        insuranceList?.length > 0 ? (
                            <>
                                {insuranceList?.length === 1 ? (
                                    <div className='w-full'>
                                        <div className="flex justify-between items-center">
                                            <div className="text-[16px] font-semibold mb-4">
                                                {insuranceList[0]?.insuranceType === SELF_PAY ? "Eligibility Checks" : "Insurance Information"}
                                            </div>

                                        </div>
                                        <InsuranceDetails showTitle={false} insurance={insuranceList[0]} />
                                    </div>
                                ) : (

                                    <div className="w-full mt-2 h-[50vh]">
                                        <Tab.Group>
                                            <Tab.List className="w-full grid grid-cols-6 border-b border-solid border-b-sjGrey-200">
                                                {insuranceList.map((insurance: IInsurancesResponse, index: number) => (
                                                    <Tab key={insurance.id}
                                                        className={({ selected }) => {
                                                            return classNames(
                                                                'col-span-1',
                                                                'mr-5 mt-2 py-2.5 text-sm font-medium',
                                                                'ring-offset-2 focus:outline-none border-b border-solid border-b-2 ',
                                                                selected
                                                                    ? 'text-sjOrange border-b-sjOrange '
                                                                    : 'text-sjGrey hover:text-sjOrange border-b-transparent'
                                                            )
                                                        }}
                                                    >
                                                        {getInsuranceTypeLabel(insurance.insuranceType)}
                                                    </Tab>
                                                ))}
                                            </Tab.List>

                                            <div className="w-full mt-4">
                                                {insuranceList.map((insurance: IInsurancesResponse) => (
                                                    <Tab.Panel key={insurance.id}>
                                                        <InsuranceDetails insurance={insurance} showTitle={true} />
                                                    </Tab.Panel>
                                                ))}
                                            </div>
                                        </Tab.Group>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex flex-col items-center w-full space-y-10 mt-10">
                                <img src={NoDataImage} alt="No Data" />
                                <div className="text-[#1D1B20] font-normal">
                                    No insurance information has been added for this patient yet.
                                </div>
                                
                            </div>
                        )
                    }
                </>
            }
        </main>
    );

};

export default InsuranceListComponent
