import {call, put, takeLatest} from "@redux-saga/core/effects";
import {apiHttpClient, getAuthHeaderConfig} from "../../../lib";
import {Either} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {
    AuthState,
    IPracticeMaster
} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {
    FETCH_PRACTICE_MASTER_LIST, fetchedPracticeMasterList,
    fetchingPracticeMasterFailed
} from "../../actions/care-coordinator/master-data.action";
import {
   fetchingPatientMasterFailed
} from "../../actions/care-coordinator/patients.action";


const apiFetchPracticeMasterList = (action: { type: string, authState: AuthState }) => {
    const url = `/physician-appointments/practice-master`;
    return apiHttpClient.get<Either<CheckedError, IPracticeMaster[]>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data?.data as IPracticeMaster[] || []);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPracticeMasterList(action: { type: string, authState: AuthState }) {
    try {
        // yield put(callInProgress());
        const apiResponse: Either<CheckedError, IPracticeMaster[]> = yield call(apiFetchPracticeMasterList, action);
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPracticeMasterFailed({ error: error.message }));
            } else {
                yield put(fetchingPracticeMasterFailed({ error: error.message }));
            }
        } else {
            yield put(fetchedPracticeMasterList(apiResponse.right()));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPracticeMasterFailed({ error: "Error fetching practice master data" }));
    } finally {
        // yield put(callFinished());
    }
}

export default function* careCoordinatorMasterDataSaga() {
    yield takeLatest(FETCH_PRACTICE_MASTER_LIST, fetchPracticeMasterList);
}
