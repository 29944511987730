import {
    ACCOUNT_RESET_PASSWORD_SUCCESS,
    ACCOUNT_SEND_EMAIL_FAILED,
    ACCOUNT_SEND_EMAIL_SUCCESS,
    ACCOUNT_SIGN_IN_SUCCESS,
    ACCOUNT_VALIDATE_CODE_FAILED,
    ACCOUNT_VALIDATE_CODE_SUCCESS,
    CHANGE_DEFAULT_PASSWORD_FAILED,
    CHANGE_DEFAULT_PASSWORD_PROGRESS,
    CHANGE_DEFAULT_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FROM_PROFILE_FAILED,
    CHANGE_PASSWORD_FROM_PROFILE_SUCCESS,
    CLEAR_ERROR_MESSAGES,
    CLEAR_IS_EMAIL_SENT,
    CLEAR_IS_TWO_FACTOR_ENABLED,
    GENERATE_QR_CODE_FAILED,
    GENERATE_QR_CODE_IN_PROGRESS,
    GENERATE_QR_CODE_SUCCESS,
    SEND_OTP_FAILED, SEND_OTP_IN_PROGRESS, SEND_OTP_SUCCESS, SET_IS_TWO_FACTOR_ENABLED, SET_PHYSICIAN_DEFAULT_LOCATION,
    UPDATE_PHONE_NUMBER,
    UPDATE_PHONE_NUMBER_FAILED,
    UPDATE_PHONE_NUMBER_IN_PROGRESS,
    UPDATE_PHONE_NUMBER_SUCCESS,
    VERIFY_OTP_FAILED,
    VERIFY_OTP_IN_PROGRESS
} from "../../actions/physician/account.action";
import {CLEAR_ACCOUNT, SET_IS_REGISTRY_ENABLED, SIGN_OUT_ACCOUNT} from "../../actions/common";
import {LocationIndexItem} from "../../../models";
import {persistor} from "../../index";

const ERROR_MAP: { [key: string]: string } = {
    "No data available": "No account exists with the given credentials, please check your email.",
    "unauthorized": "Link expired/Invalid. Please enter your email to get a new link.",
    "Invalid Phone Number": "Invalid Phone Number, please check your phone number again.",
    "Unauthorized Token is invalid": "The phone number is not valid or cannot receive messages at this moment. Please check the phone number and try again.",
    "Verification code entered is not valid": "The verification code entered is not valid. Please check the code and try again.",
    "Too Many Requests API usage limit": "You have reached the maximum number of verification attempts. Please try again later.",
    "Your account has been disabled": "Your account has been disabled"
}

export interface PhysicianAccountParams {
    id?: string
    jwt?: string
    email?: string
    phone?: string
    slug?: string
    zipCode?: string
    name?: string
    code?: string
    role?: string
    roleType?: string
    practiceId?: string
    providerId?: string
    sendEmailError?: string
    validateCodeError?: string
    isCodeSent?: boolean
    screenerUrl?: string
    address?: string
    changePasswordError?: string
    passwordChangeStatus?: string
    isDefaultPassword?: boolean;
    qrCodeUrl?: string
    fetchingQRCodeInProgress?: boolean
    practiceType?: string
    updatePhoneNumberInProgress?: boolean
    defaultLocation: undefined | null | LocationIndexItem
    isTwoFactorAuthenticationEnabled?: boolean,
    verifyOtpError?: string
    isOTPSent?: boolean
    isRegistryEnabled?: boolean
}

const initialState: PhysicianAccountParams = {
    id: undefined,
    jwt: undefined,
    email: undefined,
    phone: undefined,
    slug: undefined,
    role: undefined,
    roleType: undefined,
    zipCode: undefined,
    name: undefined,
    code: undefined,
    practiceId: undefined,
    providerId: undefined,
    sendEmailError: undefined,
    validateCodeError: undefined,
    screenerUrl: undefined,
    isCodeSent: undefined,
    address: undefined,
    changePasswordError: undefined,
    passwordChangeStatus: undefined,
    fetchingQRCodeInProgress: undefined,
    practiceType: undefined,
    qrCodeUrl: undefined,
    defaultLocation: undefined,
    isDefaultPassword: undefined,
    isTwoFactorAuthenticationEnabled: undefined,
    verifyOtpError: undefined,
    isOTPSent: undefined,
    isRegistryEnabled: undefined
}

const AccountReducer = (state: PhysicianAccountParams = initialState, action: any): PhysicianAccountParams => {

    switch (action.type) {
        case ACCOUNT_SIGN_IN_SUCCESS:
            console.log("AccountReducer ACCOUNT_SIGN_IN_SUCCESS", action.payload)
            state = {
                ...state,
                ...action.payload
            }
            return state;
        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state

        case CLEAR_ACCOUNT:
            state = {...state, ...initialState}
            return state

        case ACCOUNT_RESET_PASSWORD_SUCCESS:
            state = {
                ...state,
                ...action.payload
            }
            return state
        case CHANGE_PASSWORD_FROM_PROFILE_SUCCESS:
            state = {...state, ...action.payload, changePasswordError: undefined}
            return state
        case CHANGE_PASSWORD_FROM_PROFILE_FAILED:
            state = {...state, changePasswordError: action.payload.error}
            return state
        case ACCOUNT_SEND_EMAIL_SUCCESS:
            state = {
                ...state,
                email: action.payload.email,
                practiceId: action.payload.practiceId,
                isCodeSent: action.payload.isCodeSent,
                sendEmailError: undefined
            }
            return state
        case ACCOUNT_SEND_EMAIL_FAILED:
            state = {...state, sendEmailError: ERROR_MAP[action.payload.error]}
            return state;
        case ACCOUNT_VALIDATE_CODE_SUCCESS:
            state = {
                ...state,
                code: action.payload.code,
                email: action.payload.email,
                providerId: action.payload.providerId,
                validateCodeError: undefined
            }
            return state
        case ACCOUNT_VALIDATE_CODE_FAILED:
            state = {...state, validateCodeError: ERROR_MAP[action.payload.error]}
            return state;

        case GENERATE_QR_CODE_SUCCESS:
            state = {...state, qrCodeUrl: action.payload.url, fetchingQRCodeInProgress: false}
            return state
        case GENERATE_QR_CODE_IN_PROGRESS:
            state = {...state, fetchingQRCodeInProgress: true}
            return state
        case GENERATE_QR_CODE_FAILED:
            state = {...state, fetchingQRCodeInProgress: false}
            return state

        case CLEAR_ERROR_MESSAGES:
            state = {
                ...state,
                sendEmailError: undefined,
                changePasswordError: undefined,
                passwordChangeStatus: undefined
            , validateCodeError: undefined, verifyOtpError: undefined}
            return state
        case CLEAR_IS_EMAIL_SENT:
            state = {...state, isCodeSent: undefined}
            return state

        case UPDATE_PHONE_NUMBER_SUCCESS:
            state = {...state, phone: action.payload.phoneNumber, updatePhoneNumberInProgress: false}
            return state
        case UPDATE_PHONE_NUMBER_IN_PROGRESS:
            state = {...state, updatePhoneNumberInProgress: true}
            return state
        case UPDATE_PHONE_NUMBER_FAILED:
            state = {...state, updatePhoneNumberInProgress: false}
            return state
        case SET_PHYSICIAN_DEFAULT_LOCATION:
            state = {...state, defaultLocation: action.payload.defaultLocation}
            return state

        case CHANGE_DEFAULT_PASSWORD_PROGRESS:
            state = {...state, passwordChangeStatus: "Password change in progress"}
            return state
        case CHANGE_DEFAULT_PASSWORD_FAILED:
            state = {...state, changePasswordError: action.payload.error}
            return state
        case CHANGE_DEFAULT_PASSWORD_SUCCESS:
            state = {...state, ...action.payload, changePasswordError: undefined}
            return state

        case SET_IS_TWO_FACTOR_ENABLED:
            state = {
                ...state, isTwoFactorAuthenticationEnabled: action.payload.isTwoFactorAuthenticationEnabled,
                phone: action.payload.phone, name: action.payload.name, jwt: action.payload.token,
                isOTPSent: action.payload.isOTPSent
            }
            return state
        case VERIFY_OTP_FAILED:
            console.log(ERROR_MAP[action.payload.error])
            state = {
                ...state,
                verifyOtpError: ERROR_MAP[action.payload.error]
            }
            return state
        case VERIFY_OTP_IN_PROGRESS:
            state = {...state, verifyOtpError: undefined}
            return state

        case SEND_OTP_SUCCESS:
            state = {...state, isOTPSent: true}
            return state
        case SEND_OTP_FAILED:
            state = {...state, isOTPSent: false,
                verifyOtpError: ERROR_MAP[action.payload.error]
            }
            return state
        case SEND_OTP_IN_PROGRESS:
            state = {...state, isOTPSent: undefined}
            return state

        case CLEAR_IS_TWO_FACTOR_ENABLED:
            state = {...state, isTwoFactorAuthenticationEnabled: undefined}
            return state

        case SET_IS_REGISTRY_ENABLED:
            state = {...state, isRegistryEnabled: action.payload}
            return state
        default:
            return state;
    }

}

export default AccountReducer
