import {IPatientForScreenerSMSReminder, IPatientInCall, IUser, TGenericPhysicianAppointmentType} from "../../../models";
import {Button} from "../../generic/Buttons";
import React, {useCallback, useEffect, useState} from "react";
import SMSIcon from "../../../assets/images/common/sms.svg";
import {Tooltip} from "react-tooltip";
import ConfirmationModal from "../../clinical-notes/ConfirmationModal";
import _ from 'lodash';
import {MaskPhoneNumber} from "../../generic/MaskPhoneNumber";
import {Radio, Typography} from "@material-tailwind/react";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {fetchUsers} from "../../../store/actions/physician/admin-operations.action";
import {
    resetGenericAppointmentReminderToPatient,
    sendGenericAppointmentReminderToPatient
} from "../../../store/actions/physician/patients.action";
import {toast} from "react-toastify";
import {useFlags} from "flagsmith/react";
import {DateTime} from "luxon";
import {LiaSmsSolid} from "react-icons/lia";

interface IScreeningReminderSMSType {
    value: TGenericPhysicianAppointmentType;
    label: string;
    smsTemplate: string;
}

const AvailableScreeningReminderSMSTypes: IScreeningReminderSMSType[] = [
    {
        value: 'upcoming',
        label: 'Upcoming Appointment Screening SMS.',
        smsTemplate: 'Dear {patientName}, you have an upcoming appointment with Dr. {physicianName}. To save time during your visit, please complete the required forms before the visit <Screening Link>.'
    },
    {
        value: 'past',
        label: 'Past Appointment Screening SMS.',
        smsTemplate: 'Dear {patientName}, you have had an appointment with Dr. {physicianName} and have a few pending forms. Please fill them out here <Screening Link>.'
    },
];

export interface ScreeningReminderSMSProps {
    patient: IPatientForScreenerSMSReminder;
}

const ScreeningReminderSMS = (props: ScreeningReminderSMSProps) => {

    const isPhysicianScreeningReminderSMSForPastAppointmentEnabled = useFlags(['physician_send_screening_reminder_sms_for_past_appointments'])?.physician_send_screening_reminder_sms_for_past_appointments?.enabled;
    const {patient} = props;
    const dispatch = useDispatch();
    const {
        providerId
    } = useSelector((state: StateParams) => state.physicianAccount);
    const [reminderSMSType, setReminderSMSType] = useState<TGenericPhysicianAppointmentType | undefined>(undefined);
    const [appointmentPhysicianId, setAppointmentPhysicianId] = useState<string | undefined>(undefined);
    const [appointmentPhysician, setAppointmentPhysician] = useState<IUser | undefined>(undefined);
    const [isSinglePhysicianPractice, setIsSinglePhysicianPractice] = useState<boolean>(true);
    const [showSendSMSForm, setShowSendSMSForm] = React.useState<boolean>(false);
    const [isPatientPhoneNumberValid, setIsPatientPhoneNumberValid] = useState<boolean | undefined>(undefined);
    const [screeningReminderSMSTypes, setScreeningReminderSMSTypes] = useState<IScreeningReminderSMSType[]>();
    const [isDefaultPhysicianSelected, setIsDefaultPhysicianSelected] = useState<boolean>(false);
    const {
        users: physicianList,
        fetchUsersInProgress
    } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const {
        patientToSendGenericAppointmentReminder,
        sendGenericAppointmentReminderToPatientInProgress,
        sendGenericAppointmentReminderToPatientError,
        sendGenericAppointmentReminderToPatientSuccess
    } = useSelector((state: StateParams) => state.physicianPatients);

    useEffect(() => { // Check if the practice has only one physician
        const activePhysicianListPayload = {
            fetchPhysicians: true,
            status: true
        };
        dispatch(fetchUsers(activePhysicianListPayload));
    }, [dispatch]);

    useEffect(() => {
        if (isPhysicianScreeningReminderSMSForPastAppointmentEnabled) {
            setScreeningReminderSMSTypes(AvailableScreeningReminderSMSTypes);
        } else {
            setScreeningReminderSMSTypes([]);
            const interval = setInterval(() => {
                if (!reminderSMSType) {
                    setReminderSMSType('upcoming');
                }
            }, 1000);
            if (reminderSMSType) {
                clearInterval(interval);
            }
            return () => clearInterval(interval);
        }
    }, [isPhysicianScreeningReminderSMSForPastAppointmentEnabled, reminderSMSType]);

    const prePopulateAppointmentPhysician = useCallback(() => {
        if (physicianList) {
            if (physicianList.length === 1) {
                const singlePhysician = physicianList[0];
                setAppointmentPhysician(singlePhysician);
                setAppointmentPhysicianId(singlePhysician?.id);
                setIsSinglePhysicianPractice(true);
            } else {
                const loggedInProvider = physicianList.find((physician) => physician.id === providerId);
                if (loggedInProvider) {
                    console.log(loggedInProvider);
                    setAppointmentPhysician(loggedInProvider);
                    setAppointmentPhysicianId(loggedInProvider?.id);
                    setIsDefaultPhysicianSelected(true);
                }
                setIsSinglePhysicianPractice(false);
            }
        } else {
            setIsSinglePhysicianPractice(true);
        }
    }, [physicianList, providerId]);

    useEffect(() => {
        if (physicianList) {
            prePopulateAppointmentPhysician();
        }
    }, [physicianList, prePopulateAppointmentPhysician]);

    const openSendSMSForm = useCallback(() => {
        setAppointmentPhysician(undefined);
        setReminderSMSType(undefined);
        setShowSendSMSForm(true);
        prePopulateAppointmentPhysician();
    }, [prePopulateAppointmentPhysician]);

    const closeSendSMSForm = useCallback(() => {
        setShowSendSMSForm(false);
    }, []);

    const sendSMS = useCallback(() => {
        if (reminderSMSType && appointmentPhysician) {
            dispatch(
                sendGenericAppointmentReminderToPatient({
                    patientId: patient?.sourcePatientId,
                    practiceId: patient?.referredByPracticeId,
                    appointmentType: reminderSMSType,
                    physicianName: appointmentPhysician?.firstName + ' ' + appointmentPhysician?.lastName,
                })
            );
        }
    }, [reminderSMSType, appointmentPhysician, patient, dispatch]);

    useEffect(() => {
        if (patientToSendGenericAppointmentReminder && patientToSendGenericAppointmentReminder?.patientId === patient?.sourcePatientId) {
            if (sendGenericAppointmentReminderToPatientError) {
                console.error("sendGenericAppointmentReminderToPatientError", sendGenericAppointmentReminderToPatientError, patient?.sourcePatientId);
                toast(sendGenericAppointmentReminderToPatientError);
                dispatch(resetGenericAppointmentReminderToPatient());
            }
            if (sendGenericAppointmentReminderToPatientSuccess) {
                closeSendSMSForm();
                toast(`Screening SMS sent successfully to ${patient?.phoneNumber}`);
                dispatch(resetGenericAppointmentReminderToPatient());
            }
        }
    }, [sendGenericAppointmentReminderToPatientSuccess, patientToSendGenericAppointmentReminder, sendGenericAppointmentReminderToPatientError, patient, closeSendSMSForm]);

    useEffect(() => { // Check if the patient phone number is valid
        console.log("ScreeningReminderSMS.tsx :: useEffect :: patient :: ", patient);
        const US_FORMATTED_NUMBER_REGEX = /\+1\d{10}$/;
        const IN_FORMATTED_NUMBER_REGEX = /\+91\d{10}$/;
        if (patient && patient?.phoneNumber && (patient?.phoneNumber?.match(US_FORMATTED_NUMBER_REGEX) || patient?.phoneNumber?.match(IN_FORMATTED_NUMBER_REGEX))) {
            setIsPatientPhoneNumberValid(true);
        } else {
            setIsPatientPhoneNumberValid(false);
        }
    }, [patient]);

    const renderToolTipForInvalidPhoneNumber = useCallback(() => {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}}
                        id="physician-send-screening-reminder-sms-phone-number-tooltip">
            <div className={"w-[200px]"}>
                <span>
                    The patient phone number is invalid. Please update the phone number.
                </span>
            </div>
        </Tooltip>
    }, []);

    const handleSMSTypeSelection = useCallback((event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        const value = event?.target?.value as TGenericPhysicianAppointmentType;
        if (value) {
            setReminderSMSType(value);
        }
    }, []);

    const renderSendSMSFormModal = useCallback(() => {
        const patientFullName = `${patient?.firstName} ${patient?.lastName}`;
        const capitalizedName = _.capitalize(patientFullName);
        const phoneNumber = patient?.phoneNumber;
        const patientDob = patient && patient.dob && DateTime.fromISO(patient.dob.toISOString(), { zone: 'utc' }).toFormat('MM/dd/yyyy');
        const isFormInvalid = !reminderSMSType || !appointmentPhysician;
        const appointmentPhysicianName = `${appointmentPhysician ? appointmentPhysician?.firstName + ' ' + appointmentPhysician?.lastName : 'N/A'}`;
        return <ConfirmationModal isOpen={showSendSMSForm}
                                  onClose={closeSendSMSForm}
                                  key={'sendScreeningReminderSMSModal'}
                                  modalClassNames={'!w-1/2 text-left'}
                                  alignContentInCenter={false}
                                  actions={
                                      <Button
                                          disabled={isFormInvalid}
                                          className={`${isFormInvalid ? 'border-sjGray text-sjGray opacity-80 cursor-not-allowed' : 'border-sjOrange text-sjOrange'} rounded-l-md  !ml-2 px-[16px] py-[8px] !h-8 text-sm w-full justify-center`}
                                          onClick={sendSMS}>
                                          <div className={"flex flex-row gap-x-2 items-center"}>
                                              <span>
                                                  {sendGenericAppointmentReminderToPatientInProgress ? 'Sending...' : 'Send SMS to Patient'}
                                              </span>
                                              <LiaSmsSolid width={20}/>
                                              {/*<img src={SMSIcon} alt='sms' width={14} height={14}*/}
                                              {/*     className={`${isFormInvalid ? 'opacity-80' : '' } hover:cursor-pointer`}/>*/}
                                          </div>
                                      </Button>
                                  }
        >
            <>
                <h4 className='font-semibold'>
                    Select {isSinglePhysicianPractice ? 'SMS Type' : 'Physician &  SMS Type'}
                </h4>
                <div className='my-5'>
                    <div className='mb-1 opacity-70'>
                        To proceed with sending the SMS screening link, please select {isSinglePhysicianPractice ? '' : 'the Physician and'} the SMS type
                        for <i><>{capitalizedName} ({patientDob}) &nbsp;</>
                    </i>
                        with the phone number {<MaskPhoneNumber value={phoneNumber} disabled={true}/>}.
                    </div>
                    <div className="my-8">
                        {!isSinglePhysicianPractice && <>
                            <div className="mb-2">
                                Physician
                            </div>
                            <div>
                                <Select
                                    className="mt-3 outline-none rounded-md"
                                    options={physicianList}
                                    isSearchable={true}
                                    isDisabled={fetchUsersInProgress}
                                    getOptionLabel={(option: any) => `Dr. ${option.firstName} ${option.lastName} (#NPI : ${option.npi ? option.npi : 'N/A'})`}
                                    getOptionValue={(option: any) => option.id}
                                    placeholder='Select Physician'
                                    onChange={(value) => {
                                        if (value) {
                                            setIsDefaultPhysicianSelected(false);
                                            setAppointmentPhysicianId(value.id);
                                            setAppointmentPhysician(value);
                                        }
                                    }}
                                    value={appointmentPhysician}
                                    isMulti={false}
                                />
                                {isDefaultPhysicianSelected && <div className="text-xs text-blue-500 mt-2">
                                    Dr. {appointmentPhysicianName} is selected by default. To send the SMS for another physician, select from above.
                                </div>}
                                {fetchUsersInProgress ? 'Loading Physicians' : ''}
                            </div>
                        </>
                        }
                    </div>
                    {(screeningReminderSMSTypes && screeningReminderSMSTypes?.length > 0) && <div className='mt-5'>
                        <div>
                            SMS Type
                        </div>
                        <div>
                            {screeningReminderSMSTypes.map((type) => {
                                return <div className='flex rounded-md flex-col'>
                                    <Radio required className="text-md" onChange={handleSMSTypeSelection}
                                           name={'reminderSMSType'}
                                           value={type.value} checked={reminderSMSType === type.value}
                                           label={
                                               <Typography variant="small"
                                                           className="flex font-xs">{type.label}</Typography>
                                           }/>
                                    {reminderSMSType === type.value && <div
                                        className="border rounded-md p-2 bg-blue-100 border-blue-500 ml-10 text-blue-500">
                                        <div className="text-sm">SMS Message being sent : </div>
                                        <div className="text-sm">{type.smsTemplate.replace('{patientName}', capitalizedName).replace('{physicianName}', appointmentPhysicianName)}</div>
                                    </div>}
                                </div>
                            })}
                        </div>
                    </div>}
                </div>
            </>
        </ConfirmationModal>
    }, [patient, showSendSMSForm, closeSendSMSForm, appointmentPhysician, appointmentPhysicianId, sendSMS, isSinglePhysicianPractice, physicianList, fetchUsersInProgress, reminderSMSType, isDefaultPhysicianSelected, screeningReminderSMSTypes]);

    return (
        <>
            <Button className="rounded-l-md border-sjOrange text-sjOrange !ml-2 px-[16px] py-[8px] !h-8 text-sm"
                    onClick={openSendSMSForm}>
                <div className={"flex flex-row gap-x-2 items-center"}>
                    <span>Send Screening SMS</span>
                    <img src={SMSIcon} alt='sms' width={14} height={14} className={"hover:cursor-pointer"}/>
                </div>
            </Button>
            {!isPatientPhoneNumberValid && renderToolTipForInvalidPhoneNumber()}
            {renderSendSMSFormModal()}
        </>
    );
}

export default ScreeningReminderSMS;
