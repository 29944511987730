import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5"
import { StateParams } from "../../store/reducers";
import ProspectProfile from "./ProspectProfile";
import React, { useEffect, useState } from "react";
import { clearUpdatePatientInfoStatus, fetchPatient, fetchPatientSummary, updatePatientInfo } from "../../store/actions/counsellor/patients.action";
import { Card, Textarea } from "@material-tailwind/react";
import { Tooltip } from "react-tooltip";
import { PageButtonV2 } from "../generic/Buttons";
import Select from 'react-select'
import statusBucket from "../../assets/data/statusBucket";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import _ from "lodash";
import ConfirmationModal from "../clinical-notes/ConfirmationModal";
import { RoutePaths } from "../../shared/Utils";
import {IProspect} from "../../models";
import BackIcon from "../../assets/images/common/back.svg";

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        fontSize: '14px',
      }),

      valueContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided: any, state: any) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: '30px',
      })
}

const SESSION_STATUSES_TO_CONSIDER = ['finished', 'in_progress', 'started', 'scheduled']

const DischargeSummary = () => {
    const { dischargeReason = '', status = '', isJourney, additionalInfo = '' } = useLocation().state || {dischargeReason: '', status: '', isJourney: true, additionalInfo: ''}
    const { patient, patientSummary, updatePatientInfoSuccess } = useSelector((state: StateParams) => state.patients)
    const { patientId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [additionalNotes, setAdditionalNotes] = useState<string>("")
    const [isModalOpen, setIsModalOpen] = useState(false)

    const reasons = Object.entries(statusBucket.discharge_reason).map(([key, value]) => ({ value: key, label: value }))
    const mentalBehavioralIcdCodes = patientSummary?.mentalBehavioral || []
    const socialDeterminantsIcdCodes = patientSummary?.socialDeterminants || []
    const intakeDate = _.get(patient, 'patientSessions', [])
        .filter(session => SESSION_STATUSES_TO_CONSIDER.includes(session.status))
        .find(session => session.sessionType === 'Intake')
        ?.updatedAt
    const formattedIntakeDate = intakeDate ? DateTime.fromISO(intakeDate, { zone: 'utc' }).toFormat('MM/dd/yyyy') : 'N/A'

    const completedSessions = _.get(patient, 'patientSessions', [])
    .filter(session => SESSION_STATUSES_TO_CONSIDER.includes(session.status))
    const lastSession = _.orderBy(completedSessions, ['updatedAt'], ['desc'])?.[0]
    const formattedLastSessionDate = lastSession ? DateTime.fromISO(lastSession.updatedAt, { zone: 'utc' }).toFormat('MM/dd/yyyy') : 'N/A'

    const followUpSessionDate = _.orderBy(completedSessions, ['updatedAt'], ['desc']).find(session => session.sessionType === 'Follow_Up')?.updatedAt
    const treatmentPlanSessionDate = !followUpSessionDate && _.orderBy(completedSessions, ['updatedAt'], ['desc']).find(session => session.sessionType === 'Treatment_Plan')?.updatedAt

    const lastTreatmentDate = followUpSessionDate || treatmentPlanSessionDate
    const daysDifferenceBetweenIntakeAndRecentSession = lastTreatmentDate && intakeDate && (DateTime.fromISO(lastTreatmentDate, { zone: 'utc' }).diff(DateTime.fromISO(intakeDate, { zone: 'utc' }), 'days')).days
    const treatmentDurationDays = daysDifferenceBetweenIntakeAndRecentSession ? `${Math.ceil(daysDifferenceBetweenIntakeAndRecentSession)} day(s)` : 'N/A'

    useEffect(() => {
        if (patientId) {
            console.log("fetching patient inforamtion and summary in discharge workflow", patientId)
            dispatch(fetchPatient(patientId))
            dispatch(fetchPatientSummary(patientId))
        }
    }, [patientId])

    useEffect(() => {
        if (updatePatientInfoSuccess) {
            dispatch(clearUpdatePatientInfoStatus())
            setIsModalOpen(false)
            toast.success('Patient discharged successfully.', {
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
                toastId: 'discharge-success-toast',
                autoClose: 2000
            })
            toast.onChange((cb) => {
                if(cb.id === 'discharge-success-toast' && cb.status.includes('removed')) {
                    navigate(`/${RoutePaths.collaborativeCare.dischargedPatients.root}`)
                }
            })
        } else if (updatePatientInfoSuccess === false) {
            dispatch(clearUpdatePatientInfoStatus())
            setIsModalOpen(false)
            toast.error('Failed to discharge patient.', {
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        }
    }, [updatePatientInfoSuccess])

    const handleDischargePatient = () => {
        if(patient) {
            dispatch(updatePatientInfo(patient.sourcePatientId, patient.referredByPracticeId, {
                patientStatus: dischargeReason,
                statusAdditionalInfo: additionalNotes,
                contactPhoneNumber: patient.phoneNumber,
            }))
        }
    }

    const  renderHeading = (headingName: string) => {
        return <label className="text-md text-sjText font-bold leading-7">{headingName}</label>
    }

    const renderNotAvailable = () => {
        return <p className="text-base text-sjText font-nunito font-medium leading-6">N/A</p>
    }

    const renderIcdCodesList = (x: { code: string, name: string }) => <li className="text-base text-sjText font-medium leading-7"> {x.name} </li>

    const renderProposedMedicationTable = (medications: any) => {
        return <table className="w-full min-w-0 table-fixed text-left">
            <thead>
                <tr>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Medicine</th>
                    {/* <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Medicine Type</th> */}
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Dosage</th>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6">Frequency</th>
                    <th className="text-base text-sjAnswer font-nunito font-bold leading-6 w-3/12">Comments</th>
                </tr>
            </thead>
            <tbody>
                {medications.map((medication: any) =>
                    <tr className="py-2">
                        <td className="border-none text-sjText text-base font-nunito align-top">{medication.medicine || 'N/A'}</td>
                        {/* <td className="border-none text-sjText text-base font-nunito align-top">{medication.type || 'N/A'}</td> */}
                        <td className="border-none text-sjText text-base font-nunito align-top">{medication.dosage || 'N/A'}</td>
                        <td className="border-none text-sjText text-base font-nunito align-top">{medication.frequency || 'N/A'}</td>
                        <td className="border-none text-sjText text-base font-nunito align-top truncate py-1"
                            data-tooltip-id={`medications-${medication.medicine}`}>
                            {medication.details || 'N/A'}
                            <Tooltip id={`medications-${medication.medicine}`} style={{ width: '25%' }}>
                                <div className="whitespace-normal break-all">{medication.details}</div>
                            </Tooltip>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    }

    function renderBackButton(patient: IProspect) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                     className={"hover:cursor-pointer"}/>
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> CoCM Discharge Patient Profile {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
            </div>
        </div>;
    }
    const renderTopSection = () => {
        return (
            <div className={"p-3 floating-section"}>
                {patient ? renderBackButton(patient) : null}
            </div>
        )
    }

    const renderPatientProfile = () => {
        return (
            <div className="px-5 flex-1">
                {patient && <ProspectProfile prospect={patient} />}
                {renderDischargeSummary()}
                <div className="flex flex-row justify-stretch">
                    {renderMentalBehavorialIcdCodes()}
                    {rendeSocialDeterminantsIcdCodes()}
                </div>
                {renderMedications()}
                {renderAdditionalNotes()}
                {!isJourney && renderDischargePatientButton()}
            </div>
        )
    }

    const renderDischargeSummary = () => {
        return (
            <section className="bg-sjWhite mt-6 w-full mr-4 p-2 rounded">
                <div className="flex flex-row">
                    {renderHeading('Discharge Summary')}
                    <Select isDisabled={true} options={reasons} value={reasons.find(option => option.value === dischargeReason)} className="pl-2" styles={customStyles}/>
                </div>
                <div className={`grid grid-flow-col auto-cols-4 items-stretch m-auto mt-5`}>
                    <div className={"text-left col-start-1"}>
                        <p className={"text-sjGrey text-sm tracking-wide"}>Intake Date</p>
                        <p className={"mt-1.5 text-black text-sm"}>{formattedIntakeDate}</p>
                    </div>
                    <div className={"pl-6 text-left col-start-2"}>
                        <p className={"text-sjGrey text-sm tracking-wide"}>Last Session</p>
                        <p className={"mt-1.5 text-black text-sm"}>{formattedLastSessionDate}</p>
                    </div>
                    <div className={"pl-6 text-left col-start-3"}>
                        <p className={"text-sjGrey text-sm tracking-wide"}>Treatment Duration</p>
                        <p className={"mt-1.5 text-black text-sm"}>{treatmentDurationDays}</p>
                    </div>
                    <div className={"pl-6 text-left col-start-4"}>
                        <p className={"text-sjGrey text-sm tracking-wide"}>Total Sessions</p>
                        <p className={"mt-1.5 text-black text-sm"}>{completedSessions.length}</p>
                    </div>
            </div>
            </section>
        )
    }

    const renderMentalBehavorialIcdCodes = () => {
        return (
            <section className="bg-sjWhite mt-6 w-1/2 mr-4 p-2 rounded">
                {renderHeading('Mental, Behavioural and Neurodevelopmental Disorders F01- F99')}
                <ul className="list-disc pl-5">
                    {mentalBehavioralIcdCodes.length ? mentalBehavioralIcdCodes.map(renderIcdCodesList) : renderNotAvailable()}
                </ul>
            </section>
        )
    }

    const rendeSocialDeterminantsIcdCodes = () => {
        return (
            <section className="bg-sjWhite mt-6 w-1/2 ml-4 p-2 rounded">
                {renderHeading('Social Determinants of Health')}
                <ul className="list-disc pl-5">
                    {socialDeterminantsIcdCodes.length ? socialDeterminantsIcdCodes.map(renderIcdCodesList) : renderNotAvailable()}
                </ul>
            </section>
        )
    }

    const renderMedications = () => {
        return (
            <section className="bg-sjWhite mt-6 p-2 rounded">
                {renderHeading('Medications')}
                {patientSummary?.proposedMedication ?
                    renderProposedMedicationTable(patientSummary.proposedMedication) :
                    renderNotAvailable()
                }
            </section>
        )
    }

    const renderAdditionalNotes = () => {
        return (
            <section className="bg-sjWhite mt-6 p-2 rounded">
                {renderHeading('Additional Notes')}
                <Textarea disabled={isJourney} defaultValue={additionalInfo || ''} onChange={ev => setAdditionalNotes(ev.target.value)} rows={3} className="mt-3 outline-none rounded-md w-full" data-testid="notes"/>
            </section>
        )
    }

    const renderDischargePatientButton = () => {
        return (
            !updatePatientInfoSuccess && <PageButtonV2
                className={"!bg-sjOrange !text-white !ml-2 px-[16px] py-[8px] !h-8 text-sm my-5"}
                onClick={() => setIsModalOpen(!isModalOpen)}
            >
                <div className={"flex flex-row gap-x-2 items-center"}>
                    <span>Discharge Patient</span>
                </div>
            </PageButtonV2>
        )
    }

    const renderDischargeConfirmationModal = () => {
        return (
            <ConfirmationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}
                key={'dischargePatientConfirmationModal'} onConfirm={handleDischargePatient} modalClassNames={'!w-2/6'} continueButtonName={'Discharge Patient from CoCM'}
            >
                <p className="text-sjGray text-sm font-base text-left text-center">This action will discharge the patient from collaborative care treatment.</p>
                <p className="text-sjGray text-sm font-base text-left pt-4 text-center">Are you sure to discharge the patient?</p>
            </ConfirmationModal>
        )
    }

    return (
        <main className="relative min-h-screen mx-auto flex flex-col bg-[#F7F8FA]">
            {renderTopSection()}
            {renderPatientProfile()}
            {renderDischargeConfirmationModal()}
        </main>
    );
}

export default DischargeSummary
