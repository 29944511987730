import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from "recharts";
import { StateParams } from "../../../../store/reducers";
import Select from "react-select";

interface Category {
    label: string;
    count: number;
    percentage: number;
}

interface WeekData {
    week: number;
    totalPatients: number;
    categories: Category[];
}

interface TooltipProps {
    active?: boolean;
    payload?: { payload: WeekData }[];
    label?: string;
}

const ClinicalImprovementsChartsComponent = ({ selectedClinicalCondition, setSelectedClinicalCondition }: { selectedClinicalCondition: string[], setSelectedClinicalCondition: (clinicalCondition: string[]) => void }) => {
    const { clinicalImprovementMetrics, registryClinicalConditions } = useSelector((state: StateParams) => state.patientsDashboard);
    
    useEffect(() => {
        if (registryClinicalConditions && registryClinicalConditions.length > 0 && selectedClinicalCondition.length === 0) {
            setSelectedClinicalCondition(registryClinicalConditions[0].ids);
        }
    }, [registryClinicalConditions, setSelectedClinicalCondition, selectedClinicalCondition]);

    const chartData = useMemo(() => (
        clinicalImprovementMetrics?.weeks?.map((week) => ({
            name: `Week ${week.week}`,
            significantlyImproved: week.categories[0].percentage,
            slightlyImproved: week.categories[1].percentage,
            notImproved: week.categories[2].percentage,
            totalPatients: week.totalPatients,
            categories: week.categories,
        })) || []
    ), [clinicalImprovementMetrics]);

    const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
        if (!active || !payload || !payload.length) return null;

        const data = payload[0].payload;
        return (
            <div className="custom-tooltip" style={{ background: "#fff", padding: "10px", border: "1px solid #ccc" }}>
                <div className="text-sm text-gray-500"><p>{label} - Total Patients: {data.totalPatients}</p></div>
                {data.categories.map((category, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center", margin: "7px 0" }}>
                        <div
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor:
                                    category.label.includes("Significantly") ? "#6FB38D" :
                                    category.label.includes("Slightly") ? "#80ADEF" :
                                    "#FAC86B",
                                marginRight: "8px",
                            }}
                        ></div>
                        <span className="text-sm text-normal">{category.label}: {category.count} ({category.percentage}%)</span>
                    </div>
                ))}
            </div>
        );
    };

    const legendFormatter = (value: string) => {
        switch (value) {
            case "significantlyImproved":
                return "Patients Significantly Improved";
            case "slightlyImproved":
                return "Patients Slightly Improved";
            case "notImproved":
                return "Patients Not Improved";
            default:
                return value;
        }
    };

    return (
        <div className="border border-gray-200 p-4 rounded-md mt-10 bg-white">
            <div className="flex flex-row justify-between">
                <div className="text-lg text-normal">Patients achieving clinical improvement</div>
                <Select
                    options={registryClinicalConditions}
                    placeholder={"Clinical Condition"}
                    isClearable={true}
                    isSearchable={false}
                    getOptionLabel={option => option.registryClinicalCondition}
                    getOptionValue={option => option.ids.join(',')}
                    onChange={(selectedOptions) => {
                        const selectedId = selectedOptions ? selectedOptions.ids : [];
                        setSelectedClinicalCondition(selectedId);
                    }}
                    value={registryClinicalConditions?.filter(clinicalCondition => selectedClinicalCondition.every(id => clinicalCondition.ids.includes(id)))}
                    className="w-60"
                />
            </div>
            <div className="text-sm text-normal py-2 text-gray-500">
                Number and percentage of patients in treatment achieving significant clinical improvement as measured by a validated rating scale
            </div>
            {clinicalImprovementMetrics && Object.keys(clinicalImprovementMetrics).length > 0 ?
                <div style={{ width: "100%", height: 350 }}>
                    <ResponsiveContainer>
                        <BarChart
                            data={chartData}
                            barSize={50}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis tick={false} tickLine={false} axisLine={false}/>
                            <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                            <Bar dataKey="notImproved" stackId="a" fill="#FAC86B" isAnimationActive={false} />
                            <Bar dataKey="slightlyImproved" stackId="a" fill="#80ADEF" isAnimationActive={false} />
                            <Bar dataKey="significantlyImproved" stackId="a" fill="#6FB38D" radius={[10, 10, 0, 0]} isAnimationActive={false} />
                            <Legend verticalAlign="bottom" align="left" formatter={legendFormatter} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                :
                <div className="flex items-center justify-center text-sm text-normal m-10 text-gray-500 text-center h-[200px]">
                    Currently, there is insufficient data to display this metric. The metric will become visible once enough data has been generated.
                </div>
            }
        </div>
    );
};

export default ClinicalImprovementsChartsComponent;
