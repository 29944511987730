import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DataLabelComponent from "../../../components/generic/DataLabel";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { Button } from "../../../components/generic/Buttons";
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import InsuranceEligibilityCheck from "./Insurance-eligibility";
import ConfirmationModal from "../../../components/clinical-notes/ConfirmationModal";
import { IInsuranceEligibilityChecks } from "../../../models";
import { fetchInsuranceList, resetCheckedInsuranceEligibility, resetEligibilityInsuranceStatus, updateEligibilityInsuranceStatus } from "../../../store/actions/care-coordinator/patients.action";
import { toast } from "react-toastify";
import { ta } from "date-fns/locale";


interface InsuranceDetailsProps {
    insurance: any;
    showTitle?: boolean;
}

const insuranceCallStatusOptions: {
    [k: string]: string
} = {
    pending: "Pending Verification",
    spoke_to_payor: "Spoke to Payor",
    unable_to_reach: "Unable to reach payor",
    IVR: "IVR only",
    not_required: "No need to call payor",
};

const InsuranceDetails: React.FC<InsuranceDetailsProps> = ({ insurance, showTitle }) => {
    const location = useLocation();
    const {
        patientBasicInformation: patient,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const { prospect } = useSelector((state: StateParams) => state.prospects)
    const practice = (patient?.screenerUserPractices && patient?.screenerUserPractices?.length > 0) ? patient?.screenerUserPractices[0]?.providerPractice : undefined;
    const provider = (practice?.providers && practice?.providers?.length > 0) ? practice?.providers[0] : undefined;
    const filteredEligibilityChecks = insurance.insuranceEligibilityChecks?.filter(
        (check: IInsuranceEligibilityChecks) => check.isPatientEligible !== null
    );
    const {
        checkInsuranceEligibilitySuccess
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const latestEligibilityCheck = insurance.insuranceEligibilityChecks?.[0];
    const [isEligibilityDrawerOpen, setEligibilityDrawerOpen] = useState(false);
    const [eligibilityMode, setEligibilityMode] = useState<'view' | 'check' | null>(null);
    const [isPracticeDetailsPopupOpen, setIsPracticeDetailsPopupOpen] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
    const patientId = params.patientId || params.prospectId;
    const hasPotentialPatients = location.pathname.includes("potential-patients");
    const [practiceDetails, setPracticeDetails] = useState<any>(null);
    const [eligibilityData, setEligibilityData] = useState<any>(null);
    const [isPatientEligible, setIsPatientEligible] = useState<boolean | null>(null);
    const [patientDetails, setPatientDetails] = useState<any>(null);

    useEffect(() => {
        if (hasPotentialPatients) {
            setPracticeDetails({
                practice: practice?.name,
                provider: provider?.firstName + " " + provider?.lastName,
                taxId: practice?.taxId,
                npi: provider?.npi,
                medicaidId: provider?.medicaidId
            });

            setPatientDetails({
                firstName: patient?.firstName,
                lastName: patient?.lastName,
                dob: patient?.dob && DateTime.fromISO(patient?.dob.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy')
            });

        } else {
            setPracticeDetails({
                practice: prospect?.referredByPracticeName,
                provider: prospect?.referredByPhysicianFirstName + " " + prospect?.referredByPhysicianLastName,
                taxId: prospect?.taxId,
                npi: prospect?.patientStatuses[0].provider.npi,
                medicaidId: prospect?.patientStatuses[0].provider.medicaidId
            });

            setPatientDetails({
                firstName: prospect?.firstName,
                lastName: prospect?.lastName,
                dob: prospect?.patient?.dob && DateTime.fromISO(prospect?.patient?.dob.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy')
            });
        }
    }, [hasPotentialPatients, practice, provider, prospect]);

    const handleEligibilityView = (eligibility: IInsuranceEligibilityChecks) => {
        setEligibilityMode('view')
        setEligibilityDrawerOpen(true)
        setEligibilityData(eligibility)
    }

    useEffect(() => {
        if (checkInsuranceEligibilitySuccess) {
            setEligibilityDrawerOpen(false);
            if (patientId) {
                dispatch(resetCheckedInsuranceEligibility());
                dispatch(fetchInsuranceList(patientId));
            }
        }
        if (isPatientEligible !== null) {
            toast(`Insurance is ${isPatientEligible ? 'Eligible' : 'Not Eligible'}`)
        }
    }, [checkInsuranceEligibilitySuccess, patientId]);

    const handleInsuranceEligibilitySuccess = ((isPatientEligible: boolean) => {
        console.log("isPatientEligible", isPatientEligible)
        setIsPatientEligible(isPatientEligible)
    });

    return (
        <div className="w-full p-4 h-full">
            {showTitle && (
                <div className="text-[16px] font-semibold mb-4">
                    {insurance.insuranceType === "self_pay" ? "Eligibility Checks" : "Insurance Information"}
                </div>
            )}

            {insurance.insuranceType === "self_pay" ? (
                <div className="rounded-md border border-gray-300 bg-white p-5">
                    <div className="flex items-center space-x-6">
                        <div className="text-red-600 text-sm font-semibold">Patient Not Eligible</div>
                        <div className="text-gray-600 text-xs italic">
                            Check Date -
                            {DateTime.fromISO(insurance.updatedAt.toString(), { zone: 'utc' }).toFormat('MM-dd-yyyy')}
                        </div>
                    </div>
                    <div className="text-red-600 text-xs italic pt-2">Self Pay</div>
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">

                        <DataLabelComponent label="Insurance Payor">
                            {insurance.insurancePayor?.name || "N/A"}
                        </DataLabelComponent>
                        <DataLabelComponent label="Insurance Type" >
                            {insurance.insuranceType.charAt(0).toUpperCase() + insurance.insuranceType.slice(1).toLowerCase() || "N/A"}
                        </DataLabelComponent>
                        <DataLabelComponent label="Group Number">{insurance.groupNumber || "N/A"}</DataLabelComponent>
                        <DataLabelComponent label="Policy ID" >{insurance.policyId || "N/A"}</DataLabelComponent>
                        <DataLabelComponent label="Insurance Address">{insurance.address || "N/A"}</DataLabelComponent>
                    </div>

                    <hr className="my-4" />


                    {
                        filteredEligibilityChecks.length === 0 ?
                            <>
                                <div className="text-[16px] font-semibold mb-4">Eligibility Check</div>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                                    <DataLabelComponent label="Deductible Amount" >{latestEligibilityCheck?.deductableAmount ? `$${latestEligibilityCheck?.deductableAmount}` : "$0"} </DataLabelComponent>
                                    <DataLabelComponent label="Remaining Amount" >{latestEligibilityCheck?.remainingAmount ? `$${latestEligibilityCheck?.remainingAmount}` : "$0"}</DataLabelComponent>
                                    <DataLabelComponent label="Copay">{latestEligibilityCheck?.copay ? `$${latestEligibilityCheck?.copay}` : '$0'}</DataLabelComponent>
                                    <DataLabelComponent label="Plan Termination Date" >
                                        {latestEligibilityCheck?.isLifeTimeInsurance ? "Life Time Insurance" :
                                            latestEligibilityCheck?.terminationDate ? DateTime.fromISO(latestEligibilityCheck?.terminationDate.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy') : "N/A"
                                        }
                                    </DataLabelComponent>
                                </div>

                                <Button
                                    className={"!bg-sjOrange !text-white"}
                                    type="button"
                                    onClick={() => {
                                        setEligibilityMode('check')
                                        setEligibilityDrawerOpen(true)
                                    }}
                                    variant="primary">
                                    Determine Eligibility
                                </Button>

                            </> : <>
                                <div className="flex justify-between">
                                    <div className="text-[16px] font-semibold mb-4">Eligibility Check</div>
                                    <div className="flex justify-end">
                                        <Button
                                            onClick={() => {
                                                setEligibilityMode('check')
                                                setEligibilityDrawerOpen(true)
                                            }
                                            }
                                            className={"!border-sjOrange !text-sjOrange"}
                                            type="button" variant="outline">
                                            New Eligibility Check
                                        </Button>
                                    </div>
                                </div>
                                {filteredEligibilityChecks.map((eligibility: IInsuranceEligibilityChecks, index: number) => (
                                    <div key={index} className="px-3 py-3 my-5 rounded-sm border border-gray-200 bg-white">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <div className="flex items-center gap-2">
                                                    <div className={`text-sm font-semibold ${eligibility.isPatientEligible ? 'text-green-600' : 'text-red-600'}`}>
                                                        {eligibility.isPatientEligible ? "Insurance Eligible" : "Insurance Not Eligible"}
                                                    </div>
                                                    <div className="w-1 h-1 bg-gray-300 rounded-full flex-shrink-0"></div>
                                                    <div className="text-[#575F6E] text-xs italic">
                                                        Check Date - {eligibility.eligibilityCheckDate ? DateTime.fromISO(eligibility.eligibilityCheckDate.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy') : 'N/A'}
                                                    </div>
                                                </div>
                                                <div className="flex gap-2 items-center pt-2">
                                                    <div className="text-[#575F6E] text-xs italic">{insurance.insurancePayor?.name}</div>
                                                    <div className="w-1 h-1 bg-gray-300 rounded-full flex-shrink-0"></div>
                                                    <div className="text-[#575F6E] text-xs italic">
                                                        Plan Termination Date - {eligibility.isLifeTimeInsurance ? "Life Time Insurance" :
                                                            eligibility.terminationDate && DateTime.fromISO(eligibility.terminationDate.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    handleEligibilityView(eligibility)
                                                }}
                                                className="text-[#0C80E3] px-1 font-nunito text-[14px] italic font-medium leading-[24px] underline cursor-pointer"
                                            >
                                                View Details
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                    }
                </>
            )}

            <Modal
                isOpen={isEligibilityDrawerOpen}
                onRequestClose={() => setEligibilityDrawerOpen(false)}
                className="fixed top-0 right-0 mx-auto p-5 border w-1/2 shadow-lg bg-white h-full max-h-screen overflow-y-auto"

            >
                <>
                    <div className="flex justify-between items-center">
                        <div className="text-lg font-semibold">Insurance Eligibility Check</div>
                        <div
                            onClick={() => setEligibilityDrawerOpen(false)}
                            className="cursor-pointer"
                            data-tooltip-id="confirmation-modal-close-icon-tooltip"
                        >
                            <AiFillCloseCircle
                                className="text-gray-500 hover:text-gray-700"
                                style={{ width: '25px', height: '25px' }}
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        {
                            eligibilityMode === 'check' ?
                                <div className="font-light text-sm text-sjGray">
                                    Determine if <span className="font-md font-semibold">{patientDetails?.firstName} {patientDetails?.lastName}</span>&nbsp;
                                    (<span className="font-md font-semibold">
                                        {patientDetails?.dob && patientDetails?.dob}
                                    </span>)&nbsp;
                                    <span className="font-md font-semibold">{insurance.insurancePayor?.name}</span> Insurance is eligible for CoCM Treatment
                                </div> :

                                <div className="font-light text-sm text-sjGray">
                                    <span className="font-medium font-semibold">{patientDetails?.firstName} {patientDetails?.lastName}</span>
                                    (<span className="font-medium font-semibold">&nbsp;
                                        {patientDetails?.dob && patientDetails?.dob}
                                    </span>)&nbsp;
                                    <span className="font-medium font-semibold">{insurance.insurancePayor?.name}</span> Insurance eligibility details
                                </div>
                        }
                    </div>
                    <div className="text-[#0C80E3] my-4 font-nunito text-[14px] italic font-medium leading-[24px] underline">
                        <span className="cursor-pointer" onClick={() => setIsPracticeDetailsPopupOpen(true)}> View Practice Details </span>
                    </div>


                    {eligibilityMode === 'check' && <>
                        <InsuranceEligibilityCheck
                            onEligibilityCheck={(isPatientEligible) => {
                                handleInsuranceEligibilitySuccess(isPatientEligible)
                            }}
                            eligibilityCheckObject={latestEligibilityCheck}
                        />
                    </>}
                    {
                        eligibilityMode === 'view' && <>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
                                <DataLabelComponent label="Eligibility Check Date">
                                    {eligibilityData?.eligibilityCheckDate ? DateTime.fromISO(eligibilityData?.eligibilityCheckDate.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy') : "N/A"}
                                </DataLabelComponent>
                                <DataLabelComponent label="Plan Termination Date" >
                                    {eligibilityData?.isLifeTimeInsurance ? "Life Time Insurance" :
                                        eligibilityData?.terminationDate ? DateTime.fromISO(eligibilityData?.terminationDate.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy') : "N/A"
                                    }
                                </DataLabelComponent>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                                <DataLabelComponent label="Deductible Amount" >
                                    {eligibilityData?.deductableAmount ? `$${eligibilityData?.deductableAmount}` : "$0"}
                                </DataLabelComponent>
                                <DataLabelComponent label="Remaining Amount" >
                                    {eligibilityData?.remainingAmount ? `$${eligibilityData?.remainingAmount}` : "$0"}
                                </DataLabelComponent>
                                <DataLabelComponent label="Copay" >
                                    {eligibilityData?.copay ? `$${eligibilityData?.copay}` : "$0"}
                                </DataLabelComponent>
                            </div>

                            <div className="pb-2">Prior Auth</div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
                                <DataLabelComponent label="CPT - 99492">
                                    {eligibilityData?.priorAuth?.['99492'] === true ? 'Yes' : eligibilityData?.priorAuth?.['99492'] === false ? 'No' : 'N/A'}
                                </DataLabelComponent>
                                <DataLabelComponent label="CPT - 99493">
                                    {eligibilityData?.priorAuth?.['99493'] === true ? 'Yes' : eligibilityData?.priorAuth?.['99493'] === false ? 'No' : 'N/A'}
                                </DataLabelComponent>

                                <DataLabelComponent label="CPT - 99494">
                                    {eligibilityData?.priorAuth?.['99494'] === true ? 'Yes' : eligibilityData?.priorAuth?.['99494'] === false ? 'No' : 'N/A'}
                                </DataLabelComponent>
                            </div>

                            <div>
                                <DataLabelComponent label="Insurance Eligibility">
                                    {eligibilityData?.isPatientEligible ? "Insurance Eligible" : "Insurance Not Eligible"}
                                </DataLabelComponent>
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-2 items-center">
                                <div className="my-3">
                                    <DataLabelComponent label="Insurance Call Status">
                                        {
                                            eligibilityData.insuranceCallStatus ? insuranceCallStatusOptions[eligibilityData.insuranceCallStatus] : "N/A"
                                        }
                                    </DataLabelComponent>
                                </div>


                                {(eligibilityData.referenceNumber && eligibilityData.insuranceCallStatus === 'spoke_to_payor') && (
                                    <p className="text-[#5D7285] text-xs italic font-normal leading-[18px] mt-7">
                                        Ref No: {eligibilityData.referenceNumber}
                                    </p>
                                )}

                            </div>

                            <div>
                                <DataLabelComponent label="Verification Notes" >
                                    {eligibilityData?.verificationNotes || "N/A"}
                                </DataLabelComponent>
                            </div>



                        </>
                    }
                </>
            </Modal>

            <ConfirmationModal
                isOpen={isPracticeDetailsPopupOpen}
                modalClassNames={'!w-1/2'}
                title="Practice Details"
                onClose={() => setIsPracticeDetailsPopupOpen(false)}
                alignContentInCenter={false}
            >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-16 gap-y-6">
                    <DataLabelComponent label="Practice" >
                        {practiceDetails?.practice || 'N/A'}
                    </DataLabelComponent>
                    <DataLabelComponent label="Tax ID">
                        {practiceDetails?.taxId || 'N/A'}
                    </DataLabelComponent>
                    <DataLabelComponent label="Physician">
                        {practiceDetails?.provider || 'N/A'}
                    </DataLabelComponent>
                    <DataLabelComponent label="Physician NPI">
                        {practiceDetails?.npi || 'N/A'}
                    </DataLabelComponent>
                    <DataLabelComponent label="Medicaid ID">
                        {practiceDetails?.medicaidId || 'N/A'}
                    </DataLabelComponent>
                </div>
            </ConfirmationModal >


            {/* <ConfirmationModal
                isOpen={insuranceReferanceModal}
                modalClassNames={'!w-1/2'}
                onClose={() => setInsuranceReferanceModal(false)}
                alignContentInCenter={false}
                title={
                    <div className="text-[#1D1B20] font-nunito text-[16px] font-normal">
                        Add Call Reference Number <span className="text-[#5D7285] text-[12px] italic">(Optional)</span>
                    </div>
                }
                description={
                    <div className="text-[#5D7285] font-nunito text-[14px] font-normal leading-[22px]">
                        If you have a call reference number from the insurance provider, please enter it below.
                    </div>
                }
                actions={
                    <div className="flex justify-end space-x-2">
                        <Button
                            className="border-sjOrange text-sjOrange"
                            onClick={() => handleInsuranceCallStatusConfirm()}
                        >
                            Confirm
                        </Button>
                    </div>
                }
            >
                <div className="space-y-4">
                    <input
                        type="text"
                        className="border border-gray-300 rounded h-10 w-full"
                        value={referenceNumber}
                        placeholder="Enter Number"
                        onChange={(e) => setReferenceNumber(e.target.value)}
                    />

                    <textarea
                        className="border border-gray-300 rounded h-20 w-full"
                        value={additionalInfo}
                        placeholder="Enter any additional information if applicable"
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                </div>
            </ConfirmationModal> */}


        </div >
    );
};

export default InsuranceDetails;

