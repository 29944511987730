import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PieChart, Pie, Label } from "recharts";
import { StateParams } from "../../../../store/reducers";

const DashboardNotesMetricComponent = () => {
    const { registryNotesMetrics, fetchRegistryNotesMetricsInProgress } = useSelector(
        (state: StateParams) => state.patientsDashboard
    );

    const getPieChartData = (
        metrics: any,
        labels: { reviewed: string; notReviewed: string },
        colors: { reviewed: string; notReviewed: string },
        keys: { reviewedKey: string; notReviewedKey: string }
    ) =>
        metrics
            ? [
                { name: labels.reviewed, value: metrics[keys.reviewedKey], fill: colors.reviewed },
                { name: labels.notReviewed, value: metrics[keys.notReviewedKey], fill: colors.notReviewed },
            ]
            : [];

    const psychiatristReviewMetrics = registryNotesMetrics
        ? getPieChartData(
            registryNotesMetrics.psychiatristReviewMetrics,
            { reviewed: "Reviewed", notReviewed: "Not Reviewed" },
            { reviewed: "#70B0FF", notReviewed: "#FF9D66" },
            { reviewedKey: "reviewed", notReviewedKey: "notReviewed" }
        )
        : [];

    const patientFollowUpMetrics = registryNotesMetrics
        ? getPieChartData(
            registryNotesMetrics.patientFollowUpMetrics,
            { reviewed: "Active", notReviewed: "Dormant" },
            { reviewed: "#74DDE5", notReviewed: "#8F59EF" },
            { reviewedKey: "active", notReviewedKey: "dormant" }
        )
        : [];

    const CustomLabel: React.FC<{ viewBox?: any; total: number; label: string }> = ({ viewBox, total, label }) => {
        if (!viewBox) return null;
        const { cx, cy } = viewBox;
        return (
            <text
                x={cx}
                y={cy}
                fill="#3d405c"
                className="recharts-text recharts-label"
                textAnchor="middle"
                dominantBaseline="central"
            >
                <tspan x={cx} dy="-20" fontSize="14">
                    {label}
                </tspan>
                <tspan x={cx} dy="30" fontSize="20" fontWeight="bold">
                    {total}
                </tspan>
            </text>
        );
    };


    return <>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
            <>
                <div className="border border-gray-200 p-4 rounded-md flex flex-col bg-white">
                    <div className="text-lg text-normal">Patients reviewed by Psychiatrist</div>
                    {registryNotesMetrics && Object.keys(registryNotesMetrics.psychiatristReviewMetrics).length > 0 ?
                        <>
                            <div className="text-sm text-normal py-2 text-gray-500">
                                Percentage of patients in treatment who have been reviewed by a psychiatric consultant who has
                                made recommendations to the primary care-based treating medical/behavioral health providers
                            </div>
                            <div className="flex items-start justify-between my-10">
                                <div className="text-md text-normal text-gray-600">
                                    <div className="flex items-center">
                                        <span
                                            className="w-2 h-2 rounded-full mr-2"
                                            style={{ backgroundColor: "#70B0FF" }}
                                        ></span>
                                        Patients reviewed by psychiatrist -{" "}
                                        {registryNotesMetrics.psychiatristReviewMetrics.reviewed} (
                                        {registryNotesMetrics.psychiatristReviewMetrics.reviewedPercentage}%)
                                    </div>
                                    <div className="mt-3 flex items-center">
                                        <span
                                            className="w-2 h-2 rounded-full mr-2"
                                            style={{ backgroundColor: "#FF9D66" }}
                                        ></span>
                                        Patients not reviewed by psychiatrist -{" "}
                                        {registryNotesMetrics.psychiatristReviewMetrics.notReviewed} (
                                        {registryNotesMetrics.psychiatristReviewMetrics.notReviewedPercentage}%)
                                    </div>
                                </div>
                                <PieChart width={200} height={160}>
                                    <Pie
                                        data={psychiatristReviewMetrics}
                                        innerRadius={60}
                                        outerRadius={80}
                                        paddingAngle={0}
                                        dataKey="value"
                                    >
                                        <Label
                                            content={
                                                <CustomLabel
                                                    total={registryNotesMetrics.psychiatristReviewMetrics.total}
                                                    label="Total Patients"
                                                />
                                            }
                                        />
                                    </Pie>
                                </PieChart>
                            </div>
                        </>
                        :
                        <div className="flex items-center justify-center text-sm text-normal m-10 text-gray-500 text-center h-[200px]">
                            Currently, there is insufficient data to display this metric. The metric will become visible once enough data has been generated.
                        </div>
                    }
                </div>

                {/* Patient Follow-Up Metrics */}
                <div className="border border-gray-200 p-4 rounded-md flex flex-col bg-white">
                    <div className="text-lg text-normal">Active vs Dormant Analysis</div>
                    {registryNotesMetrics && Object.keys(registryNotesMetrics.patientFollowUpMetrics).length > 0 ?
                        <>
                            <div className="text-sm text-normal py-2 text-gray-500">
                                Patient engagement breakdown for follow-ups over the past four weeks.
                            </div>
                            <div className="flex items-start justify-between my-12 pt-1">
                                <div className="text-md text-normal text-gray-600">
                                    <div className="flex items-center">
                                        <span
                                            className="w-2 h-2 rounded-full mr-2"
                                            style={{ backgroundColor: "#74DDE5" }}
                                        ></span>
                                        Active patients with follow-up -{" "}
                                        {registryNotesMetrics.patientFollowUpMetrics.active} (
                                        {registryNotesMetrics.patientFollowUpMetrics.activePercentage}%)
                                    </div>
                                    <div className="mt-3 flex items-center">
                                        <span
                                            className="w-2 h-2 rounded-full mr-2"
                                            style={{ backgroundColor: "#8F59EF" }}
                                        ></span>
                                        Dormant patients with no follow-up -{" "}
                                        {registryNotesMetrics.patientFollowUpMetrics.dormant} (
                                        {registryNotesMetrics.patientFollowUpMetrics.dormantPercentage}%)
                                    </div>
                                </div>
                                <PieChart width={200} height={160}>
                                    <Pie
                                        data={patientFollowUpMetrics}
                                        innerRadius={60}
                                        outerRadius={80}
                                        paddingAngle={0}
                                        dataKey="value"
                                    >
                                        <Label
                                            content={
                                                <CustomLabel
                                                    total={registryNotesMetrics.patientFollowUpMetrics.total}
                                                    label="Total Patients"
                                                />
                                            }
                                        />
                                    </Pie>
                                </PieChart>
                            </div>
                        </>
                        :
                        <div className="flex items-center justify-center text-sm text-normal m-10 text-gray-500 text-center h-[200px]">
                            Currently, there is insufficient data to display this metric. The metric will become visible once enough data has been generated.
                        </div>
                    }
                </div>
            </>
        </div>
    </>
};

export default DashboardNotesMetricComponent;
