import {call, put, takeLatest} from "@redux-saga/core/effects";
import {apiHttpClient, getAuthHeaderConfig} from "../../../lib";
import {Either} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {
    AuthState,
    CatchupNotes,
    CatchupNotesResponse,
    CurrentQuestion, DischargedPatients, IAppointmentWithProspect,
    IAssignedScreeners,
    IProspect,
    ITherapistPatient,
    NotesStatus,
    PatientAccount,
    PatientAccountResponse,
    PatientDailyCheckinInsights,
    PatientJourney,
    PatientProgressNotes,
    PatientSafetyPlanNotes,
    PatientScreenerSummaryRequest,
    PatientSessionInsights,
    PsychiatristNotes,
    PsychiatristNotesResponse,
    SWScreenerInsights,
    SWScreenerInsightsResponse,
    SWUserScreenerRequest,
    ScreenerProviderType,
    SearchDischargedPatients,
    TreatmentPlanNotes,
} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {asyncCallFinished, asyncCallInProgress, callFinished, callInProgress} from "../../actions/loading.action";
import {
    FETCH_DAILY_CHECKIN_INSIGHTS,
    FETCH_PATIENT,
    FETCH_PATIENT_BY_SOURCE_PATIENT_ID,
    FETCH_PATIENT_JOURNEY,
    FETCH_PATIENTS,
    FETCH_PROGRESS_NOTES,
    FETCH_SAFETY_PLAN_NOTES,
    FETCH_SESSION_INSIGHTS,
    FETCH_TREATMENT_NOTES,
    fetchedDailyCheckinInsights,
    fetchedPatient,
    fetchedPatientBySource,
    fetchedPatientJourney,
    fetchedPatients,
    fetchedProgressNotes,
    fetchedSafetyPlanNotes,
    fetchedSessionInsights,
    fetchedTreatmentNotes,
    fetchingDailyCheckinInsightsFailed,
    fetchingPatientBySourceFailed,
    fetchingPatientBySourceInProgress,
    fetchingPatientFailed,
    fetchingPatientJourneyFailed,
    fetchingPatientsFailed,
    fetchingProgressNotesFailed,
    fetchingSafetyPlanNotesFailed,
    fetchingSessionInsightsFailed,
    fetchingTreatmentNotesFailed,
    REVIEW_AND_SUBMIT_TREATMENT_PLAN,
    reviewAndSubmittedTreatmentNotes,
    reviewAndSubmittingTreatmentNotesFailed,
    SAVE_PROGRESS_NOTES,
    SAVE_SAFETY_PLAN_NOTES,
    SAVE_TREATMENT_NOTES,
    savedProgressNotes,
    savedSafetyPlanNotes,
    savedTreatmentNotes,
    savingProgressNotesFailed,
    savingSafetyPlanNotesFailed,
    savingTreatmentNotesFailed,
    FETCH_CATCHUP_NOTES,
    SAVE_CATCHUP_NOTES,
    fetchingCatchupNotesFailed,
    fetchedCatchupNotes,
    savedCatchupNotes,
    savingCatchupNotesFailed,
    FETCH_PSYCHIATRIST_NOTES,
    savedPsychiatristNotes,
    SAVE_PSYCHIATRIST_NOTES,
    savingPsychiatristNotesFailed,
    fetchingPsychiatristNotesFailed,
    fetchedPsychiatristNotes,
    FETCH_ASSIGNED_SCREENERS,
    fetchingAssignedScreenersFailed,
    fetchedAssignedScreeners,
    FETCH_SCREENER_Q_AND_A,
    fetchingScreenerQAndAFailed,
    fetchedScreenerQAndA,
    SAVE_SCREENER_ANSWER,
    savingScreenerAnswerFailed,
    savedScreenerAnswer,
    UPDATE_PATIENT_INFO,
    updatePatientInfoFailed,
    updatePatientInfoSuccess,
    FETCH_PATIENT_SUMMARY,
    fetchPatientSummaryFailed,
    fetchPatientSummarySuccess,
    fetchingPatientWithSummaryFailed,
    fetchedPatientWithSummary,
    FETCH_PATIENT_WITH_SUMMARY,
    searchingPatientsFailed,
    searchedPatients,
    SEARCH_PATIENTS,
    searchPatientsInProgress,
    FETCH_PRACTICE_PATIENT,
    FETCH_PATIENT_LOCATION,
    fetchPatientLocationFailed,
    fetchPatientLocationSuccess,
    SEARCH_DISCHARGED_PATIENTS,
    searchingDischargedPatientsFailed,
    searchedDischargedPatients, PatientFilters, FETCH_SOURCE_PATIENT_DETAILS, fetchingSourcePatientDetailsFailed, fetchedSourcePatientDetails,
    FETCH_SW_SCREENERS_INSIGHTS,
    fetchingSWScreenersInsightsFailed,
    fetchedSWScreenersInsights,
    FETCH_PATIENT_SCREENING_SUMMARY,
    fetchedScreeningSummary,
    fetchingScreeningSummaryFailed,
    updatePatientInfoStatusSuccess
} from "../../actions/counsellor/patients.action";
import _ from "lodash";
import { updateContactPhoneNumber } from "../../actions/counsellor/prospects.action";
import {createQueryString} from "../../../lib/api-helpers";

const apiFetchPatients = (action: { type: string, authState: AuthState}) => {
    const url = `/${action.authState.accountId}/patients`;
    return apiHttpClient.get<Either<CheckedError, ITherapistPatient[]>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as ITherapistPatient[])
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

interface SearchPatientsResponse {
    patients: ITherapistPatient[];
    count: number;
}

const apiSearchPatients = (action: { type: string, payload: {pageNumber: number, recordsPerPage: number, searchText: string, filters?: PatientFilters}, authState: AuthState}) => {
    const query = createQueryString(action.payload)
    const url = `/${action.authState.accountId}/patients/v2?${query}`;
    return apiHttpClient.get<Either<CheckedError, SearchPatientsResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as SearchPatientsResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}
const apiSearchDischargedPatients = (action: { type: string, payload: {currentPage: number, recordsPerPage: number, searchText: string}, authState: AuthState}) => {
    const url = `/${action.authState.accountId}/patients/v1/discharged?searchText=${action.payload.searchText}&pageNumber=${action.payload.currentPage}&recordsPerPage=${action.payload.recordsPerPage}&discharged=true`;
    return apiHttpClient.get<Either<CheckedError, SearchDischargedPatients>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as SearchDischargedPatients)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}
const apiFetchPatient = (action: { type: string, authState: AuthState, payload: {patientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}`;
    return apiHttpClient.get<Either<CheckedError, IProspect>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IProspect)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatientWithSummary = (action: { type: string, authState: AuthState, payload: {patientId: string, practiceId: string, sourcePatientId: string, sessionId?: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/summary?practiceId=${action.payload.practiceId}&sourcePatientId=${action.payload.sourcePatientId}&sessionId=${action.payload.sessionId}`;
    return apiHttpClient.get<Either<CheckedError, IProspect>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IProspect)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatientBySource = (action: { type: string, authState: AuthState, payload: {sourcePatientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/patient-with-source-id/${action.payload.sourcePatientId}`;
    return apiHttpClient.get<Either<CheckedError, IProspect>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IProspect)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatientByPracticeAndSource = (action: { type: string, authState: AuthState, payload: {sourcePatientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/practice-patient-with-source-id/${action.payload.sourcePatientId}`;
    return apiHttpClient.get<Either<CheckedError, IProspect>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IProspect)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchTreatmentPlan = (action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) => {
    let url
    if(action.payload.notesId) url = `/${action.authState.accountId}/patients/${action.payload.patientId}/treatment-plan?notesId=${action.payload.notesId}`;
    else url = `/${action.authState.accountId}/patients/${action.payload.patientId}/treatment-plan`;

    return apiHttpClient.get<Either<CheckedError, {treatmentPlanNotes:TreatmentPlanNotes, treatmentPlanCount: number, treatmentId: string, notesStatus: NotesStatus}>>
        (url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as {treatmentPlanNotes:TreatmentPlanNotes, treatmentPlanCount: number, treatmentId: string})
        }).catch((e: AxiosError<ServiceError>) => {
            catchErrorMessage(e)
        });
}
const apiFetchProgressNotes = (action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) => {
    let url
    if(action.payload.notesId) url = `/${action.authState.accountId}/patients/${action.payload.patientId}/progress-notes?notesId=${action.payload.notesId}`;
    else url = `/${action.authState.accountId}/patients/${action.payload.patientId}/progress-notes`

    return apiHttpClient.get<Either<CheckedError, PatientProgressNotes>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as PatientProgressNotes)
        }).catch((e: AxiosError<ServiceError>) => {
            catchErrorMessage(e)
        });
}
const apiFetchCatchupNotes = (action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) => {
    let url
    if(action.payload.notesId) url = `/${action.authState.accountId}/patients/${action.payload.patientId}/catchup-notes?notesId=${action.payload.notesId}`;
    else url = `/${action.authState.accountId}/patients/${action.payload.patientId}/catchup-notes`

    return apiHttpClient.get<Either<CheckedError, CatchupNotesResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as CatchupNotesResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            catchErrorMessage(e)
        });
}
const apiFetchPsychiatristNotes = (action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) => {
    let url
    if(action.payload.notesId) url = `/${action.authState.accountId}/patients/${action.payload.patientId}/psychiatrist-notes?notesId=${action.payload.notesId}`;
    else url = `/${action.authState.accountId}/patients/${action.payload.patientId}/psychiatrist-notes`

    return apiHttpClient.get<Either<CheckedError, PsychiatristNotesResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as PsychiatristNotesResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            catchErrorMessage(e)
        });
}

const apiSaveTreatmentPlan = (action: { type: string, authState: AuthState, payload: { patientId: string, notes: { treatmentNotes: { [key: string]: [value: any] } }, noteId: string | null } }) => {
    const noteId = action.payload.noteId ? `?noteId=${action.payload.noteId}` : '';
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/treatment-plan${noteId}` ;
    return apiHttpClient.patch<Either<CheckedError, {isTreatmentNotesSaved: boolean}>>(url, action.payload.notes, getAuthHeaderConfig(action.authState))
      .then((response: AxiosResponse) => {
        return Either.right(response.data.data as any)
      }).catch(e => catchErrorMessage(e));
}
const apiSaveProgressNotes = (action: { type: string, authState: AuthState, payload: { patientId: string, notes: { progressNotes: { [key: string]: [value: any] }  }, noteId: string | null } }) => {
    const noteId = action.payload.noteId ? `?notesId=${action.payload.noteId}` : '';
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/progress-notes${noteId}`;
    return apiHttpClient.patch<Either<CheckedError, {isProgressNotesSaved: boolean}>>(url, action.payload.notes, getAuthHeaderConfig(action.authState) )
      .then((response: AxiosResponse) => {
        return Either.right(response.data.data as any)
      }).catch(e => catchErrorMessage(e));
}
const apiSaveCatchupNotes = (action: { type: string, authState: AuthState, payload: { patientId: string, notes: CatchupNotes, notesId: string | null }}) => {
    const noteId = action.payload.notesId ? `?notesId=${action.payload.notesId}` : '';
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/catchup-notes${noteId}`;
    return apiHttpClient.patch<Either<CheckedError, CatchupNotesResponse>>(url, action.payload.notes, getAuthHeaderConfig(action.authState) )
      .then((response: AxiosResponse) => {
        return Either.right(response.data.data as any)
      }).catch(e => catchErrorMessage(e));
}
const apiSavePsychiatristNotes = (action: { type: string, authState: AuthState, payload: { patientId: string, notes: PsychiatristNotes, noteId: string | null  } }) => {
    const url = action.payload.noteId ? `/${action.authState.accountId}/patients/${action.payload.patientId}/psychiatrist-notes?noteId=${action.payload.noteId}`
    : `/${action.authState.accountId}/patients/${action.payload.patientId}/psychiatrist-notes`
    return apiHttpClient.patch<Either<CheckedError, PsychiatristNotesResponse>>(url, action.payload.notes, getAuthHeaderConfig(action.authState) )
      .then((response: AxiosResponse) => {
        return Either.right(response.data.data as any)
      }).catch(e => catchErrorMessage(e));
}

const apiReviewAndSubmitTreatmentNotes = (action: { type: string, authState: AuthState, payload: { patientId: string, screeners: string[], sourcePatientId: string, treatmentId: string, practiceId: string } }) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/review-treatment-plan/${action.payload.treatmentId}`;
    const body = {
        screeners: action.payload.screeners,
        sourcePatientId: action.payload.sourcePatientId,
        practiceId: action.payload.practiceId
    }
    return apiHttpClient.post<Either<CheckedError, {isScreenersAssigned: boolean}>>(url, body, getAuthHeaderConfig(action.authState))
      .then((response: AxiosResponse) => {
        return Either.right(response.data.data as any)
      }).catch(e => catchErrorMessage(e));
}

const apiFetchSafetyPlanNotes = (action: { type: string, authState: AuthState, payload: { patientId: string } }) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/safety-plan-notes`;
    return apiHttpClient.get<Either<CheckedError, PatientSafetyPlanNotes>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as PatientSafetyPlanNotes)
        }).catch((e: AxiosError<ServiceError>) => {
            catchErrorMessage(e)
        });
}

const apiSaveSafetyPlan = (action: { type: string, authState: AuthState, payload: { patientId: string, notes: { safetyPlanNotes: { [key: string]: [value: any] } } } }) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/safety-plan-notes`;
    return apiHttpClient.patch<Either<CheckedError, {isSafetyPlanNotesSaved: boolean}>>(url, action.payload.notes, getAuthHeaderConfig(action.authState))
      .then((response: AxiosResponse) => {
        return Either.right(response.data.data as any)
      }).catch(e => catchErrorMessage(e));
}

const apiFetchPatientJourney = (action: { type: string, authState: AuthState, payload: {patientId: string, sourcePatientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/journey?sourcePatientId=${action.payload.sourcePatientId}`;
    return apiHttpClient.get<Either<CheckedError, PatientJourney[]>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as PatientJourney[])
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}
const apiFetchSessionInsights = (action: { type: string, authState: AuthState, payload: {patientId: string, frequency: number}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/insights/session?frequency=${action.payload.frequency}`;
    return apiHttpClient.get<Either<CheckedError, PatientSessionInsights>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as PatientSessionInsights)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}
const apiFetchDailyCheckinInsights = (action: { type: string, authState: AuthState, payload: {patientId: string, frequency: number, sourcePatientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/insights/daily-checkin?frequency=${action.payload.frequency}&sourcePatientId=${action.payload.sourcePatientId}`;
    return apiHttpClient.get<Either<CheckedError, PatientDailyCheckinInsights>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as PatientDailyCheckinInsights)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiPatchPatient = (action: { type: string, authState: AuthState, payload: { patientId: string, practiceId: string, patientInfo: Partial<PatientAccount> } }) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}`;
    return apiHttpClient.patch<Either<CheckedError, PatientAccountResponse>>(url, {
        patientInfo: action.payload.patientInfo,
        practiceId: action.payload.practiceId
    }, getAuthHeaderConfig(action.authState))
      .then((response: AxiosResponse) => {
        return Either.right(response.data as PatientAccountResponse)
      }).catch(e => catchErrorMessage(e));
}

const catchErrorMessage = (e: AxiosError<ServiceError>) => {
    console.log("API Error", e)
    const statusCode = e.response?.status || 500
    if (statusCode >= 400 && statusCode < 500) {
      const errorResponse = e.response?.data?.error || e.response?.statusText;
      console.log("Encountered a 4XX Error", statusCode, errorResponse)
      return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
    }
    console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
    return Either.left(new Error(e.message))
  }

const apiFetchAssignedScreeners = (action: { type: string, authState: AuthState, payload: {patientId: string, treatmentId: string, providerType: ScreenerProviderType}}) => {
  const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/treatment/${action.payload.treatmentId}?providerType=${action.payload.providerType}`;
  return apiHttpClient.get<Either<CheckedError, IAssignedScreeners[]>>(url, getAuthHeaderConfig(action.authState))
      .then((response: AxiosResponse) => {
          return Either.right(response.data.data as IAssignedScreeners[])
      }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiFetchScreenerQAndA = (action: { type: string, authState: AuthState, payload: {patientId: string, userScreenerId: string, screenerConditionId: string|null}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/screener/${action.payload.userScreenerId}?screenerConditionId=${action.payload.screenerConditionId}`;
    return apiHttpClient.get<Either<CheckedError, CurrentQuestion>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as CurrentQuestion)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiSaveScreenerAnswer = (action: { type: string, authState: AuthState, payload: SWUserScreenerRequest }) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/screener/${action.payload.userScreenerId}/question/${action.payload.questionId}/answer/${action.payload.answerId}`;
    return apiHttpClient.post<Either<CheckedError, {nextQuestionLink: string}>>(url, {
        screenerStatus: action.payload.screenerStatus
    }, getAuthHeaderConfig(action.authState))
      .then((response: AxiosResponse) => {
        return Either.right(response.data.data as any)
      }).catch(e => catchErrorMessage(e));
}

const apiFetchPatientSummary = (action: { type: string, authState: AuthState, payload: {patientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/patient-summary`;
    return apiHttpClient.get<Either<CheckedError, any>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as any)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiFetchPatientLocation = (action: { type: string, authState: AuthState, payload: {patientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/practice/null/location`;
    return apiHttpClient.get<Either<CheckedError, any>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as any)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}
const apiFetchSourcePatientDetails = (action: { type: string, authState: AuthState, payload: {patientId: string}}) => {
    const url = `/source-patients/${action.payload.patientId}`;
    return apiHttpClient.get<Either<CheckedError, any>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as any)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}
const apiFetchSWScreenersInsights = (action: { type: string, authState: AuthState, payload: {patientId: string, sourcePatientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/insights/sw-screeners-scores?sourcePatientId=${action.payload.sourcePatientId}`;
    return apiHttpClient.get<Either<CheckedError, SWScreenerInsightsResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as SWScreenerInsightsResponse)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiFetchPatientScreenerSummary = (action: { type: string, authState: AuthState, payload: PatientScreenerSummaryRequest}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/summary?practiceId=${action.payload.practiceId}&sourcePatientId=${action.payload.sourcePatientId}&sessionId=${action.payload.sessionId}`;
    return apiHttpClient.get<Either<CheckedError, IProspect>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IProspect)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

function* fetchPatients(action: { type: string, authState: AuthState}) {
    try {
        console.log(`PatientsSaga:fetchPatients`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, ITherapistPatient[]> = yield call(apiFetchPatients, action)
        if(apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientsFailed({error: error.message}));
            } else {
                yield put(fetchingPatientsFailed({error: error.message}));
            }
        } else {
            const therapistPatients = apiFetchPatientsResponse.right();
            yield put(fetchedPatients(therapistPatients));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* searchPatients(action: { type: string, authState: AuthState, payload: {pageNumber: number, recordsPerPage: number, searchText: string, filters?: PatientFilters}}) {
    try {
        console.log(`PatientsSaga:searchPatients`)
        yield put(callInProgress())
        yield put(searchPatientsInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, SearchPatientsResponse> = yield call(apiSearchPatients, action)
        if(apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(searchingPatientsFailed({error: error.message}));
            } else {
                yield put(searchingPatientsFailed({error: error.message}));
            }
        } else {
            const searchResults = apiFetchPatientsResponse.right();
            yield put(searchedPatients(searchResults.patients, searchResults.count));
        }
    } catch (e) {
        console.log(e)
        yield put(searchingPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* searchDischargedPatients(action: { type: string, authState: AuthState, payload: {currentPage: number, recordsPerPage: number, searchText: string}}) {
    try {
        console.log(`PatientsSaga:searchDischargedPatients`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, SearchDischargedPatients> = yield call(apiSearchDischargedPatients, action)
        if(apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(searchingDischargedPatientsFailed({error: error.message}));
            } else {
                yield put(searchingDischargedPatientsFailed({error: error.message}));
            }
        } else {
            const searchResults = apiFetchPatientsResponse.right();
            yield put(searchedDischargedPatients(searchResults.patients, searchResults.recordsCount));
        }
    } catch (e) {
        console.log(e)
        yield put(searchingDischargedPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatient(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchPatient`)
        yield put(callInProgress())
        const apiFetchPatientResponse: Either<CheckedError, IProspect> = yield call(apiFetchPatient, action)
        if(apiFetchPatientResponse.isLeft()) {
            const error = apiFetchPatientResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientFailed({error: error.message}));
            } else {
                yield put(fetchingPatientFailed({error: error.message}));
            }
        } else {
            const patient = apiFetchPatientResponse.right();
            yield put(fetchedPatient(patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPracticePatientBySource(action: { type: string, authState: AuthState, payload: { sourcePatientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchPracticePatientBySource`)
        yield put(callInProgress())
        const apiFetchPatientResponse: Either<CheckedError, IProspect> = yield call(apiFetchPatientByPracticeAndSource, action)
        if(apiFetchPatientResponse.isLeft()) {
            const error = apiFetchPatientResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga:fetchPracticePatientBySource: Encountered a Checked Error", error.message)
                yield put(fetchingPatientFailed({error: error.message}));
            } else {
                yield put(fetchingPatientFailed({error: error.message}));
            }
        } else {
            const patient = apiFetchPatientResponse.right();
            yield put(fetchedPatient(patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientWithSummary(action: { type: string, authState: AuthState, payload: { patientId: string, practiceId: string, sourcePatientId: string, sessionId?: string } }) {
    try {
        console.log(`PatientsSaga:fetchPatientWithSummary`)
        yield put(callInProgress())
        const apiFetchPatientWithSummaryResponse: Either<CheckedError, IProspect> = yield call(apiFetchPatientWithSummary, action)
        if(apiFetchPatientWithSummaryResponse.isLeft()) {
            const error = apiFetchPatientWithSummaryResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientWithSummaryFailed({error: error.message}));
            } else {
                yield put(fetchingPatientWithSummaryFailed({error: error.message}));
            }
        } else {
            const patient = apiFetchPatientWithSummaryResponse.right();
            yield put(fetchedPatientWithSummary(patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientWithSummaryFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientBySource(action: { type: string, authState: AuthState, payload: { sourcePatientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchPatient`)
        yield put(callInProgress())
        yield put(fetchingPatientBySourceInProgress())
        const apiFetchPatientResponse: Either<CheckedError, IProspect> = yield call(apiFetchPatientBySource, action)
        if(apiFetchPatientResponse.isLeft()) {
            const error = apiFetchPatientResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientBySourceFailed({error: error.message}));
            } else {
                yield put(fetchingPatientBySourceFailed({error: error.message}));
            }
        } else {
            const patient = apiFetchPatientResponse.right();
            yield put(fetchedPatientBySource(patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchTreatmentNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) {
    try {
        console.log(`PatientsSaga:fetchTreatmentNotes`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, {treatmentPlanNotes:TreatmentPlanNotes, treatmentPlanCount: number, treatmentId: string, notesStatus: NotesStatus}> =
            yield call(apiFetchTreatmentPlan, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingTreatmentNotesFailed({error: error.message}));
            } else {
                yield put(fetchingTreatmentNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(fetchedTreatmentNotes(notes.treatmentPlanNotes, notes.treatmentPlanCount, notes.treatmentId, notes.notesStatus));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingTreatmentNotesFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}
function* fetchCatchupNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) {
    try {
        console.log(`PatientsSaga:fetchCatchupNotes`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, CatchupNotesResponse> =
            yield call(apiFetchCatchupNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingCatchupNotesFailed({error: error.message}));
            } else {
                yield put(fetchingCatchupNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(fetchedCatchupNotes(notes));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingCatchupNotesFailed({error: "Error fetching catchup notes!"}));
    } finally {
        yield put(callFinished())
    }
}
function* fetchPsychiatristNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) {
    try {
        console.log(`PatientsSaga:fetchPsychiatristNotes`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, PsychiatristNotesResponse> =
            yield call(apiFetchPsychiatristNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPsychiatristNotesFailed({error: error.message}));
            } else {
                yield put(fetchingPsychiatristNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(fetchedPsychiatristNotes(notes));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPsychiatristNotesFailed({error: "Error fetching psychiatrist notes!"}));
    } finally {
        yield put(callFinished())
    }
}
function* fetchProgressNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notesId: string | null } }) {
    try {
        console.log(`PatientsSaga:fetchProgressNotes`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, PatientProgressNotes> = yield call(apiFetchProgressNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingProgressNotesFailed({error: error.message}));
            } else {
                yield put(fetchingProgressNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(fetchedProgressNotes(notes.progressNotes, notes.progressNotesId, notes.notesStatus));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingProgressNotesFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* saveTreatmentNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notes: { treatmentNotes: { [key: string]: [value: any] } }, noteId: string | null } }) {
    try {
        console.log(`PatientsSaga:saveTreatmentNotes`)
        yield put(asyncCallInProgress())
        const apiResponse: Either<CheckedError, {isTreatmentNotesSaved: boolean}> = yield call(apiSaveTreatmentPlan, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(savingTreatmentNotesFailed({error: error.message}));
            } else {
                yield put(savingTreatmentNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(savedTreatmentNotes(_.keys(action.payload.notes.treatmentNotes), notes.isTreatmentNotesSaved));
        }
    } catch (e) {
        console.log(e)
        yield put(savingTreatmentNotesFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(asyncCallFinished())
    }
}

function* saveProgressNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notes: { progressNotes: { [key: string]: [value: any] }  }, noteId: string | null } }) {
    try {
        console.log(`PatientsSaga:saveProgressNotes`)
        yield put(asyncCallInProgress())
        const apiResponse: Either<CheckedError, {isProgressNotesSaved: boolean}> = yield call(apiSaveProgressNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(savingProgressNotesFailed({error: error.message}));
            } else {
                yield put(savingProgressNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(savedProgressNotes(_.keys(action.payload.notes.progressNotes), notes.isProgressNotesSaved));
        }
    } catch (e) {
        console.log(e)
        yield put(savingProgressNotesFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(asyncCallFinished())
    }
}
function* saveCatchupNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notes: CatchupNotes, notesId: string | null} }) {
    try {
        console.log(`PatientsSaga:saveCatchupNotes`)
        yield put(asyncCallInProgress())
        const apiResponse: Either<CheckedError, CatchupNotesResponse> = yield call(apiSaveCatchupNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(savingCatchupNotesFailed({error: error.message}));
            } else {
                yield put(savingCatchupNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(savedCatchupNotes(_.keys(action.payload.notes.catchupNotes), notes));
        }
    } catch (e) {
        console.log(e)
        yield put(savingCatchupNotesFailed({error: "Error fetching catchup notes!"}));
    } finally {
        yield put(asyncCallFinished())
    }
}
function* savePsychiatristNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notes: PsychiatristNotes, noteId: string | null } }) {
    try {
        console.log(`PatientsSaga:savePsychiatristNotes`)
        yield put(asyncCallInProgress())
        const apiResponse: Either<CheckedError, PsychiatristNotesResponse> = yield call(apiSavePsychiatristNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(savingPsychiatristNotesFailed({error: error.message}));
            } else {
                yield put(savingPsychiatristNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(savedPsychiatristNotes(_.keys(action.payload.notes.psychiatristNotes), notes));
        }
    } catch (e) {
        console.log(e)
        yield put(savingPsychiatristNotesFailed({error: "Error fetching psychiatrist notes!"}));
    } finally {
        yield put(asyncCallFinished())
    }
}

function* reviewAndSubmitTreatmentNotes(action: { type: string, authState: AuthState, payload: { patientId: string, screeners: string[], sourcePatientId: string, treatmentId: string, practiceId: string} }) {
    try {
        console.log(`PatientsSaga:reviewAndSubmitTreatmentNotes`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, {isScreenersAssigned: boolean}> = yield call(apiReviewAndSubmitTreatmentNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(reviewAndSubmittingTreatmentNotesFailed({error: error.message}));
            } else {
                yield put(reviewAndSubmittingTreatmentNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(reviewAndSubmittedTreatmentNotes(notes.isScreenersAssigned));
        }
    } catch (e) {
        console.log(e)
        yield put(reviewAndSubmittingTreatmentNotesFailed({error: "Error while submitting treatment notes!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchSafetyPlanNotes(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchSafetyPlanNotes`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, PatientSafetyPlanNotes> = yield call(apiFetchSafetyPlanNotes, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingSafetyPlanNotesFailed({error: error.message}));
            } else {
                yield put(fetchingSafetyPlanNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(fetchedSafetyPlanNotes(notes.safetyPlanNotes, notes.notesStatus));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingSafetyPlanNotesFailed({error: "Error fetching safety plan notes!"}));
    } finally {
        yield put(callFinished())
    }
}

function* saveSafetyPlanNotes(action: { type: string, authState: AuthState, payload: { patientId: string, notes: { safetyPlanNotes: { [key: string]: [value: any] } } } }) {
    try {
        console.log(`PatientsSaga:saveSafetyPlanNotes`)
        yield put(asyncCallInProgress())
        const apiResponse: Either<CheckedError, {isSafetyPlanNotesSaved: boolean}> = yield call(apiSaveSafetyPlan, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(savingSafetyPlanNotesFailed({error: error.message}));
            } else {
                yield put(savingSafetyPlanNotesFailed({error: error.message}));
            }
        } else {
            const notes = apiResponse.right();
            yield put(savedSafetyPlanNotes(_.keys(action.payload.notes.safetyPlanNotes), notes.isSafetyPlanNotesSaved));
        }
    } catch (e) {
        console.log(e)
        yield put(savingSafetyPlanNotesFailed({error: "Error fetching safety plan notes!"}));
    } finally {
        yield put(asyncCallFinished())
    }
}

function* fetchPatientJourney(action: { type: string, authState: AuthState, payload: { patientId: string, sourcePatientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchPatientJourney`)
        yield put(callInProgress())
        const apiFetchPatientJourneyResponse: Either<CheckedError, PatientJourney[]> = yield call(apiFetchPatientJourney, action)
        if(apiFetchPatientJourneyResponse.isLeft()) {
            const error = apiFetchPatientJourneyResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientJourneyFailed({error: error.message}));
            } else {
                yield put(fetchingPatientJourneyFailed({error: error.message}));
            }
        } else {
            const patient = apiFetchPatientJourneyResponse.right();
            yield put(fetchedPatientJourney(patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientJourneyFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}
function* fetchSessionInsights(action: { type: string, authState: AuthState, payload: { patientId: string, frequency: number } }) {
    try {
        console.log(`PatientsSaga:fetchSessionInsights`)
        yield put(callInProgress())
        const apiFetchSessionInsightsResponse: Either<CheckedError, PatientSessionInsights> = yield call(apiFetchSessionInsights, action)
        if(apiFetchSessionInsightsResponse.isLeft()) {
            const error = apiFetchSessionInsightsResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingSessionInsightsFailed({error: error.message}));
            } else {
                yield put(fetchingSessionInsightsFailed({error: error.message}));
            }
        } else {
            const insights = apiFetchSessionInsightsResponse.right();
            yield put(fetchedSessionInsights(insights));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingSessionInsightsFailed({error: "Error fetching session insights!"}));
    } finally {
        yield put(callFinished())
    }
}
function* fetchDailyCheckinInsights(action: { type: string, authState: AuthState, payload: { patientId: string, frequency: number, sourcePatientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchDailyCheckinInsights`)
        yield put(callInProgress())
        const apiFetchDailyCheckinInsightsResponse: Either<CheckedError, PatientDailyCheckinInsights> = yield call(apiFetchDailyCheckinInsights, action)
        if(apiFetchDailyCheckinInsightsResponse.isLeft()) {
            const error = apiFetchDailyCheckinInsightsResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingDailyCheckinInsightsFailed({error: error.message}));
            } else {
                yield put(fetchingDailyCheckinInsightsFailed({error: error.message}));
            }
        } else {
            const insights = apiFetchDailyCheckinInsightsResponse.right();
            yield put(fetchedDailyCheckinInsights(insights));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingDailyCheckinInsightsFailed({error: "Error fetching daily checkin insights!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchAssignedScreeners(action: { type: string, authState: AuthState, payload: { patientId: string, treatmentId: string, providerType: ScreenerProviderType } }) {
    try {
        console.log(`PatientsSaga:fetchAssignedScreeners`)
        yield put(callInProgress())
        const apiFetchAssignedScreenersResponse: Either<CheckedError, IAssignedScreeners[]> = yield call(apiFetchAssignedScreeners, action)
        if(apiFetchAssignedScreenersResponse.isLeft()) {
            const error = apiFetchAssignedScreenersResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingAssignedScreenersFailed({error: error.message}));
            } else {
                yield put(fetchingAssignedScreenersFailed({error: error.message}));
            }
        } else {
            const patientAssignedScreeners = apiFetchAssignedScreenersResponse.right();
            yield put(fetchedAssignedScreeners(patientAssignedScreeners));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingAssignedScreenersFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchScreenerQAndA(action: { type: string, authState: AuthState, payload: { patientId: string, userScreenerId: string, screenerConditionId: string|null } }) {
    try {
        console.log(`PatientsSaga:fetchScreenerQAndA`)
        yield put(callInProgress())
        const apiFetchScreenerQAndAResponse: Either<CheckedError, CurrentQuestion> = yield call(apiFetchScreenerQAndA, action)
        if(apiFetchScreenerQAndAResponse.isLeft()) {
            const error = apiFetchScreenerQAndAResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingScreenerQAndAFailed({error: error.message}));
            } else {
                yield put(fetchingScreenerQAndAFailed({error: error.message}));
            }
        } else {
            const patientScreenerQAndA = apiFetchScreenerQAndAResponse.right();
            yield put(fetchedScreenerQAndA(patientScreenerQAndA));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingScreenerQAndAFailed({error: "Error fetching screener questions and answers!"}));
    } finally {
        yield put(callFinished())
    }
}

function* saveScreenerAnswer(action: { type: string, authState: AuthState, payload: SWUserScreenerRequest }) {
    try {
        console.log(`PatientsSaga:saveScreenerAnswer`)
        yield put(asyncCallInProgress())
        const apiResponse: Either<CheckedError, {nextQuestionLink: string}> = yield call(apiSaveScreenerAnswer, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(savingScreenerAnswerFailed({error: error.message}));
            } else {
                yield put(savingScreenerAnswerFailed({error: error.message}));
            }
        } else {
            const link = apiResponse.right().nextQuestionLink;
            yield put(savedScreenerAnswer(link));
        }
    } catch (e) {
        console.log(e)
        yield put(savingScreenerAnswerFailed({error: "Error saving screener answer"}));
    } finally {
        yield put(asyncCallFinished())
    }
}

function* patchPatient(action: { type: string, authState: AuthState, payload: { patientId: string, practiceId: string, patientInfo: Partial<PatientAccount> } }) {
    try {
        console.log(`PatientsSaga:patchPatient`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, PatientAccountResponse> = yield call(apiPatchPatient, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(updatePatientInfoFailed({error: error.message}));
            } else {
                yield put(updatePatientInfoFailed({error: error.message}));
            }
        } else {
            const patient = apiResponse.right();
            if(action.payload.patientInfo.contactPhoneNumber) {
                yield put(updatePatientInfoSuccess(action.payload.patientInfo.contactPhoneNumber));
                yield put(updateContactPhoneNumber(action.payload.patientInfo.contactPhoneNumber));
            }
            yield put(updatePatientInfoStatusSuccess());
        }
    } catch (e) {
        console.log(e)
        yield put(updatePatientInfoFailed({error: "Error patching patient"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientSummary(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchPatientSummary`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, any> = yield call(apiFetchPatientSummary, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientSummaryFailed({error: error.message}));
            } else {
                yield put(fetchPatientSummaryFailed({error: error.message}));
            }
        } else {
            const patientSummary = apiResponse.right();
            yield put(fetchPatientSummarySuccess(patientSummary));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientSummaryFailed({error: "Error fetching patient summary"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientLocation(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchPatientLocation`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, any> = yield call(apiFetchPatientLocation, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientLocationFailed({error: error.message}));
            } else {
                yield put(fetchPatientLocationFailed({error: error.message}));
            }
        } else {
            const patientLocation = apiResponse.right();
            yield put(fetchPatientLocationSuccess(patientLocation));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientLocationFailed({error: "Error fetching patient location"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchSourcePatientDetails(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchSourcePatientDetails`)
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, any> = yield call(apiFetchSourcePatientDetails, action)
        if(apiResponse.isLeft()) {
            const error = apiResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingSourcePatientDetailsFailed({error: error.message}));
            } else {
                yield put(fetchingSourcePatientDetailsFailed({error: error.message}));
            }
        } else {
            const sourcePatientDetails = apiResponse.right();
            yield put(fetchedSourcePatientDetails(sourcePatientDetails));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingSourcePatientDetailsFailed({error: "Error fetching source patient details"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchSWScreenersInsights(action: { type: string, authState: AuthState, payload: { patientId: string, sourcePatientId: string } }) {
    try {
        console.log(`PatientsSaga:fetchSWScreenersInsights`)
        yield put(callInProgress())
        const apiFetchSWScreenersInsightsResponse: Either<CheckedError, SWScreenerInsightsResponse> = yield call(apiFetchSWScreenersInsights, action)
        if(apiFetchSWScreenersInsightsResponse.isLeft()) {
            const error = apiFetchSWScreenersInsightsResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingSWScreenersInsightsFailed({error: error.message}));
            } else {
                yield put(fetchingSWScreenersInsightsFailed({error: error.message}));
            }
        } else {
            const insights = apiFetchSWScreenersInsightsResponse.right();
            yield put(fetchedSWScreenersInsights(insights.swScreenerInsights));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingSWScreenersInsightsFailed({error: "Error fetching SW screeners insights!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientScreenerSummary(action: { type: string, authState: AuthState, payload: PatientScreenerSummaryRequest }) {
    try {
        console.log(`PatientsSaga:fetchPatientScreenedDates`)
        yield put(callInProgress())
        const apiFetchPatientScreenedDatesResponse: Either<CheckedError, IProspect> = yield call(apiFetchPatientScreenerSummary, action)
        if(apiFetchPatientScreenedDatesResponse.isLeft()) {
            const error = apiFetchPatientScreenedDatesResponse.left()
            if(error.isChecked) {
                console.log("PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingScreeningSummaryFailed({error: error.message}));
            } else {
                yield put(fetchingScreeningSummaryFailed({error: error.message}));
            }
        } else {
            const screenedDates = apiFetchPatientScreenedDatesResponse.right();
            yield put(fetchedScreeningSummary(screenedDates));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingScreeningSummaryFailed({error: "Error fetching patient screened dates!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* patientsSaga() {
    yield takeLatest(SEARCH_PATIENTS, searchPatients)
    yield takeLatest(FETCH_PATIENTS, fetchPatients)
    yield takeLatest(FETCH_PATIENT, fetchPatient)
    yield takeLatest(FETCH_PRACTICE_PATIENT, fetchPracticePatientBySource)
    yield takeLatest(FETCH_PATIENT_WITH_SUMMARY, fetchPatientWithSummary)
    yield takeLatest(FETCH_PATIENT_BY_SOURCE_PATIENT_ID, fetchPatientBySource)

    yield takeLatest(FETCH_TREATMENT_NOTES, fetchTreatmentNotes)
    yield takeLatest(FETCH_PROGRESS_NOTES, fetchProgressNotes)
    yield takeLatest(FETCH_CATCHUP_NOTES, fetchCatchupNotes)
    yield takeLatest(FETCH_PSYCHIATRIST_NOTES, fetchPsychiatristNotes)
    yield takeLatest(FETCH_SAFETY_PLAN_NOTES, fetchSafetyPlanNotes)

    yield takeLatest(FETCH_PATIENT_JOURNEY, fetchPatientJourney)
    yield takeLatest(FETCH_SESSION_INSIGHTS, fetchSessionInsights)
    yield takeLatest(FETCH_DAILY_CHECKIN_INSIGHTS, fetchDailyCheckinInsights)
    yield takeLatest(FETCH_ASSIGNED_SCREENERS, fetchAssignedScreeners)
    yield takeLatest(FETCH_SCREENER_Q_AND_A, fetchScreenerQAndA)

    yield takeLatest(SAVE_TREATMENT_NOTES, saveTreatmentNotes)
    yield takeLatest(SAVE_PROGRESS_NOTES, saveProgressNotes)
    yield takeLatest(SAVE_CATCHUP_NOTES, saveCatchupNotes)
    yield takeLatest(SAVE_PSYCHIATRIST_NOTES, savePsychiatristNotes)
    yield takeLatest(REVIEW_AND_SUBMIT_TREATMENT_PLAN, reviewAndSubmitTreatmentNotes)
    yield takeLatest(SAVE_SAFETY_PLAN_NOTES, saveSafetyPlanNotes)
    yield takeLatest(SAVE_SCREENER_ANSWER, saveScreenerAnswer)

    yield takeLatest(UPDATE_PATIENT_INFO, patchPatient)

    yield takeLatest(FETCH_PATIENT_SUMMARY, fetchPatientSummary)
    yield takeLatest(FETCH_PATIENT_LOCATION, fetchPatientLocation)
    yield takeLatest(SEARCH_DISCHARGED_PATIENTS, searchDischargedPatients)

    yield takeLatest(FETCH_SOURCE_PATIENT_DETAILS, fetchSourcePatientDetails)
    yield takeLatest(FETCH_SW_SCREENERS_INSIGHTS, fetchSWScreenersInsights)

    yield takeLatest(FETCH_PATIENT_SCREENING_SUMMARY, fetchPatientScreenerSummary)
}
