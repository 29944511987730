import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { StateParams } from "../../store/reducers";
import { createAppointment } from "../../store/actions/counsellor/appointments.action";
import { ICreateAppointment, PatientSessionTypes } from "../../models";
import { useNavigate } from "react-router";
import withPatientSchedule from "../appointments/withPatientSchedule";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { fetchPatient } from "../../store/actions/counsellor/patients.action";

type AppointmentFormData = {
  selectedDate: Date;
  sessionType: keyof typeof PatientSessionTypes
};

const SESSION_TYPES: { key: PatientSessionTypes; value: string }[] = [{
  key: PatientSessionTypes.FollowUp, value: "Follow Up ( 30m )"
}, {
  key: PatientSessionTypes.TreatmentPlan, value: "Treatment Plan ( 30m )"
}, {
  key: PatientSessionTypes.Catchup, value: "Catchup ( 30m )"
}]

const PatientSchedule: React.FC = () => {
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors } } = useForm<AppointmentFormData>();
  const appointmentCreated = useSelector((state: StateParams) => state.appointments.createdAppointment)
  const createAppointmentError = useSelector((state: StateParams) => state.appointments.createAppointmentError)
  const [appointmentRequest, setAppointmentRequest] = useState<ICreateAppointment>()
  const router = useNavigate()
  const navigate = useNavigate()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [sessionTypes, setSessionTypes] = useState(SESSION_TYPES)
  const params = useParams()

  const { patient } = useSelector((state: StateParams) => state.patients)

  const onSubmit = handleSubmit((data: AppointmentFormData) => {
    if (!patient) {
      return
    }
    const scheduledDate = DateTime.fromJSDate(data.selectedDate).toUTC().toISO();
    if (scheduledDate && patient.sourcePatientId) {
      const appointmentRequest: ICreateAppointment = {
        ...data,
        patientId: patient.id,
        sessionType: data.sessionType,
        scheduledDate: scheduledDate
      }
      setAppointmentRequest(appointmentRequest)
      dispatch(createAppointment(appointmentRequest, patient.sourcePatientId))
    }
  });

  useEffect(() => {
    if (params.patientId) {
      dispatch(fetchPatient(String(params.patientId)))
    }
  }, [params.patientId, appointmentCreated]);

  useEffect(() => {
    if (appointmentRequest && appointmentCreated && appointmentCreated?.patientId === appointmentRequest?.patientId) {
      router(-1)
    }
  }, [appointmentRequest, appointmentCreated]);

  const filterFutureTime = useCallback((time: Date) => {
    const selectedDate = new Date(time);
    const currentDate = new Date();
    return currentDate.getTime() < selectedDate.getTime();
  }, []);

  function renderFormForAppointment() {
    return (
      <div className={"mt-10"}>
        <div className="mb-8">
          <label
            htmlFor="sessionType"
            className={`block text-md mb-2 text-sjOrange`} >
            Session Type
          </label>
          <select {...register("sessionType", { required: true })}
            className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500`}
            defaultValue={'Treatment_Plan'}>
            {sessionTypes.map((item: { key: string, value: string }) => <option value={item.key}>{item.value}</option>)}
          </select>
        </div>
        <div className="mb-8">
          <label className={"block text-md mb-2 text-sjOrange"}>Patient Name and DOB</label>
          {patient &&
            <label className="mb-8">
              {patient.firstName} {patient.lastName} ({patient.dob ? DateTime.fromISO(patient.dob.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy') : ''})
            </label>
          }
        </div>
        <div className="mb-8 grid grid-cols-2">
          <label
            htmlFor="sessionDate"
            className={`block text-md mb-2 self-center ${errors.selectedDate ? "text-red-400" : "text-sjOrange"
              }`}
          >
            {`Date (${timeZone})`}
          </label>
          <Controller
            {...register("selectedDate", { required: true })}
            control={control}
            name='selectedDate'
            render={({ field }) => (
              <DatePicker
                className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 ${errors.selectedDate
                  ? "text-red-300 border-red-400"
                  : "text-black border-sjOrange"
                  }`}
                dateFormat="d MMM yyyy h:mm aa"
                minDate={new Date()}
                showTimeSelect={true}
                filterTime={filterFutureTime}
                todayButton="Today"
                dropdownMode="select"
                isClearable
                shouldCloseOnSelect
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                timeIntervals={10}
              />
            )}
          />
          {errors.selectedDate && (
            <p className="text-red-500 text-sm mt-2">
              A valid Session Date is required.
            </p>
          )}
        </div>
      </div>
    )
  }

  function renderHeader() {
    return (
      <div>
        <div className="flex flex-row">
          <div className="grow"><span className="text-xl font-bold text-[#242731]">Create Appointment</span></div>
          <div
            onClick={() => navigate(-1)}
            className="cursor-pointer grow-0"
          >
            <AiOutlineClose
              className="text-gray-500 hover:text-gray-700"
              style={{ width: '25px', height: '25px' }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderHeader()}
      <form onSubmit={onSubmit}>
        {renderFormForAppointment()}
        <input type={"submit"} value={"Create Note"}
          className="inline-block bg-orange-500 text-white rounded shadow py-2 px-5 text-sm" />
        {createAppointmentError && <p className="error-msg">{createAppointmentError}</p>}
      </form>
    </div>
  )
}

export default withPatientSchedule(PatientSchedule)
