import React, { useMemo } from "react";
import "../PhysicianDashboardScreen.scss";
import { useSelector } from "react-redux";
import { StateParams } from "../../../../store/reducers";

const PhysicianClinicalMetricsComponent = () => {
    const { registryPatientStaticsCard, fetchRegistryPatientStaticsCardInProgress } = useSelector((state: StateParams) => state.patientsDashboard);

    const averageTimeInTreatmentUnit = useMemo(() => {
        const averageTime = registryPatientStaticsCard?.averageTimeInTreatment ?? 0;
        return averageTime > 1 ? "Weeks" : "Week";
    }, [registryPatientStaticsCard]);

    const renderClinicalMetricsStatisticsCard = (label: string, count: number | undefined, duration?: string) => (
        <div className="physician-dashboard-statistics-card">
            {fetchRegistryPatientStaticsCardInProgress && <div className="card-loading" />}
            <div className="physician-dashboard-statistics-card-label-and-count">
                <div className="physician-dashboard-statistics-card-count">
                    {count || 0}
                    {duration && <span className="text-lg"> {duration}</span>}
                </div>
                <div className="physician-dashboard-statistics-card-label">
                    {label}
                </div>
            </div>
        </div>
    );

    const statisticsCards = useMemo(() => [
        { label: "Total Active CoCM Patients", count: registryPatientStaticsCard?.totalActivePatients },
        { label: "Total Discharged Patients", count: registryPatientStaticsCard?.totalDischargedPatients },
        { label: "Average sessions per patient", count: registryPatientStaticsCard?.averageContactsPerPatient },
        { label: "Average treatment length per patient", count: registryPatientStaticsCard?.averageTimeInTreatment, duration: averageTimeInTreatmentUnit }
    ], [registryPatientStaticsCard, averageTimeInTreatmentUnit]);

    return (
        <div className="physician-dashboard-statistics">
            <div className="mb-5">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {statisticsCards.map((card, index) => (
                        renderClinicalMetricsStatisticsCard(card.label, card.count, card.duration)
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PhysicianClinicalMetricsComponent;
