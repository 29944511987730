import React, { useCallback } from "react";

interface TextAreaProps {
    value: string;
    onChange?: (e: string) => void;
    placeholder: string;
    maxHeight?: number;
}

const TextAreaComponent: React.FC<TextAreaProps> = ({ value, onChange, placeholder, maxHeight = 300 }) => {
    const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        onChange && onChange(value);
    }, [onChange]);

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = e.target;
        textarea.style.height = "auto";
        const newHeight = Math.min(textarea.scrollHeight, maxHeight) + "px";
        textarea.style.height = newHeight;
    };

    return (
        <textarea
            className="w-full border rounded-md border-gray-200 p-2 resize-none overflow-y-auto"
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            onInput={handleInput}
        />
    );
};

export default TextAreaComponent;
