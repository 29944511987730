import {call, put, takeLatest} from "@redux-saga/core/effects";
import {
    downloadedBillingReport,
    downloadedPatientResponses,
    downloadingBillingReportFailed,
    downloadingPatientResponsesFailed,
    fetchedAllTherapists,
    fetchedBillingPatients,
    fetchedCoCMPatients,
    fetchedPatient,
    fetchedPatients,
    fetchedPatientWithSummary,
    fetchedQAndA,
    fetchedReferenceNotes,
    fetchedReferredPatients,
    fetchingAllTherapistsFailed,
    fetchingAllTherapistsInProgress,
    fetchingBillingPatientsFailed,
    fetchingBillingPatientsInProgress,
    fetchingCoCMPatientsFailed,
    fetchingPatientFailed,
    fetchingPatientsFailed,
    fetchingPatientWithSummaryFailed,
    fetchingQAndAFailed,
    fetchingReferenceNotesFailed,
    fetchingReferenceNotesInProgress,
    fetchingReferredPatientsFailed,
    fetchingReferredPatientsInProgress,
    fetchPatientInProgress,
    fetchPatientQAndAInProgress,
    fetchPatientWithSummaryInProgress,
    PHYSICIAN_DOWNLOAD_BILLING_REPORT,
    PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES,
    PHYSICIAN_FETCH_ALL_THERAPISTS,
    PHYSICIAN_FETCH_BILLING_PATIENTS,
    PHYSICIAN_FETCH_COCM_PATIENTS,
    PHYSICIAN_FETCH_PATIENT,
    PHYSICIAN_FETCH_PATIENT_Q_AND_A,
    PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY,
    PHYSICIAN_FETCH_PATIENTS,
    PHYSICIAN_FETCH_REFERENCE_NOTES,
    PHYSICIAN_FETCH_REFERRED_PATIENTS,
    PHYSICIAN_SAVE_REFER_NOTES,
    PHYSICIAN_SAVE_SCHEDULE,
    savedReferNotes,
    savedSchedule,
    saveReferNotesInProgress,
    savingReferNotesFailed,
    savingScheduleFailed,
    savingScheduleInProgress,
    DOWNLOAD_PATIENT_SUMMARY,
    downloadingPatientSummaryFailed,
    downloadedPatientSummary,
    DOWNLOAD_PATIENT_SESSION_NOTES,
    downloadingPatientSessionNotesFailed,
    downloadedPatientSessionNotes,
    PHYSICIAN_FETCH_ICD_CODES,
    fetchedICDCodes,
    fetchingICDCodesFailed,
    SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT,
    sendingGenericAppointmentReminderToPatientFailed, sentGenericAppointmentReminderToPatient,
} from "../../actions/physician/patients.action";
import {apiHttpClient, getAuthHeaderConfig, physicianHttpClient} from "../../../lib";
import {Either, Right} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {
    AuthState,
    DownloadNotesRequest,
    DownloadReport,
    DownloadScreenerRequest,
    FetchCoCMPatientsPayload,
    FetchPatientsPayload,
    Generic,
    IBasicPatient,
    IBillingPatient,
    ICoCMPatient,
    IPatient,
    IPatientDetails,
    IPatientIndexItem,
    IPatientListItem,
    IPatientWithSummary,
    IProspect,
    IRefer,
    IScreenerQuestionAnswer,
    ITherapists,
    IcdCodesRequest,
    ReferenceResponse,
    SendScreenerLinkToPatientPayload,
    ISendScreenerLinkToPatientResponse,
    SendGenericPhysicianAppointmentReminderRequest, ISendGenericPhysicianAppointmentReminderResponse
} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {createQueryString} from "../../../lib/api-helpers";
import { DateTime } from "luxon";
import { clearPatientAndSourcePatient } from "../../actions/counsellor/patients.action";
import {
    SEND_SCREENER_LINK_TO_PATIENT,
    sendingScreenerLinkToPatientFailed, sentScreenerLinkToPatient
} from "../../actions/care-coordinator/patients.action";

function getAPI<R>(url: string, authState: AuthState) : Promise<Either<CheckedError | any, R>> {
    const config = { headers: { "x-auth-token": authState.token }}
    return physicianHttpClient.get<Either<CheckedError, R>>(url, config)
        .then((response: AxiosResponse) => {
            return Right(response.data.data as R)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if(statusCode >=400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatients = (action: { type: string, authState: AuthState, payload: FetchPatientsPayload}) => {
    const showAllLocationPatients = !action.payload.isPotentialReferrals
    const locationId = showAllLocationPatients ? null : action.authState.practiceLocationId
    const query = createQueryString({...action.payload, locationId}, {"isPotentialReferrals": "potentialReferrals"})
    const url = `/${action.authState.accountId}/patients/v2?${query}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, IPatientListItem[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patients: response.data.data as IPatientListItem[], recordsCount: response.data.recordsCount, sideBarTab: response.data.sideBarTab })
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchCoCMPatients = (action: { type: string, authState: AuthState, payload: FetchCoCMPatientsPayload}) => {
    const query = createQueryString({...action.payload, locationId: action.authState.practiceLocationId})
    const url = `/${action.authState.accountId}/cocm-patients?${query}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, ICoCMPatient[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patients: response.data.data as ICoCMPatient[], recordsCount: response.data.recordsCount, sideBarTab: response.data.sideBarTab })
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatient = (action: { type: string, authState: AuthState, payload: {patientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}`;
    return getAPI<IPatientDetails>(url, action.authState)
}

const apiFetchPatientWithSummary = (action: { type: string, authState: AuthState, payload: {patientId: string, sessionId?: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/summary?sessionId=${action.payload.sessionId}`;
    return getAPI<IPatientWithSummary>(url, action.authState)
}

const apiFetchScreenerAnswersForPatient = (action: { type: string, authState: AuthState, payload: {patientId: string, screenerId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/screeners/${action.payload.screenerId}`;
    return getAPI<IScreenerQuestionAnswer[]>(url, action.authState)
}

const apiDownloadPatientResponses = (action: { type: string, authState: AuthState, payload: {patientId: string, filters?: DownloadScreenerRequest}}) => {
    const query = createQueryString({cptCode: action.payload.filters?.cptCode, sessionId: action.payload.filters?.sessionId})
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/download?${query}`;
    return getAPI<{url:string, fileName: string}>(url, action.authState)
}

const apiSaveReferNotes = (action: { type: string, authState: AuthState, payload: {patientId: string, referringPhysicianId: string, symptoms?: Generic, problems?: Generic, currentMedications?: Generic, diagnosis?: Generic, comorbidities?: Generic, patientMedicationsHistory?: Generic, psychiatricMedication?: string, physicianNotes?: string, isConsentGiven: string, allergies?: Generic }}) => {
    const payload = {referringPhysicianId: action.payload.referringPhysicianId, symptoms: action.payload.symptoms, problems: action.payload.problems, currentMedications: action.payload.currentMedications, diagnosis: action.payload.diagnosis, patientMedicationsHistory: action.payload.patientMedicationsHistory, psychiatricMedication: action.payload.psychiatricMedication, physicianNotes: action.payload.physicianNotes, isConsentGiven: action.payload.isConsentGiven, comorbidities: action.payload.comorbidities, allergies: action.payload.allergies}
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.post<Either<CheckedError, IRefer>>(`/${action.authState.accountId}/patients/${action.payload.patientId}/refer-notes`, payload, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IRefer)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if(statusCode >=400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred saving refer notes"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchReferredPatients = (action: { type: string, authState: AuthState, payload: {pageNumber: number, recordsPerPage: number, searchText: string, referredPatients?: boolean}}) => {
    const url = `/${action.authState.accountId}/referred-patients?pageNumber=${action.payload.pageNumber}&recordsPerPage=${action.payload.recordsPerPage}&searchText=${action.payload.searchText}&referredPatients=${action.payload.referredPatients}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, IPatient[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patients: response.data.data as IPatient[], recordsCount: response.data.recordsCount, sideBarTab: response.data.sideBarTab })
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchReferenceNotes = (action: { type: string, authState: AuthState, payload: {patientId: string, slug: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/reference-data`;
    return getAPI<ReferenceResponse>(url, action.authState)
}

const apiFetchAllTherapists = (action: { type: string, authState: AuthState}) => {
    const url = `/${action.authState.accountId}/therapists`;
    return getAPI<ITherapists[]>(url, action.authState)
}

const apiSaveSchedule = (action: { type: string, authState: AuthState, payload: {patientId: string, therapistPracticeId: string }}) => {
    return physicianHttpClient.post<Either<CheckedError, IRefer>>(`/${action.authState.accountId}/patients/${action.payload.patientId}/update-prospect`, action.payload)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IRefer)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if(statusCode >=400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred saving refer notes"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchBillingPatients = (action: { type: string, authState: AuthState, payload: {pageNumber: number, recordsPerPage: number, searchText: string, startDate?: string, endDate?: string}}) => {
    const initialStartDate = action.payload.startDate !== 'Invalid DateTime' ? action.payload.startDate : DateTime.local().startOf('month').toISO({includeOffset: false});
    const initialEndDate = action.payload.endDate !== 'Invalid DateTime' ? action.payload.endDate : DateTime.local().endOf('day').toISO({includeOffset: false});
    const query = createQueryString({...action.payload, locationId: action.authState.practiceLocationId, startDate: initialStartDate, endDate: initialEndDate})
    const url = `/${action.authState.accountId}/billing-patients?${query}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, IBillingPatient[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ billingPatients: response.data.data as IBillingPatient[], recordsCount: response.data.recordsCount, sideBarTab: response.data.sideBarTab })
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchBillingPatientsExport = (action: { type: string, authState: AuthState, payload: {pageNumber: number, recordsPerPage: number, searchText: string, startDate?: string, endDate?: string}}) => {
    const query = createQueryString({...action.payload, locationId: action.authState.practiceLocationId})
    const url = `/${action.authState.accountId}/billing-patients/export?${query}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.post<Either<CheckedError, DownloadReport>>(url, {},config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as DownloadReport)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during POST process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiDownloadPatientSummary = (action: { type: string, authState: AuthState, payload: {patientId: string}}) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/patient-summary/download`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, DownloadReport>>(url,config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as DownloadReport)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during POST process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiDownloadPatientSessionNotes = (action: { type: string, authState: AuthState, payload: DownloadNotesRequest}) => {
    const query = createQueryString({...action.payload})
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/session-notes/${action.payload.notesId}/download?${query}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, DownloadReport>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as DownloadReport)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during POST process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchICDCodes = (action: { type: string, authState: AuthState, payload: IcdCodesRequest}) => {
    const query = createQueryString({...action.payload})
    const url = `/${action.authState.accountId}/patients/${action.payload.userId}/screeners/icd-code/${action.payload.cptCode}?${query}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, string>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data.icdCodes as string)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatients(action: { type: string,  authState: AuthState, payload: FetchPatientsPayload}) {
    try {
        console.log(`PatientSaga:fetchPatients`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, {patients: IPatientListItem[], recordsCount: number, sideBarTab: string}> = yield call(apiFetchPatients, action)
        if(apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientsFailed({error: error.message}));
            } else {
                yield put(fetchingPatientsFailed({error: error.message}));
            }
        } else {
            const patients = apiFetchPatientsResponse.right().patients;
            const recordsCount = apiFetchPatientsResponse.right().recordsCount;
            const sideBarTab = apiFetchPatientsResponse.right().sideBarTab
            yield put(fetchedPatients(patients, recordsCount, sideBarTab));
            yield put(clearPatientAndSourcePatient())
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchCoCMPatients(action: { type: string,  authState: AuthState, payload: FetchCoCMPatientsPayload}) {
    try {
        console.log(`PatientSaga:fetchCoCMPatients`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, {patients: ICoCMPatient[], recordsCount: number, sideBarTab: string}> = yield call(apiFetchCoCMPatients, action)
        if(apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga:fetchCoCMPatients: Encountered a Checked Error", error.message)
                yield put(fetchingCoCMPatientsFailed({error: error.message}));
            } else {
                yield put(fetchingCoCMPatientsFailed({error: error.message}));
            }
        } else {
            const patients = apiFetchPatientsResponse.right().patients;
            const recordsCount = apiFetchPatientsResponse.right().recordsCount;
            const sideBarTab = apiFetchPatientsResponse.right().sideBarTab
            yield put(fetchedCoCMPatients(patients, recordsCount, sideBarTab));
            yield put(clearPatientAndSourcePatient())
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingCoCMPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatient(action: { type: string,  authState: AuthState, payload: {patientId: string}}) {
    try {
        console.log(`PatientSaga:fetchPatient`)
        yield put(callInProgress())
        yield put(fetchPatientInProgress())
        const apiFetchPatientResponse: Either<CheckedError, IPatientDetails> = yield call(apiFetchPatient, action)
        if(apiFetchPatientResponse.isLeft()) {
            const error = apiFetchPatientResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientFailed({error: error.message}));
            } else {
                yield put(fetchingPatientFailed({error: error.message}));
            }
        } else {
            const patient = apiFetchPatientResponse.right();
            console.log(patient.patient, "Patient Details")
            yield put(fetchedPatient(patient.patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientWithSummary(action: { type: string,  authState: AuthState, payload: {patientId: string, sessionId?: string}}) {
    try {
        console.log(`PatientSaga:fetchPatientWithSummary`)
        yield put(callInProgress())
        yield put(fetchPatientWithSummaryInProgress())
        const apiFetchPatientWithSummaryResponse: Either<CheckedError, IPatientWithSummary> = yield call(apiFetchPatientWithSummary, action)
        if(apiFetchPatientWithSummaryResponse.isLeft()) {
            const error = apiFetchPatientWithSummaryResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientWithSummaryFailed({error: error.message}));
            } else {
                yield put(fetchingPatientWithSummaryFailed({error: error.message}));
            }
        } else {
            const patient = apiFetchPatientWithSummaryResponse.right();
            console.log(patient.patient, "Patient Details")
            yield put(fetchedPatientWithSummary(patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientWithSummaryFailed({error: "Error fetching patient with summary!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchQuestionAnswersForPatient(action: { type: string,  authState: AuthState, payload: {patientId: string, screenerId: string}}) {
    try {
        console.log(`PatientSaga:fetchQuestionAnswersForPatient`)
        yield put(callInProgress())
        yield put(fetchPatientQAndAInProgress())
        const apiFetchQAndAResponse: Either<CheckedError,IScreenerQuestionAnswer[]> = yield call(apiFetchScreenerAnswersForPatient, action)
        if(apiFetchQAndAResponse.isLeft()) {
            const error = apiFetchQAndAResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingQAndAFailed({error: error.message}));
            } else {
                yield put(fetchingQAndAFailed({error: error.message}));
            }
        } else {
            const questionAnswers = apiFetchQAndAResponse.right();
            yield put(fetchedQAndA(questionAnswers, action.payload.screenerId))
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingQAndAFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}

function* downloadPatientResponses(action: {type: string,  authState: AuthState, payload: {patientId: string, filters?: DownloadScreenerRequest}}) {
    try {
        console.log(`PatientSaga:fetchQuestionAnswersForPatient`)
        yield put(callInProgress())
        const apiDownloadPatientResponsesUrl: Either<CheckedError,{url: string, fileName: string}> = yield call(apiDownloadPatientResponses, action)
        if(apiDownloadPatientResponsesUrl.isLeft()) {
            const error = apiDownloadPatientResponsesUrl.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(downloadingPatientResponsesFailed({error: error.message}));
            } else {
                yield put(downloadingPatientResponsesFailed({error: error.message}));
            }
        } else {
            const {url, fileName} = apiDownloadPatientResponsesUrl.right();
            yield put(downloadedPatientResponses(url, fileName))
        }
    } catch (e) {
        console.log(e)
        yield put(downloadingPatientResponsesFailed({error: "Error while downloading!"}));
    } finally {
        yield put(callFinished())
    }
}

function* saveReferNotes(action: { type: string,  authState: AuthState, payload: {patientId: string,referringPhysicianId:string, symptoms?: Generic, problems?: Generic, currentMedications?: Generic, diagnosis?: Generic, comorbidities?: Generic, patientMedicationsHistory?: Generic, psychiatricMedication?: string, physicianNotes?: string, isConsentGiven: string, allergies?: Generic }}) {
    try {
        console.log(`PatientSaga:saveReferNotesForPatient`, action.payload.patientId, action.authState)
        yield put(callInProgress())
        yield put(saveReferNotesInProgress())
        const apiSaveReferNotesResponse: Either<CheckedError, IRefer> = yield call(apiSaveReferNotes, action)
        if(apiSaveReferNotesResponse.isLeft()) {
            const error = apiSaveReferNotesResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(savingReferNotesFailed({error: error.message}));
            } else {
                yield put(savingReferNotesFailed({error: error.message}));
            }
        } else {
            const referNotes = apiSaveReferNotesResponse.right();
            yield put(savedReferNotes(referNotes))
        }
    } catch (e) {
        console.log(e)
        yield put(savingReferNotesFailed({error: "Error saving refer notes!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchReferredPatients(action: { type: string,  authState: AuthState, payload: {pageNumber: number, recordsPerPage: number, searchText: string, referredPatients?: boolean}}) {
    try {
        console.log(`PatientSaga:fetchReferredPatients`)
        yield put(callInProgress())
        yield put(fetchingReferredPatientsInProgress())
        const apiFetchReferredPatientsResponse: Either<CheckedError, {patients: IPatient[], recordsCount: number, sideBarTab: string}> = yield call(apiFetchReferredPatients, action)
        if(apiFetchReferredPatientsResponse.isLeft()) {
            const error = apiFetchReferredPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingReferredPatientsFailed({error: error.message}));
            } else {
                yield put(fetchingReferredPatientsFailed({error: error.message}));
            }
        } else {
            const patients = apiFetchReferredPatientsResponse.right().patients;
            const recordsCount = apiFetchReferredPatientsResponse.right().recordsCount;
            const sideBarTab = apiFetchReferredPatientsResponse.right().sideBarTab
            yield put(fetchedReferredPatients(patients, recordsCount, sideBarTab));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingReferredPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchReferenceNotes(action: { type: string,  authState: AuthState, payload: {patientId: string, slug: string}}) {
    try {
        console.log(`PatientSaga:fetchReferenceNotes`)
        yield put(callInProgress())
        yield put(fetchingReferenceNotesInProgress())
        const apiFetchReferenceNotesResponse: Either<CheckedError, ReferenceResponse> = yield call(apiFetchReferenceNotes, action)
        if(apiFetchReferenceNotesResponse.isLeft()) {
            const error = apiFetchReferenceNotesResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingReferenceNotesFailed({error: error.message}));
            } else {
                yield put(fetchingReferenceNotesFailed({error: error.message}));
            }
        } else {
            const physicianReportReference = apiFetchReferenceNotesResponse.right().physicianReportReference;
            const physicianNotes = apiFetchReferenceNotesResponse.right().physicianNotes;
            yield put(fetchedReferenceNotes(physicianReportReference, physicianNotes));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingReferenceNotesFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchAllTherapists(action: { type: string,  authState: AuthState}) {
    try {
        console.log(`PatientSaga:fetchAllTherapists`)
        yield put(callInProgress())
        yield put(fetchingAllTherapistsInProgress())
        const apiFetchAllTherapistsResponse: Either<CheckedError, ITherapists[]> = yield call(apiFetchAllTherapists, action)
        if(apiFetchAllTherapistsResponse.isLeft()) {
            const error = apiFetchAllTherapistsResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingAllTherapistsFailed({error: error.message}));
            } else {
                yield put(fetchingAllTherapistsFailed({error: error.message}));
            }
        } else {
            const therapists = apiFetchAllTherapistsResponse.right();
            yield put(fetchedAllTherapists(therapists));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingAllTherapistsFailed({error: "Error fetching therapists!"}));
    } finally {
        yield put(callFinished())
    }
}

function* saveSchedule(action: { type: string,  authState: AuthState, payload: {patientId: string, therapistPracticeId: string }}) {
    try {
        console.log(`PatientSaga:saveSchedule`, action.payload.therapistPracticeId, action.authState)
        yield put(callInProgress())
        yield put(savingScheduleInProgress())
        const apiSaveScheduleResponse: Either<CheckedError, IProspect> = yield call(apiSaveSchedule, action)
        if(apiSaveScheduleResponse.isLeft()) {
            const error = apiSaveScheduleResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(savingScheduleFailed({error: error.message}));
            } else {
                yield put(savingScheduleFailed({error: error.message}));
            }
        } else {
            const prospect = apiSaveScheduleResponse.right();
            yield put(savedSchedule(prospect))
        }
    } catch (e) {
        console.log(e)
        yield put(savingScheduleFailed({error: "Error saving schedule!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchBillingPatients(action: { type: string,  authState: AuthState, payload: {pageNumber: number, recordsPerPage: number, searchText: string, startDate?: string, endDate?: string}}) {
    try {
        console.log(`PatientSaga:fetchBillingPatients`)
        yield put(callInProgress())
        yield put(fetchingBillingPatientsInProgress())
        const apiFetchBillingPatientsResponse: Either<CheckedError, {billingPatients: IBillingPatient[], recordsCount: number, sideBarTab: string}> = yield call(apiFetchBillingPatients, action)
        if(apiFetchBillingPatientsResponse.isLeft()) {
            const error = apiFetchBillingPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingBillingPatientsFailed({error: error.message}));
            } else {
                yield put(fetchingBillingPatientsFailed({error: error.message}));
            }
        } else {
            const billingPatients = apiFetchBillingPatientsResponse.right().billingPatients;
            const recordsCount = apiFetchBillingPatientsResponse.right().recordsCount;
            const sideBarTab = apiFetchBillingPatientsResponse.right().sideBarTab
            yield put(fetchedBillingPatients(billingPatients, recordsCount, sideBarTab));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingReferredPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchBillingPatientsExport(action: { type: string,  authState: AuthState, payload: {pageNumber: number, recordsPerPage: number, searchText: string, startDate?: string, endDate?: string}}) {
    try {
        console.log(`PatientSaga:fetchBillingPatientsExport`)
        yield put(callInProgress())
        const apiFetchBillingPatientsResponse: Either<CheckedError, DownloadReport> = yield call(apiFetchBillingPatientsExport, action)
        if(apiFetchBillingPatientsResponse.isLeft()) {
            const error = apiFetchBillingPatientsResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(downloadingBillingReportFailed({error: error.message}));
            } else {
                yield put(downloadingBillingReportFailed({error: error.message}));
            }
        } else {
            const {link, fileName} = apiFetchBillingPatientsResponse.right()
            yield put(downloadedBillingReport(link, fileName));
        }
    } catch (e) {
        console.log(e)
        yield put(downloadingBillingReportFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* downloadPatientSummary(action: { type: string,  authState: AuthState, payload: {patientId: string}}) {
    try {
        console.log(`PatientSaga:downloadPatientSummary`)
        yield put(callInProgress())
        const apiDownloadPatientSummaryResponse: Either<CheckedError, DownloadReport> = yield call(apiDownloadPatientSummary, action)
        if(apiDownloadPatientSummaryResponse.isLeft()) {
            const error = apiDownloadPatientSummaryResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(downloadingPatientSummaryFailed({error: error.message}));
            } else {
                yield put(downloadingPatientSummaryFailed({error: error.message}));
            }
        } else {
            const {presignedURL, fileName} = apiDownloadPatientSummaryResponse.right()
            yield put(downloadedPatientSummary(presignedURL, fileName));
        }
    } catch (e) {
        console.log(e)
        yield put(downloadingPatientSummaryFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* downloadPatientSessionNotes(action: { type: string,  authState: AuthState, payload: DownloadNotesRequest}) {
    try {
        console.log(`PatientSaga:downloadPatientSessionNotes`)
        yield put(callInProgress())
        const apiDownloadPatientSessionNotesResponse: Either<CheckedError, DownloadReport> = yield call(apiDownloadPatientSessionNotes, action)
        if(apiDownloadPatientSessionNotesResponse.isLeft()) {
            const error = apiDownloadPatientSessionNotesResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(downloadingPatientSessionNotesFailed({error: error.message}));
            } else {
                yield put(downloadingPatientSessionNotesFailed({error: error.message}));
            }
        } else {
            const {presignedURL, fileName} = apiDownloadPatientSessionNotesResponse.right()
            yield put(downloadedPatientSessionNotes(presignedURL, fileName));
        }
    } catch (e) {
        console.log(e)
        yield put(downloadingPatientSessionNotesFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchICDCodes(action: { type: string,  authState: AuthState, payload: IcdCodesRequest}) {
    try {
        console.log(`PatientSaga:fetchICDCodes`)
        yield put(callInProgress())
        const apiIcdCodesResponse: Either<CheckedError, string> = yield call(apiFetchICDCodes, action)
        if(apiIcdCodesResponse.isLeft()) {
            const error = apiIcdCodesResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(fetchingICDCodesFailed({error: error.message}));
            } else {
                yield put(fetchingICDCodesFailed({error: error.message}));
            }
        } else {
            const icdCodes = apiIcdCodesResponse.right();
            yield put(fetchedICDCodes(icdCodes));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingICDCodesFailed({error: "Error fetching icd codes!"}));
    } finally {
        yield put(callFinished())
    }
}

const apiSendGenericAppointmentReminderToPatient = (action: { type: string, payload: SendGenericPhysicianAppointmentReminderRequest , authState: AuthState }) => {
    const url = `/${action.authState.accountId}/comm/screening-reminder-for-generic-appointment`;
    return physicianHttpClient.post<Either<CheckedError, ISendGenericPhysicianAppointmentReminderResponse>>(url,action.payload, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data as ISendGenericPhysicianAppointmentReminderResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during sending generic appointment reminder to patient"));
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* sendGenericAppointmentReminderToPatient(action: { type: string, payload: SendGenericPhysicianAppointmentReminderRequest, authState: AuthState }) {
    try {
        console.log(`PatientSaga:sendGenericAppointmentReminderToPatient`)
        yield put(callInProgress())
        const apiSendGenericAppointmentReminderToPatientResponse: Either<CheckedError, ISendGenericPhysicianAppointmentReminderResponse> = yield call(apiSendGenericAppointmentReminderToPatient, action)
        if(apiSendGenericAppointmentReminderToPatientResponse.isLeft()) {
            const error = apiSendGenericAppointmentReminderToPatientResponse.left()
            if(error.isChecked) {
                console.log("PatientSaga: Encountered a Checked Error", error.message)
                yield put(sendingGenericAppointmentReminderToPatientFailed({error: error.message}));
            } else {
                yield put(sendingGenericAppointmentReminderToPatientFailed({error: error.message}));
            }
        } else {
            yield put(sentGenericAppointmentReminderToPatient());
        }
    } catch (e) {
        console.log(e)
        yield put(sendingGenericAppointmentReminderToPatientFailed({error: "Error sending generic appointment reminder to patient!"}));
    } finally {
        yield put(callFinished())
    }
}


export default function* patientsSaga() {
    yield takeLatest(PHYSICIAN_FETCH_PATIENTS, fetchPatients)
    yield takeLatest(PHYSICIAN_FETCH_COCM_PATIENTS, fetchCoCMPatients)
    yield takeLatest(PHYSICIAN_FETCH_PATIENT, fetchPatient)
    yield takeLatest(PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY, fetchPatientWithSummary)
    yield takeLatest(PHYSICIAN_FETCH_PATIENT_Q_AND_A, fetchQuestionAnswersForPatient)
    yield takeLatest(PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES, downloadPatientResponses)
    yield takeLatest(PHYSICIAN_SAVE_REFER_NOTES, saveReferNotes)
    yield takeLatest(PHYSICIAN_FETCH_REFERRED_PATIENTS, fetchReferredPatients)
    yield takeLatest(PHYSICIAN_FETCH_REFERENCE_NOTES, fetchReferenceNotes)
    yield takeLatest(PHYSICIAN_FETCH_ALL_THERAPISTS, fetchAllTherapists)
    yield takeLatest(PHYSICIAN_SAVE_SCHEDULE, saveSchedule)
    yield takeLatest(PHYSICIAN_FETCH_BILLING_PATIENTS, fetchBillingPatients)
    yield takeLatest(PHYSICIAN_DOWNLOAD_BILLING_REPORT, fetchBillingPatientsExport)
    yield takeLatest(DOWNLOAD_PATIENT_SUMMARY, downloadPatientSummary)
    yield takeLatest(DOWNLOAD_PATIENT_SESSION_NOTES, downloadPatientSessionNotes)
    yield takeLatest(PHYSICIAN_FETCH_ICD_CODES, fetchICDCodes)
    yield takeLatest(SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT, sendGenericAppointmentReminderToPatient)
}
