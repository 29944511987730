import { Controller, useForm } from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Input } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { saveLocation } from "../../store/actions/physician/admin-operations.action";
import { useEffect, useState } from "react";
import { StateParams } from "../../store/reducers";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput, {Country, isValidPhoneNumber} from "react-phone-number-input";
import PhoneNumberInputComponent from "../../components/generic/PhoneNumberInputComponent";
import Message from "../../components/generic/Message";

type Inputs = {
    name: string,
    addressLine: string,
    zipCode: string,
    phone: string,
}

const withLocationForm = (WrappedComponent: any, WrappedSubmitComponent: any, WrappedVerificationComponent?: any) => (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const locationPath = useLocation();
    const location = useSelector((state: StateParams) => state.physicianAdminOperations.location)
    const {initiatingPhoneVerificationError, saveLocationError} = useSelector((state: StateParams) => state.physicianAdminOperations)
    const { register, control, handleSubmit, watch, formState: { errors }, clearErrors, setValue, reset, setError } = useForm<Inputs>()
    const params = useParams()

    useEffect(() => {
        if (params && params.locationId && location?.id) {
            reset(location)
        }
    }, [params, location])

    useEffect(() => {
        if (location?.id && locationPath.pathname.includes("create")) {
            setTimeout(() => navigate(`/locations/edit/${location.id}`), 2000)
        }
    }, [location?.id])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name && type === 'change') {
                if (name === 'phone') {
                    const hasPhoneNumberError = handlePhoneError()
                    if(hasPhoneNumberError && params.locationId && value.phone) {
                        dispatch(saveLocation({ ...{phone: value.phone, name: value.name}, id: params.locationId }))
                    }
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const onSubmit = handleSubmit((data: Inputs) => {
        if(handlePhoneError()) {
            dispatch(saveLocation({ ...data, id: params.locationId }))
            toast.success(props.successfullSaveMessage || 'You have successfully saved location.', {
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        }
    })

    const handlePhoneError = () => {
        const phoneValue = watch('phone');
        const isInvalidPhoneNumber = phoneValue && !isValidPhoneNumber(phoneValue);

        if (isInvalidPhoneNumber) {
            setError('phone', { type: 'required', message: 'Phone number format is invalid.' });
        } else {
            clearErrors('phone');
        }
        return !isInvalidPhoneNumber;
    };

    const renderPhoneVerificationMessage = () => {
        if (errors.phone?.message) {
            return <Message message={errors.phone.message} className={'error-msg text-sm'} />;
        }
        if (initiatingPhoneVerificationError) {
            return <Message message={initiatingPhoneVerificationError} className={'error-msg text-sm'} />;
        }
        if (location && !location.isPhoneVerified) {
            return <Message message={'Phone Number not verified'} className={'error-msg text-sm'} />;
        }
        if (location && location.isPhoneVerified) {
            return <Message message={'Phone Number verified'} className={'success-msg text-sm'} />;
        }
        return null;
    };

    return (
        <div className="py-10 px-10 bg-white-700 min-h-screen flex flex-col">
            <form onSubmit={onSubmit}>
                <WrappedComponent />
                <div className="flex flex-row mt-3 ">
                    <div className="w-2/5">
                        <label className="block text-sm mt-6 text-sjDarkGray">Location Name</label>
                        <Input size="lg" type={'text'} placeholder="Enter Location Name" {...register("name", { required: 'This field is required' })} 
                            className="mt-2 border-sjLighterGray outline-none"/>
                        {errors.name?.message && <Message message={errors.name.message} className={'error-msg text-sm'}/>}
                    </div>
                    <div className="ml-14 w-2/5">
                        <label className="block text-sm mt-6 text-sjDarkGray">Phone Number</label>
                        <div className="flex-row">
                            <Controller
                                {...register("phone", { required: 'This field is required' })}
                                control={control}
                                name='phone'
                                render={({ field }) => (
                                    <PhoneNumberInputComponent 
                                        value={field.value} 
                                        onChange={field.onChange} 
                                        id="phoneLocation"
                                        className="w-full mt-2"
                                    />
                                )}
                            />
                            {renderPhoneVerificationMessage()}
                            {WrappedVerificationComponent && <WrappedVerificationComponent/>}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row mt-3 ">
                    <div className="w-2/5">
                        <label className="block text-sm mt-6 text-sjDarkGray">Address</label>
                        <Input size="lg" type={'text'} placeholder="Enter Address" {...register("addressLine", { required: 'This field is required' })} 
                            className="mt-2 border-sjLighterGray outline-none" />
                        {errors.addressLine?.message && <Message message={errors.addressLine.message} className={'error-msg text-sm'}/>}
                    </div>
                    <div className="ml-14 w-2/5">
                        <label className="block text-sm mt-6 text-sjDarkGray">Zip code</label>
                        <Input size="lg" type={'text'} placeholder="Enter Zip code" {...register("zipCode", { required: 'This field is required' })} 
                            className="mt-2 border-sjLighterGray outline-none" />
                        {errors.zipCode?.message && <Message message={errors.zipCode.message} className={'error-msg text-sm'}/>}
                    </div>
                </div>
                <div className="flex flex-row mt-10">
                    <WrappedSubmitComponent/>
                </div>
            </form>
        </div>
    )
}

export default withLocationForm