import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { clearErrors } from "../../../../store/actions/counsellor/appointments.action";
import { fetchProspectWithSummary } from "../../../../store/actions/counsellor/prospects.action";
import { StateParams } from "../../../../store/reducers";
import { DateOfVisit } from "../../../../components/patients/DateOfVisit";
import ScreenerResults from "../../../../components/screeners/ScreenerResults";


const ReferredPatientScreenerSummary = () => {
    const { prospect } = useSelector((state: StateParams) => state.prospects);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0)

    return (
        <main>{prospect && <>
            {prospect?.patient && <DateOfVisit patientWithSummary={prospect} setSelectedIndex={setSelectedIndex} />}
            {prospect?.summary && <ScreenerResults prospect={prospect} summary={prospect?.summary} selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex} />}
        </>
        }
        </main>
    )
}
export default ReferredPatientScreenerSummary;
