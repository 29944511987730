import React, { useCallback, useEffect, useState } from "react";
import { ICommentRequest } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { addCommentToPatient, fetchPatientComments, resetCommentToPatient } from "../../store/actions/care-coordinator/patients.action";
import ProfileIcon from "../../assets/images/common/profile.svg";
import { Button } from "../generic/Buttons";
import ConfirmationModal from "../clinical-notes/ConfirmationModal";
import { StateParams } from "../../store/reducers";
import TextAreaComponent from "../generic/TextAreaComponent";

interface AddCommentComponentProps {
    patientId: string;
    source?: string;
    onClose?: () => void;
    checkCommentExist: (hasText: boolean) => void
    onCommentSubmit?: () => void;

}

const currentPage = 1;
const pageSize = 10;

const AddCommentComponent = (props: AddCommentComponentProps) => {
    const [comment, setComment] = useState<string>('');
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);
    const { addedCommentSuccess } = useSelector((state: StateParams) => state.coordinatorPatients);
    const dispatch = useDispatch();
    const { patientId, source, onClose, checkCommentExist, onCommentSubmit } = props;

    const onSubmit = useCallback(() => {
        const commentRequest: ICommentRequest = {
            comment,
            source: source,
        };
        dispatch(addCommentToPatient(patientId, commentRequest));
    }, [dispatch, comment, patientId, source]);

    const handleDiscardComment = () => {
        setComment('');
        setIsDiscardModalOpen(false);
        onClose && onClose();
        checkCommentExist(false);
    }

    useEffect(() => {
        if (addedCommentSuccess) {
            dispatch(resetCommentToPatient());
            handleDiscardComment();
            onCommentSubmit && onCommentSubmit();
        }
    }, [addedCommentSuccess, dispatch, patientId]);

    const renderDiscardCommentConfirmationModal = () => {
        return (
            <ConfirmationModal isOpen={isDiscardModalOpen}
                onClose={() => setIsDiscardModalOpen(!isDiscardModalOpen)}
                key={'discardCommentConfirmationModal'}
                modalClassNames={'!w-1/2'}
                continueButtonName={'Discard'}
                actions={
                    <div className="flex justify-end gap-4">
                        <Button className="border-sjOrange text-sjOrange" onClick={
                            () => setIsDiscardModalOpen(false)
                        }>Cancel</Button>
                        <Button className="!bg-sjOrange text-white" onClick={handleDiscardComment}>Discard</Button>
                    </div>
                }
            >
                <p className="text-sm text-medium text-left">Discard Comment?</p>
                <p className="text-sjGray text-sm font-base text-left pt-4">Are you sure you want to discard the comment? Any comment that isn't saved will be lost.</p>
            </ConfirmationModal>
        )
    }


    return (
        <div>
            <div className="border border-gray-200 rounded-md p-4 my-4">
                <div className="flex gap-4 m-5">
                    <img src={ProfileIcon} alt="Profile Icon" className="w-7 h-7" />
                    <TextAreaComponent
                        value={comment}
                        onChange={(value) => {
                            setComment(value);
                            if (value) {
                                checkCommentExist(true);
                            } else {
                                checkCommentExist(false);
                            }
                        }}
                        placeholder="Type comment" />

                </div>

                <div className="flex justify-end gap-4 mr-5">
                    <Button className="border-sjOrange text-sjOrange" onClick={() => setIsDiscardModalOpen(true)}>Discard</Button>
                    <Button
                        className={`!bg-sjOrange text-white ${!comment.trim() ? 'cursor-not-allowed opacity-50' : ''}`}
                        disabled={!comment.trim()}
                        onClick={onSubmit}
                    >
                        Submit Comment
                    </Button>
                </div>
            </div>
            {renderDiscardCommentConfirmationModal()}
        </div>
    );
}

export default AddCommentComponent;



