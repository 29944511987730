import {classNames} from "../../shared/Utils";
import React from "react";

interface IBadge {
    text: string
}

export const Badge: React.FC<IBadge> = ({text}) => {

    return (
        <span
            className={classNames(
                "inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium text-black bg-sjSideBar",
            )}>
            {text}
        </span>
    );
}
