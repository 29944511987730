import {AnyAction} from "redux";
import {CallStatus, IPatientInCall} from "../../../models";

export const REQUEST_CALL = "START_CALL"
export const INITIATE_CALL = "INITIATE_CALL"
export const CALL_INITIATED = "CALL_INITIATED"
export const CALL_INITIATION_FAILED = "CALL_INITIATED_FAILED"
export const CARE_COORDINATOR_INITIATE_CALL = "CARE_COORDINATOR_INITIATE_CALL"
export const END_CALL = "END_CALL"
export const RESET_CALL = "RESET_CALL"
export const END_CALL_REQUESTED = "END_CALL_REQUESTED"
export const SET_CALL_STATUS = "SET_CALL_STATUS"
export const SET_CALL_ERROR_REASON = "SET_CALL_ERROR_REASON"

export const ACTIVE_CALL_STATUSES = [CallStatus.ACTIVE, CallStatus.ON_CALL, CallStatus.INITIATED, CallStatus.RINGING]

export const requestCall = (patient: IPatientInCall): AnyAction => {
  return { type: REQUEST_CALL, payload: { patient: patient } }
}

export const initiateCall = (patient: IPatientInCall): AnyAction => {
  return { type: INITIATE_CALL, payload: { patientId: patient.id } }
}
export const callInitiationFailed = () => {
  return { type: CALL_INITIATION_FAILED }
}
export const callInitiated = (payload: {token: string}): AnyAction => {
  return { type: CALL_INITIATED, payload }
}

export const initiateCareCoordinatorCall = (patient: IPatientInCall, locationId?: string): AnyAction => {
  return { type: CARE_COORDINATOR_INITIATE_CALL, payload: { practiceId: patient.referredByPracticeId, patientId: patient.sourcePatientId, locationId } }
}

export const endCall = (): AnyAction => {
  return { type: END_CALL  }
}

export const resetCall = (): AnyAction => {
  return { type: RESET_CALL  }
}

export const requestEndCall = (): AnyAction => {
  return { type: END_CALL_REQUESTED  }
}

export const setCallStatus = (callStatus: CallStatus) : AnyAction => {
  return {type: SET_CALL_STATUS, payload: {status: callStatus}}
}
export const setCallErrorReason = (reason: string) : AnyAction => {
  return {type: SET_CALL_ERROR_REASON, payload: {errorReason: reason}}
}
