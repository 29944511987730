import React, {useCallback, useState} from "react";
import {HiCalendarDays} from "react-icons/hi2";
import {XCircleIcon} from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker";
import TableFilterPaginationV2 from "../../components/generic/TableFilterPaginationV2";
import {DateTime} from "luxon";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import Select from "react-select";

interface IPhysicianAppointmentsFiltersComponentProps {
    searchText: string
    setSearchText: (text: string) => void
    setCurrentPage: (page: number) => void
    onFilterChange: (startDate: string | undefined, endDate: string | undefined) => void;
    startDate?: string;
    endDate?: string;
    practiceId?: string;
    setPracticeId: (id: string) => void;
}

const PhysicianAppointmentsFiltersComponent = (props: IPhysicianAppointmentsFiltersComponentProps)=>{

    const {startDate, endDate, practiceId, setCurrentPage, setSearchText, searchText, setPracticeId, onFilterChange} = props;
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(startDate ? new Date(startDate) : undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(endDate ? new Date(endDate) : undefined);
    const [appliedStartDate, setAppliedStartDate] = useState<Date | undefined>(startDate ? new Date(DateTime.fromISO(startDate).toJSDate()) : undefined);
    const [appliedEndDate, setAppliedEndDate] = useState<Date | undefined>(endDate ? new Date(DateTime.fromISO(endDate).toJSDate()) : undefined);
    const {practiceMaster} = useSelector((state: StateParams) => state.careCoordinatorMasterData);

    const handleDateInputClick = useCallback(() => {
        setShowCustomDateFilter(!showCustomDateFilter);
    }, [showCustomDateFilter]);

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
    }, []);

    const handleDateFilterAction = useCallback((action: 'clear' | 'apply' | 'cancel') => {
        if (action === 'clear') {
            setFilterStartDate(undefined);
            setFilterEndDate(undefined);
            setAppliedStartDate(undefined);
            setAppliedEndDate(undefined);
            onFilterChange(undefined, undefined);
        } else if (filterStartDate && filterEndDate && action === 'apply') {
            setAppliedStartDate(filterStartDate);
            setAppliedEndDate(filterEndDate);
            onFilterChange(
                DateTime.fromJSDate(filterStartDate).toFormat('yyyy-MM-dd'),
                DateTime.fromJSDate(filterEndDate).toFormat('yyyy-MM-dd')
            );
            setShowCustomDateFilter(false);
            setCurrentPage(1);
        } else if (action === 'cancel') {
            appliedStartDate ? setFilterStartDate(appliedStartDate) : setFilterStartDate(undefined);
            appliedEndDate ? setFilterEndDate(appliedEndDate) : setFilterEndDate(undefined);
            setShowCustomDateFilter(false);
        }
    }, [filterStartDate, filterEndDate, onFilterChange, appliedStartDate, appliedEndDate, setCurrentPage]);

    const getDateString = useCallback((date: Date | undefined) => {
        return date ? DateTime.fromJSDate(date).toFormat('MM-dd-yyyy') : undefined;
    }, []);

    const renderFilters = useCallback(() => {
        const isDatesSelected = filterStartDate && filterEndDate;
        return <div className='flex flex-row align-bottom gap-3'>
            <Select
                placeholder={"Practice"}
                className="h-12 min-w-[250px] max-w-[250px]"
                classNamePrefix={"sj-react-select"}
                options={practiceMaster}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
                value={practiceMaster.find(x => x.id === practiceId)}
                isClearable={true}
                onChange={x => {
                    if (x) {
                        if (!x?.id || x?.id === practiceId) return;
                        setPracticeId( x?.id);
                        setCurrentPage(1);
                    } else {
                        setPracticeId('');
                        setCurrentPage(1);
                    }
                }}
                defaultValue={undefined}
                isSearchable={practiceMaster.length > 5}
            />
            <div className="relative">
                <input
                    value={isDatesSelected ? `${getDateString(filterStartDate)} - ${getDateString(filterEndDate)}` : ''}
                    placeholder="Appointment Date"
                    className={"p-2 w-[245px] border-[1.3px] rounded-md h-10 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                    readOnly
                    onClick={handleDateInputClick}
                />
                {
                    !isDatesSelected && <span className="absolute top-2.5 right-0 flex items-center pr-2">
                                <HiCalendarDays className="w-5 h-5 text-sjLightGrey"/>
                    </span>
                }
                {
                    isDatesSelected && <span onClick={()=>handleDateFilterAction('clear')} className="cursor-pointer absolute top-2.5 right-0 flex items-center pr-2">
                                <XCircleIcon height={20} className="w-5 h-5 text-sjLightGrey"/>
                    </span>
                }
            {showCustomDateFilter &&
                <div className="absolute mt-2 ml-0 text-center">
                    <DatePicker
                        onChange={handleChange}
                        startDate={filterStartDate}
                        endDate={filterEndDate}
                        selectsRange
                        inline
                    >
                        <div className="custom-date-filter-actions mb-2">
                            <button
                                onClick={() => handleDateFilterAction('cancel')}
                                className="rounded-l-md text-sjDarkGray py-2 px-2"
                            >Cancel
                            </button>
                            <button
                                onClick={() => handleDateFilterAction('clear')}
                                className="rounded-l-md border-sjOrange text-sjOrange py-2 px-4"
                            >Clear
                            </button>
                            &nbsp;
                            <button
                                onClick={() => handleDateFilterAction('apply')}
                                disabled={!filterStartDate || !filterEndDate}
                                className="bg-sjOrange text-white py-2 px-4 rounded"
                            >Apply
                            </button>
                        </div>
                    </DatePicker>
                </div>
            }
            </div>
            <TableFilterPaginationV2
                placeholder={"Search by Name"}
                setTableFilter={setSearchText}
                currentPageHandler={setCurrentPage}
            />
        </div>
    }, [practiceId, filterStartDate, filterEndDate, getDateString, handleDateInputClick, showCustomDateFilter, handleChange, handleDateFilterAction, setSearchText, setCurrentPage, practiceMaster]);

    return <div className='flex flex-row gap-2'>{renderFilters()}</div>
}

export default PhysicianAppointmentsFiltersComponent;
