import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {
    endCall,
    initiateCall,
    initiateCareCoordinatorCall,
    resetCall,
    setCallErrorReason,
    setCallStatus
} from "../../store/actions/counsellor/calls.action";
import {Call, Device} from "@twilio/voice-sdk";
import {TwilioError} from "@twilio/voice-sdk/es5/twilio/errors";
import {CallStatus, Roles} from "../../models";
import { initiatePhysicianCall } from "../../store/actions/physician/calls.action";

const CallManagerComponent: React.FC = () => {
    const dispatch = useDispatch()
    const [callDevice, setDevice] = useState<undefined | Device>();
    const [connection, setConnection] = useState(null);
    const {callStatus, patient, token} = useSelector((state: StateParams) => state.calls)
    const { locationIdToInitiateCall } = useSelector((state: StateParams) => state.physicianAdminOperations)
    const role = useSelector((state: StateParams) => state.account.role)

    const initDevice = async (token: string) => {
        try {
            console.log("Token connected successfully!!", token);
            const device = new Device(token, {
                logLevel: 1,
                codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
                closeProtection: true,
                enableImprovedSignalingErrorPrecision: true,

            });
            device.addListener("connect", (device) => {
                console.log("Connect event listener added .....");
                return device;
            });
            device.on("registered", () => {
                console.log("Agent registered");
            });
            device.on("connect", (connection) => {
                console.log("Call connect");
                setConnection(connection);
            });
            device.on("disconnect", () => {
                console.log("Disconnect event");
                dispatch(setCallStatus(CallStatus.OFFLINE));
                setConnection(null);
            });
            device.on("error", function (error) {
                console.log("Twilio.Device Error: " + error.message);
            });
            await device.register();
            setDevice(device);
            return () => {
                console.log("Cleaning up the Manager")
                device.destroy();
                setDevice(undefined);
                dispatch(endCall())
            };
        } catch (error) {
            console.log("Error", error);
        }
    }

    const handleCall = async (device: Device, phoneNumber: string) => {
        const params: Record<string, string> = {To: phoneNumber};
        device.emit("connect");
        device.connect({
            params: params,
            rtcConstraints: {
                audio: true,
            },
        })
            .then((call) => {
                call.on("accept", () => {
                    console.log("call accepted");
                    setConnection(connection);
                    dispatch(setCallStatus(CallStatus.ON_CALL));
                });
                call.on("disconnect", () => {
                    console.log("The call has been disconnected.");
                    setConnection(null);
                    dispatch(setCallStatus(CallStatus.ENDED));
                });
                call.on("reject", () => {
                    console.log("The call was rejected.");
                    dispatch(setCallStatus(CallStatus.REJECTED));
                });
                call.on('ringing', hasEarlyMedia => {
                    console.log("The call is ringing.");
                    dispatch(setCallStatus(CallStatus.RINGING));
                });
                call.on('error', (error: TwilioError) => {
                    console.log('An error has occurred: ', error);
                    dispatch(setCallStatus(CallStatus.ERRORED));
                    dispatch(setCallErrorReason(error.description))
                });
            });
    };


    useEffect(() => {
        console.log("CallManagerComponent: Initiate Call hook", patient, callStatus, locationIdToInitiateCall, role);
        if (callStatus === CallStatus.REQUESTED && patient) {
            if (role === Roles.Care_Coordinator) {
                console.log("CallManagerComponent: Initiating Call for coordinator For Token", callStatus, patient);
                if (locationIdToInitiateCall) {
                    dispatch(initiateCareCoordinatorCall(patient, locationIdToInitiateCall))
                } else {
                    dispatch(initiateCareCoordinatorCall(patient))
                }
            } else if(role === Roles.Physician) {
                dispatch(initiatePhysicianCall(patient, locationIdToInitiateCall))
            }else {
                console.log("CallManagerComponent: Initiating Call for non-coordinator For Token", callStatus, patient);
                dispatch(initiateCall(patient))
            }
        }
    }, [callStatus, patient, locationIdToInitiateCall, role]);

    useEffect(() => {
        console.log("CallManagerComponent: InitDevice Hook")
        if (callStatus === CallStatus.INITIATED && token) {
            console.log("CallManagerComponent: InitDevice", token, callStatus)
            initDevice(token)
        }
    }, [callStatus, token])

    useEffect(() => {
        console.log("CallManagerComponent: handleCall hook", callDevice)
        if (callDevice && patient) {
            console.log("CallManagerComponent: handleCall", callDevice, patient)
            handleCall(callDevice, patient.phoneNumber)
        }
    }, [callDevice, patient])

    useEffect(() => {
        console.log("CallManagerComponent: end call hook", callDevice, callStatus)
        if (callDevice && callStatus === CallStatus.END_CALL_REQUESTED) {
            console.log("CallManagerComponent: end call requested", callDevice, callStatus)
            dispatch(endCall())
        }
    }, [callDevice, callStatus])

    useEffect(() => {
        console.log("CallManagerComponent: call ended hook", callDevice, callStatus)
        if (callDevice && callStatus === CallStatus.ENDED) {
            console.log("CallManagerComponent: call ended", callDevice, callStatus)
            callDevice.disconnectAll()
            setTimeout(() => {
                dispatch(resetCall())
            }, 1000)
        }
    }, [callDevice, callStatus])

    return null

}

export default CallManagerComponent
