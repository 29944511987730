import {AnyAction} from "redux";
import {
    IAppointment,
    IAppointmentWithProspect,
    IAppointmentWithTherapist,
    IBackdatedAppointmentCreateResponse,
    ICreateAppointment,
    IEditAppointment,
    ITherapistsResponse,
    PatientSessionStatus,
    PatientSessionStatusType,
    SessionTypes
} from "../../../models";

export const FETCH_APPOINTMENTS="FETCH_APPOINTMENTS"
export const FETCH_APPOINTMENTS_IN_PROGRESS="FETCH_APPOINTMENTS_IN_PROGRESS"
export const FETCH_APPOINTMENTS_SUCCESS="FETCH_APPOINTMENTS_SUCCESS"
export const FETCH_APPOINTMENTS_FAILED="FETCH_APPOINTMENTS_FAILED"

export const SEARCH_APPOINTMENTS="SEARCH_APPOINTMENTS"
export const SEARCH_APPOINTMENTS_IN_PROGRESS="SEARCH_APPOINTMENTS_IN_PROGRESS"
export const SEARCH_APPOINTMENTS_SUCCESS="SEARCH_APPOINTMENTS_SUCCESS"
export const SEARCH_APPOINTMENTS_FAILED="SEARCH_APPOINTMENTS_FAILED"

export const FETCH_SESSION = "FETCH_SESSION"
export const FETCH_SESSION_SUCCESS = "FETCH_SESSION_SUCCESS"
export const FETCH_SESSION_FAILED = "FETCH_SESSION_FAILED"

export const CREATE_APPOINTMENT="CREATE_APPOINTMENT"
export const CREATE_APPOINTMENT_IN_PROGRESS="CREATE_APPOINTMENT_IN_PROGRESS"
export const CREATE_APPOINTMENT_SUCCESS="CREATE_APPOINTMENT_SUCCESS"
export const CREATE_APPOINTMENT_FAILED="CREATE_APPOINTMENT_FAILED"
export const RESET_CREATE_APPOINTMENT="RESET_CREATE_APPOINTMENT"

export const CANCEL_APPOINTMENT="CANCEL_APPOINTMENT"
export const CANCEL_APPOINTMENT_IN_PROGRESS="CANCEL_APPOINTMENT_IN_PROGRESS"
export const CANCEL_APPOINTMENT_SUCCESS="CANCEL_APPOINTMENT_SUCCESS"
export const CANCEL_APPOINTMENT_FAILED="CANCEL_APPOINTMENT_FAILED"

export const UPDATE_SESSION_CALL_LOG="UPDATE_SESSION_CALL_LOG"
export const UPDATING_SESSION_CALL_LOG_SUCCESS="UPDATING_SESSION_CALL_LOG_SUCCESS"
export const UPDATING_SESSION_CALL_LOG_FAILED="UPDATING_SESSION_CALL_LOG_FAILED"

export const UPDATING_SESSION_STATUS_IN_PROGRESS="UPDATING_SESSION_STATUS_IN_PROGRESS"
export const UPDATE_SESSION_STATUS="UPDATE_SESSION_STATUS"
export const UPDATE_SESSION_STATUS_FAILED="UPDATE_SESSION_STATUS_FAILED"
export const UPDATE_SESSION_STATUS_SUCCESS="UPDATE_SESSION_STATUS_SUCCESS"
export const CLEAR_SESSION_STATUS="CLEAR_SESSION_STATUS"

export const UPDATE_SESSION_INFORMATION="UPDATE_SESSION_INFORMATION"
export const UPDATING_SESSION_INFORMATION_IN_PROGRESS="UPDATING_SESSION_INFORMATION_IN_PROGRESS"
export const UPDATE_SESSION_SUCCESS="UPDATE_SESSION_SUCCESS"
export const CLEAR_SESSION_INFORMATION="CLEAR_SESSION_INFORMATION"

export const UPDATE_SESSION_ID="UPDATE_SESSION_ID"

export const PATCH_SESSION_STATUS="PATCH_SESSION_STATUS"
export const PATCH_APPONTMENT_LIST_WITH_STATUS="PATCH_APPONTMENT_LIST_WITH_STATUS"

export const FETCH_THERAPISTS="FETCH_THERAPISTS"
export const FETCH_THERAPISTS_IN_PROGRESS="FETCH_THERAPISTS_IN_PROGRESS"
export const FETCH_THERAPISTS_FAILED="FETCH_THERAPISTS_FAILED"
export const FETCH_THERAPISTS_SUCCESS="FETCH_THERAPISTS_SUCCESS"

export const CLEAR_CREATE_APPOINTMENT_ERROR="CLEAR_CREATE_APPOINTMENT_ERROR"

export const CREATE_BACKDATED_APPOINTMENT="CREATE_BACKDATED_APPOINTMENT";
export const CREATE_BACKDATED_APPOINTMENT_SUCCESS="CREATE_BACKDATED_APPOINTMENT_SUCCESS";
export const CREATE_BACKDATED_APPOINTMENT_FAILED="CREATE_BACKDATED_APPOINTMENT_FAILED";
export const CLEAR_CREATE_BACKDATED_APPOINTMENT_ERROR="CLEAR_CREATE_BACKDATED_APPOINTMENT_ERROR";

export const fetchAppointments = (filters?: {status: string[]}) : AnyAction => {
    return {type: FETCH_APPOINTMENTS, payload: {filters: filters}}
}
export const fetchingAppointmentsFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_APPOINTMENTS_FAILED, payload}
}
export const fetchedAppointments = (appointments: IAppointmentWithProspect[]) : AnyAction => {
    return {type: FETCH_APPOINTMENTS_SUCCESS, payload: {appointments}}
}

export const searchAppointmentsInProgress = () : AnyAction => {
    return {type: SEARCH_APPOINTMENTS_IN_PROGRESS }
}
export const searchAppointments = (currentPage: number, recordsPerPage: number, searchText: string, filters?: {status: string[] | undefined} ) : AnyAction => {
    const searchFilters = filters && filters.status ? filters : undefined
    return {type: SEARCH_APPOINTMENTS, payload: {filters: searchFilters, currentPage, recordsPerPage, searchText}}
}
export const searchingAppointmentsFailed = (payload: {error: string}) : AnyAction => {
    return {type: SEARCH_APPOINTMENTS_FAILED, payload}
}
export const searchedAppointments = (appointments: IAppointmentWithProspect[], count?: number) : AnyAction => {
    return {type: SEARCH_APPOINTMENTS_SUCCESS, payload: {appointments, count: count}}
}

export const fetchSession = (patientId: string, sessionType: SessionTypes) : AnyAction => {
    return {type: FETCH_SESSION, payload: {patientId, sessionType: sessionType}}
}
export const fetchingSessionFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_SESSION_FAILED, payload}
}
export const fetchedSession = (session: IAppointmentWithTherapist | null) : AnyAction => {
    return {type: FETCH_SESSION_SUCCESS, payload: {session}}
}

export const resetCreateAppointment = () : AnyAction => {
    return {type: RESET_CREATE_APPOINTMENT}
}

export const createAppointment = (appointment: ICreateAppointment, sourcePatientId: string, practiceId?: string) : AnyAction => {
    return {type: CREATE_APPOINTMENT, payload: {appointment, sourcePatientId, practiceId}}
}
export const creatingAppointmentFailed = (payload: {error: string}) : AnyAction => {
    return {type: CREATE_APPOINTMENT_FAILED, payload}
}
export const createdAppointment = (appointment: IAppointment) : AnyAction => {
    return {type: CREATE_APPOINTMENT_SUCCESS, payload: {appointment}}
}

export const cancelAppointment = (appointment: IEditAppointment, sourcePatientId?: string, practiceId?: string) : AnyAction => {
    return {type: CANCEL_APPOINTMENT, payload: {appointment, sourcePatientId, practiceId}}
}
export const cancelAppointmentInProgress = () : AnyAction => {
    return {type: CANCEL_APPOINTMENT_IN_PROGRESS }
}
export const cancelingAppointmentFailed = (payload: {error: string}) : AnyAction => {
    return {type: CANCEL_APPOINTMENT_FAILED, payload}
}
export const canceledAppointment = (appointment: IAppointment) : AnyAction => {
    return {type: CANCEL_APPOINTMENT_SUCCESS, payload: {appointment}}
}

export const updateSessionCallLog = (payload: {sessionId: string, callInstanceId: string, connectedAtTime: string, closedAtTime?: string, status: "started" | "in_progress" | "finished"}) : AnyAction => {
    return {type: UPDATE_SESSION_CALL_LOG, payload }
}
export const updatingSessionCallLogFailed = (payload: {error: string}) : AnyAction => {
    return {type: UPDATING_SESSION_CALL_LOG_FAILED, payload}
}
export const updatedSessionCallLog = (appointment: IAppointment) : AnyAction => {
    return {type: UPDATING_SESSION_CALL_LOG_SUCCESS, payload: {appointment}}
}

export const updateSessionStatus = (payload: {sessionId: string,  status: keyof typeof PatientSessionStatus}) : AnyAction => {
    return {type: UPDATE_SESSION_STATUS, payload }
}
export const updateSessionStatusFailed = (payload: {error: string}) : AnyAction => {
    return {type: UPDATE_SESSION_STATUS_FAILED, payload}
}
export const updatedSessionStatus = (appointment: IAppointment) : AnyAction => {
    return {type: UPDATE_SESSION_STATUS_SUCCESS, payload: {appointment}}
}
export const clearSessionStatus = (): AnyAction => {
    return {type: CLEAR_SESSION_STATUS}
}

export const updateSessionInformation = (payload: { sessionType: SessionTypes, currentSessionsPatientId: string }) : AnyAction => {
    return {type: UPDATE_SESSION_INFORMATION, payload}
}
export const clearSessionInformation = (): AnyAction => {
    return {type: CLEAR_SESSION_INFORMATION}
}

export const updateSessionId = (payload: {sessionId: string}) : AnyAction => {
    return {type: UPDATE_SESSION_ID, payload }
}

export const patchSessionStatus = (payload: {sessionStatus: string}): AnyAction => {
    return {type: PATCH_SESSION_STATUS, payload}
}

export const patchAppointmentListWithStatus = (payload: {appointmentId: string, status: PatientSessionStatusType}): AnyAction => {
    return {type: PATCH_APPONTMENT_LIST_WITH_STATUS, payload}
}

export const fetchTherapists = () : AnyAction => {
    return {type: FETCH_THERAPISTS}
}
export const fetchedTherapists = (therapists: ITherapistsResponse[]) : AnyAction => {
    return {type: FETCH_THERAPISTS_SUCCESS, payload: {therapists}}
}
export const fetchTherapistsFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_THERAPISTS_FAILED, payload}
}

export const clearErrors = () : AnyAction => {
    return {type: CLEAR_CREATE_APPOINTMENT_ERROR}
}

export const createBackDatedAppointment = (appointment: ICreateAppointment, sourcePatientId: string) : AnyAction => {
    return {type: CREATE_BACKDATED_APPOINTMENT, payload: {appointment, sourcePatientId}}
}

export const creatingBackDatedAppointmentFailed = (payload: {error: string}) : AnyAction => {
    return {type: CREATE_BACKDATED_APPOINTMENT_FAILED, payload}
}

export const createdBackDatedAppointment = (appointment: IBackdatedAppointmentCreateResponse) : AnyAction => {
    return {type: CREATE_BACKDATED_APPOINTMENT_SUCCESS, payload: {appointment}}
}

export const clearCreateBackDatedAppointmentError = () : AnyAction => {
    return {type: CLEAR_CREATE_BACKDATED_APPOINTMENT_ERROR}
}
