import { call, put, takeLatest } from "@redux-saga/core/effects";
import { physicianHttpClient } from "../../../lib";
import { Either, Right } from "monet";
import { CheckedError, ServiceError } from "../../../types/ServiceError";
import { AggregatePatientSummaryByLocation, AuthState, ClinicalConditionsResponse, clinicalImprovementMetricsResponse, IRegistryCocmPatientStatusFilter, PatientSummaryResponseType, RegistryClinicalConditionDistributionResponse, RegistryNotesMetricsResponse, RegistryPatientTreatmentMetricsResponse, RegistryStaticsCardResponse, ScreenerCompletionRate } from "../../../models";
import { AxiosError, AxiosResponse } from "axios";
import { callFinished, callInProgress } from "../../actions/loading.action";
import {
    FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE,
    FETCH_DASHBOARD_PATIENTS_SUMMARY,
    FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION,
    fetchPatientsScreenerCompletionRateFailed,
    fetchPatientsSummaryByLocationFailed,
    fetchPatientsSummaryFailed,
    fetchedPatientsScreenerCompletionRate,
    fetchedPatientsSummary,
    fetchedPatientsSummaryByLocation,
    fetchPatientsSummaryInProgress,
    FETCH_REGISTRY_STATICS_CARD,
    fetchRegistryStaticsCardInProgress,
    fetchRegistryStaticsCardFailed,
    fetchedRegistryStaticsCard,
    FETCH_REGISTRY_NOTES_METRICS,
    fetchRegistryNotesMetricsInProgress,
    fetchedRegistryNotesMetrics,
    FETCH_CLINICAL_IMPROVEMENT_METRICS,
    fetchClinicalImprovementMetricsFailed,
    fetchedClinicalImprovementMetrics,
    fetchClinicalImprovementMetricsInProgress,
    fetchRegistryNotesMetricsFailed,
    fetchedRegistryClinicalConditions,
    fetchRegistryClinicalConditionsInProgress,
    fetchRegistryClinicalConditionsFailed,
    FETCH_REGISTRY_CLINICAL_CONDITIONS,
    FETCH_REGISTRY_BASELINE_METRICS,
    fetchRegistryBaselineMetricsInProgress,
    fetchRegistryBaselineMetricsFailed,
    fetchedRegistryBaselineMetrics,
    FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION,
    fetchRegistryClinicalConditionDistributionInProgress,
    fetchRegistryClinicalConditionDistributionFailed,
    fetchedRegistryClinicalConditionDistribution,
} from "../../actions/physician/dashboard.action";
import { DashboardFilters } from "../../../screens/dashboard/physician/PhysicianDashboardScreen";
import { appendFiltersToSearchParams } from "../../../shared/Utils";
import { ca, tr } from "date-fns/locale";

const roleIdMapping = {
    "Physician": "practiceId",
    "Counsellor": "therapistId",
    "Psychiatrist": "psychiatristId"
}

const apiFetchPatientsSummary = (action: { type: string, authState: AuthState, payload: { filters: DashboardFilters } }) => {
    const url = `/${action.authState.accountId}/dashboard?startDate=${action.payload.filters.startDate}&endDate=${action.payload.filters.endDate}`;
    const config = { headers: { "x-auth-token": action.authState.token } }
    return physicianHttpClient.get<Either<CheckedError, PatientSummaryResponseType>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patientsSummary: response.data.data as PatientSummaryResponseType })
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatientsSummaryByLocation = (action: { type: string, authState: AuthState, payload: { filters: DashboardFilters } }) => {
    const url = `/${action.authState.accountId}/dashboard/summary-by-location?startDate=${action.payload.filters.startDate}&endDate=${action.payload.filters.endDate}`;
    const config = { headers: { "x-auth-token": action.authState.token } }
    return physicianHttpClient.get<Either<CheckedError, AggregatePatientSummaryByLocation[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patientsSummaryByLocation: response.data.data as AggregatePatientSummaryByLocation })
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatientsScreenerCompletionRate = (action: { type: string, authState: AuthState, payload: { filters: DashboardFilters } }) => {
    const url = `/${action.authState.accountId}/dashboard/completion-rate-by-location?startDate=${action.payload.filters.startDate}&endDate=${action.payload.filters.endDate}`;
    const config = { headers: { "x-auth-token": action.authState.token } }
    return physicianHttpClient.get<Either<CheckedError, ScreenerCompletionRate[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patientsScreenerCompletionRate: response.data.data as ScreenerCompletionRate })
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchStaticsCard = (action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) => {
    const searchParams = new URLSearchParams()
    let filters = action.payload.filters

    appendFiltersToSearchParams(searchParams, filters)

    const url = `/${action.authState.accountId}/dashboard/registry-statics-cards?${searchParams.toString()}`;
    const config = { headers: { "x-auth-token": action.authState.token, "x-auth-role": action.authState.role }}
    return physicianHttpClient.get<Either<CheckedError, RegistryStaticsCardResponse>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as RegistryStaticsCardResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchNotesMetrics = (action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) => {
    const searchParams = new URLSearchParams()
    let filters = action.payload.filters

    appendFiltersToSearchParams(searchParams, filters)

    const url = `/${action.authState.accountId}/dashboard/registry-notes-metrics?${searchParams.toString()}`;
    const config = { headers: { "x-auth-token": action.authState.token, "x-auth-role": action.authState.role } }
    return physicianHttpClient.get<Either<CheckedError, RegistryNotesMetricsResponse>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as RegistryNotesMetricsResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiClinicalImprovmentsMetrics = (action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) => {
    const searchParams = new URLSearchParams()
    let filters = action.payload.filters

    appendFiltersToSearchParams(searchParams, filters)

    const url = `/${action.authState.accountId}/dashboard/registry-clinical-improvement-metrics?${searchParams.toString()}`;
    const config = { headers: { "x-auth-token": action.authState.token, "x-auth-role": action.authState.role } }
    return physicianHttpClient.get<Either<CheckedError, clinicalImprovementMetricsResponse>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as clinicalImprovementMetricsResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiRegistryPatientTreatmentMetrics = (action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) => {
    const searchParams = new URLSearchParams()
    let filters = action.payload.filters

    appendFiltersToSearchParams(searchParams, filters)

    const url = `/${action.authState.accountId}/dashboard/registry-baseline-metrics?${searchParams.toString()}`;
    const config = { headers: { "x-auth-token": action.authState.token, "x-auth-role": action.authState.role } }
    return physicianHttpClient.get<Either<CheckedError, RegistryPatientTreatmentMetricsResponse>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as RegistryPatientTreatmentMetricsResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchRegistryClinicalConditions = (action: { type: string, authState: AuthState }) => {
    const url = `/${action.authState.accountId}/dashboard/active-screeners`;
    const config = { headers: { "x-auth-token": action.authState.token, "x-auth-role": action.authState.role } }
    return physicianHttpClient.get<Either<CheckedError, ClinicalConditionsResponse[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as ClinicalConditionsResponse[])
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
        });
}

const apiFetchRegistryClinicalConditionDistribution = (action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) => {
    const searchParams = new URLSearchParams()
    let filters = action.payload.filters

    appendFiltersToSearchParams(searchParams, filters)

    const url = `/${action.authState.accountId}/dashboard/clinical-condition-distribution?${searchParams.toString()}`;
    const config = { headers: { "x-auth-token": action.authState.token, "x-auth-role": action.authState.role } }
    return physicianHttpClient.get<Either<CheckedError, RegistryClinicalConditionDistributionResponse[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as RegistryClinicalConditionDistributionResponse[])
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
        });
}


function* fetchPatientsSummary(action: { type: string, authState: AuthState, payload: { filters: DashboardFilters } }) {
    try {
        console.log(`DashboardSaga:fetchPatientsSummary`)
        yield put(callInProgress());
        yield put(fetchPatientsSummaryInProgress());
        const apiFetchPatientsSummaryResponse: Either<CheckedError, { patientsSummary: PatientSummaryResponseType }> = yield call(apiFetchPatientsSummary, action)
        if (apiFetchPatientsSummaryResponse.isLeft()) {
            const error = apiFetchPatientsSummaryResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientsSummaryFailed({ error: error.message }));
            } else {
                yield put(fetchPatientsSummaryFailed({ error: error.message }));
            }
        } else {
            const patientsSummary = apiFetchPatientsSummaryResponse.right().patientsSummary
            yield put(fetchedPatientsSummary(patientsSummary));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientsSummaryFailed({ error: "Error fetching patients summary!" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientsSummaryByLocation(action: { type: string, authState: AuthState, payload: { filters: DashboardFilters } }) {
    try {
        console.log(`DashboardSaga:fetchPatientsSummaryByLocation`)
        yield put(callInProgress())
        const apiFetchPatientsSummaryByLocResponse: Either<CheckedError, { patientsSummaryByLocation: AggregatePatientSummaryByLocation[] }> = yield call(apiFetchPatientsSummaryByLocation, action)
        if (apiFetchPatientsSummaryByLocResponse.isLeft()) {
            const error = apiFetchPatientsSummaryByLocResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientsSummaryByLocationFailed({ error: error.message }));
            } else {
                yield put(fetchPatientsSummaryByLocationFailed({ error: error.message }));
            }
        } else {
            const patientsSummaryByLocation = apiFetchPatientsSummaryByLocResponse.right().patientsSummaryByLocation
            yield put(fetchedPatientsSummaryByLocation(patientsSummaryByLocation));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientsSummaryByLocationFailed({ error: "Error fetching patients summary by location!" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientsScreenerCompletionRate(action: { type: string, authState: AuthState, payload: { filters: DashboardFilters } }) {
    try {
        console.log(`DashboardSaga:fetchPatientsScreenerCompletionRate`)
        yield put(callInProgress())
        const apiFetchPatientsScreenerCompletionRateResponse: Either<CheckedError, { patientsScreenerCompletionRate: ScreenerCompletionRate[] }> = yield call(apiFetchPatientsScreenerCompletionRate, action)
        if (apiFetchPatientsScreenerCompletionRateResponse.isLeft()) {
            const error = apiFetchPatientsScreenerCompletionRateResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientsScreenerCompletionRateFailed({ error: error.message }));
            } else {
                yield put(fetchPatientsScreenerCompletionRateFailed({ error: error.message }));
            }
        } else {
            const patientsScreenerCompletionRate = apiFetchPatientsScreenerCompletionRateResponse.right().patientsScreenerCompletionRate
            yield put(fetchedPatientsScreenerCompletionRate(patientsScreenerCompletionRate));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientsScreenerCompletionRateFailed({ error: "Error fetching patients screener completion rate!" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchRegistryStaticsCard(action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) {
    try {
        console.log(`DashboardSaga:fetchRegistryStaticsCard`)
        yield put(callInProgress());
        yield put(fetchRegistryStaticsCardInProgress());
        const apiFetchRegistryStaticsCardResponse: Either<CheckedError, RegistryStaticsCardResponse> = yield call(apiFetchStaticsCard, action)
        if (apiFetchRegistryStaticsCardResponse.isLeft()) {
            const error = apiFetchRegistryStaticsCardResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Registry Statics Card Encountered a Checked Error", error.message)
                yield put(fetchRegistryStaticsCardFailed({ error: error.message }));
            } else {
                yield put(fetchRegistryStaticsCardFailed({ error: error.message }));
            }
        } else {
            const patientsStaticsCardRes = apiFetchRegistryStaticsCardResponse.right()
            yield put(fetchedRegistryStaticsCard(patientsStaticsCardRes));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchRegistryStaticsCardFailed({ error: "Error fetching registry statics card!" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchRegistryNotesMetrics(action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) {
    try {
        yield put(callInProgress());
        yield put(fetchRegistryNotesMetricsInProgress());
        const apiFetchRegistryNotesMetricsResponse: Either<CheckedError, RegistryNotesMetricsResponse> = yield call(apiFetchNotesMetrics, action)
        if (apiFetchRegistryNotesMetricsResponse.isLeft()) {
            const error = apiFetchRegistryNotesMetricsResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Registry Notes Metrics Encountered a Checked Error", error.message)
                yield put(fetchRegistryNotesMetricsFailed({ error: error.message }));
            } else {
                yield put(fetchRegistryNotesMetricsFailed({ error: error.message }));
            }
        } else {
            const registryNotesMetrics = apiFetchRegistryNotesMetricsResponse.right()
            yield put(fetchedRegistryNotesMetrics(registryNotesMetrics));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchRegistryNotesMetricsFailed({ error: "Error fetching registry notes metrics!" }));
    }
    finally {
        yield put(callFinished())
    }

}

function* fetchClinicalImprovementMetrics(action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) {
    try {
        yield put(callInProgress());
        yield put(fetchClinicalImprovementMetricsInProgress());
        const apiFetchClinicalImprovementResponse: Either<CheckedError, clinicalImprovementMetricsResponse> = yield call(apiClinicalImprovmentsMetrics, action)
        if (apiFetchClinicalImprovementResponse.isLeft()) {
            const error = apiFetchClinicalImprovementResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Clinical Improvement Metrics Encountered a Checked Error", error.message)
                yield put(fetchClinicalImprovementMetricsFailed({ error: error.message }));
            } else {
                yield put(fetchClinicalImprovementMetricsFailed({ error: error.message }));
            }
        } else {
            const clinicalImprovementMetrics = apiFetchClinicalImprovementResponse.right()
            yield put(fetchedClinicalImprovementMetrics(clinicalImprovementMetrics));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchClinicalImprovementMetricsFailed({ error: "Error fetching registry notes metrics!" }));
    }
    finally {
        yield put(callFinished())
    }
}

function* fetchRegistryBaselineMetrics(action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) {
    try{
        yield put(callInProgress());
        yield put(fetchRegistryBaselineMetricsInProgress());
        const apiFetchRegistryBaselineMetricsResponse: Either<CheckedError, RegistryPatientTreatmentMetricsResponse> = yield call(apiRegistryPatientTreatmentMetrics, action)
        if (apiFetchRegistryBaselineMetricsResponse.isLeft()) {
            const error = apiFetchRegistryBaselineMetricsResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Registry Patients Treatment Metrics Encountered a Checked Error", error.message)
                yield put(fetchRegistryBaselineMetricsFailed({ error: error.message }));
            } else {
                yield put(fetchRegistryBaselineMetricsFailed({ error: error.message }));
            }
        } else {
            const baselineMetrics = apiFetchRegistryBaselineMetricsResponse.right()
            yield put(fetchedRegistryBaselineMetrics(baselineMetrics));
        }
    }
    catch(e){
        console.log(e)
        yield put(fetchRegistryBaselineMetricsFailed({ error: "Error fetching registry baseline metrics!" }));
    }
    finally {
        yield put(callFinished())
    }

}

function* fetchClinicalConditions(action: { type: string, authState: AuthState} ) {
    try {
        yield put(callInProgress());
        yield put(fetchRegistryClinicalConditionsInProgress());
        const apiFetchClinicalConditionsResponse: Either<CheckedError, ClinicalConditionsResponse[]> = yield call(apiFetchRegistryClinicalConditions, action)
        if (apiFetchClinicalConditionsResponse.isLeft()) {
            const error = apiFetchClinicalConditionsResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Clinical Conditions Encountered a Checked Error", error.message)
                yield put(fetchRegistryClinicalConditionsFailed({ error: error.message }));
            } else {
                yield put(fetchRegistryClinicalConditionsFailed({ error: error.message }));
            }
        }
        else {
            const clinicalConditions = apiFetchClinicalConditionsResponse.right()
            yield put(fetchedRegistryClinicalConditions(clinicalConditions));
        }
    }
    catch(e){
        console.log(e)
        yield put(fetchRegistryClinicalConditionsFailed({ error: "Error fetching clinical conditions!" }));
    }
    finally {
        yield put(callFinished())
    }
}

function* fetchRegistryClinicalConditionDistribution(action: { type: string, authState: AuthState, payload: { filters: IRegistryCocmPatientStatusFilter } }) {
    try {
        yield put(callInProgress());
        yield put(fetchRegistryClinicalConditionDistributionInProgress());
        const apiFetchRegistryClinicalConditionDistributionResponse: Either<CheckedError, RegistryClinicalConditionDistributionResponse[]> = yield call(apiFetchRegistryClinicalConditionDistribution, action)
        if (apiFetchRegistryClinicalConditionDistributionResponse.isLeft()) {
            const error = apiFetchRegistryClinicalConditionDistributionResponse.left()
            if (error.isChecked) {
                console.log("DashboardSaga: Clinical Condition Distribution Encountered a Checked Error", error.message)
                yield put(fetchRegistryClinicalConditionDistributionFailed({ error: error.message }));
            } else {
                yield put(fetchRegistryClinicalConditionDistributionFailed({ error: error.message }));
            }
        }
        else {
            const clinicalConditionDistribution = apiFetchRegistryClinicalConditionDistributionResponse.right()
            yield put(fetchedRegistryClinicalConditionDistribution(clinicalConditionDistribution));
        }
    }
    catch (e) {
        console.log(e)
        yield put(fetchRegistryClinicalConditionDistributionFailed({ error: "Error fetching registry clinical condition distribution!" }));
    }
    finally {
        yield put(callFinished())
    }
}

export default function* patientsDashboardSaga() {
    yield takeLatest(FETCH_DASHBOARD_PATIENTS_SUMMARY, fetchPatientsSummary)
    yield takeLatest(FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, fetchPatientsSummaryByLocation)
    yield takeLatest(FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE, fetchPatientsScreenerCompletionRate)
    yield takeLatest(FETCH_REGISTRY_STATICS_CARD, fetchRegistryStaticsCard)
    yield takeLatest(FETCH_REGISTRY_NOTES_METRICS, fetchRegistryNotesMetrics)
    yield takeLatest(FETCH_CLINICAL_IMPROVEMENT_METRICS, fetchClinicalImprovementMetrics)
    yield takeLatest(FETCH_REGISTRY_BASELINE_METRICS, fetchRegistryBaselineMetrics)
    yield takeLatest(FETCH_REGISTRY_CLINICAL_CONDITIONS, fetchClinicalConditions)
    yield takeLatest(FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION, fetchRegistryClinicalConditionDistribution)
}