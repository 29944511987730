import React, {PropsWithChildren} from "react";
import {useNavigate} from "react-router";
import {SideBarButtonProps, withActiveComponentTreatment, withIconStyling} from "./WithActiveComponentTreatment";

const CareCoordinatiorReferredPatientsButton: React.FC<PropsWithChildren<SideBarButtonProps> & {}> = ({style, navigateTo, iconRenderer}) => {
    const navigate = useNavigate()

    const navigateToProspects = () => {
        navigate(navigateTo)
    }

    function render() {
        return <div className="flex">
            <button
                className={style}
                onClick={navigateToProspects}>
                {iconRenderer("Referred Patients")}
            </button>
        </div>;
    }

    return render()
}

export default withActiveComponentTreatment(withIconStyling(CareCoordinatiorReferredPatientsButton))
