import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFlags } from "flagsmith/react";
import { RoutePaths } from "../../../../shared/Utils";
import { IPatient, IPotentialReferralPatientBasicDetails, IProspect } from "../../../../models";


const PATIENT_SUB_MENU_LIST = [
    {
        key: "patient_information",
        title: "Patient & Practice",
        link: RoutePaths.careCoordinator.ccReferredPatients.patientDetails.patientInformation
    },
    {
        key: 'screeners',
        title: 'Screeners',
        link: RoutePaths.careCoordinator.ccReferredPatients.patientDetails.screenerSummary
    },
    {
        key: 'insurance',
        title: 'Insurance',
        link: RoutePaths.careCoordinator.ccReferredPatients.patientDetails.insurance
    },
]

const ReferredPatientsSideBar: React.FC<{ patient?: IPatient }> = ({ patient }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const flags = useFlags(['cc_patient_insurance'])
    const isPatientInsuranceEnabled = flags.cc_patient_insurance.enabled
    const [practiceId, setPracticeId] = useState<string | null>(null)
    const [sourcePatientId, setSourcePatientId] = useState<string | null>(null)

    useEffect(() => {
        if(searchParams) {
            setPracticeId(searchParams.get('practiceId'))
            setSourcePatientId(searchParams.get('sourcePatientId'))
        }
    },[patient])



    const filteredSubMenuList = isPatientInsuranceEnabled
    ? PATIENT_SUB_MENU_LIST
    : PATIENT_SUB_MENU_LIST.filter((menu) => menu.key !== 'insurance');


    return (
        <div className={"patient-details-screen"}>
            <div className="patient-sub-menu-wrapper">
                <div className="patient-sub-menu-navigation">
                    <div className="patient-sub-menu-list">
                        {filteredSubMenuList.map((item) => {
                            return (
                                <NavLink to={`${item.link}?practiceId=${practiceId}&sourcePatientId=${sourcePatientId}`}
                                    className={`patient-sub-menu-item`}
                                    key={item?.key}>
                                    <span className="patient-sub-menu-item-title">{item.title}</span>
                                </NavLink>
                            )
                        })}
                    </div>
                </div>
                <div className="patient-sub-menu-route-outlet">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ReferredPatientsSideBar
