import React, {useCallback, useEffect, useState} from "react";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {DateTime} from "luxon";
import './TimePickerComponent.scss';
import { XCircleIcon, ClockIcon } from '@heroicons/react/20/solid';

interface TimePickerComponentProps {
    name?: string;
    value?: string;
    hasError?: boolean;
    placeholder?: string;
    use12Hours?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    onChange?: (value: string) => void;
}

const TimePickerComponent = (props: TimePickerComponentProps) => {

    const {
        disabled,
        fullWidth,
        hasError,
        onChange,
    } = props;

    const format = "hh:mm A";
    const placeholder = props.placeholder || 'Time';
    const [value, setValue] = useState<any>(props.value ? DateTime.fromJSDate(new Date(props.value)) : null);
    const use12Hours = props.use12Hours;
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);

    const handleChange = useCallback((newValue: any) => {
        setValue(newValue);
        if (newValue && newValue.hours() !== null && newValue.minutes() !== null) {
            if (onChange) {
                onChange(newValue && newValue.format(format));
            }
        } else {
            if (onChange) {
                onChange('');
            }
        }
    }, [onChange, format]);

    const handleTimePickerOpen = useCallback(() => {
        setIsTimePickerOpen(true);
        document.documentElement.style.overflow = "hidden"; // Prevent scrolling
    }, []);

    const handleTimePickerClose = useCallback(() => {
        setIsTimePickerOpen(false);
        document.documentElement.style.overflow = "auto"; // Restore scrolling
    }, []);

    useEffect(() => {
        if (props.value && DateTime.fromJSDate(new Date(props.value)).isValid) {
            setValue(DateTime.fromJSDate(new Date(props.value)));
        }
    }, [props.value, format]);

    return (
        <div
            className={`time-picker-component mt-[5px] ${fullWidth ? "fullWidth" : ''} ${disabled ? 'disabled' : ''} ${hasError ? 'has-error' : ''}`}>
            <div className={"time-picker-container"}>
                <TimePicker
                    placeholder={placeholder}
                    className={`time-picker-field`}
                    onChange={handleChange}
                    open={isTimePickerOpen}
                    onOpen={handleTimePickerOpen}
                    onClose={handleTimePickerClose}
                    value={value}
                    format={format}
                    showHour={true}
                    showMinute={true}
                    showSecond={false}
                    use12Hours={use12Hours}
                    clearIcon={<span>
                        <XCircleIcon height={20} className="time-picker-icon"/>
                    </span>}
                    clearText={"Clear"}
                    inputReadOnly
                    addon={() => <div className="time-picker-close" onClick={handleTimePickerClose}>
                        Apply
                    </div>
                    }
                />
                {!value &&
                    <ClockIcon height={20} className="time-picker-icon" onClick={handleTimePickerOpen}/>
                }
            </div>
        </div>
    );
};

export default TimePickerComponent;
